import { useEffect, useState } from "react";
import { CreditCard, Smartphone } from "react-feather";
import { parsePhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Loader from "../../../../../../components/common/Loader";
import { DisbursalCountrySelect } from "../../../../../../components/shared/disbursal-country/disbursal-country-select";
import SenderSelect from "../../../../../../components/shared/sender";
import { getRandomColor } from "../../../../../../helpers/utils";
import { useLocalPagination } from "../../../../../../hooks/use-local-paginaton";
import restService from "../../../../../../services/restService";
import Search from "../search";
import Institutions from "./institutions";
import { RecipientDetails } from "./recipient-details";
import { WalletType } from "./wallet-type";

const keys = ["firstname", "lastname", "account_number", "msisdn"];

export default function AddNewReceiver({
  onSubmitSuccess,
  countryId,
  senderId,
  initialStep = 3,
  disbursal_country,
}) {
  const [searchSender, setSearchSender] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState("");

  const { addToast } = useToasts();
  const user = useSelector((state) => state.auth?.user);

  const [isLoading, setIsLoading] = useState(false);
  const [disbursalCountry, setDisbursalCountry] = useState(disbursal_country);

  const { data, nextPage, handleSearch, setInitialItems } =
    useLocalPagination();

  const [apiErrors, setApiErrors] = useState({});
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    account_number: "",
    msisdn: "",
    country_id: countryId ?? "",
    destination_institution_id: "",
    sender_id: senderId ?? "",
    relationship: "Other",
  });

  const [currentStep, setCurrentStep] = useState(initialStep);

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errorList = { ...errors };
    keys.forEach((key) => {
      // TODO validate phone numbers
      if (!form[key]) {
        errorList[key] = "This field is required.";
      } else {
        delete errorList[key];
        if (key === "email") {
          const regex = /\S+@\S+\.\S+/;
          if (!regex.test(form[key])) {
            errorList[key] = "Enter a valid email.";
          }
        }
      }
    });
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };

  const handleSubmit = () => {
    const cid = form.country_id;
    const sid = form.sender_id;

    form.country_id = cid ?? countryId;
    form.sender_id = sid ?? senderId;

    if (validateForm()) {
      setIsLoading(true);
      let formData = { ...form };
      formData.contact_number = formData.msisdn;
      formData.agent_id = user.id;
      restService.ReceiversApi.addReceiver(formData, user.id)
        .then(({ data: { data } }) => {
          const res = {
            ...data,
            pivot: {
              ...(data?.pivot ?? {}),
              sender_relationship: formData.relationship,
            },
          };
          // setName(`${formData.firstname} ${formData.lastname}`);

          setForm({
            firstname: "",
            lastname: "",
            account_number: "",
            email: "",
            msisdn: "",
            address: "",
            relationship: "Other",
          });

          addToast("Receiver added successfully.", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });

          onSubmitSuccess?.(res);
        })
        .catch(({ response }) => {
          addToast(response?.data?.message ?? "Error occurred! Try again.", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });

          setIsLoading(false);

          const { data } = JSON.parse(JSON.stringify(response?.data ?? "{}"));
          setApiErrors(data);
        });
    }
  };

  async function getDestinationInstitutionsByWallet(wallet) {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await restService.UtilsApi.getDestinationInstitutionsByWallet(
        countryId,
        wallet
      );

      setInitialItems(
        data?.map((item, index) => ({
          ...item,
          bgColor: getRandomColor(index),
        }))
      );

      setCurrentStep(4);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const onFormChange = ({ target }) => {
    let details = { ...form };
    details[target.name] = target.value;
    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);

    setForm(details);
  };

  const onPhoneNumberChange = (value) => {
    const calling_code = parsePhoneNumber(String(value));

    setForm({
      ...form,
      calling_code: calling_code?.countryCallingCode ?? "",
      msisdn: value,
    });
  };

  useEffect(() => {
    if (!countryId && currentStep > 1) {
      setCurrentStep(1);
      return;
    }

    if (!senderId && currentStep > 2) {
      setCurrentStep(2);
      return;
    }
  }, [countryId, senderId]);

  return (
    <div className="flex flex-col gap-4">
      {isLoading && <Loader message={"Please wait..."} />}
      <div className="flex items-center justify-center">
        <h2 className="text-2xl text-theme-1 font-semibold">
          Add New Recipient
        </h2>
      </div>

      {currentStep === 1 && (
        <DisbursalCountrySelect
          setIsFetching={setIsLoading}
          onSelect={(country) => {
            setDisbursalCountry(country);
            setForm((prev) => ({
              ...prev,
              country_id: country?.id,
            }));
            setCurrentStep(2);
          }}
          onFetchSuccess={(res) => {
            if (res.length === 1) {
              setForm((prev) => ({
                ...prev,
                country_id: res[0]?.id,
              }));
              setCurrentStep(2);
              return;
            }
          }}
        />
      )}

      {currentStep === 2 && (
        <div className="flex intro-y flex-col gap-6">
          <div className="flex flex-col gap-4 items-center justify-center">
            <h2 className="text-base">Select a Sender</h2>

            <div
              style={{
                maxWidth: "300px",
              }}
              className="w-full"
            >
              <Search
                placeholder={"Search for sender...  "}
                onChange={(e) => setSearchSender(e.target.value)}
                value={searchSender}
              />
            </div>
          </div>
          <SenderSelect
            setIsFetching={setIsLoading}
            onSelect={(sender) => {
              setForm((prev) => ({
                ...prev,
                sender_id: sender?.id,
              }));
              setCurrentStep((prev) => prev + 1);
            }}
            searchValue={searchSender}
            selectedSenderId={form?.sender_id}
            onGoBack={() => setCurrentStep((prev) => prev - 1)}
          />
        </div>
      )}

      {currentStep === 3 && (
        <WalletType
          data={[
            { name: "Mobile Money", label: "wallet", icon: Smartphone },
            { name: "Bank Account", label: "bank", icon: CreditCard },
          ]}
          onSelect={(type) => {
            getDestinationInstitutionsByWallet(type);
            setSelectedAccountType(type);
          }}
        />
      )}

      {currentStep === 4 && (
        <Institutions
          handleSearch={handleSearch}
          nextPage={nextPage}
          data={data}
          goBack={() => setCurrentStep((prev) => prev - 1)}
          onSelect={(institution) => {
            setForm((prev) => ({
              ...prev,
              destination_institution_id: institution?.id,
            }));
            setCurrentStep(5);
          }}
        />
      )}

      {currentStep === 5 && (
        <div>
          <RecipientDetails
            onFormChange={onFormChange}
            goBack={() => setCurrentStep((prev) => prev - 1)}
            data={form}
            errors={errors}
            onPhoneNumberChange={onPhoneNumberChange}
            onSubmit={handleSubmit}
            apiErrors={apiErrors}
            setForm={setForm}
            disbursal_country={disbursalCountry}
            selectedAccountType={selectedAccountType}
          />
        </div>
      )}
    </div>
  );
}
