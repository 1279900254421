import styled from 'styled-components';
import tw from 'twin.macro'


const TopNavStyles = styled.div.attrs({
  className : "top-bar"
})`
  & {
      .breadcrumb{
          ${tw `mr-auto hidden sm:flex`}
        }
      .search-div{
        ${tw `relative mr-3 sm:mr-6`}
      }

        td{
          .name{
            ${tw`font-medium whitespace-nowrap`},
          }
          .id{
            ${tw`text-gray-600 text-xs whitespace-nowrap mt-0.5`}
          }
        }

        .balance{
            padding:0 ;
          }
      }
  }
`
export default TopNavStyles;
