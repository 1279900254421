import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import restService from "../../services/restService";

const activeClass =
  "text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none text-blue-500 border-b-2 font-medium border-blue-500";
const inactiveClass =
  "text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none";
const moment = require("moment");

const IdentificationForm = ({initCallback, handleSubmit, data}) => {
  const [types, setTypes] = useState([]);
  const [form, setForm] = useState({
    ...data,
    id_type: "",
    expiry_date: new Date().setDate(new Date().getDate() + 2),
    date_issued: new Date().setDate(new Date().getDate() - 2),
    id_category: "FULL IMAGE",
    id_number: "",
    id_full_image: {preview: "", raw: ""},
    id_front_image: {preview: "", raw: ""},
    id_back_image: {preview: "", raw: ""},
    type: "full",
  });

  const [proofOfAddress, setProofOfAddress] = useState({
    image: {preview: "", raw: ""},
  });
  const [errors, setErrors] = useState({});
  const switchTab = (val) => {
    setForm({...form, type: val});
    if (val !== "full") {
      delete errors["id_full_image"];
    } else {
      delete errors["id_front_image"];
      delete errors["id_back_image"];
    }
  };

  const handleChange = ({target}) => {
    const details = {...form};
    if (
      target.name === "id_full_image" ||
      target.name === "id_front_image" ||
      target.name === "id_back_image"
    ) {
      if (target.files.length) {
        details[target.name] = {
          preview: URL.createObjectURL(target.files[0]),
          raw: target.files[0],
        };
      }
    } else if (target.name === "proof") {
      details[target.name] = {
        image: {
          preview: URL.createObjectURL(target.files[0]),
          raw: target.files[0],
        },
      };
      setProofOfAddress({
        image: {
          preview: URL.createObjectURL(target.files[0]),
          raw: target.files[0],
        },
      });
    } else {
      details[target.name] = target.value;
      setForm({...form, [target.name]: target.value});
    }
    setForm(details);
    delete errors[target.name];
  };

  const validate = () => {
    let errorList = {};
    if (!form.id_number || form.id_number === "") {
      errorList.id_number = "Please enter an identification number";
    }
    if (form.type === "full" && !form.id_full_image.raw) {
      errorList.id_full_image = "Please upload a full image";
    }
    if (form.type !== "full" && !form.id_front_image.raw) {
      errorList.id_front_image = "Please upload a front image";
    }
    if (form.type !== "full" && !form.id_back_image.raw) {
      errorList.id_back_image = "Please upload a back image";
    }
    if (!proofOfAddress.image.raw) {
      errorList.proof = "Please upload a proof of address";
    }

    setErrors(errorList);

    return Object.keys(errorList).length === 0;
  };
  const save = () => {
    if (validate()) {
      //is valid
      let formData = {...form};
      formData.msisdn = form.calling_code + form.msisdn;
      if (form.id_full_image) {
        formData.id_full_image = form.id_full_image.raw;
        formData.category = "Full Image";
        // delete formData.id_back_image;
        // delete formData.id_front_image;

        formData.id_front_image = form.id_full_image.raw;
        formData.id_back_image = form.id_full_image.raw;
      } else {
        formData.id_front_image = form.id_front_image.raw;
        formData.id_back_image = form.id_back_image.raw;
        delete formData.id_full_image;
      }
      formData.proof_of_address = form.proof.image.raw;
      formData.date_issued = moment(form.date_issued).format("YYYY-MM-DD");
      formData.expiry_date = moment(form.expiry_date).format("YYYY-MM-DD");
      handleSubmit(formData);
    }
  };

  useEffect(() => {
    restService.UtilsApi.getIdentificationConfigs()
      .then(({data}) => {
        if (data.data.types) {
          let type = "";
          setForm({...form, id_type: data.data.types[0]});
          setTypes(data.data.types);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);

  useEffect(() => {
    initCallback(save);
  }, [form]);

  const changeDateIssued = (date) => {
    setForm({...form, date_issued: date});
  };

  const changeExpiryDate = (date) => {
    setForm({...form, expiry_date: date});
  };
  return (
    <>
      <div className="px-5 ">
        <div className="font-medium text-center text-lg">
          Add Identification
        </div>
        <div className="bg-white">
          <div className="m-auto w-fit">
            <nav className="flex flex-col sm:flex-row">
              <button
                onClick={() => switchTab("full")}
                className={form.type === "full" ? activeClass : inactiveClass}
              >
                Full Picture
              </button>
              <button
                onClick={() => switchTab("FaB")}
                className={form.type !== "full" ? activeClass : inactiveClass}
              >
                Front & Back
              </button>
            </nav>
          </div>
        </div>
      </div>
      <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5">
        <form className="w-full max-w-3xl m-auto">
          <div className="px-3">
            <h1 className="block uppercase tracking-wide text-gray-700 text-xl font-bold mb-2">
              Full Image
            </h1>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="type"
                >
                  Identification Type
                </label>
                <div className="relative">
                  <select
                    id="type"
                    name="id_type"
                    className="block appearance-none w-full bg-gray-200 border
                                        border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
                                        focus:bg-white focus:border-gray-500"
                    onChange={handleChange}
                  >
                    {types.map((type) => {
                      return <option value={type}>{type}</option>;
                    })}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                  {errors.type && (
                    <p className="text-red-500 text-xs italic">{errors.type}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="type"
                >
                  Identification Number
                </label>
                <input
                  id="input-wizard-1"
                  type="text"
                  value={form.id_number}
                  onChange={handleChange}
                  name="id_number"
                  className="form-control"
                  placeholder="GH22333434332"
                />
                <p className="text-red-500 text-xs italic pt-1">
                  {errors.id_number}
                </p>
              </div>
            </div>
            <div className="w-full mt-2 md:w-1/2 px-3 mb-6 md:mb-0">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="type"
                >
                  Date Issued
                </label>
                <div className="relative">
                  <DatePicker
                    selected={form.date_issued}
                    onChange={changeDateIssued}
                    maxDate={new Date().setDate(new Date().getDate() - 2)}
                    selectsStart
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    dateFormat="yyyy-MM-dd"
                    className="w-full block appearance-none w-full bg-gray-200 border
                                                border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
                                                focus:bg-white focus:border-gray-500"
                  />
                </div>
              </div>
            </div>
            <div className="w-full mt-2 md:w-1/2 px-3 mb-6 md:mb-0">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="type"
                >
                  Expiry Date
                </label>
                <div className="relative">
                  <DatePicker
                    selected={form.expiry_date}
                    onChange={changeExpiryDate}
                    minDate={new Date().setDate(new Date().getDate() + 2)}
                    selectsStart
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    dateFormat="yyyy-MM-dd"
                    className="w-full block appearance-none w-full bg-gray-200 border
                                                border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
                                                focus:bg-white focus:border-gray-500"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full mt-4 px-3 mb-6 md:mb-0">
              <div className="w-full px-3 mb-6 md:mb-0">
                <div className="flex  flex-wrap m-auto">
                  {form.type === "full" && (
                    <label htmlFor="upload-full-button">
                      {form.id_full_image.preview ? (
                        <figure>
                          <img
                            src={form.id_full_image.preview}
                            className="h-36 w-36 m-auto image-preview"
                            alt="id"
                          />
                          {/*<figcaption className="text-center">Hi</figcaption>*/}
                        </figure>
                      ) : (
                        <>
                          <span className="fa-stack fa-2x mt-3 mb-2">
                            <i className="fas fa-circle fa-stack-2x" />
                            <i className="fas fa-store fa-stack-1x fa-inverse" />
                          </span>
                          <div className="m-auto">
                            <span className="ml-auto btn btn-success upload-id text-center">
                              Upload Full Image
                            </span>
                          </div>
                        </>
                      )}
                    </label>
                  )}
                  {form.type !== "full" && (
                    <div className="w-full md:w-1/2">
                      <label htmlFor="upload-front-button">
                        {form.id_front_image.preview ? (
                          <figure>
                            <img
                              src={form.id_front_image.preview}
                              className="h-36 w-36 m-auto image-preview"
                              alt="id"
                            />
                            <figcaption className="text-center">
                              Front Image
                            </figcaption>
                          </figure>
                        ) : (
                          <>
                            <span className="fa-stack fa-2x mt-3 mb-2">
                              <i className="fas fa-circle fa-stack-2x" />
                              <i className="fas fa-store fa-stack-1x fa-inverse" />
                            </span>
                            <div className="m-auto">
                              <span className="ml-auto btn btn-success upload-id text-center">
                                Upload Front Image
                              </span>
                            </div>
                          </>
                        )}
                      </label>
                    </div>
                  )}
                  {form.type !== "full" && (
                    <div className="md:w-1/2 w-full">
                      <label htmlFor="upload-back-button">
                        {form.id_back_image.preview ? (
                          <figure>
                            <img
                              src={form.id_back_image.preview}
                              className="h-36 w-36 m-auto image-preview"
                              alt="id"
                            />
                            <figcaption className="text-center">
                              Back Image
                            </figcaption>
                          </figure>
                        ) : (
                          <>
                            <span className="fa-stack fa-2x mt-3 mb-2">
                              <i className="fas fa-circle fa-stack-2x" />
                              <i className="fas fa-store fa-stack-1x fa-inverse" />
                            </span>
                            <div className="m-auto">
                              <span className="ml-auto btn btn-success upload-id text-center">
                                Upload Back Image
                              </span>
                            </div>
                          </>
                        )}
                      </label>
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    id="upload-full-button"
                    name="id_full_image"
                    style={{display: "none"}}
                    onChange={handleChange}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    id="upload-front-button"
                    name="id_front_image"
                    style={{display: "none"}}
                    onChange={handleChange}
                  />
                  <input
                    accept="image/*"
                    type="file"
                    id="upload-back-button"
                    name="id_back_image"
                    style={{display: "none"}}
                    onChange={handleChange}
                  />
                </div>
                <p className="text-red-500 text-xs italic">
                  {errors.id_full_image}
                </p>
                <p className="text-red-500 text-xs italic">
                  {errors.id_front_image}
                </p>
                <p className="text-red-500 text-xs italic">
                  {errors.id_back_image}
                </p>
              </div>
            </div>
          </div>
        </form>

        <form className="w-full max-w-3xl m-auto">
          <div className="px-3">
            <h1 className="block uppercase tracking-wide text-gray-700 text-xl font-bold mb-2">
              Proof of Address
            </h1>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full mt-4 px-3 mb-6 md:mb-0">
              <div className="w-full px-3 mb-6 md:mb-0">
                <div className="flex  flex-wrap m-auto">
                  <label htmlFor="upload-proof-button">
                    {proofOfAddress.image.preview ? (
                      <figure>
                        <img
                          src={proofOfAddress.image.preview}
                          className="h-36 w-36 m-auto image-preview"
                          alt="id"
                        />
                      </figure>
                    ) : (
                      <>
                        <span className="fa-stack fa-2x mt-3 mb-2">
                          <i className="fas fa-circle fa-stack-2x" />
                          <i className="fas fa-store fa-stack-1x fa-inverse" />
                        </span>
                        <div className="m-auto">
                          <span className="ml-auto btn btn-success upload-id text-center">
                            Upload Proof of Address
                          </span>
                        </div>
                      </>
                    )}
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    id="upload-proof-button"
                    name="proof"
                    style={{display: "none"}}
                    onChange={handleChange}
                  />
                </div>
                <p className="text-red-500 text-xs italic pt-1">
                  {errors.proof}
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default IdentificationForm;
