import React from 'react';
import LoaderStyles from "./styles";


const Loader = ({message}) => {
  return (
    <LoaderStyles>
      <div className="spinner" />
      {message &&
      <div className="message">{message}</div>
      }
    </LoaderStyles>
  );
};


export default Loader;
