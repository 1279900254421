import styled from 'styled-components'
import {colors, functions, properties, snippets} from "../../../constants";

const LoaderStyles = styled.div`
  & ~ * {
    filter: blur(4px);
  }
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  max-width: none !important;
  width: 100vw;
  background-color: ${functions.rgba(colors.primary, 0.1)};
  z-index: ${properties.highestZIndex};
  ${snippets.flexCenter};
  .spinner {
    width: 5rem;
    height: 5rem;
    border: 5px solid ${functions.rgba(colors.primary, 0.5)};
    border-top-color: ${colors.primary};
    border-radius: 50%;
    animation: spin 0.3s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .message {
    margin-top: 0.5rem;
  }
`

export default LoaderStyles
