import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Badge, Empty, Pagination} from "antd";
import Search from "../../../components/common/Search";
import RestService from "../../../services/restService";
import Pusher from "pusher-js";
import {useSelector} from "react-redux";
import PaginationComponent from "../../../components/common/Pagination";
import AddNewAgent from "../../../components/common/AddNewAgent";

const Agents = () => {
  const agent = useSelector((state) => state.auth.user);
  const [agentList, setAgentList] = useState([]);
  const [pageTo, setPageTo] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [from, setFrom] = useState(0);
  const [showAddNewAgent, setShowAddNewAgent] = useState(false);

  const setTopUpCallback = (data) => {
    let user = {...agent};
    user.balance_available = data.message?.model.balance_available;
    user.actual_balance = data.message?.model.actual_balance;
    let indexOf = -1;
    agentList.forEach((a, index) => {
      if (a.id === data.message?.model.id) {
        indexOf = index;
      }
    });

    if (indexOf >= 0) {
      agentList[indexOf] = user;
      setAgentList(agentList);
    }
  };

  const setDebtCallback = (data) => {
    let user = {...agent};
    user.balance_owed = data.message?.model.balance_owed;
    let indexOf = -1;
    agentList.forEach((a, index) => {
      if (a.id === data.message?.model.id) {
        indexOf = index;
      }
    });

    if (indexOf >= 0) {
      agentList[indexOf] = user;
      setAgentList([...agentList]);
    }
  };

  const fetchAgents = (page) => {
    RestService.AgentApi.getAgentsByAgency(agent?.agency?.id, page).then(
      ({data}) => {
        setAgentList(data?.data?.data);
        setPageTo(data?.data?.to);
        setTotalEntries(data?.data?.total);
        setFrom(data?.data?.from);
      }
    );
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  useEffect(() => {
    if (agentList.length > 0) {
      const pusher = new Pusher(
          process.env.REACT_APP_API_ENVIRONMENT === "dev"
              ? process.env.REACT_APP_PUSHER_KEY_DEVELOPMENT:
              process.env.REACT_APP_API_ENVIRONMENT === "staging" ?
                  process.env.REACT_APP_PUSHER_KEY_STAGING
                  : process.env.REACT_APP_PUSHER_KEY_PRODUCTION,
        {
          cluster: "eu",
          forceTLS: true,
        }
      );
      const channel = pusher.subscribe(
        `funding-agency-${agent?.agency?.id}-agent-${agent?.id}`
      );
      channel.bind("agent-debt-reduction", setDebtCallback);

      channel.bind("agent-evalue-topup", setTopUpCallback);
    }
  }, [agentList]);

  const searchAgentByInput = async (input) => {
    const response = await RestService.AgentApi.getAgentBySearchTerm(input);
    const agents = await response?.data?.data?.data;
    setAgentList(agents);
  };

  const pageChange = (page) => {
    setCurrentPage(page);
    fetchAgents(page);
  };

  return (
    <>
      <div className="grid  mt-[5rem] grid-cols-12 gap-6 modal-active">
        <div className="intro-y col-span-12 flex w-full justify-between flex-wrap md:flex-row-reverse sm:flex-nowrap items-center mt-2">
          <button
            onClick={() => setShowAddNewAgent(true)}
            className="btn btn-primary-2 gap-1 items-center shadow-md mr-2"
          >
            <span>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.98389 1.24805C6.24188 1.24805 6.45103 1.45719 6.45103 1.71519V5.45229H10.1881C10.4461 5.45229 10.6553 5.66144 10.6553 5.91943C10.6553 6.17743 10.4461 6.38657 10.1881 6.38657H6.45103V10.1237C6.45103 10.3817 6.24188 10.5908 5.98389 10.5908C5.72589 10.5908 5.51675 10.3817 5.51675 10.1237V6.38657H1.77964C1.52165 6.38657 1.3125 6.17743 1.3125 5.91943C1.3125 5.66144 1.52165 5.45229 1.77964 5.45229H5.51675V1.71519C5.51675 1.45719 5.72589 1.24805 5.98389 1.24805Z"
                  fill="black"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span>Add Agent</span>
          </button>

          <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
              <Search
                placeholder={"Search Agent"}
                onChange={(e) => searchAgentByInput(e.target.value)}
              />
            </div>
          </div>
        </div>

        {agentList && agentList.length <= 0 && (
          <div className="font-bold justify-center h-[55vh] flex items-center  col-span-12">
            <Empty />
          </div>
        )}
        {agentList.map((agent, index) => {
          const label = agent.agent_role?.name;
          return (
            <div className="intro-y col-span-12 md:col-span-6 lg:col-span-4">
              <Badge.Ribbon
                size="default"
                text={`Available : ${agent.agency?.country?.currency_symbol}${agent.balance_available}`}
                color="#2db7f5"
              >
                <div className="box">
                  <div className="flex items-start px-5 pt-5">
                    <div className="w-full flex flex-col lg:flex-row items-center">
                      <div className="w-16 h-16 image-fit">
                        <img
                          alt="#"
                          className="rounded-full"
                          src="https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png"
                        />
                      </div>
                      <div className="lg:ml-4 text-center lg:text-left mt-3 lg:mt-0">
                        <Link
                          to={`/agent/${agent.id}`}
                          className="font-medium"
                        >{`${agent.firstname} ${agent.lastname}`}</Link>
                        <div className="text-gray-600 text-xs mt-0.5">
                          {agent.agency?.name}
                        </div>
                        <div
                          className={
                            "text-xs mt-0.5 " +
                            (label === "Agent"
                              ? "text-green-400"
                              : "text-red-400")
                          }
                        >
                          {" "}
                          {label}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center lg:text-left p-5">
                    <div className="flex items-center justify-center lg:justify-start text-gray-600 mt-1">
                      Actual Balance : {agent.agency?.country?.currency_symbol}
                      {agent.actual_balance}
                    </div>
                    {/*<div className="flex items-center justify-center lg:justify-start text-gray-600 mt-1">*/}
                    {/*  Amount Owed : {agent.agency?.country?.currency_symbol}*/}
                    {/*  {agent.balance_owed}*/}
                    {/*</div>*/}
                  </div>
                  <div className="text-center justify-end flex flex-col md:flex-row  lg:text-right p-3 border-t border-gray-200 ">
                    <div className="flex mr-2 sm:mt-2">
                      
                      <Link
                        to={`/statement/agent/${agent.id}`}
                        className="btn font-medium"
                      >
                        View Statement
                      </Link>
                    </div>
                    <div className="flex mr-2 sm:mt-2">
                      <Link
                        to={`/commissions/agent/${agent.id}`}
                        className="btn font-medium"
                      >
                        View Commissions
                      </Link>
                    </div>
                    <div className="flex mr-2 sm:mt-2">
                      <Link
                        to={`/agent/${agent.id}`}
                        className="btn font-medium"
                      >
                        View Profile
                      </Link>
                    </div>
                  </div>
                </div>
              </Badge.Ribbon>

              {/*<AgentCard key={index} agent={agent} />*/}
            </div>
          );
        })}
      </div>
      <div className="flex flex-col mb-[10vh] gap-4 justify-between md:items-center md:flex-row mt-8">
        <div className="page text-[#999999] text-xs">
          Showing {from} to {pageTo} of {totalEntries} entries
        </div>

        <div className="mt-3  flex justify-end float-right">
          <PaginationComponent
            current={currentPage}
            defaultCurrent={currentPage}
            showSizeChanger={false}
            onChange={pageChange}
            total={totalEntries}
          />
        </div>
      </div>

      {showAddNewAgent && (
        <AddNewAgent
          show={showAddNewAgent}
          setShow={setShowAddNewAgent}
          refetchData={fetchAgents}
        />
      )}
    </>
  );
};
export default Agents;
