/** Action types **/

export const SET_USER = "SET_USER";
export const SET_AGENCY = "SET_AGENCY";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGGED_OUT_DUE_TO_INACTIVITY = "LOGGED_OUT_DUE_TO_INACTIVITY";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
