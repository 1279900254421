/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from "react";
import {Chart, registerables} from "chart.js";
import "chart.js/auto";
import DateRangePicker from "../../../components/common/DateRange";
import chartConfig from "../../../assets/static_json/chartsConfig.json";
import "react-datepicker/dist/react-datepicker.css";
import {useSelector} from "react-redux";
import SENDERS_ICON from "../../../assets/images/icons/senders.svg";
import CREDIT_CARD_ICON from "../../../assets/images/icons/creditCard.svg";
import WALLET_ICON from "../../../assets/images/icons/wallet.svg";
import RECEIVERS_ICON from "../../../assets/images/icons/receivers.svg";
import AGENTS_ICON from "../../../assets/images/icons/agents.svg";
import DashboardCard from "../../../components/common/DashboardCard";
// import LineChart from "./LineChart";
import ApexChartComponent from "../../../components/Charts/ApexChartComponent";
import {addDays} from "date-fns";

Chart.register(...registerables);

const Dashboard = () => {
  //Chart Refs
  const agentsChart = useRef(null);
  const transactionsChart = useRef(null);
  const transactionsStatusChart = useRef(null);
  const sendersChart = useRef(null);
  const receiversChart = useRef(null);
  const datePicker = useRef(null);

  const transactionsChartConfig = chartConfig.transactionsChartConfig;
  const agentsPieChart = chartConfig.agentsPieChartConfig;
  const transactionsStatusChartConfig =
    chartConfig.transactionsStatusChartConfig;
  const senderChartConfig = chartConfig.sendersChart;
  const receiversChartConfig = chartConfig.receiversChart;

  const user = useSelector((state) => state.auth.user);

  const [agentsChartInstance, setAgentsChartInstance] = useState(null);
  const [transactionsChartInstance, setTranactionsChartInstance] =
    useState(null);
  const [transactionsStatusChartInstance, setTranactionsStatusChartInstance] =
    useState(null);
  const [sendersInstance, setSendersInstance] = useState(null);
  const [receiversInstance, setReceiversInstance] = useState(null);
  const [showDateRange, setShowDateRange] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (agentsChart && agentsChart.current) {
      const newChartInstance = new Chart(
        agentsChart.current.getContext("2d"),
        agentsPieChart
      );
      setAgentsChartInstance(newChartInstance);
    }

    if (transactionsChart && transactionsChart.current) {
      const newChartInstance = new Chart(
        transactionsChart.current.getContext("2d"),
        transactionsChartConfig
      );
      setTranactionsChartInstance(newChartInstance);
    }

    if (transactionsStatusChart && transactionsStatusChart.current) {
      const newChartInstance = new Chart(
        transactionsStatusChart.current.getContext("2d"),
        transactionsStatusChartConfig
      );
      setTranactionsStatusChartInstance(newChartInstance);
    }

    if (sendersChart && sendersChart.current) {
      const newChartInstance = new Chart(
        sendersChart.current.getContext("2d"),
        senderChartConfig
      );
      setSendersInstance(newChartInstance);
    }

    if (receiversChart && receiversChart.current) {
      const newChartInstance = new Chart(
        receiversChart.current.getContext("2d"),
        receiversChartConfig
      );
      setReceiversInstance(newChartInstance);
    }
  }, [transactionsChart]);

  const [activeAgentsZoomOption, setActiveAgentsZoomOption] = useState({
    label: "1D",
    type: "day",
  });
  const [activeVol1ZoomOption, setActiveVol1ZoomOption] = useState({
    label: "1D",
    type: "day",
  });
  const [activeVol2ZoomOption, setActiveVol2ZoomOption] = useState({
    label: "1D",
    type: "day",
  });

  const zoomOptions = [
    {label: "1D", type: "day"},
    {label: "1W", type: "week"},
    {label: "1M", type: "month"},
    {label: "3M", type: "month"},
    {label: "6M", type: "month"},
    {label: "YTD", type: "year"},
  ];

  const sample = [
    [Date.now() - 7 * 24 * 60 * 60 * 1000, 750],
    [Date.now() - 6 * 24 * 60 * 60 * 1000, 80],
    [Date.now() - 5 * 24 * 60 * 60 * 1000, 850],
    [Date.now() - 4 * 24 * 60 * 60 * 1000, 90],
    [Date.now() - 3 * 24 * 60 * 60 * 1000, 900],
    [Date.now() - 2 * 24 * 60 * 60 * 1000, 100],
    [Date.now() - 1 * 24 * 60 * 60 * 1000, 130],
    [Date.now() - 0 * 24 * 60 * 60 * 1000, 5],
    [Date.now() - 365 * 24 * 60 * 60 * 1000, 90],
    [Date.now() - 335 * 24 * 60 * 60 * 1000, 2],
    [Date.now() - 300 * 24 * 60 * 60 * 1000, 2],
    [Date.now() - 265 * 24 * 60 * 60 * 1000, 3],
    [Date.now() - 230 * 24 * 60 * 60 * 1000, 7],
    [Date.now() - 195 * 24 * 60 * 60 * 1000, 66],
    [Date.now() - 160 * 24 * 60 * 60 * 1000, 0],
    [Date.now() - 125 * 24 * 60 * 60 * 1000, 4],
    [Date.now() - 90 * 24 * 60 * 60 * 1000, 0],
    [Date.now() - 55 * 24 * 60 * 60 * 1000, 10],
    [Date.now() - 20 * 24 * 60 * 60 * 1000, 23],
  ];

  const ZoomOptions = ({action, state}) => {
    return (
      <ul className="flex items-center text-[#8C8C8C]  text-xs justify-center w-full gap-4 md:gap-6">
        {zoomOptions.map((x, i) => (
          <li
            key={i}
            onClick={() => {
              action(x);
            }}
            className={
              state.label === x.label
                ? "bg-[#CDD5FF] px-2 py-1 cursor-pointer rounded-md "
                : "px-2 py-1 cursor-pointer rounded-md "
            }
          >
            {x.label}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      {/* <PageTitle title={`Dashboard`} /> */}
      <div className="grid  grid-cols-12 gap-6 my-[5rem]">
        <div className="col-span-12 xxl:col-span-12">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 mt-8">
              <div className=" flex items-center h-10">
                <h2 className="text-lg font-medium truncate mr-5">Overview</h2>
                <div className="ml-auto flex items-center">
                  <DateRangePicker
                    range={dateRange}
                    show={showDateRange}
                    setShow={setShowDateRange}
                    setRange={setDateRange}
                  />
                </div>
              </div>

              <div className="grid grid-cols-12 intro-y gap-6 mt-5">
                <div className="xxl:col-span-12  col-span-12 gap-6 ">
                  <div className="col-span-12  gap-6 grid grid-cols-12">
                    <div className="col-span-12 sm:col-span-6 xl:col-span-3 lg:col-span-3 ">
                      <DashboardCard
                        containerStyle={" zoom-in"}
                        icon={CREDIT_CARD_ICON}
                        isTrendingUp={true}
                        value={"22,500"}
                        label={"Transactions Processed"}
                        trendingValue={"12%"}
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6 xl:col-span-3 lg:col-span-3">
                      <DashboardCard
                        containerStyle={"zoom-in"}
                        icon={WALLET_ICON}
                        isTrendingUp={false}
                        value={"22,500"}
                        label={"Total Transaction Amount"}
                        trendingValue={"12%"}
                        currency={"$"}
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6 xl:col-span-2 lg:col-span-2">
                      <DashboardCard
                        containerStyle={"zoom-in"}
                        icon={AGENTS_ICON}
                        isTrendingUp={true}
                        value={"213"}
                        label={"Total Agents"}
                        trendingValue={"12%"}
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6 xl:col-span-2 lg:col-span-2">
                      <DashboardCard
                        containerStyle={"zoom-in"}
                        icon={RECEIVERS_ICON}
                        isTrendingUp={true}
                        value={"213"}
                        label={"Total Recievers"}
                        trendingValue={"12%"}
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6 xl:col-span-2 lg:col-span-2">
                      <DashboardCard
                        containerStyle={"zoom-in"}
                        icon={SENDERS_ICON}
                        isTrendingUp={false}
                        value={"213"}
                        label={"Total Senders"}
                        trendingValue={"1%"}
                      />
                    </div>
                  </div>

                  {/* transaction */}
                  <div className=" grid grid-cols-12  gap-4 col-span-12 lg:col-span-6 mt-8">
                    <div className="col-span-12  px-4 py-5 border bg-white border-[#DEDEDE] rounded-md md:col-span-5 w-full">
                      <div className=" block sm:flex items-center h-10 ">
                        <h2 className="text-base text-black font-medium truncate mr-5">
                          Transaction Volume
                        </h2>
                      </div>
                      <div className="intro-y mt-12 sm:mt-5">
                        <ZoomOptions
                          action={(x) => {
                            setActiveVol1ZoomOption(x);
                          }}
                          state={activeVol1ZoomOption}
                        />
                        <ApexChartComponent
                          series={[
                            {
                              name: "Transaction",
                              data: sample.sort((a, b) => a[0] - b[0]),
                            },
                          ]}
                          colors={["#0042C6"]}
                          type={"area"}
                          showToolbar={false}
                          activeZoomOption={activeVol1ZoomOption}
                        />
                      </div>
                    </div>

                    <div className="col-span-12  px-4 py-5 border bg-white border-[#DEDEDE] rounded-md md:col-span-5">
                      <div className=" block sm:flex items-center h-10 ">
                        <h2 className="text-base text-black font-medium truncate mr-5">
                          Transaction Volume
                        </h2>
                      </div>

                      <div className="intro-y mt-12 sm:mt-5">
                        <ZoomOptions
                          action={(x) => {
                            setActiveVol2ZoomOption(x);
                          }}
                          state={activeVol2ZoomOption}
                        />
                        <ApexChartComponent
                          series={[
                            {
                              name: "Transaction",
                              data: sample.sort((a, b) => a[0] - b[0]),
                            },
                          ]}
                          colors={["#0042C6"]}
                          type={"area"}
                          showToolbar={false}
                          activeZoomOption={activeVol2ZoomOption}
                        />
                      </div>
                    </div>
                    <div className="col-span-12  px-4 py-5 border bg-white border-[#DEDEDE] rounded-md md:col-span-2">
                      <div className=" items-center justify-center">
                        <div className="intro-y text-center">
                          <h2 className="text-base font-normal">
                            Transactions Status
                          </h2>
                        </div>
                        <div className="intro-y  p-5 ">
                          <canvas
                            ref={transactionsStatusChart}
                            className="mt-3"
                            // height="300"
                          ></canvas>
                          <div className="mt-8">
                            <div className="flex items-center">
                              <div className="w-2 h-2 bg-theme-1 rounded-full mr-3"></div>
                              <span className="truncate">Successful</span>
                              <div className="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                              <span className="font-medium xl:ml-auto">
                                62%
                              </span>
                            </div>
                            <div className="flex items-center mt-1">
                              <div className="w-2 h-2 bg-theme-12 rounded-full mr-3"></div>
                              <span className="truncate">Pending</span>
                              <div className="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                              <span className="font-medium xl:ml-auto">
                                33%
                              </span>
                            </div>
                            <div className="flex items-center mt-1">
                              <div className="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                              <span className="truncate">Failed</span>
                              <div className="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                              <span className="font-medium xl:ml-auto">
                                10%
                              </span>
                            </div>
                            <div className="flex items-center mt-1">
                              <div className="w-2 h-2 bg-theme-6 rounded-full mr-3"></div>
                              <span className="truncate">Reversed</span>
                              <div className="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                              <span className="font-medium xl:ml-auto">2%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* top agents */}
                <div className="col-span-12 grid grid-cols-12 gap-5">
                  <div className="col-span-12  px-4 py-5 border bg-white border-[#DEDEDE] rounded-md md:col-span-6 w-full">
                    <div className=" block sm:flex items-center h-10 ">
                      <h2 className="text-base text-black font-medium truncate mr-5">
                        Top Agents
                      </h2>
                    </div>
                    <div className="intro-y mt-12 sm:mt-5">
                      <div className="w-full px-4">
                        <ZoomOptions
                          state={activeAgentsZoomOption}
                          action={(x) => setActiveAgentsZoomOption(x)}
                        />
                      </div>

                      <ApexChartComponent
                        series={[
                          {
                            name: "Transaction",
                            data: sample.sort((a, b) => a[0] - b[0]),
                          },
                        ]}
                        colors={["#0042C6"]}
                        type={"area"}
                        showToolbar={false}
                        activeZoomOption={activeAgentsZoomOption}
                      />
                    </div>
                  </div>

                  {/* top countries */}
                  <div className=" col-span-12 md:col-span-3  flex flex-col gap-5">
                    <div className=" flex flex-col items-center justify-center gap-6 px-4 py-5 border bg-white border-[#DEDEDE] rounded-md">
                      <div className="intro-y text-center">
                        <h2 className="text-base font-medium">Top Countries</h2>
                      </div>

                      <ul className="intro-y w-full flex flex-col gap-2">
                        <li className="flex flex-col w-full gap-2">
                          <p className="flex w-full items-center justify-between">
                            <span>United Kingdom</span>
                            <span>£50,000</span>
                          </p>
                          <progress value="70" max="100"></progress>
                        </li>
                        <li className="flex flex-col w-full gap-2">
                          <p className="flex w-full items-center justify-between">
                            <span>Ghana</span>
                            <span>£50,000</span>
                          </p>
                          <progress value="60" max="100"></progress>
                        </li>
                        <li className="flex flex-col w-full gap-2">
                          <p className="flex w-full items-center justify-between">
                            <span>Nigeria</span>
                            <span>£50,000</span>
                          </p>
                          <progress value="50" max="100"></progress>
                        </li>
                        <li className="flex flex-col w-full gap-2">
                          <p className="flex w-full items-center justify-between">
                            <span>Ivory Coast</span>
                            <span>£50,000</span>
                          </p>
                          <progress value="40" max="100"></progress>
                        </li>
                        <li className="flex flex-col w-full gap-2">
                          <p className="flex w-full items-center justify-between">
                            <span>Netherlands</span>
                            <span>£50,000</span>
                          </p>
                          <progress value="20" max="100"></progress>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
