import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import RestService from "../../../services/restService";
import {useReactToPrint} from "react-to-print";

const TransactionReceipt = () => {
  let {id} = useParams();

  const receiptRef = useRef();

  const [transaction, setTransaction] = useState({});

  useEffect(() => {
    RestService.TransactionsApi.getSpecificTransactionDetails(id).then(
      ({data}) => {
        //console.log("Transaction ", data.data);
        setTransaction(data.data);
      }
    );
  }, []);

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  return (
    <div className=" flex gap-6 mt-[5rem]  md:flex-row flex-col border border-theme-31 rounded-md w-full md:px-6 md:py-8 px-4 py-6">
      <div className="flex-1 flex flex-col gap-12">
        <div className="flex flex-col items-start w-full gap-6 pr-8">
          <h4 className="text-[#202020] font-medium border-b border-[#ECECEC] w-full pb-4 ">
            Transaction Receipt
          </h4>

          <div className="flex flex-row items-start gap-4 w-full justify-between">
            <div className="flex flex-col flex-1 gap-1 text-[#4B4B4B]">
              <p className="text-[#4B4B4B]">Telephone</p>
              <p>Part 7th Floor, North Block</p>
              <p>55 Baker Street</p>
              <p>London W1u 7EU, United Kingdom</p>
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <p>{transaction?.agent?.agency?.name}</p>
              <p>{transaction?.agent?.agency?.email}</p>
              <p>{transaction?.agent?.address}</p>
              <p>{transaction?.agent?.agency?.address}</p>
              <br />
              <p>Tel : {transaction?.agent?.agency?.msisdn}</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col px-6 rounded border border-theme-31 py-4 bg-white items-start w-full gap-6 pr-8">
            <h4 className="text-[#202020] font-medium border-b border-[#ECECEC] w-full pb-4 ">
              Sender’s Information (C1211452090)
            </h4>

            <div className="flex flex-row items-start gap-4 w-full justify-between">
              <div className="flex flex-col flex-1 gap-2 text-[#4B4B4B]">
                <p className="text-[#4B4B4B]">Telephone</p>
                <p>Customer Name</p>
                <p>Customer Address</p>
              </div>
              <div className="flex flex-col flex-1 gap-2">
                <p>Tel : + 44(0) 845 600 8537</p>
                <p>
                  {transaction?.sender?.firstname}{" "}
                  {transaction?.sender?.lastname}
                </p>

                <p>{transaction?.sender?.address}</p>
                <br />
              </div>
            </div>
          </div>
          <div className="flex flex-col px-6 rounded border border-theme-31 py-4 bg-white items-start w-full gap-6 pr-8">
            <h4 className="text-[#202020] font-medium border-b border-[#ECECEC] w-full pb-4 ">
              Recipient Information
            </h4>

            <div className="flex flex-row items-start gap-4 w-full justify-between">
              <div className="flex flex-col flex-1 gap-2 text-[#4B4B4B]">
                <p className="text-[#4B4B4B]">Beneficiary Name</p>
                <p>Beneficiary Address</p>
                <p>Telephone</p>
              </div>
              <div className="flex flex-col flex-1 gap-2">
                <p>
                  {transaction?.receiver?.firstname}{" "}
                  {transaction?.receiver?.lastname}
                </p>
                <p>{transaction?.receiver?.address}</p>

                <p>{transaction?.receiver?.contact_number}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col px-6 rounded border border-theme-31 py-4 bg-white items-start w-full gap-6 pr-8">
            <h4 className="text-[#202020] font-medium border-b border-[#ECECEC] w-full pb-4 ">
              Payment Agent Info
            </h4>

            <div className="flex flex-col md:flex-row items-start gap-4 w-full justify-between">
              <div className="flex flex-col w-full flex-1 gap-2 text-[#4B4B4B]">
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">
                    Paying Agent
                  </p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    {transaction?.agent?.firstname}{" "}
                    {transaction?.agent?.lastname}
                  </p>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">
                    Delivery Method
                  </p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    {transaction?.transaction_type?.type}
                  </p>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">Address</p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    {transaction?.agent?.address}
                  </p>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">
                    Message for Beneficiary
                  </p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    {transaction?.message}
                  </p>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">Ben ID No.</p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    {transaction?.receiver_wallet?.account_number}
                  </p>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">Bank Name</p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    {
                      transaction?.receiver_wallet?.destination_institution
                          ?.name
                    }
                  </p>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">Bank City</p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    Accra
                  </p>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">
                    Account Number
                  </p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    {transaction?.receiver_wallet?.account_number}
                  </p>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">
                    Reference
                  </p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    {
                      transaction?.reference
                    }
                  </p>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">
                    Account Type
                  </p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    {
                      transaction?.receiver_wallet?.destination_institution
                          ?.type
                    }
                  </p>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">
                    Partner
                  </p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    {
                      transaction?.partner?.name
                    }
                  </p>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">
                    Partner Reference
                  </p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    {
                      transaction?.partner_reference
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
              className="flex flex-col px-6 rounded border border-theme-31 py-4 bg-white items-start w-full gap-6 pr-8">
            <h4 className="text-[#202020] font-medium border-b border-[#ECECEC] w-full pb-4 ">
              Payment Information
            </h4>

            <div className="flex flex-col md:flex-row items-start gap-4 w-full justify-between">
              <div className="flex flex-col w-full flex-1 gap-2 text-[#4B4B4B]">
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">
                    Transfer Reason
                  </p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    {transaction?.transaction_purpose?.purpose}
                  </p>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">
                    Amount to be Delivered
                  </p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    {transaction?.source_currency} {transaction?.send_amount}
                  </p>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">
                    Exchange Rate
                  </p>
                  <div className="text-[#4B4B4B] flex-col flex-[1.2] w-full flex items-start justify-start">
                    <p>
                      {transaction?.destination_currency} /{" "}
                      {transaction?.source_currency}
                    </p>
                    <p>{transaction?.rate}</p>
                  </div>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">
                    Amount to be Transmitted
                  </p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    {transaction?.destination_currency}{" "}
                    {transaction?.receive_amount}
                  </p>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">Charge</p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    {transaction?.source_currency} {transaction?.charge}
                  </p>
                </div>
                <div className="flex items-start justify-between w-full gap-6">
                  <p className="text-[#4B4B4B] flex-[.8] w-full">Surcharge</p>
                  <p className="text-[#4B4B4B] flex-[1.2] w-full flex items-start justify-start">
                    GHS 0.00
                  </p>
                </div>
                <div className="flex mt-4 items-start justify-between w-full gap-6">
                  <p className="text-[#000] text-xl font-medium flex-[.8] w-full">
                    Total Cash
                  </p>
                  <p className="text-[#000] text-xl font-medium flex-[1.2] w-full flex items-start justify-start">
                    {transaction?.source_currency} {transaction?.send_amount}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionReceipt;
