import React, {useReducer, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useToasts} from "react-toast-notifications";
import {setUserObject} from "../../../redux/actions/user";
import restService from "../../../services/restService";
import Input from "../../../components/common/Input";
import PhoneInputComponent from "../../../components/common/PhoneInput";

const UpdateAccount = () => {
  const [user, setUser] = useState(useSelector((state) => state?.auth?.user));
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const {addToast} = useToasts();

  const handleInputChange = ({target}) => {
    setUser({...user, [target.name]: target.value});
    delete errors[target.name];
  };
  const validateForm = () => {
    let errorList = {};
    if (!user.firstname) {
      errorList.firstname = "Please enter a first name";
    }
    if (!user.lastname) {
      errorList.lastname = "Please enter a last name";
    }
    if (!user.email) {
      errorList.email = "Please enter an email";
    } else {
      const regex = /\S+@\S+\.\S+/;
      if (!regex.test(user.email)) {
        errorList.email = "Enter a valid email.";
      }
    }
    if (!user.msisdn) {
      errorList.msisdn = "Please enter a phone number";
    }
    if (!user.address) {
      errorList.address = "Please enter an address";
    }

    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };

  const saveChanges = () => {
    if (validateForm()) {
      restService.StaffApi.updateStaff(user).then(() => {
        addToast("Update Successful", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
        dispatch(setUserObject(user));
      });
    }
  };

  return (
    <>
      <div className="flex md:flex-row flex-col gap-4 items-start border-t border-[#ECECEC]  py-5 w-full">
        <div className="flex flex-col items-start flex-[.6]">
          <h4 className="text-base font-medium text-black">Personal Details</h4>
          <p className="text-[#999999] text-xs">
            This information will be displayed publicly so be careful what you
            share
          </p>
        </div>

        <form className="flex-[1.4]  flex  gap-8 items-start w-full justify-start flex-col">
          <div className="grid gap-8 w-full md:w-[70%] mx-auto grid-cols-1 md:grid-cols-2">
            <div className="flex gap-8 items-center w-full justify-center flex-col">
              <div className="w-full">
                <Input
                  containerStyle={"w-full flex flex-col"}
                  labelName={"Email address"}
                  name="email"
                  value={user?.email}
                  onChange={handleInputChange}
                  type="email"
                />
                {errors?.email && (
                  <p className="text-red-500 text-[10px] italic">
                    {errors?.email}
                  </p>
                )}
              </div>
              <div className="w-full flex-col">
                <Input
                  containerStyle={"w-full"}
                  onChange={handleInputChange}
                  placeholder="Broni"
                  value={user?.othernames}
                  labelName={"Other Names"}
                  name="othernames"
                  type="text"
                />
                {errors?.othernames && (
                  <p className="text-red-500 text-[10px] italic">
                    {errors?.othernames}
                  </p>
                )}
              </div>
              <div className="w-full flex flex-col">
                <PhoneInputComponent
                  labelFor={"msisdn"}
                  labelName={"Phone Number"}
                  placeholder="Enter phone number"
                  value={user?.msisdn}
                  defaultCountry="GB"
                  name="msisdn"
                  disabled={true}
                  withCountryCallingCode
                  international={true}
                  className={`flex  text-xs bg-white PhoneInput-2 `}
                  containerStyles={" w-full"}
                  // validationMessage={validationMessage}
                  handlePhoneInputChange={(value) => {
                    setUser({...user, msisdn: value});
                  }}
                />
                {errors?.msisdn && (
                  <p className="text-red-500 text-[10px] italic">
                    {errors.msisdn}
                  </p>
                )}
              </div>
            </div>
            <div className="flex gap-8 items-start w-full justify-start flex-col">
              <div className="w-full flex flex-col">
                <Input
                  containerStyle={"w-full"}
                  labelName={"First Name*"}
                  name="firstname"
                  value={user?.firstname}
                  onChange={handleInputChange}
                  type="text"
                />
                {errors?.firstname && (
                  <p className="text-red-500 text-[10px] italic">
                    {errors?.firstname}
                  </p>
                )}
              </div>
              <div className="w-full flex flex-col">
                <Input
                  containerStyle={"w-full"}
                  onChange={handleInputChange}
                  value={user?.lastname}
                  labelName={"Last Name*"}
                  name="lastname"
                  type="text"
                />
                {errors?.lastname && (
                  <p className="text-red-500 text-[10px] italic">
                    {errors?.lastname}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="w-full flex items-end justify-end  md:w-[85%]">
            <button onClick={saveChanges} className="btn btn-primary-2">
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateAccount;
