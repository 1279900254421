import React, {useState, useEffect} from "react";
import restService from "../../../services/restService";
import {useSelector} from "react-redux";
import Input from "../../../components/common/Input";
import PhoneInputComponent from "../../../components/common/PhoneInput";

const formKeys = [
  "firstname",
  "lastname",
  "staff_role",
  "country_id",
  "email",
  "msisdn",
  "address",
];
const UpdateStaff = ({setStaffToUpdate, staff, initCallback, onSubmit}) => {
  const user = useSelector((state) => state.auth?.user);
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState(staff);
  const [countries, setCountries] = useState([]);
  const [roles, setRoles] = useState([]);
  const validateForm = () => {
    let errorList = {};
    formKeys.forEach((key) => {
      // TODO validate phone numbers
      // console.log(key, form[key]);
      if (!form[key]) {
        errorList[key] = "This field is required.";
      } else {
        if (key === "email") {
          const regex = /\S+@\S+\.\S+/;
          if (!regex.test(form[key])) {
            errorList[key] = "Enter a valid email.";
          }
        }
      }
    });
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };
  const onFormChange = ({target}) => {
    let details = {...form};
    details[target.name] = target.value;
    setForm(details);

    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);
  };
  const handleSubmit = () => {
    if (validateForm()) {
      restService.StaffApi.updateStaff(form).then((response) => {
        onSubmit();
      });
    }
  };

  const fetchCountriesAndRoles = async () => {
    const countryList = await restService.UtilsApi.getCountries();
    const roleList = await restService.UtilsApi.getStaffRoles();
    const country = staff.country;

    setCountries(countryList.data?.data);
    setRoles(roleList.data.data);
    setForm({
      ...form,
      calling_code: country.calling_code,
      staff_role: staff.staff_role.name,
      country_id: country.id,
    });
  };
  useEffect(() => {
    fetchCountriesAndRoles();
  }, []);
  useEffect(() => {
    initCallback(handleSubmit);
  }, [form]);

  return (
    <div className="flex intro-y flex-col items-center md:gap-11 gap-6 justify-center py-10 px-10">
      <div className="flex items-start justify-between w-full">
        <div className="flex items-center flex-col gap-1 justify-center w-full">
          <h1 className="text-xl font-medium text-[#0042C6]">Update Staff</h1>
        </div>

        <span
          onClick={() => setStaffToUpdate(undefined)}
          className="btn border-none"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.8337 1.34199L10.6587 0.166992L6.00033 4.82533L1.34199 0.166992L0.166992 1.34199L4.82533 6.00033L0.166992 10.6587L1.34199 11.8337L6.00033 7.17533L10.6587 11.8337L11.8337 10.6587L7.17533 6.00033L11.8337 1.34199Z"
              fill="black"
            />
          </svg>
        </span>
      </div>
      <div className="grid grid-cols-12 gap-4 gap-y-5 ">
        <div className="intro-y col-span-12 sm:col-span-6">
          <Input
            labelName={"First Name *"}
            id="input-wizard-1"
            type="text"
            value={form.firstname}
            onChange={onFormChange}
            name="firstname"
            className="form-control"
            placeholder="Kojo"
          />
          <p className="text-red-500 text-xs italic pt-1">{errors.firstname}</p>
        </div>
        <div className="intro-y col-span-12 sm:col-span-6">
          <Input
            labelName={"Other Names"}
            id="input-wizard-3"
            type="text"
            value={form.othernames}
            onChange={onFormChange}
            name="othernames"
            className="form-control"
            placeholder="Y"
          />
        </div>
        <div className="intro-y col-span-12 sm:col-span-6">
          <Input
            labelName={"Last Name *"}
            id="input-wizard-2"
            type="text"
            value={form.lastname}
            onChange={onFormChange}
            name="lastname"
            className="form-control"
            placeholder="Nsiah"
          />
          <p className="text-red-500 text-xs italic pt-1">{errors.lastname}</p>
        </div>
        <div className="intro-y col-span-12 sm:col-span-6">
          <Input
            labelName={"Address *"}
            id="input-wizard-3"
            type="text"
            value={form.address}
            onChange={onFormChange}
            name="address"
            className="form-control"
            placeholder="Awoshie"
          />
          <p className="text-red-500 text-xs italic pt-1">{errors.address}</p>
        </div>
        <div className="intro-y col-span-12 sm:col-span-6">
          <Input
            labelName={"Email *"}
            id="input-wizard-5"
            type="text"
            value={form.email}
            onChange={onFormChange}
            name="email"
            className="form-control"
            placeholder="kojo@gmail.com"
          />
          <p className="text-red-500 text-xs italic pt-1">{errors.email}</p>
        </div>
        <div className="intro-y col-span-12 sm:col-span-6">
          {/* <label htmlFor="input-wizard-4" className="form-label">
            Phone Number *
          </label>
          <div className="grid grid-cols-7">
            <input
              id="input-wizard-4"
              disabled={true}
              type="text"
              onChange={onFormChange}
              value={form.calling_code}
              name="msisdn"
              className="col-span-1 form-control"
              placeholder="44"
            />
            <input
              id="input-wizard-4"
              type="text"
              value={form.msisdn}
              onChange={onFormChange}
              name="msisdn"
              className="col-span-6 form-control"
              placeholder="548410151"
            />
          </div> */}

          <div className="w-full">
            <PhoneInputComponent
              labelFor={"msisdn"}
              labelName={"Phone Number"}
              placeholder="Enter phone number"
              value={form.msisdn}
              defaultCountry="GB"
              withCountryCallingCode
              international={true}
              // onChange={onFormChange}
              className={`flex  text-xs bg-white PhoneInput-2 `}
              containerStyles={" w-full"}
              // validationMessage={validationMessage}
              // handlePhoneInputChange={handlePhoneInput}
            />
          </div>

          <p className="text-red-500 text-xs italic pt-1">{errors.msisdn}</p>
        </div>
        <div className="intro-y col-span-12 sm:col-span-6">
          <label htmlFor="input-wizard-5" className="form-label mb-0 text-xs">
            Country *
          </label>
          <select
            id="custom-form-select"
            value={form.country_id}
            onChange={onFormChange}
            name="country_id"
            className="form-select"
          >
            {countries.map((country) => {
              return <option value={country.id}>{country.name}</option>;
            })}
          </select>
        </div>
        <div className="intro-y col-span-12 sm:col-span-6">
          <label htmlFor="input-wizard-5" className="form-label mb-0 text-xs">
            Role *
          </label>
          <select
            id="custom-form-select"
            value={form.staff_role}
            onChange={onFormChange}
            name="staff_role"
            className="form-select"
          >
            {roles.map((role) => {
              return <option value={role.name}>{role.name}</option>;
            })}
          </select>
        </div>
      </div>

      <div className="flex items-center gap-4">
        <button
          onClick={() => setStaffToUpdate(undefined)}
          className="btn rounded-md border font-medium border-[#2D2C2C] text-[#2D2C2C] px-6 py-2"
        >
          Cancel
        </button>
        <button
          onClick={() => handleSubmit()}
          className="btn btn-primary-2"
        >
          Update Staff
        </button>
      </div>
    </div>
  );
};
export default UpdateStaff;
