import React from 'react';
import SkeletonSubProfileStyles from "./SkeletonSubProfileStyles";
import SkeletonElement from "../SkeletonElement";

const SkeletonSubProfile = () => {
  return (
    <SkeletonSubProfileStyles>
      <div className="max-w-xs">
          <div className="photo-wrapper p-2 mb-3">
            <SkeletonElement type={"image"} />
          </div>
          <div className="p-2">
            <div className="flex flex-col space-y-1">
              <SkeletonElement type={"text"} />
              <SkeletonElement type={"title"} />
            </div>

            <div className="text-center my-3">
                 <SkeletonElement type={"button"}/>
            </div>

          </div>
      </div>

    </SkeletonSubProfileStyles>
  );
};

export default SkeletonSubProfile;

