import styled from "styled-components";
import tw from "twin.macro";

const SearchStyles = styled.div.attrs({
  className: "search-div",
})`
        ${tw`relative md:mr-3 sm:mr-6`}

  .search-div{
            ${tw`w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0`}
            .search{
              ${tw`md:w-56 relative text-gray-700 dark:text-gray-300`}
              .search-input{
              ${tw`md:w-56 pl-10 placeholder-theme-13`}
              }
              .search-icon{
               ${tw`dark:text-gray-300 w-5 h-5 absolute my-auto top-[10px] bottom-0 ml-3 left-0 text-theme-7`}
             }
            }
          }
      }
  & {

  }
`;
export default SearchStyles;
