import React, {useState, useEffect} from "react";
import {addDays} from "date-fns";
import {DateRangePicker as DateRange} from "react-date-range";
import Modal2 from "../Modal/Modal2";
import Button from "../Button";
import moment from "moment";

const DateRangePicker = ({
  show,
  setShow,
  handleSelectedRange,
  range,

  setRange,
  skip,
  rewind,
}) => {
  const [dateRange, setDateRange] = useState(
    range
    //   [
    //   {
    //     startDate: addDays(new Date(), -7),
    //     endDate: new Date(),
    //     key: "selection",
    //   },
    // ]
  );

  const handleSetNewDateRange = () => {
    const startDate = new Date(dateRange[0].startDate);
    const endDate = new Date(dateRange[0].endDate);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 0);

    setRange([{...dateRange[0], startDate: startDate, endDate: endDate}]);
    handleSelectedRange && handleSelectedRange({startDate, endDate});
    setShow(false);
  };

  const handleSkip = (skip) => {
    const times = skip ?? 7;
    const startDate = addDays(new Date(dateRange[0].startDate), times);
    const endDate = addDays(new Date(dateRange[0].endDate), times);

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 0);
    setDateRange([{...dateRange[0], startDate: startDate, endDate: endDate}]);
    setRange([{...dateRange[0], startDate: startDate, endDate: endDate}]);
  };

  const handleRewind = (rewind) => {
    const times = rewind ?? -7;
    const startDate = addDays(new Date(dateRange[0].startDate), times);
    const endDate = addDays(new Date(dateRange[0].endDate), times);

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 0);
    setDateRange([{...dateRange[0], startDate: startDate, endDate: endDate}]);
    setRange([{...dateRange[0], startDate: startDate, endDate: endDate}]);
  };

  useEffect(() => {
    setDateRange(range);
  }, [range]);

  return (
    <div>
      <div className="flex items-center  w-full relative gap-4 bg-white border text-sm border-theme-31 rounded-[6.7px] px-3 py-2">
        <button
          onClick={() => {
            handleRewind(rewind);
          }}
          className="cursor-pointer absolute top-0 flex items-center justify-center hover:bg-theme-2 transition bottom-0 left-0 right-[85%]"
        >
          <svg
            width="4"
            height="8"
            viewBox="0 0 4 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.85355 7.01102C4.04882 6.81576 4.04882 6.49918 3.85355 6.30392L1.20711 3.65747L3.85355 1.01102C4.04882 0.815762 4.04882 0.499179 3.85355 0.303917C3.65829 0.108655 3.34171 0.108655 3.14645 0.303917L0.146446 3.30392C-0.0488158 3.49918 -0.0488158 3.81576 0.146446 4.01102L3.14645 7.01102C3.34171 7.20629 3.65829 7.20629 3.85355 7.01102Z"
              fill="black"
            />
          </svg>
        </button>

        <span
          className="mx-4 cursor-pointer text-center w-full transition"
          onClick={() => setShow(true)}
        >
          {moment(dateRange[0].startDate).format("D MMM")} -{" "}
          {moment(dateRange[0].endDate).format("D MMM")}
        </span>

        <button
          onClick={() => {
            handleSkip(skip);
          }}
          className="cursor-pointer absolute top-0 flex items-center justify-center hover:bg-theme-2 transition bottom-0  right-0 left-[85%]"
        >
          <svg
            width="4"
            height="8"
            viewBox="0 0 4 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.146447 7.01102C-0.0488155 6.81576 -0.0488155 6.49918 0.146447 6.30392L2.79289 3.65747L0.146447 1.01102C-0.0488155 0.815762 -0.0488155 0.499179 0.146447 0.303917C0.341709 0.108655 0.658291 0.108655 0.853553 0.303917L3.85355 3.30392C4.04882 3.49918 4.04882 3.81576 3.85355 4.01102L0.853553 7.01102C0.658291 7.20629 0.341709 7.20629 0.146447 7.01102Z"
              fill="black"
            />
          </svg>
        </button>
      </div>

      {show && (
        <Modal2
          size={""}
          onHide={() => {
            setDateRange(range);
            setShow(false);
          }}
        >
          <div className="flex intro-y flex-col gap-2">
            <DateRange
              editableDateInputs={false}
              onChange={(item) => setDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              showSelectionPreview={true}
              ranges={dateRange}
              months={1}
              direction="horizontal"
              rangeColors={["#0042C6"]}
              color="#0042C6"
            />

            <div className="flex items-center gap-4 justify-end pb-4 px-4">
              <Button
                className="px-4 py-2"
                onClick={() => {
                  setDateRange(range);
                  setShow(false);
                }}
              >
                Cancel
              </Button>

              <Button
                onClick={handleSetNewDateRange}
                className={
                  "py-2 rounded-md text-white hover:bg-[#0042c6e8] transition  bg-[#0042C6] px-[32px!important] justify-center"
                }
              >
                Done
              </Button>
            </div>
          </div>
        </Modal2>
      )}
    </div>
  );
};

export default DateRangePicker;
