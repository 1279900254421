import React from "react";

const Success = ({next, prev}) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <span>
        <svg
          width="106"
          height="106"
          viewBox="0 0 106 106"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="53" cy="53" r="53" fill="#0042C6" />
          <path
            d="M78.0645 46.252C77.0291 46.6274 76.0338 47.0451 75.0029 47.3406C74.4653 47.4939 73.8677 47.4672 73.2967 47.4672C58.5135 47.4739 43.728 47.4717 28.9448 47.4761C28.6138 47.4761 28.2694 47.4739 27.9561 47.5628C27.343 47.7383 26.9742 48.2026 26.9675 48.8269C26.9408 51.1886 26.9564 53.5502 26.9564 55.9919C27.2052 55.9919 27.4185 55.9919 27.6318 55.9919C30.5889 55.9919 33.546 55.9919 36.503 55.9919C37.8094 55.9919 38.316 56.4962 38.3182 57.8004C38.3204 60.3887 38.3204 62.9747 38.3182 65.563C38.3182 66.8605 37.8094 67.3604 36.4919 67.3626C33.5526 67.3648 30.6155 67.3626 27.6762 67.3626C27.4585 67.3626 27.2385 67.3626 26.9475 67.3626V67.9513C26.9475 70.9462 26.9475 73.9388 26.9475 76.9337C26.9475 78.1956 27.4674 78.7155 28.7293 78.7155C43.1815 78.7155 57.6314 78.7155 72.0836 78.7155C75.6428 78.7155 78.0622 76.2894 78.0622 72.7236C78.0622 64.0567 78.0622 55.3876 78.0622 46.7207V46.2542L78.0645 46.252Z"
            fill="white"
          />
          <path
            d="M56.8053 36.1201C56.7697 36.8732 56.8053 37.5642 56.692 38.2285C56.3076 40.4857 54.4214 41.9276 52.1286 41.7765C50.0291 41.6366 48.345 39.8437 48.254 37.6375C48.234 37.1598 48.2517 36.68 48.2517 36.1201H47.6341C43.2174 36.1201 38.8028 36.1201 34.3861 36.1201C33.1553 36.1201 32.6354 36.6355 32.6309 37.8708C32.6265 39.9392 32.6309 42.0098 32.6309 44.0805C32.6309 44.2426 32.6487 44.407 32.6598 44.6203C32.8775 44.6292 33.0731 44.647 33.2686 44.647C46.6832 44.647 60.0956 44.647 73.5103 44.647C76.003 44.647 77.8848 43.014 78.0559 40.7168C78.2425 38.2063 76.3119 36.129 73.7436 36.1245C68.2382 36.1112 62.7306 36.1201 57.2252 36.1201C57.0986 36.1201 56.9719 36.1201 56.8075 36.1201H56.8053Z"
            fill="white"
          />
          <path
            d="M51.0975 26.3245C51.0975 29.977 51.0975 33.5051 51.0975 37.0332C51.0975 37.2553 51.0886 37.4775 51.1108 37.6975C51.1819 38.4084 51.7862 38.9527 52.4971 38.9572C53.2081 38.9638 53.8168 38.4328 53.899 37.7197C53.9235 37.4997 53.9213 37.2775 53.9213 37.0554C53.9213 33.7117 53.9213 30.3658 53.9213 27.0222C53.9213 26.8267 53.9213 26.6311 53.9213 26.3068C54.4389 26.7156 54.8521 27.0399 55.2654 27.3687C56.3629 28.2441 57.4538 29.1283 58.5557 29.9992C59.3378 30.6191 60.1776 30.5747 60.7152 29.9037C61.2351 29.2528 61.0796 28.3841 60.3198 27.7753C58.0714 25.9713 55.8208 24.1717 53.568 22.3744C52.9393 21.8745 52.075 21.8745 51.4418 22.3788C49.1735 24.1873 46.9073 25.998 44.6479 27.8131C43.9214 28.3952 43.7903 29.2572 44.3057 29.9082C44.8234 30.5613 45.6676 30.6235 46.4186 30.0304C47.8249 28.9195 49.2179 27.7931 50.6198 26.6756C50.7442 26.5756 50.8775 26.4867 51.0975 26.3268V26.3245Z"
            fill="white"
          />
          <path
            d="M35.457 64.4684V58.8809H26.979V64.4684H35.457Z"
            fill="white"
          />
          <circle
            cx="74.9574"
            cy="68.9002"
            r="11.9629"
            fill="white"
            stroke="#0042C6"
            strokeWidth="1.81714"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M81.2942 64.4824L73.5606 73.3209L69.6572 69.4176L70.6046 68.4701L73.4953 71.3608L80.2859 63.6001L81.2942 64.4824Z"
            fill="#0042C6"
            stroke="#0042C6"
            strokeWidth="1.51429"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
        </svg>
      </span>
      <h3 className="text-3xl font-medium text-theme-32">Sender Added</h3>
    </div>
  );
};

export default Success;
