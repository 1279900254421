export function CountryCard({ iconCode, countryName }) {
  return (
    <button className="flex items-center w-full rounded p-3 gap-2 justify-center  flex-col">
      <span
        style={{
          width: "60px",

          height: "60px",
          borderRadius: "100%",
        }}
        className={`fi fis fi-${iconCode?.toLowerCase()}`}
      ></span>
      <h3 className="text-lg line-clamp-1 font-semibold whitespace-normal">
        {countryName}
      </h3>
    </button>
  );
}
