import styled from "styled-components";
import tw from "twin.macro";

const SkeletonElementStyles = styled.div.attrs({
  className: "skeleton h-6 animate-pulse",
})`
  & {
    .image {
      ${tw`h-12 w-12 bg-gray-300 rounded-full`}
    }
    .title {
      ${tw`w-24 bg-gray-300 h-4 rounded-md`}
    }
    .text {
      ${tw`w-36 bg-gray-300 h-4 rounded-md`}
    }
    .button {
      ${tw`w-full bg-gray-300 h-8 rounded-md`}
    }
  }
`;
export default SkeletonElementStyles;
