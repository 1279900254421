import styled from 'styled-components';

const TextFieldStyles = styled.div`
  position:relative;

.input-div{
  position: relative;
}

`;

export default TextFieldStyles;
