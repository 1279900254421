import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth-service";

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const API_URL =
  environment === "dev"
    ? process.env.REACT_APP_API_ENDPOINT_DEVELOPMENT
    : environment === "staging"
    ? process.env.REACT_APP_API_ENDPOINT_STAGING
    : process.env.REACT_APP_API_ENDPOINT_PRODUCTION;

const API_URL_V2 =
  environment === "dev"
    ? process.env.REACT_APP_API_ENDPOINT_DEVELOPMENT_V2
    : environment === "staging"
    ? process.env.REACT_APP_API_ENDPOINT_STAGING_V2
    : process.env.REACT_APP_API_ENDPOINT_PRODUCTION_V2;

const serialize = function (obj) {
  let str = [];
  for (let p in obj) {
    if (obj.hasOwnProperty(p) && !!obj[p]) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }
  return str.join("&");
};

axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === 401 && !error.config?.isRetry) {
      //place your reentry code
      const token = await AuthService.getAccessTokenAsync();

      if (token) {
        // console.log("Retrying request")
        error.config.headers = authHeader();
        error.config.headers.Authorization = `Bearer ${token}`;
        error.config.baseURL = undefined;
        error.config.isRetry = true;
        return axios.request(error.config);
      }
    }
    return Promise.reject(error);
  }
);

const SendersApi = {
  addSender: (data, agentId) => {
    const form = new FormData();
    Object.keys(data).forEach((key) => {
      form.append(key, data[key]);
    });
    // console.log(data)
    return axios.post(`${API_URL}senders/agents/${agentId}`, form, {
      headers: authHeader(),
    });
  },

  updateId : (id, data) => {
  },

  addSenderWithoutId: (data, agentId) => {
    const form = new FormData();
    Object.keys(data).forEach((key) => {
      form.append(key, data[key]);
    });
    // console.log(data)
    return axios.post(`${API_URL_V2}senders/agents/${agentId}`, form, {
      headers: authHeader(),
    });
  },

  getSenders: (page, list, limit = 10) => {
    return axios.get(`${API_URL}senders?page=${page}&limit=${limit}`, {
      headers: authHeader(list),
    });
  },

  getSendersCreatedByAgent: (agentId, page) => {
    return axios.get(`${API_URL}senders/by-agent/${agentId}?page=${page}`, {
      headers: authHeader(),
    });
  },

  getSendersBySearchTerm: (input) => {
    return axios.get(`${API_URL}senders/search/${input}`, {
      headers: authHeader(),
    });
  },

  getSendersByRecipient: (receiverId) => {
    return axios.get(`${API_URL}senders/by-receiver/${receiverId}`, {
      headers: authHeader(),
    });
  },
  getSenderById: (id) => {
    return axios.get(API_URL + `senders/${id}`, { headers: authHeader() });
  },
  updateSender: (id, sender) => {
    return axios.patch(API_URL + `senders/${id}`, sender, {
      headers: authHeader(),
    });
  },

  uploadIdentification: (id, data) => {
    const form = new FormData();
    Object.keys(data).forEach((key) => {
      form.append(key, data[key]);
    });

    return axios.post(API_URL + `senders/upload-new-id/${id}`, form, {
      headers: authHeader(),
    });
  },
};

const ReceiversApi = {
  getReceivers: (page) => {
    return axios.get(API_URL + `receivers?page=${page}`, {
      headers: authHeader(),
    });
  },

  getReceiverById: (id) => {
    return axios.get(API_URL + `receivers/${id}`, { headers: authHeader() });
  },

  getReceiverBySearchTerm: (input) => {
    return axios.get(`${API_URL}receivers/search/${input}`, {
      headers: authHeader(),
    });
  },

  getReceiversCreatedByAgent: (agentId, page) => {
    return axios.get(API_URL + `receivers/by-agent/${agentId}?page=${page}`, {
      headers: authHeader(),
    });
  },

  getReceiversBySender: (senderId, page, list) => {
    return axios.get(API_URL + `receivers/by-sender/${senderId}?page=${page}`, {
      headers: authHeader(list),
    });
  },

  addReceiver: (data, id) => {
    return axios.post(API_URL + `receivers/agents/${id}`, data, {
      headers: authHeader(),
    });
  },
  updateReceiver: (id, receiver) => {
    return axios.patch(API_URL + `receivers/${id}`, receiver, {
      headers: authHeader(),
    });
  },
  addWallet: (data, id) => {
    return axios.post(API_URL + `receivers/wallets/${id}`, data, {
      headers: authHeader(),
    });
  },
  nameLookup: (destinationInstitution, accountNumber) => {
    return axios.post(
      API_URL +
        `receivers/name-lookup/destinationInstitution/${destinationInstitution}/${accountNumber}`,
      {},
      {
        headers: authHeader(),
      }
    );
  },
};

const AgentsApi = {
  getAgents(page) {
    return axios.get(API_URL + `agents?page=${page}`, {
      headers: authHeader(),
    });
  },
  changePassword: (id, old_password, password, password_confirmation) => {
    return axios.post(
      API_URL + `agents/auth/update-password/${id}`,
      {
        old_password,
        password,
        password_confirmation,
      },
      { headers: authHeader() }
    );
  },
};
const AgencyApi = {
  getAgencies(page, list) {
    return axios.get(API_URL + `agencies?page=${page}`, {
      headers: authHeader(list),
    });
  },
  addAgency: (agency) => {
    return axios.post(API_URL + `agencies`, agency, { headers: authHeader() });
  },
  updateAgency: (agency) => {
    return axios.patch(API_URL + `agencies/${agency.id}`, agency, {
      headers: authHeader(),
    });
  },
  removeAgency: (agency) => {
    return axios.delete(API_URL + `agencies/${agency.id}`, {
      headers: authHeader(),
    });
  },
};

const AgentApi = {
  addAgent: (agent) => {
    const form = new FormData();
    Object.keys(agent).forEach((key) => {
      // console.log(key, agent[key])
      form.append(key, agent[key]);
    });
    return axios.post(API_URL + `agents/agencies/${agent.agency_id}`, form, {
      headers: authHeader(),
    });
  },
  updateAgent: (agent) => {
    return axios.patch(API_URL + `agents/${agent.id}`, agent, {
      headers: authHeader(),
    });
  },
  getAgent(id) {
    return axios.get(API_URL + `agents/${id}`, { headers: authHeader() });
  },
  getAgents(page, list) {
    const listHeader =
      list === "allItems" ? { "x-instaant-do-single-pagination": true } : false;

    return axios.get(API_URL + `agents?page=${page}`, {
      headers: authHeader(list),
    });
  },
  getAgentBySearchTerm(input, page) {
    return axios.get(`${API_URL}agents/search/${input}?page=${page}`, {
      headers: authHeader(),
    });
  },
  getAgentsByAgency(id, page, list) {
    const listHeader =
      list === "allItems" ? { "x-instaant-do-single-pagination": true } : false;
    return axios.get(API_URL + `agents/by-agency/${id}?page=${page}`, {
      headers: authHeader(list),
    });
  },
  removeAgent(id) {
    return axios.delete(API_URL + `agents/${id}`, { headers: authHeader() });
  },
};

const RatesApi = {
  getRates: (page) => {
    return axios.get(API_URL + `rates?page=${page}`, { headers: authHeader() });
  },
  getRatesBySourceCurrency: (sourceCurrency) => {
    return axios.get(API_URL + `rates/by-currency/source/${sourceCurrency}`, {
      headers: authHeader(),
    });
  },
  getRatesBySearchTerm: (searchTerm) => {
    return axios.get(API_URL + `rates?searchTerm=${searchTerm}`, {
      headers: authHeader(),
    });
  },
  addRate: (rate) => {
    return axios.post(API_URL + `rates`, rate, { headers: authHeader() });
  },
  updateRate: (rate) => {
    return axios.patch(API_URL + `rates/${rate.id}`, rate, {
      headers: authHeader(),
    });
  },
  removeRate: (id) => {
    return axios.delete(API_URL + `rates/${id}`, { headers: authHeader() });
  },
};

const ChargesApi = {
  getCharges: (page) => {
    return axios.get(API_URL + `charges?page=${page}`, {
      headers: authHeader(),
    });
  },
  getChargesBySearchTerm: (searchTerm, page) => {
    return axios.get(
      API_URL + `charges?searchTerm=${searchTerm}?page=${page}`,
      { headers: authHeader() }
    );
  },
  addCharge: (charge) => {
    return axios.post(API_URL + `charges`, charge, { headers: authHeader() });
  },
  updateCharge: (charge) => {
    return axios.patch(API_URL + `charges/${charge.id}`, charge, {
      headers: authHeader(),
    });
  },
  removeCharge: (id) => {
    return axios.delete(API_URL + `charges/${id}`, { headers: authHeader() });
  },
};

const CommissionsConfigApi = {
  getCommissionConfigurations: (page) => {
    return axios.get(API_URL + `commission-configuration?page=${page}`, {
      headers: authHeader(),
    });
  },
  addCommissionConfiguration: (commission) => {
    return axios.post(API_URL + `commission-configuration`, commission, {
      headers: authHeader(),
    });
  },
  updateCommissionConfiguration: (commission, id) => {
    return axios.patch(
      API_URL + `commission-configuration/${id}`,
      commission,
      { headers: authHeader() }
    );
  },
  removeCommissionConfiguration: (id) => {
    return axios.delete(API_URL + `commission-configuration/${id}`, {
      headers: authHeader(),
    });
  },
  getCommissionConfigurationByAgent: (id) => {
    return axios.get(API_URL + `commission-configuration/by-agency/${id}`, {
      headers: authHeader(),
    });
  },
  getCommissionConfigurationByAgency: (id) => {
    return axios.get(API_URL + `commission-configuration/by-agent/${id}`, {
      headers: authHeader(),
    });
  },
};

const CommissionsApi = {
  getAllCommission: (page) => {
    return axios.get(API_URL + `commissions?page=${page}`, {
      headers: authHeader(),
    });
  },
  getCommissionsByAgent: (agentId, page) => {
    return axios.get(API_URL + `commissions/agents/${agentId}?page=${page}`, {
      headers: authHeader(),
    });
  },
  getCommissionsByDate: (date, page) => {
    return axios.get(API_URL + `commissions?date=${date}?page=${page}`, {
      headers: authHeader(),
    });
  },
  getCommissionsByDateRange: (startDate, endDate, page) => {
    return axios.get(
      API_URL +
        `commissions?start_date=${startDate}&end_date=${endDate}&page=${page}`,
      { headers: authHeader() }
    );
  },
  getCommissionsByAgency: (agencyId, page) => {
    return axios.get(
      API_URL + `commissions/agencies/${agencyId}?page=${page}`,
      { headers: authHeader() }
    );
  },
};
const FundingStatementApi = {
  getAllFundingStatements: (page) => {
    return axios.get(API_URL + `funding-statement?page=${page}`, {
      headers: authHeader(),
    });
  },
  getFundingStatementsByAgent: (agentId, page) => {
    return axios.get(
      API_URL + `funding-statement/agents/${agentId}?page=${page}`,
      { headers: authHeader() }
    );
  },
  getFundingStatementsByDate: (date, page) => {
    return axios.get(API_URL + `funding-statement?date=${date}?page=${page}`, {
      headers: authHeader(),
    });
  },
  getFundingStatementsByAgency: (agencyId, page) => {
    return axios.get(
      API_URL + `funding-statement/agencies/${agencyId}?page=${page}`,
      { headers: authHeader() }
    );
  },
};

const TransactionsApi = {
  getRates: (source, destination) => {
    return axios.get(
      API_URL + `rates/by-currency/source/${source}/destination/${destination}`,
      { headers: authHeader() }
    );
  },

  getTransactions: (page) => {
    return axios.get(`${API_URL}transactions?page=${page}`, {
      headers: authHeader(),
    });
  },

  getTransactionsByAgent: (agentId, page) => {
    return axios.get(
      `${API_URL}transactions/by-agent/${agentId}?page=${page}`,
      { headers: authHeader() }
    );
  },

  getTransactionsBySearchTerms: (
    startDate,
    endDate,
    sendCurrency,
    receiveCurrency,
    sendAmount,
    receiveAmount,
    status,
    agent_id,
    agency_id,
    partner_id,
    page
  ) => {
    const params = {
      start_date: startDate,
      end_date: endDate,
      send_currency: sendCurrency,
      receive_currency: receiveCurrency,
      send_amount: sendAmount,
      receive_amount: receiveAmount,
      status,
      page,
      agent_id,
      agency_id,
      partner_id,
    };
    return axios.get(API_URL + `transactions/search/?${serialize(params)}`, {
      headers: authHeader(),
    });
  },

  getTransactionsBySender: (id, page) => {
    return axios.get(API_URL + `transactions/sender/${id}?page=${page}`, {
      headers: authHeader(),
    });
  },

  getTransactionsByReceiver: (id, page) => {
    return axios.get(API_URL + `transactions/receiver/${id}?page=${page}`, {
      headers: authHeader(),
    });
  },

  getChargeByAmount: async (amount, source, destination, page) => {
    if (source && destination && amount) {
      return axios.get(
        API_URL +
          `charges/resolve/amount/${amount}?source_currency=${source}&destination_currency=${destination}&page=${
            page || 0
          }`,
        { headers: authHeader() }
      );
    }
  },

  sendMoney: (data) => {
    return axios.post(API_URL + `transactions`, data, {
      headers: authHeader(),
    });
  },
  updateTransactionStatus: (id, status, staffId) => {
    return axios.patch(API_URL + `transactions/${id}/status-change/${status}/${staffId}`, null,{
      headers: authHeader(),
    });
  },

  getSpecificTransactionDetails: (transactionId) => {
    return axios.get(API_URL + "transactions/" + transactionId, {
      headers: authHeader(),
    });
  },
};

const UtilsApi = {
  getIdentificationConfigs: () => {
    return axios.get(API_URL + "configurations/identification-configurations", {
      headers: authHeader(),
    });
  },

  getSenderRelationships: () => {
    return axios.get(
      API_URL + "configurations/get-sender-relationships-types",
      { headers: authHeader() }
    );
  },

  getCountries() {
    return axios.get(API_URL + "configurations/get-country-listing-info", {
      headers: authHeader(),
    });
  },

  getStaffRoles() {
    return axios.get(API_URL + "configurations/roles/get-allowed-staff-roles", {
      headers: authHeader(),
    });
  },

  getAgentRoles() {
    return axios.get(API_URL + "configurations/roles/get-allowed-agent-roles", {
      headers: authHeader(),
    });
  },
  getPartners(list) {
    const listHeader =
      list === "allItems" ? { "x-instaant-do-single-pagination": true } : false;
    return axios.get(API_URL + "configurations/partners", {
      headers: authHeader(list),
    });
  },

  getTransferReasons() {
    return axios.get(
      API_URL + "configurations/transactions/get-allowed-purposes",
      { headers: authHeader() }
    );
  },
  getDestinationInstitutionsByCountry(country) {
    return axios.get(
      `${API_URL}configurations/destination-institutions/${country}`,
      { headers: authHeader() }
    );
  },
  getDestinationInstitutionsByWallet(disbursal_county_code, wallet_type) {
    return axios.get(
      `${API_URL}configurations/destination-institutions/${disbursal_county_code}/type/${wallet_type}`,
      { headers: authHeader() }
    );
  },
  getTransactionStatuses() {
    return axios.get(
      `${API_URL}configurations/transactions/get-allowed-statuses`,
      { headers: authHeader() }
    );
  },
};

const StaffApi = {
  getStaff: (page) => {
    return axios.get(API_URL + `staff?page=${page}`, { headers: authHeader() });
  },

  addStaff: (staff) => {
    const form = new FormData();
    Object.keys(staff).forEach((key) => {
      form.append(key, staff[key]);
    });
    return axios.post(API_URL + "staff", form, { headers: authHeader() });
  },
  updateStaff: (staff) => {
    return axios.patch(API_URL + "staff/" + staff.id, staff, {
      headers: authHeader(),
    });
  },
  removeStaff: (staff) => {
    return axios.delete(API_URL + "staff/" + staff.id, {
      headers: authHeader(),
    });
  },
  changePassword: (id, old_password, password, password_confirmation) => {
    return axios.post(
      API_URL + `staff/auth/update-password/${id}`,
      {
        old_password,
        password,
        password_confirmation,
      },
      { headers: authHeader() }
    );
  },
};

const EValueAPi = {
  topUpAgency: (data, id) => {
    return axios.patch(
      API_URL + `e-value/profiles/agency/${id}/topup-evalue-profile`,
      data,
      { headers: authHeader() }
    );
  },
  topUpAgent: (data, id) => {
    return axios.patch(
      API_URL + `e-value/profiles/agent/${id}/topup-evalue-profile`,
      data,
      { headers: authHeader() }
    );
  },
  topUpStaff: (data, id) => {
    return axios.patch(
      API_URL + `e-value/profiles/staff/${id}/topup-evalue-profile`,
      data,
      { headers: authHeader() }
    );
  },
  reduceAgencyDebt: (data, id) => {
    return axios.patch(
      API_URL + `e-value/profiles/agency/${id}/reduce-debt-profile`,
      data,
      { headers: authHeader() }
    );
  },
  reduceAgentDebt: (data, id) => {
    return axios.patch(
      API_URL + `e-value/profiles/agent/${id}/reduce-debt-profile`,
      data,
      { headers: authHeader() }
    );
  },
  reduceStaffDebt: (data, id) => {
    return axios.patch(
      API_URL + `e-value/profiles/staff/${id}/reduce-debt-profile`,
      data,
      { headers: authHeader() }
    );
  },
};
export default {
  SendersApi,
  ReceiversApi,
  AgencyApi,
  AgentsApi,
  AgentApi,
  TransactionsApi,
  UtilsApi,
  StaffApi,
  RatesApi,
  ChargesApi,
  CommissionsConfigApi,
  CommissionsApi,
  FundingStatementApi,
  EValueAPi,
};
