import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Input from "../Input";

const keys = [
  "account_number",
  "country_id",
  "destination_institution_id",
  "sender_id",
];
const Step2 = ({
  data,
  handleSubmit,
  senderOptions,
  countries,
  destinationInstitutions,
  onFormChange,
  relationships,
}) => {
  const [errors, setErrors] = useState({});

  const validate = () => {
    let errorList = { ...errors };
    keys.forEach((key) => {
      // TODO validate phone numbers
      if (!data[key]) {
        errorList[key] = "This field is required.";
      } else {
        delete errorList[key];
        if (key === "email") {
          const regex = /\S+@\S+\.\S+/;
          if (!regex.test(data[key])) {
            errorList[key] = "Enter a valid email.";
          }
        }
      }
    });
    setErrors(errorList);
    //console.log(errorList);
    return Object.keys(errorList).length === 0;
  };

  const save = () => {
    if (validate()) {
      handleSubmit();
    }
  };

  return (
    <div className="flex  intro-y flex-col items-center justify-center gap-11">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-theme-32 font-medium text-xl">Add Receiver</h1>
        <p className="text-[#727681] text-center text-xs font-normal">
          Please enter the receiver's personal details.
        </p>
      </div>

      <div className="flex flex-col md:max-w-[800px] flex-wrap items-center gap-2 justify-center w-full">
        <div className="grid w-full grid-cols-12 items-center flex-wrap gap-4">
          <div className="col-span-12 min-h-[90px] md:col-span-12  w-full  ">
            <Input
              containerStyle={"w-full md:w-auto"}
              labelFor={"account_number"}
              className="md:min-w-[200px] w-full"
              type="number"
              value={data.account_number}
              onChange={onFormChange}
              name="account_number"
              id={"account_number"}
              labelName={"Account Number or Mobile Money Number *"}
              placeholder="144454666"
            />
            {errors.account_number && (
              <p className="text-red-500 text-[10px] italic pt-1">
                {errors?.account_number}
              </p>
            )}
          </div>
        </div>

        <div className="grid w-full grid-cols-12 items-start flex-wrap gap-4">
          <div className="col-span-12 md:col-span-6 flex-col flex w-full md:w-auto gap-1">
            <label className="text-xs font-normal" htmlFor="type">
              Sender *
            </label>
            <div className="relative">
              <select
                id="type"
                value={data.sender_id}
                onChange={onFormChange}
                name="sender_id"
                className="border appearance-none w-full focus:border-theme-31 outline-none rounded px-4 py-2 border-theme-31"
              >
                <option value="" disabled selected>
                  Select a sender
                </option>

                {senderOptions?.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.label}
                    </option>
                  );
                })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
              {errors.type && (
                <p className="text-red-500 text-xs italic">{errors.type}</p>
              )}
            </div>
          </div>

          <div className="col-span-12 md:col-span-6 flex-col flex w-full md:w-auto gap-1">
            <label className="text-xs font-normal" htmlFor="type">
              Relationship To Sender
            </label>
            <div className="relative">
              <select
                id="type"
                value={data.relationship}
                onChange={onFormChange}
                name="relationship"
                className="border appearance-none w-full focus:border-theme-31 outline-none rounded px-4 py-2 border-theme-31"
              >
                {relationships.map((relationship) => {
                  return <option value={relationship}>{relationship}</option>;
                })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            {errors.relationship && (
              <p className="text-red-500 text-[10px] italic pt-1">
                {errors?.relationship}
              </p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-12 flex-wrap items-start gap-4 justify-center w-full">
          <div className="col-span-12 md:col-span-6 flex-col flex w-full md:w-auto gap-1">
            <label className="text-xs font-normal" htmlFor="type">
              Country *
            </label>
            <div className="relative">
              <select
                id="type"
                value={data.country_id}
                onChange={onFormChange}
                name="country_id"
                className="border appearance-none w-full focus:border-theme-31 outline-none rounded px-4 py-2 border-theme-31"
              >
                <option value="" hidden selected>
                  Select country
                </option>

                {countries?.map((country) => {
                  return (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  );
                })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
              {errors.country_id && (
                <p className="text-red-500 text-xs italic">
                  {errors.country_id}
                </p>
              )}
            </div>
          </div>

          <div className="col-span-12 md:col-span-6 flex-col flex w-full md:w-auto gap-1">
            <label className="text-xs font-normal" htmlFor="type">
              Destination Institution
            </label>
            <div className="relative flex flex-col gap-1">
              <select
                id="type"
                disabled={!data.calling_code}
                value={data?.destination_institution_id}
                onChange={onFormChange}
                name="destination_institution_id"
                className="border appearance-none w-full focus:border-theme-31 outline-none rounded px-4 py-2 border-theme-31"
              >
                <option value="" disabled selected>
                  Select a destination institution
                </option>
                {destinationInstitutions.map((institution) => {
                  return (
                    <option value={institution.id}>{institution.name}</option>
                  );
                })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
              {errors.destination_institution_id && (
                <p className="text-red-500 text-[10px] italic">
                  {errors.destination_institution_id}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className=" flex mt-6  justify-end flex-col gap-8 w-full ">
          <div className="flex justify-end ">
            <button
              onClick={save}
              className="flex items-center gap-1 bg-theme-32 border border-theme-32 hover:bg-theme-1 transition text-white rounded-md px-3 py-2"
            >
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
