import React, {useEffect, useState} from "react";
import restService from "../../../../services/restService";
import Input from "../../../../components/common/Input";

const keys = ["rate", "destination_currency", "source_currency"];
const UpdateRate = ({rate, onSubmit, initCallback, setRateToUpdate}) => {

  const [form, setForm] = useState(rate);
  const [errors, setErrors] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [agents, setAgents] = useState([]);

  const initFormValues = async () => {

    const countryList = await restService.UtilsApi.getCountries();
    const currencies = countryList?.data?.data?.map((c) => {
      return c.currency_code;
    });
    setCurrencies(currencies);
  };

  useEffect(() => {
    initFormValues();
  }, []);

  const validateForm = () => {
    let errorList = {...errors};
    keys.forEach((key) => {
      if (!form[key]) {
        errorList[key] = "This field is required.";
      }

      if (key === "rate" && form.rate && form.rate <= 0) {
        errorList.rate = "Enter a number greater than 0";
      }
    });
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };

  const saveRate = () => {
    if (validateForm()) {
      restService.RatesApi.updateRate(form).then((response) => {
        onSubmit();
      });
    }
  };

  const onFormChange = ({target}) => {
    let details = {...form};
    details[target.name] = target.value;

    if (target.name === "agency_id") {
      restService.AgentApi.getAgentsByAgency(target.value).then(({data}) => {
        setAgents(data?.data.data);
        setForm({...details, agent_id: data?.data?.data[0]?.id});
      });
      let errorList = {...errors};
      delete errorList["agent_id"];
    }
    setForm(details);

    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);
  };
  useEffect(() => {
    initCallback(saveRate);
  }, [form]);

  return (


    <div className="flex intro-y flex-col items-center md:gap-11 gap-6 justify-center py-10 px-10">
      <div className="flex items-start justify-between w-full">
        <div className="flex items-center flex-col gap-1 justify-center w-full">
          <h1 className="text-xl font-medium text-[#0042C6]">Update Rate</h1>
          <p className="text-xs text-[#727681]">
            Please enter the rate details
          </p>
        </div>

        <span
          onClick={() => setRateToUpdate(undefined)}
          className="btn border-none"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.8337 1.34199L10.6587 0.166992L6.00033 4.82533L1.34199 0.166992L0.166992 1.34199L4.82533 6.00033L0.166992 10.6587L1.34199 11.8337L6.00033 7.17533L10.6587 11.8337L11.8337 10.6587L7.17533 6.00033L11.8337 1.34199Z"
              fill="black"
            />
          </svg>
        </span>
      </div>

      <div className="grid md:grid-cols-2 gap-4 grid-cols-1">
        <Input
          containerStyle={"w-full md:w-auto"}
          labelFor={"firstName"}
          type="number"
          min={0}
          placeholder="2.3"
          value={form.rate}
          onChange={onFormChange}
          name="rate"
          labelName={" Rate *"}
        />
        <div className="flex flex-col gap-1">
          <label htmlFor="input-wizard-5" className="form-label mb-0 text-xs">
            Source Currency
          </label>
          <select
            id="custom-form-select"
            value={form.source_currency}
            onChange={onFormChange}
            name="source_currency"
            className="form-select"
          >
            {currencies.map((currency) => {
              return <option key={currency} value={currency}>{currency}</option>;
            })}
          </select>
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="input-wizard-5" className="form-label mb-0 text-xs">
            Destination Currency
          </label>
          <select
            id="custom-form-select"
            value={form.destination_currency}
            onChange={onFormChange}
            name="destination_currency"
            className="form-select"
          >
            {currencies.map((currency) => {
              return <option value={currency}>{currency}</option>;
            })}
          </select>
        </div>
      </div>

      <div className="flex items-center gap-4">
        <button
          onClick={() => setRateToUpdate(undefined)}
          className="btn rounded-md border font-medium border-[#2D2C2C] text-[#2D2C2C] px-6 py-2"
        >
          Cancel
        </button>
        <button
          onClick={() => saveRate(form)}
          className="btn btn-primary-2"
        >
          Update Rate
        </button>
      </div>
    </div>
  );
};

export default UpdateRate;
