import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import Otp from "./Otp";
import LoginForm from "./LoginForm";
import AuthService from "../../services/auth-service";

const StaffLogin = () => {
  const [msisdn, setMsisdn] = useState("");
  const [step, setStep] = useState(1);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const callback = (value) => {
    setShowChangePassword(value);
  };

  useEffect(() => {
    AuthService.getAccessToken();
  }, []);

  return (
    <div>
      <section className="min-h-screen flex items-stretch text-black ">
        <div className="lg:w-2/5 w-full gap-11 flex flex-col items-start justify-center bg-white text-center md:px-16 px-4 z-0">
          <h1 className="text-3xl font-bold">Instaant</h1>
          <div className="w-full py-6 z-20">
            {step === 1 ? (
              <LoginForm
                userType={"staff"}
                step={step}
                setStep={setStep}
                msisdn={msisdn}
                setMsisdn={setMsisdn}
                toggleReset={showChangePassword}
                callback={callback}
              />
            ) : (
              <Otp userType={"staff"} msisdn={msisdn} />
            )}
          </div>
        </div>

        <div className="auth-bg-cover-theme w-full lg:flex flex-col hidden">
          <div className=" bg-auth-img bg-no-repeat bg-cover relative">
            <p className="absolute bottom-4 right-16 text-white text-xs">
              Privacy Policy & Terms of Use
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

function mapStateToProps(state) {
  const {isLoggedIn} = state.auth;
  const {message} = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default connect(mapStateToProps)(StaffLogin);
