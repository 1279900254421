import React, {useState} from "react";
import Charts from "react-apexcharts";
const LineChart = () => {
  const [series, setSeries] = useState([
    {
      name: "Agency 1",
      data: [
        [1546300800000, 2000],
        [1548979200000, 2500],
        [1551398400000, 3000],
        [1554166400000, 3500],
        [1556758400000, 4000],
      ],
    },
    {
      name: "Agency 2",
      data: [
        [1546300800000, 22000],
        [1548979200000, 2540],
        [1551398400000, 6000],
        [1554166400000, 4500],
        [1556758400000, 10000],
      ],
    },
    {
      name: "Agency 3",
      data: [
        [1546300800000, 2400],
        [1548979200000, 21240],
        [1551398400000, 640],
        [1554166400000, 3400],
        [1556758400000, 8000],
      ],
    },
    {
      name: "Agency 4",
      data: [
        [1546300800000, 12400],
        [1548979200000, 5540],
        [1551398400000, 6560],
        [1554166400000, 1500],
        [1556758400000, 1000],
      ],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: "area",
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    stroke: {
      width: 2,
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: "",
    },
    yaxis: {
      labels: {
        // formatter: function (val) {
        //   return (val / 1000000).toFixed(0);
        // },
      },
      title: {
        text: "",
      },
    },
    xaxis: {
      type: "datetime",
      //   min: new Date("01 Mar 2012").getTime(),
    },
    tooltip: {
      shared: false,
      y: {
        // formatter: function (val) {
        //   return (val / 1000000).toFixed(0);
        // },
      },
      x: {
        format: "dd MMM yyyy",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0,
        opacityFrom: 0,
        opacityTo: 0, // set opacity to 0 to turn off the fill
        stops: [0, 100],
      },
    },
    colors: ["#FCBB14", "#C6005F", "#0042C6", "#B4E29E"],
  });

  return (
    <div>
      <Charts options={options} series={series} type="area" height={350} />
    </div>
  );
};

export default LineChart;
