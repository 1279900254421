import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import commissionsData from "../../../assets/static_json/commisions.json";
import TransactionStyles from "../Transactions/styles";
import restService from "../../../services/restService";
import {useSelector} from "react-redux";
import moment from "moment";
import Search from "../../../components/common/Search";
import {downloadFile} from "../../../helpers/fileUtils";
import FilterModal from "./FilterModal";
import {Empty} from "antd";
import PaginationComponent from "../../../components/common/Pagination";
import DateRangePicker from "../../../components/common/DateRange";
import Table2 from "../../../components/common/Table/Table2";
import SkeletonElement from "../../../components/Skeletons/SkeletonElement";
import {addDays} from "date-fns";


const Commissions = () => {
  const agentId = useSelector((state) => state?.auth?.user?.id);
  const agencyId = useSelector((state) => state?.auth?.user?.agency?.id);
  const roleId = useSelector((state) => state?.auth?.user?.agent_role_id);

  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageTo, setPageTo] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [from, setFrom] = useState(0);
  const [commissions, setCommissions] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [showDateRange, setShowDateRange] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const applyFilter = (values) => {
    setShowModal(false);

    setLoading(true);
    if (values.dateRange) {
      setStartDate(values.dateRange[0].format("YYYY-MM-DD"));
      setEndDate(values.dateRange[1].format("YYYY-MM-DD"));
    }

    restService.CommissionsApi.getCommissionsByDateRange(
      values.dateRange[0].format("YYYY-MM-DD"),
      values.dateRange[1].format("YYYY-MM-DD")
    )
      .then((response) => {
        setStartDate("");
        setEndDate("");
        setPageTo(response.data?.data?.to);
        setTotalEntries(response.data?.data?.total);
        setCommissions(response.data?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.data?.code === 404) {
          setCommissions([]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCommissions = (agentId, roleId, page) => {
    setLoading(true);

    if (roleId === 3) {
      restService.CommissionsApi.getCommissionsByAgent(agentId, page)
        .then((response) => {
          if (response) {
            setPageTo(response.data?.data?.to);
            setTotalEntries(response.data?.data?.total);
            setFrom(response.data?.data?.from);
            setCommissions(response.data?.data?.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (roleId === 1) {
      restService.CommissionsApi.getCommissionsByAgency(agencyId, page)
        .then((response) => {
          if (response) {
            setPageTo(response.data?.data?.to);
            setTotalEntries(response.data?.data?.total);
            setCommissions(response.data?.data?.data);
            setFrom(response.data?.data?.from);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    setLoading(false);
  };

  const exportCommission = () => {
    let headers = ["ID,Commission,Transaction Amount,Date Created "];

    let commissionCsv = commissions.reduce((acc, commission) => {
      const {
        transaction_id,
        agent_commission_amount,
        transaction: {send_amount},
        created_at,
      } = commission;
      acc.push(
        [
          transaction_id,
          agent_commission_amount,
          send_amount,
          moment(new Date(created_at)).format("MMMM DD YYYY"),
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...commissionCsv].join("\n"),
      fileName: `Commission-${moment().format("YYYY-MM-DD-H-m-s")}.csv`,
      fileType: "text/csv",
    });
  };

  useEffect(() => {
    fetchCommissions(agentId, roleId);
  }, []);

  const pageChange = (page) => {
    setCurrentPage(page);
    fetchCommissions(agentId, roleId, page);
  };

  const [viewTransactions, setViewTransactions] = useState(false);

  return (
    // <TransactionStyles>
    //   <PageTitle title={`Commissions`} />
    //   <div className="content-layout">
    //     <div className="header-layout">
    //       <div className="page">
    //         Showing {from} to {pageTo} of {totalEntries} entries
    //       </div>
    //       <Search
    //         placeholder={"Search Transaction"}
    //         onChange={(e) => setSearchTerm(e.target.value)}
    //       />

    //       <div className="intro-y flex flex-col-reverse sm:flex-row items-center mr-3">
    //         <div
    //           className="flex items-center w-12 h-8 cursor-pointer text-gray-700 dark:text-gray-300"
    //           role="button"
    //           aria-expanded="false"
    //         >
    //           {/*<button className="btn btn-primary" >Filter</button>*/}
    //           <button
    //             onClick={(e) => {
    //               setShowModal(true);
    //             }}
    //             className="btn btn-primary"
    //           >
    //             Filter
    //           </button>
    //         </div>
    //       </div>

    //       <div className="intro-y flex flex-col-reverse sm:flex-row items-center mr-3">
    //         <button className="btn btn-primary" onClick={exportCommission}>
    //           {" "}
    //           Download as CSV{" "}
    //         </button>
    //       </div>
    //     </div>

    //     {showModal && (
    //       <FilterModal
    //         visible={showModal}
    //         onCreate={applyFilter}
    //         onCancel={() => {
    //           setShowModal(false);
    //         }}
    //       />
    //     )}

    //     {!loading ? (
    //       <div className="table-container">
    //         <Table
    //           headData={head}
    //           renderHead={(item, index) => renderHeadData(item, index)}
    //           bodyData={body}
    //           renderBody={(item, index) => renderBodyData(item, index)}
    //         />
    //       </div>
    //     ) : (
    //       <>
    //         <Table
    //           headData={head}
    //           renderHead={(item, index) => renderHeadData(item, index)}
    //         />
    //         {Array(3)
    //           .fill()
    //           .map((item, index) => (
    //             <SkeletonTransaction key={index} />
    //           ))}
    //       </>
    //     )}
    //   </div>
    //   <div className="mt-3 float-right">
    //     <Pagination
    //       current={currentPage}
    //       defaultCurrent={currentPage}
    //       showSizeChanger={false}
    //       onChange={pageChange}
    //       total={totalEntries}
    //     />
    //   </div>
    // </TransactionStyles>

    <>
      <div className="mt-13-important">
        <TransactionStyles>
          <div className="content-layout">
            <div className="header-layout gap-2">
              <div
                onClick={() => {
                  setShowModal(true);
                }}
                className="flex md:items-center w-full gap-2 md:flex-row flex-col "
              >
                <Search
                  placeholder={"Search "}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div className=" flex flex-col-reverse w-full justify-end sm:flex-row md:items-center">
                <div className=" gap-4 flex justify-end md:justify-end sm:flex-row items-center md:mr-3">
                  <DateRangePicker
                    range={dateRange}
                    show={showDateRange}
                    setShow={setShowDateRange}
                    setRange={setDateRange}
                  />

                  <button
                    className="btn btn-primary-2  flex items-center gap-2 text-xs"
                    onClick={exportCommission}
                  >
                    <span>
                      <svg
                        width="9"
                        height="11"
                        viewBox="0 0 9 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.77902 4.4763C1.63547 4.4763 1.4978 4.53332 1.3963 4.63482C1.2948 4.73633 1.23777 4.87399 1.23777 5.01754V9.70832C1.23777 9.85187 1.2948 9.98954 1.3963 10.091C1.4978 10.1925 1.63547 10.2496 1.77902 10.2496H7.19146C7.33501 10.2496 7.47267 10.1925 7.57418 10.091C7.67568 9.98954 7.7327 9.85187 7.7327 9.70832V5.01754C7.7327 4.87399 7.67568 4.73633 7.57418 4.63482C7.47267 4.53332 7.33501 4.4763 7.19146 4.4763H6.28939C6.0901 4.4763 5.92856 4.31475 5.92856 4.11547C5.92856 3.91619 6.0901 3.75464 6.28939 3.75464H7.19146C7.5264 3.75464 7.84763 3.88769 8.08447 4.12453C8.32131 4.36137 8.45436 4.6826 8.45436 5.01754V9.70832C8.45436 10.0433 8.32131 10.3645 8.08447 10.6013C7.84763 10.8382 7.5264 10.9712 7.19146 10.9712H1.77902C1.44407 10.9712 1.12285 10.8382 0.886009 10.6013C0.649169 10.3645 0.516113 10.0433 0.516113 9.70832V5.01754C0.516113 4.6826 0.649169 4.36137 0.886009 4.12453C1.12285 3.88769 1.44407 3.75464 1.77902 3.75464H2.68109C2.88037 3.75464 3.04192 3.91619 3.04192 4.11547C3.04192 4.31475 2.88037 4.4763 2.68109 4.4763H1.77902Z"
                          fill="white"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.84613 0.86792C5.04541 0.86792 5.20696 1.02178 5.20696 1.21157V6.91122L6.39513 5.77963C6.53604 5.64543 6.76451 5.64543 6.90542 5.77963C7.04633 5.91383 7.04633 6.13142 6.90542 6.26562L5.10127 7.98386C4.96036 8.11806 4.7319 8.11806 4.59098 7.98386L2.78684 6.26562C2.64592 6.13142 2.64592 5.91383 2.78684 5.77963C2.92775 5.64543 3.15621 5.64543 3.29713 5.77963L4.4853 6.91122V1.21157C4.4853 1.02178 4.64685 0.86792 4.84613 0.86792Z"
                          fill="white"
                        />
                      </svg>
                    </span>

                    <span>Download CSV</span>
                  </button>
                </div>
              </div>
            </div>

            {showModal && (
              <FilterModal
                visible={showModal}
                onCreate={applyFilter}
                onCancel={() => {
                  setShowModal(false);
                }}
              />
            )}

            <div className="header-layout intro-y gap-2">
              {!loading ? (
                <div className="flex w-full flex-col">
                  <Table2
                    // headData={head}
                    tHead={
                      <>
                        <th>
                          <input type="checkbox" name="" value="" />
                        </th>
                        <th className="">ID</th>
                        <th className="">Transaction Amount</th>
                        <th className="">Timestamp</th>
                        <th className="">Commission</th>
                        <th className="">Action</th>
                      </>
                    }
                    // renderHead={(item, index) => renderHeadData(item, index)}
                  >
                    {commissions.map((item, index) => (
                      <tr key={index} className=" text-[#555555] font-normal">
                        <td>
                          <input type="checkbox" name="" value="" />
                        </td>

                        <td>
                          <p className="text-black">{item?.transaction_id}</p>
                        </td>
                        <td>
                          <p className="text-black">
                            {item?.source_currency}
                            {item?.transaction.send_amount}
                          </p>
                        </td>
                        <td>
                          <p className="text-[#999999]">
                            {moment(new Date(item.created_at)).format(
                              "MMMM d, YYYY"
                            )}
                          </p>
                        </td>
                        <td>
                          <p className="text-[#999999]">
                            {item.source_currency}{" "}
                            {item?.agent_commission_amount}
                          </p>
                        </td>
                        <td>
                          <Link
                            to={`/transaction/${item?.transaction.id}`}
                            className="rounded-md px-2 py-2 border border-[#555555] text-[#555555] text-center"
                          >
                            View Transaction
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </Table2>

                  {commissions.length < 1 && (
                    <div className="flex items-center justify-center h-[30vh]">
                      <Empty />
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex w-full flex-col">
                  <Table2
                    // headData={head}
                    tHead={
                      <>
                        <th>
                          <input type="checkbox" name="" value="" />
                        </th>
                        <th className="">ID</th>
                        <th className="">Transaction Amount</th>
                        <th className="">Timestamp</th>
                        <th className="">Commission</th>
                        <th className="">Action</th>
                      </>
                    }
                    // renderHead={(item, index) => renderHeadData(item, index)}
                  >
                    {Array(10)
                      .fill()
                      .map((item, index) => (
                        <tr key={index}>
                          {Array(6)
                            .fill()
                            .map((x, i) => (
                              <td key={i}>
                                <SkeletonElement type={"title"} />
                              </td>
                            ))}
                        </tr>
                      ))}
                  </Table2>
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col mb-[10vh]  gap-4 justify-between md:items-center md:flex-row mt-8">
            <div className="page text-[#999999] text-xs">
              {loading
                ? ""
                : commissions && commissions.length <= 0
                ? "No Transactions Found"
                : `Showing  ${pageTo} out of ${totalEntries} results`}
            </div>

            <div className="mt-3 flex justify-end float-right">
              <PaginationComponent
                current={currentPage}
                defaultCurrent={currentPage}
                showSizeChanger={false}
                onChange={pageChange}
                total={totalEntries}
              />
            </div>
          </div>
        </TransactionStyles>
      </div>

      {/* <div className="mt-[13vh]  w-full flex flex-col gap-4">
          <span>
            <svg
              className="cursor-pointer"
              onClick={() => setViewTransactions(false)}
              width="15"
              height="14"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.14446 1.14446L3.09818 5.19074H12.4741V6.80926H3.09818L7.14446 10.8555L6 12L0 6L6 0L7.14446 1.14446Z"
                fill="black"
              />
            </svg>
          </span>

          <TransactionReceipt
            viewTransactions={viewTransactions}
            setViewTransactions={setViewTransactions}
          />
        </div> */}
    </>
  );
};
export default Commissions;
