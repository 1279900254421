import styled from 'styled-components';
import tw from 'twin.macro'


const TableStyles = styled.div.attrs({
  className : "overflow-auto lg:overflow-visible mt-8 sm:mt-0"
})`
  & {
      table{
        ${tw ``}
        th {
          ${tw`whitespace-nowrap`}
        }

        td{
          .name{
            ${tw`font-medium whitespace-nowrap`},
          }
          .id{
            ${tw`text-gray-600 text-xs whitespace-nowrap mt-0.5`}
          }
        }
      }
  }
`
export default TableStyles;
