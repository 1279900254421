import { ChevronRight, UserPlus } from "react-feather";
import Button from "../../../../../components/common/Button";

export default function AddItemCard({ label = "Add New Sender", ...props }) {
  return (
    <Button className="btn btn-primary flex items-center gap-2" {...props}>
      <div className="rounded-full bg-white h-6 w-6 flex items-center justify-center">
        <UserPlus size={14} color="#1c3faa" />
      </div>
      <p>{label}</p>
      <ChevronRight size={14} />
    </Button>
  );
}
