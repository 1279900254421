import React from "react";
import AddIdentification from "../AddNewSender/AddIdentification";

const Step2 = ({data, handleSubmit}) => {
  return (
    <AddIdentification
      description={
        "Upload the staff's identification and proof of address. Upload full images or front and back images"
      }
      handleSubmit={handleSubmit}
      data={data}
    />
  );
};

export default Step2;
