import React, {useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import Otp from "./Otp";
import LoginForm from "./LoginForm";
import AuthService from "../../services/auth-service";
import ResetPasswordForm from "./ResetPasswordForm";
import ResetPassword from "./ResetPassword";
import {Link} from "react-router-dom";

const StaffPasswordReset = () => {
  const [msisdn,setMsisdn] = useState("");
  const [step, setStep] = useState(1);
  const [staff, setStaff] = useState({});

  const onResetSuccess = (staff) => {
    setStaff(staff)
    setStep(step + 1)
  }

  useEffect(() => {
    AuthService.getAccessToken();
  }, []);


  return (
      <div>
        <section className="min-h-screen flex items-stretch text-white ">
          <div className="lg:flex flex-col w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative " style={{backgroundImage:"url(https://images.unsplash.com/photo-1577495508048-b635879837f1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=675&q=80)"}}>
            <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
            <div className="w-full p-3 z-10">
              <Link to={"/staff"}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24"
                     stroke="white">
                  <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2"
                        d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"/>
                </svg>
              </Link>
            </div>
            <div className="w-full px-24 z-10 m-auto">
              <h1 className="text-5xl font-bold text-left tracking-wide text-white  text-center">Reset Password</h1>
              <p className="text-2xl my-4 text-center">You will receive an SMS with a temporary password that will be used to create a new password</p>
            </div>
          </div>
          <div className="lg:w-1/2 w-full flex items-center justify-center text-center md:px-16 px-0 z-0" style={{backgroundColor: "#161616"}}>
            <div className="absolute lg:hidden z-10 inset-0 bg-gray-500 bg-no-repeat bg-cover items-center" style={{backgroundImage: "url(https://images.unsplash.com/photo-1577495508048-b635879837f1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=675&q=80)"}}>
              <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
            </div>
            <div className="w-full py-6 z-20">
              <div className="w-full px-24 z-10">
                <p className="text-3xl my-4">Reset Your Password</p>
              </div>

              { step === 1 ?
                  <ResetPasswordForm userType={"staff"} step={step} setStep={setStep} msisdn={msisdn} setMsisdn={setMsisdn} onSuccess={onResetSuccess}/>
                  :
                  <ResetPassword userType={"staff"} staffOrAgent={staff} />
              }
            </div>
          </div>
        </section>

      </div>
  );
};

function mapStateToProps(state) {
  const {isLoggedIn} = state.auth;
  const {message} = state.message;
  return {
    isLoggedIn,
    message
  };
}

export default connect(mapStateToProps)(StaffPasswordReset);
