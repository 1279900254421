import RestService from "../../services/restService";
import {SET_USER} from "./types";

export const updateAgent = (user) => (dispatch) => {
    return RestService.AgentApi.updateAgent(user)
        .then((data) => {
            dispatch({
                type: SET_USER,
                payload: { user: user },
            });
        })
}
