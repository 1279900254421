/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from "react";
import PageTitle from "../../../components/common/PageTitle/PageTitle";
import {Chart, registerables} from "chart.js";
import "chart.js/auto";
import DateRangePicker from "../../../components/common/DateRange";
import {
  Calendar,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  ChevronUp,
  CreditCard,
  Plus,
  RefreshCcw,
  User,
} from "react-feather";
import chartConfig from "../../../assets/static_json/chartsConfig.json";
import "react-datepicker/dist/react-datepicker.css";
import LitePicker from "litepicker";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import SENDERS_ICON from "../../../assets/images/icons/senders.svg";
import CREDIT_CARD_ICON from "../../../assets/images/icons/creditCard.svg";
import WALLET_ICON from "../../../assets/images/icons/wallet.svg";
import RECEIVERS_ICON from "../../../assets/images/icons/receivers.svg";
import AGENTS_ICON from "../../../assets/images/icons/agents.svg";
import DashboardCard from "../../../components/common/DashboardCard";
import LineChart from "./LineChart";
import ApexChartComponent from "../../../components/Charts/ApexChartComponent";
import {addDays} from "date-fns";

Chart.register(...registerables);

const Dashboard = () => {
  //Chart Refs
  const agentsChart = useRef(null);
  const transactionsChart = useRef(null);
  const transactionsStatusChart = useRef(null);
  const sendersChart = useRef(null);
  const receiversChart = useRef(null);
  const datePicker = useRef(null);

  const transactionsChartConfig = chartConfig.transactionsChartConfig;
  const agentsPieChart = chartConfig.agentsPieChartConfig;
  const transactionsStatusChartConfig =
    chartConfig.transactionsStatusChartConfig;
  const senderChartConfig = chartConfig.sendersChart;
  const receiversChartConfig = chartConfig.receiversChart;

  const user = useSelector((state) => state.auth.user);

  const [agentsChartInstance, setAgentsChartInstance] = useState(null);
  const [transactionsChartInstance, setTranactionsChartInstance] =
    useState(null);
  const [transactionsStatusChartInstance, setTranactionsStatusChartInstance] =
    useState(null);
  const [sendersInstance, setSendersInstance] = useState(null);
  const [receiversInstance, setReceiversInstance] = useState(null);
  const [showDateRange, setShowDateRange] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (agentsChart && agentsChart.current) {
      const newChartInstance = new Chart(
        agentsChart.current.getContext("2d"),
        agentsPieChart
      );
      setAgentsChartInstance(newChartInstance);
    }

    if (transactionsChart && transactionsChart.current) {
      const newChartInstance = new Chart(
        transactionsChart.current.getContext("2d"),
        transactionsChartConfig
      );
      setTranactionsChartInstance(newChartInstance);
    }

    if (transactionsStatusChart && transactionsStatusChart.current) {
      const newChartInstance = new Chart(
        transactionsStatusChart.current.getContext("2d"),
        transactionsStatusChartConfig
      );
      setTranactionsStatusChartInstance(newChartInstance);
    }

    if (sendersChart && sendersChart.current) {
      const newChartInstance = new Chart(
        sendersChart.current.getContext("2d"),
        senderChartConfig
      );
      setSendersInstance(newChartInstance);
    }

    if (receiversChart && receiversChart.current) {
      const newChartInstance = new Chart(
        receiversChart.current.getContext("2d"),
        receiversChartConfig
      );
      setReceiversInstance(newChartInstance);
    }

    // new LitePicker({
    //   autoApply: true,
    //   element: datePicker.current,
    //   firstDay: 0,
    //   format: "MM/DD/YYYY",
    //   mobileFriendly: true,
    //   numberOfColumns: 2,
    //   numberOfMonths: 2,
    //   singleMode: false,
    //   onSelect: (date1, date2) => {
    //     console.log(date1, date2);
    //   },
    // });
  }, [transactionsChart]);

  return (
    <>
      {/* <PageTitle title={`Dashboard`} /> */}
      <div className="grid  grid-cols-12 gap-6 my-[5rem]">
        <div className="col-span-12 xxl:col-span-12">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 mt-8">
              <div className=" flex  items-center h-10">
                <h2 className="text-lg font-medium truncate mr-5">
                  General Report
                </h2>
                <div className="ml-auto flex items-center">
                  <DateRangePicker
                    range={dateRange}
                    show={showDateRange}
                    setShow={setShowDateRange}
                    setRange={setDateRange}
                  />
                </div>
              </div>

              <div className="grid intro-y grid-cols-12 gap-6 mt-5">
                <div className="xxl:col-span-9 xl:col-span-9 lg:col-span-8  col-span-12 gap-6 ">
                  <div className="col-span-12 intro-y gap-6 grid grid-cols-12">
                    <div className="col-span-12 sm:col-span-6 xl:col-span-4 lg:col-span-4 ">
                      <DashboardCard
                        containerStyle={" zoom-in"}
                        icon={CREDIT_CARD_ICON}
                        isTrendingUp={true}
                        value={"22,500"}
                        label={"Transactions Processed"}
                        trendingValue={"12%"}
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6 xl:col-span-4 lg:col-span-4">
                      <DashboardCard
                        containerStyle={"zoom-in"}
                        icon={WALLET_ICON}
                        isTrendingUp={false}
                        value={"22,500"}
                        label={"Total Transaction Amount"}
                        trendingValue={"12%"}
                        currency={"$"}
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6 xl:col-span-4 lg:col-span-4">
                      <DashboardCard
                        containerStyle={"zoom-in"}
                        icon={AGENTS_ICON}
                        isTrendingUp={true}
                        value={"213"}
                        label={"Total Agents"}
                        trendingValue={"12%"}
                      />
                    </div>
                  </div>
                  {/* top agents */}
                  <div className="border md:flex items-center gap-4 px-4 py-5 bg-white border-[#DEDEDE] rounded-md col-span-12 lg:col-span-6 mt-8">
                    <div className="flex-[1.6] w-full">
                      <div className=" block sm:flex items-center h-10 ">
                        <h2 className="text-base text-black font-medium truncate mr-5">
                          Top Agents
                        </h2>
                        <div className="sm:ml-auto flex items-center gap-4 mt-3 sm:mt-0 relative text-gray-700 dark:text-gray-300">
                          <div className="bg-white py-1 px-2 border border-[#DEDEDE] rounded-sm">
                            <p>Week</p>
                          </div>

                          <DateRangePicker
                            range={dateRange}
                            show={showDateRange}
                            setShow={setShowDateRange}
                            setRange={setDateRange}
                          />
                        </div>
                      </div>
                      <div className="intro-y mt-12 sm:mt-5">
                        {/* <div className="flex flex-col xl:flex-row xl:items-center">
                        <div className="flex">
                          <div>
                            <div className="text-theme-19 dark:text-gray-300 text-lg xl:text-xl font-bold">
                              112
                            </div>
                            <div className="mt-0.5 text-gray-600 dark:text-gray-600">
                              Total Transactions
                            </div>
                          </div>
                          <div className="w-px h-12 border border-r border-dashed border-gray-300 dark:border-dark-5 mx-4 xl:mx-6"></div>
                          <div>
                            <div className="text-gray-600 dark:text-gray-600 text-lg xl:text-xl font-medium">
                              ₵10,000
                            </div>
                            <div className="mt-0.5 text-gray-600 dark:text-gray-600">
                              Total Amount
                            </div>
                          </div>
                        </div>
                      </div> */}
                        {/* <div className="report-chart">
                          <canvas
                            ref={transactionsChart}
                            height="169"
                            className="mt-6"
                          ></canvas>
                        </div> */}

                        <LineChart />
                      </div>
                    </div>

                    <ul className="flex-[.4] flex-row flex-wrap w-full flex md:flex-col gap-6 h-full items-center justify-center">
                      <li className="flex md:flex-row flex-col-reverse items-start gap-1 md:gap-3">
                        <div>
                          <p className="text-[10px] font-normal text-black">
                            Agency
                          </p>
                          <h5 className=" text-xs font-medium text-black">
                            £55,000
                          </h5>
                        </div>

                        <div className="h-4 w-4 rounded-sm bg-[#FCBB14]"></div>
                      </li>
                      <li className="flex md:flex-row flex-col-reverse items-start gap-1 md:gap-3">
                        <div>
                          <p className="text-[10px] font-normal text-black">
                            Agency
                          </p>
                          <h5 className=" text-xs font-medium text-black">
                            £55,000
                          </h5>
                        </div>

                        <div className="h-4 w-4 rounded-sm bg-[#C6005F]"></div>
                      </li>
                      <li className="flex md:flex-row flex-col-reverse items-start gap-1 md:gap-3">
                        <div>
                          <p className="text-[10px] font-normal text-black">
                            Agency
                          </p>
                          <h5 className=" text-xs font-medium text-black">
                            £55,000
                          </h5>
                        </div>

                        <div className="h-4 w-4 rounded-sm bg-[#0042C6]"></div>
                      </li>
                      <li className="flex md:flex-row flex-col-reverse items-start gap-1 md:gap-3">
                        <div>
                          <p className="text-[10px] font-normal text-black">
                            Agency
                          </p>
                          <h5 className=" text-xs font-medium text-black">
                            £55,000
                          </h5>
                        </div>

                        <div className="h-4 w-4 rounded-sm bg-[#B4E29E]"></div>
                      </li>
                    </ul>
                  </div>

                  {/* transaction */}
                  <div className="border grid grid-cols-12  gap-4 px-4 py-5 bg-white border-[#DEDEDE] rounded-md col-span-12 lg:col-span-6 mt-8">
                    <div className="col-span-12 md:col-span-6 w-full">
                      <div className=" block sm:flex items-center h-10 ">
                        <h2 className="text-base text-black font-medium truncate mr-5">
                          Transaction Volume
                        </h2>
                        {/* <div className="sm:ml-auto flex items-center gap-4 mt-3 sm:mt-0 relative text-gray-700 dark:text-gray-300">
                          <div className="bg-white py-1 px-2 border border-[#DEDEDE] rounded-sm">
                            <p>Week</p>
                          </div>

                          <DateRangePicker
                            show={showDateRange}
                            setShow={setShowDateRange}
                          />
                        </div> */}
                      </div>
                      <div className="intro-y mt-12 sm:mt-5">
                        <ApexChartComponent
                          series={[
                            {
                              name: "Transaction",
                              data: [
                                [1546300800000, 2000],
                                [1548979200000, 2500],
                                [1551398400000, 3000],
                                [1554166400000, 3500],
                                [1556758400000, 4000],
                              ],
                            },
                          ]}
                          colors={["#0042C6"]}
                          fill={{
                            type: "gradient",
                            gradient: {
                              shadeIntensity: 0,
                              opacityFrom: 0,
                              opacityTo: 0, // set opacity to 0 to turn off the fill
                              stops: [0, 100],
                            },
                          }}
                          type={"area"}
                        />
                      </div>
                    </div>

                    <div className="col-span-12 md:col-span-6">
                      <div className=" block sm:flex items-center h-10 ">
                        <h2 className="text-base text-black font-medium truncate mr-5">
                          Transaction Volume
                        </h2>
                        <div className="sm:ml-auto flex items-center gap-4 mt-3 sm:mt-0 relative text-gray-700 dark:text-gray-300">
                          <div className="bg-white py-1 text-black px-2 border border-[#DEDEDE] rounded-sm">
                            <p>Current Week</p>
                          </div>
                        </div>
                      </div>

                      <div className="intro-y mt-12 sm:mt-5">
                        <ApexChartComponent
                          series={[
                            {
                              name: "Transaction",
                              data: [
                                [1546300800000, 2000],
                                [1548979200000, 2500],
                                [1551398400000, 3000],
                                [1554166400000, 3500],
                                [1556758400000, 4000],
                              ],
                            },
                          ]}
                          colors={["#292D30"]}
                          fill={{
                            type: "gradient",
                            gradient: {
                              shadeIntensity: 0,
                              opacityFrom: 0,
                              opacityTo: 0, // set opacity to 0 to turn off the fill
                              stops: [0, 100],
                            },
                          }}
                          type={"area"}
                          curve={"straight"}
                          dropShadow={{
                            enabled: true,
                            enabledOnSeries: undefined,
                            top: 0,
                            left: 0,
                            blur: 2.02,
                            color: "#000",
                            opacity: 0.25,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-12  xxl:col-span-3 xl:col-span-3 lg:col-span-3 flex flex-col gap-5">
                  <div className=" items-center justify-center gap-6 px-4 py-5 border bg-white border-[#DEDEDE] rounded-md">
                    <div className="intro-y text-center">
                      <h2 className="text-base font-normal">
                        Transactions Status
                      </h2>
                    </div>
                    <div className="intro-y  p-5 ">
                      <canvas
                        ref={transactionsStatusChart}
                        className="mt-3"
                        height="300"
                      ></canvas>
                      <div className="mt-8">
                        <div className="flex items-center">
                          <div className="w-2 h-2 bg-theme-1 rounded-full mr-3"></div>
                          <span className="truncate">Successful</span>
                          <div className="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                          <span className="font-medium xl:ml-auto">62%</span>
                        </div>
                        <div className="flex items-center mt-1">
                          <div className="w-2 h-2 bg-theme-12 rounded-full mr-3"></div>
                          <span className="truncate">Pending</span>
                          <div className="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                          <span className="font-medium xl:ml-auto">33%</span>
                        </div>
                        <div className="flex items-center mt-1">
                          <div className="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                          <span className="truncate">Failed</span>
                          <div className="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                          <span className="font-medium xl:ml-auto">10%</span>
                        </div>
                        <div className="flex items-center mt-1">
                          <div className="w-2 h-2 bg-theme-6 rounded-full mr-3"></div>
                          <span className="truncate">Reversed</span>
                          <div className="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                          <span className="font-medium xl:ml-auto">2%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" flex flex-col items-center justify-center gap-6 px-4 py-5 border bg-white border-[#DEDEDE] rounded-md">
                    <div className="intro-y text-center">
                      <h2 className="text-base font-medium">Top Countries</h2>
                    </div>

                    <ul className="intro-y w-full flex flex-col gap-2">
                      <li className="flex flex-col w-full gap-2">
                        <p className="flex w-full items-center justify-between">
                          <span>United Kingdom</span>
                          <span>£50,000</span>
                        </p>
                        <progress value="70" max="100"></progress>
                      </li>
                      <li className="flex flex-col w-full gap-2">
                        <p className="flex w-full items-center justify-between">
                          <span>Ghana</span>
                          <span>£50,000</span>
                        </p>
                        <progress value="60" max="100"></progress>
                      </li>
                      <li className="flex flex-col w-full gap-2">
                        <p className="flex w-full items-center justify-between">
                          <span>Nigeria</span>
                          <span>£50,000</span>
                        </p>
                        <progress value="50" max="100"></progress>
                      </li>
                      <li className="flex flex-col w-full gap-2">
                        <p className="flex w-full items-center justify-between">
                          <span>Ivory Coast</span>
                          <span>£50,000</span>
                        </p>
                        <progress value="40" max="100"></progress>
                      </li>
                      <li className="flex flex-col w-full gap-2">
                        <p className="flex w-full items-center justify-between">
                          <span>Netherlands</span>
                          <span>£50,000</span>
                        </p>
                        <progress value="20" max="100"></progress>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/*// <!-- END: General Report -->*/}
            {/*// <!-- BEGIN: Sales Report -->*/}

            {/*<div className="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">*/}
            {/*  <div className="intro-y flex items-center h-8">*/}
            {/*    <h2 className="text-lg font-medium truncate mr-5">*/}
            {/*      Agents*/}
            {/*    </h2>*/}
            {/*    <a href="#" className="ml-auto text-theme-1 dark:text-theme-10 truncate">Show More</a>*/}
            {/*  </div>*/}

            {/*  /!*<div className="intro-y box p-5 mt-5">*!/*/}
            {/*  /!*  <canvas ref={agentsChart} className="mt-5" height="350"></canvas>*!/*/}

            {/*  /!*  <div className="mt-10">*!/*/}
            {/*  /!*    <div className="flex items-center">*!/*/}
            {/*  /!*      <div className="w-2 h-2 bg-theme-1 rounded-full mr-3"></div>*!/*/}
            {/*  /!*      <span className="truncate">Active</span>*!/*/}
            {/*  /!*      <div className="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>*!/*/}
            {/*      <span className="font-medium xl:ml-auto">65%</span>*/}
            {/*    </div>*/}
            {/*    <div className="flex items-center mt-4 mb-4">*/}
            {/*      <div className="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>*/}
            {/*  /!*      <span className="truncate">Inactive</span>*!/*/}
            {/*  /!*      <div className="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>*!/*/}
            {/*  /!*      <span className="font-medium xl:ml-auto">33%</span>*!/*/}
            {/*  /!*    </div>*!/*/}
            {/*  /!*  </div>*!/*/}
            {/*  /!*</div>*!/*/}

            {/*  <div className="intro-y box p-5 mt-5">*/}
            {/*    <canvas className="mt-5" height="350"></canvas>*/}

            {/*    <div className="mt-10">*/}
            {/*      <div className="flex items-center">*/}
            {/*        <div className="w-2 h-2 bg-theme-1 rounded-full mr-3"></div>*/}
            {/*        <span className="truncate">Active</span>*/}
            {/*        <div className="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>*/}
            {/*        <span className="font-medium xl:ml-auto">65%</span>*/}
            {/*      </div>*/}
            {/*//       <div className="flex items-center mt-4 mb-4">*/}
            {/*//         <div className="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>*/}
            {/*//         <span className="truncate">Inactive</span>*/}
            {/*        <div className="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>*/}
            {/*        <span className="font-medium xl:ml-auto">33%</span>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}

            {/*</div>*/}

            {/* <div className="col-span-12 grid grid-cols-12 gap-6 mt-8">
              <div className="col-span-12 sm:col-span-6 xxl:col-span-3 intro-y">
                <div className="box p-5 zoom-in">
                  <div className="flex items-center">
                    <div className="w-2/4 flex-none">
                      <div className="text-lg font-medium truncate">
                        Senders
                      </div>
                      <div className="text-green-600 mt-1">300 Active</div>
                      <div className="text-red-600 mt-1">11 Inactive</div>
                    </div>
                    <div className="flex-none ml-auto relative">
                      <canvas
                        ref={sendersChart}
                        width="90"
                        height="90"
                      ></canvas>
                 
                   
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6 xxl:col-span-3 intro-y">
                <div className="box p-5 zoom-in">
                  <div className="flex items-center">
                    <div className="w-2/4 flex-none">
                      <div className="text-lg font-medium truncate">
                        Receivers
                      </div>
                      <div className="text-green-600 mt-1">210 Active</div>
                      <div className="text-red-600 mt-1">9 Inactive</div>
                    </div>
                    <div className="flex-none ml-auto relative">
                      <canvas
                        ref={receiversChart}
                        width="90"
                        height="90"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-span-12 mt-6">
              <div className="intro-y block sm:flex items-center h-10">
                <h2 className="text-lg font-medium truncate mr-5">
                  Recent Transactions
                </h2>
                <div className="flex items-center sm:ml-auto mt-3 sm:mt-0">
                  <button className="btn box flex items-center text-gray-700 dark:text-gray-300">
                    <i
                      data-feather="file-text"
                      className="hidden sm:block w-4 h-4 mr-2"
                    ></i>{" "}
                    Export to Excel
                  </button>
                  <button className="ml-3 btn box flex items-center text-gray-700 dark:text-gray-300">
                    <i
                      data-feather="file-text"
                      className="hidden sm:block w-4 h-4 mr-2"
                    ></i>{" "}
                    Export to PDF
                  </button>
                </div>
              </div>
              <div className="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
                <table className="table table-report sm:mt-2">
                  <thead>
                    <tr>
                      <th className="whitespace-nowrap">Transaction ID</th>
                      <th className="whitespace-nowrap">Description</th>
                      <th className="text-center whitespace-nowrap">Amount</th>
                      <th className="text-center whitespace-nowrap">Partner</th>
                      <th className="text-center whitespace-nowrap">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="intro-x">
                      <td className="w-40">
                        <div className="flex">112213</div>
                      </td>
                      <td>
                        <a href="#" className="font-medium whitespace-nowrap">
                          Bernard Aryee
                        </a>
                        <div className="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                          Mobile Money
                        </div>
                      </td>
                      <td className="text-center">50</td>
                      <td className="w-40">
                        <div className="flex items-center justify-center text-theme-9">
                          Zeepay
                        </div>
                      </td>
                      <td className="table-report__action w-56">
                        <div className="flex justify-center items-center text-medium">
                          2021-08-21 17:21
                        </div>
                      </td>
                    </tr>
                    <tr className="intro-x">
                      <td className="w-40">
                        <div className="flex">112213</div>
                      </td>
                      <td>
                        <a href="#" className="font-medium whitespace-nowrap">
                          Bernard Aryee
                        </a>
                        <div className="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                          Mobile Money
                        </div>
                      </td>
                      <td className="text-center">50</td>
                      <td className="w-40">
                        <div className="flex items-center justify-center text-theme-9">
                          Zeepay
                        </div>
                      </td>
                      <td className="table-report__action w-56">
                        <div className="flex justify-center items-center text-medium">
                          2021-08-21 17:21
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="intro-y flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3">
                <ul className="pagination">
                  <li>
                    <a className="pagination__link" href="#">
                      {" "}
                      <ChevronsLeft className={`w-4 h-4`} />
                    </a>
                  </li>
                  <li>
                    <a className="pagination__link" href="#">
                      <ChevronLeft className={`w-4 h-4`} />
                    </a>
                  </li>
                  <li>
                    <a className="pagination__link" href="#">
                      ...
                    </a>
                  </li>
                  <li>
                    <a className="pagination__link" href="#">
                      1
                    </a>
                  </li>
                  <li>
                    <a
                      className="pagination__link pagination__link--active"
                      href="#"
                    >
                      2
                    </a>
                  </li>
                  <li>
                    <a className="pagination__link" href="#">
                      3
                    </a>
                  </li>
                  <li>
                    <a className="pagination__link" href="#">
                      ...
                    </a>
                  </li>
                  <li>
                    <a className="pagination__link" href="#">
                      {" "}
                      <ChevronRight className={`w-4 h-4`} />
                    </a>
                  </li>
                  <li>
                    <a className="pagination__link" href="#">
                      {" "}
                      <ChevronsRight className={`w-4 h-4`} />
                    </a>
                  </li>
                </ul>
                <select className="w-20 form-select box mt-3 sm:mt-0">
                  <option>10</option>
                  <option>25</option>
                  <option>35</option>
                  <option>50</option>
                </select>
              </div>
            </div> */}
          </div>
        </div>
        {/* <div className="col-span-12 xxl:col-span-3">
          <div className="xxl:border-l border-theme-5 -mb-10 pb-10">
            <div className="xxl:pl-6 grid grid-cols-12 gap-6">
              <div className="box p-5 mt-6 bg-theme-3 col-span-12 sm:col-span-6 md:col-span-12 lg:col-span-12">
                <div className="flex flex-wrap gap-3 justify-center items-center">
                  <div className="mx-auto">
                    <div className=" text-white text-opacity-70 flex items-center justify-center mb-3">
                      {" "}
                      CURRENT BALANCE
                    </div>
                    <div className="flex text-white text-2xl font-medium justify-center items-center">
                      <span>{user?.agency?.country?.currency_symbol}</span>{" "}
                      {user?.balance_available}
                    </div>

                    <div className="text-white flex items-center leading-3 mt-5">
                      <Link
                        to={"/send-money"}
                        type="button"
                        className="btn text-gray-700 dark:text-gray-300 bg-white h-12 w-64 dark:bg-theme-1 mt-1"
                      >
                        {" "}
                        Send Money
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
               <div className="max-h-full xl:overflow-y-auto box mt-5 col-span-12 sm:col-span-6 lg:col-span-12">
                <div className="bg-white dark:bg-dark-3 xl:sticky top-0 px-5 pt-5 pb-6">
                  <div className="flex items-center">
                    <div className="text-lg font-medium truncate mr-5">
                      Summary Report
                    </div>
                    <a
                      href=""
                      className="ml-auto flex items-center text-theme-1 dark:text-theme-10"
                    >
                      {" "}
                      <i
                        data-feather="refresh-ccw"
                        className="w-4 h-4 mr-3"
                      ></i>{" "}
                      Refresh{" "}
                    </a>
                  </div>
                  <div
                    className="boxed-tabs nav nav-tabs justify-center border border-gray-400 border-dashed text-gray-600 dark:border-gray-700 dark:bg-dark-3 dark:text-gray-500 rounded-md p-1 mt-5 mx-auto"
                    role="tablist"
                  >
                    <input
                      style={{outline: "none"}}
                      ref={datePicker}
                      type="text"
                      placeholder={`Click Here To Select Date Range`}
                      className="datepicker text-center sm:w-56"
                    />
                  </div>
                </div>
                <div className="tab-content px-5 pb-5">
                  <div
                    className="tab-pane active grid grid-cols-12 gap-y-6"
                    id="active-users"
                    role="tabpanel"
                    aria-labelledby="active-users-tab"
                  >
                    <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12">
                      <div className="text-gray-600 dark:text-gray-500">
                        Funding Received
                      </div>
                      <div className="mt-1.5 flex items-center">
                        <div className="text-lg">₵155.430.000</div>
                        <div
                          className="text-theme-6 flex text-xs font-medium tooltip cursor-pointer ml-2"
                          title="2% Lower than last month"
                        >
                          {" "}
                          2% <ChevronDown className="w-4 h-4 ml-0.5" />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12">
                      <div className="text-gray-600 dark:text-gray-500">
                        Disbursements Made
                      </div>
                      <div className="mt-1.5 flex items-center">
                        <div className="text-lg">₵57,300.20</div>
                       
                      

                        <div
                          className="text-theme-6 flex text-xs font-medium tooltip cursor-pointer ml-2"
                          title="0.1% Lower than last month"
                        >
                          {" "}
                          11% default{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12">
                      <div className="text-gray-600 dark:text-gray-500">
                        Commission on Disbursement
                      </div>
                      <div className="mt-1.5 flex items-center">
                        <div className="text-lg">₵75.430.000</div>
                        <div
                          className="text-theme-9 flex text-xs font-medium tooltip cursor-pointer ml-2"
                          title="49% Higher than last month"
                        >
                          {" "}
                          36% <ChevronUp className="w-4 h-4 ml-0.5" />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12">
                      <div className="text-gray-600 dark:text-gray-500">
                        Success Rate
                      </div>
                      <div className="mt-1.5 flex items-center">
                        <div className="text-lg">100%</div>
                      </div>
                    </div>
                    <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12">
                      <div className="text-gray-600 dark:text-gray-500">
                        Waiting For Disbursement
                      </div>
                      <div className="mt-1.5 flex items-center">
                        <div className="text-lg">0</div>
                      </div>
                    </div>
                    <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12">
                      <div className="text-gray-600 dark:text-gray-500">
                        Reversals
                      </div>
                      <div className="mt-1.5 flex items-center">
                        <div className="text-lg">₵21.430.000</div>
                        <div
                          className="text-theme-6 flex text-xs font-medium tooltip cursor-pointer ml-2"
                          title="2% Lower than last month"
                        >
                          {" "}
                          23% <ChevronUp className="w-4 h-4 ml-0.5" />
                        </div>
                      </div>
                    </div>
                    <button className="btn btn-outline-secondary col-span-12 border-gray-400 border-dashed relative justify-start mb-2">
                      <div className="truncate mr-5">My Portfolio Details</div>
                      <span className="w-8 h-8 absolute flex justify-center items-center right-0 top-0 bottom-0 my-auto ml-auto mr-0.5">
                        {" "}
                        <i
                          data-feather="arrow-right"
                          className="w-4 h-4"
                        ></i>{" "}
                      </span>
                    </button>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Dashboard;
