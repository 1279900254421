import React, { useEffect, useState } from "react";
import { parsePhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import RestService from "../../../services/rest-service";
import restService from "../../../services/restService";
import Loader from "../Loader";
import AddSender from "./AddSender";
import "./style.scss";
import Success from "./Success";

const keys = ["firstname", "lastname", "msisdn"];
const AddNewSender = ({ showGoback = true, refetchData, onCancel }) => {
  const { addToast } = useToasts();

  const history = useHistory();

  const user = useSelector((state) => state.auth?.user);
  const [current, setCurrent] = useState(0);
  const [isAddingSender, setIsAddingSender] = useState(false);
  const [senderAdded, setSenderAdded] = useState(false);
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    othernames: "",
    email: "",
    msisdn: "",
    country_id: "",
    address: "",
  });
  const [errors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    let errorList = {};
    keys.forEach((key) => {
      if (!form[key]) {
        errorList[key] = "This field is required.";
      } else {
        if (key === "email") {
          const regex = /\S+@\S+\.\S+/;
          if (!regex.test(form[key])) {
            errorList[key] = "Enter a valid email.";
          }
        }
      }
    });
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };

  const next = () => {
    if (validateForm()) {
      handleSubmit(form);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
    setApiErrors({});
  };

  useEffect(() => {
    RestService.getCountries().then(({ data }) => {
      setCountries(data?.data);

      setForm({
        ...form,
        calling_code: user.agency?.country?.calling_code,
        country_id: data?.data?.filter((country) => {
          return country.name === user.agency?.country?.name;
        })[0]?.id,
      });
    });
  }, []);

  const onFormChange = ({ target }) => {
    let details = { ...form };
    details[target.name] = target.value;
    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);

    setForm(details);
  };

  const onPhoneNumberChange = (value) => {
    const calling_code = parsePhoneNumber(String(value));
    setForm({
      ...form,
      calling_code: calling_code?.countryCallingCode ?? "",
      msisdn: value,
    });
  };

  const handleSubmit = (data) => {
    //Api request to save staff
    setLoading(true);
    setIsAddingSender(true);
    restService.SendersApi.addSenderWithoutId(data, user.id)
      .then(({ data: { data: response } }) => {

        setForm({
          firstname: "",
          lastname: "",
          othernames: "",
          email: "",
          msisdn: "",
          country_id: "",
          address: "",
        });
        // setConfirmation(true);
        setIsAddingSender(false);
        setSenderAdded(true);

        addToast("Sender added successfully.", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });

        if (typeof refetchData === "function") {
          refetchData(response);
          onCancel?.();
          return;
        }

        setCurrent(0);
        history?.goBack?.();
      })
      .catch(({ response }) => {
        addToast(response?.data?.message, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });

        const { data } = JSON.parse(JSON.stringify(response?.data));
        setApiErrors(data);
      })
      .finally(() => {
        setLoading(false);
        setIsAddingSender(false);
      });
  };

  return (
    <>
      {isAddingSender ? <Loader /> : null}

      <div className="container flex flex-col w-full gap-6 mt-[5rem]">
        {senderAdded ? (
          <div className="min-h-[50vh] intro-y flex items-center justify-center">
            <Success />
          </div>
        ) : (
          <div className="p-8 px-4 intro-y flex flex-col gap-4 ">
            {showGoback && (
              <span
                className="cursor-pointer"
                onClick={() => {
                  if (current === 0) {
                    history.goBack();

                    return;
                  }

                  prev();
                }}
              >
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.14446 1.14446L3.09818 5.19074H12.4741V6.80926H3.09818L7.14446 10.8555L6 12L0 6L6 0L7.14446 1.14446Z"
                    fill="black"
                  />
                </svg>
              </span>
            )}

            {/* <div className="md:max-w-[8vw] mx-auto">
              <Steps direction="horizontal" current={current} items={items} />
            </div>
            <div>{steps[current].content}</div> */}

            <AddSender
              errors={errors}
              setForm={setForm}
              onPhoneNumberChange={onPhoneNumberChange}
              onFormChange={onFormChange}
              form={form}
              submit={next}
              onCancel={onCancel}
              apiErrors={apiErrors}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AddNewSender;
