import React, { useEffect } from "react";
import { sideBarItems } from "../../constants";
import SidebarItem from "./SidebarItem";
import SidebarStyles from "./styles";

const Sidebar = ({ location, user, setShowSidebar, showSidebar }) => {
  const userType = user?.type;

  const roleName = user?.agent_role
    ? user?.agent_role?.name
    : user?.staff_role?.name;
  // const activeItem = sideBarItems["Administrator"]?.findIndex(
  //   (item) => item.route === location
  // );
  const activeItem = sideBarItems[roleName]?.findIndex(
    (item) => item.route === location
  );

  useEffect(() => {}, []);

  return (
    <>
      <div
        style={{
          display: showSidebar ? "block" : "none",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 50,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
        onClick={() => setShowSidebar(false)}
        className="sidebar-cover"
      ></div>
      <SidebarStyles>
        <nav className="h-full w-full overflow-y-auto">
          {/* <a href="" className="intro-x flex items-center pl-5 pt-4 ">
    
          <span className="hidden xl:block text-white text-lg ml-3">
       
            Instaant <span className="font-medium">{roleName}</span>{" "}
          </span>
        </a> */}

          {/* {userType === "agent" && (
          <div
            className=" m-5 alert alert-warning-soft show flex items-center mb-2"
            role="alert"
          >
            Balance :{" "}
            <span className="font-bold">
              &nbsp;{user?.agency?.country?.currency_symbol}
              {user?.balance_available}{" "}
            </span>
          </div>
        )} */}
          {/* <div className="side-nav__divider my-6"></div> */}
          <ul className="h-full w-full flex flex-col px-6">
            {sideBarItems[roleName].map((item, index) => (
              // {/* {sideBarItems["Administrator"].map((item, index) => ( */}
              <SidebarItem
                key={index}
                name={item.name}
                route={item.route}
                icon={item?.icon}
                userType={userType}
                active={index === activeItem}
                onOpenChange={setShowSidebar}
              />
            ))}
          </ul>
        </nav>
      </SidebarStyles>
    </>
  );
};

export default Sidebar;
