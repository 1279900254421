import React from "react";
import IdentificationForm from "../../../components/Identification/identificationForm";

const AddIdentification = ({onClick}) => {
  return (
    <div className="intro-y mt-[5rem] box py-10 sm:py-20 ">
      <IdentificationForm
        handleSubmit={() => {}}
        sendConfirmation={() => {}}
        initializeCallback={() => {}}
        initCallback={() => {}}
      />
      <div className="mt-2 px-20">
        <button className="btn btn-primary">Save Agent</button>
      </div>
    </div>
  );
};
export default AddIdentification;
