import React, {useEffect, useState} from "react";
import {useToasts} from "react-toast-notifications";
import restService from "../../../../services/restService";
import {Redirect} from "react-router";

const keys = [
  "minimum_threshold_amount",
  "maximum_threshold_amount",
  "fixed_charge_amount",
  "percentage_charge_amount",
  "destination_currency",
  "source_currency",
];
const AddCharge = () => {
  const {addToast} = useToasts();

  const [form, setForm] = useState({
    agent_id: "",
    agency_id: "",
    minimum_threshold_amount: 0,
    maximum_threshold_amount: 0,
    fixed_charge_amount: 0,
    percentage_charge_amount: 0,
    destination_currency: "",
    source_currency: "",
  });
  const [confirmation, setConfirmation] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [errors, setErrors] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [agents, setAgents] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [callback, setCallback] = useState(() => {});

  const initFormValues = async () => {
    let details = {};
    const agencyList = await restService.AgencyApi.getAgencies();
    const agentList = await restService.AgentApi.getAgentsByAgency(
      agencyList.data?.data?.data[0]?.id
    );
    const countryList = await restService.UtilsApi.getCountries();
    const currencies = countryList?.data?.data?.map((c) => {
      return c.currency_code;
    });
    setCurrencies(currencies);
    setAgents(agentList.data?.data?.data);
    setAgencies(agencyList.data?.data?.data);

    setForm({
      ...form,
      source_currency: currencies[0],
      destination_currency: currencies[0],
    });
  };

  useEffect(() => {
    initFormValues();
  }, []);

  const validateForm = () => {
    let errorList = {...errors};
    keys.forEach((key) => {
      if (!form[key]) {
        if (
          key === "fixed_charge_amount" ||
          key === "percentage_charge_amount"
        ) {
          errorList[key] =
            "At least a fixed charge amount or percentage charge amount is required.";
        } else {
          errorList[key] = "This field is required.";
        }
      }
    });
    if (form.fixed_charge_amount || form.percentage_charge_amount) {
      delete errorList.fixed_charge_amount;
      delete errorList.percentage_charge_amount;
    }
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };

  const saveCharge = () => {
    if (validateForm()) {
      restService.ChargesApi.addCharge(form).then((response) => {
        setForm({
          agent_id: "",
          agency_id: "",
          minimum_threshold_amount: 0,
          maximum_threshold_amount: 0,
          fixed_charge_amount: 0,
          percentage_charge_amount: 0,
        });
        addToast("Successfully Added Charge", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      });
    }
  };

  const onFormChange = ({target}) => {
    let details = {...form};
    details[target.name] = target.value;

    if (target.name === "agency_id") {
      restService.AgentApi.getAgentsByAgency(target.value).then(({data}) => {
        setAgents(data?.data.data);
        setForm({...details, agent_id: data?.data?.data[0]?.id});
      });

      let errorList = {...errors};
      delete errorList["agent_id"];
    }
    setForm(details);

    let errorList = errors;
    delete errorList[target.name];

    if (details.fixed_charge_amount || details.percentage_charge_amount) {
      delete errorList.fixed_charge_amount;
      delete errorList.percentage_charge_amount;
    }
    setErrors(errorList);
  };

  if (redirect) {
    return <Redirect to="/configurations" />;
  }
  return (
    <div className="intro-y mt-13-important box py-10 sm:py-20 mt-5">
      <div className="px-5 mt-10">
        <div className="font-medium text-center text-lg">Add Charge</div>
        <div className="text-gray-600 text-center mt-2">
          Please enter the charge details.
        </div>
      </div>
      <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5">
        <div className="mt-5">
          <div className="grid grid-cols-12 gap-4 gap-y-5 ">
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-1" className="form-label">
                Minimum Threshold Amount *
              </label>
              <input
                type="number"
                min={0}
                value={form.minimum_threshold_amount}
                onChange={onFormChange}
                name="minimum_threshold_amount"
                className="form-control"
                placeholder="13.20"
              />
              <p className="text-red-500 text-xs italic pt-1">
                {errors.minimum_threshold_amount}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-1" className="form-label">
                Maximum Threshold Amount *
              </label>
              <input
                type="number"
                min={0}
                value={form.maximum_threshold_amount}
                onChange={onFormChange}
                name="maximum_threshold_amount"
                className="form-control"
                placeholder="43.20"
              />
              <p className="text-red-500 text-xs italic pt-1">
                {errors.maximum_threshold_amount}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-1" className="form-label">
                Fixed Charge Amount {!form.percentage_charge_amount ? "*" : ""}
              </label>
              <input
                type="number"
                min={0}
                value={form.fixed_charge_amount}
                onChange={onFormChange}
                name="fixed_charge_amount"
                className="form-control"
                placeholder="23.20"
              />
              <p className="text-red-500 text-xs italic pt-1">
                {errors.fixed_charge_amount}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-1" className="form-label">
                Percentage Charge Amount {!form.fixed_charge_amount ? "*" : ""}
              </label>
              <input
                type="number"
                min={0}
                max={100}
                value={form.percentage_charge_amount}
                onChange={onFormChange}
                name="percentage_charge_amount"
                className="form-control"
                placeholder="13.20"
              />
              <p className="text-red-500 text-xs italic pt-1">
                {errors.percentage_charge_amount}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-5" className="form-label">
                Agency
              </label>
              <select
                id="input-wizard-6"
                value={form.agency_id}
                onChange={onFormChange}
                name="agency_id"
                className="form-select"
              >
                <option value="">None</option>
                {agencies.map((agency) => {
                  return <option value={agency.id}>{agency.name}</option>;
                })}
              </select>
              <p className="text-red-500 text-xs italic pt-1">
                {errors.agency_id}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-5" className="form-label">
                Agent
              </label>
              <select
                id="input-wizard-6"
                value={form.agent_id}
                onChange={onFormChange}
                name="agent_id"
                className="form-select"
              >
                <option value="">None</option>
                {agents.map((agent) => {
                  return (
                    <option value={agent.id}>
                      {agent.firstname} {agent.lastname}
                    </option>
                  );
                })}
              </select>
              <p className="text-red-500 text-xs italic pt-1">
                {errors.agent_id}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-5" className="form-label">
                Source Currency
              </label>
              <select
                id="input-wizard-6"
                value={form.source_currency}
                onChange={onFormChange}
                name="source_currency"
                className="form-select"
              >
                {currencies.map((currency) => {
                  return <option value={currency}>{currency}</option>;
                })}
              </select>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-5" className="form-label">
                source_currency
              </label>
              <select
                id="input-wizard-6"
                value={form.destination_currency}
                onChange={onFormChange}
                name="destination_currency"
                className="form-select"
              >
                {currencies.map((currency) => {
                  return <option value={currency}>{currency}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
            <button className="btn btn-primary  ml-2" onClick={saveCharge}>
              {" "}
              Add Charge
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCharge;
