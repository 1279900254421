import { Steps, message } from "antd";
import React, { useEffect, useState } from "react";
import { parsePhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import restService from "../../../services/restService";
import Loader from "../Loader";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Success from "./Success";

import "../AddNewSender/style.scss";

import { useHistory } from "react-router-dom";
import AddNewReceiver from "../../../app/AgentViews/SendMoney/steps/components/add-recipient";

const keys = [
  "firstname",
  "lastname",
  // "account_number",
  "msisdn",
  // "country_id",
  // "destination_institution_id",
  // "sender_id",
];
const AddNewReceiverComp = ({ show, refetchData, setShow }) => {
  const { addToast } = useToasts();

  const user = useSelector((state) => state.auth?.user);

  const history = useHistory();

  const [current, setCurrent] = useState(1);
  const [isAddingReceiver, setIsAddingReceiver] = useState(false);
  const [receiverAdded, setReceiverAdded] = useState(false);
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    account_number: "",
    msisdn: "",
    country_id: "",
    destination_institution_id: "",
    sender_id: "",
  });
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sender, setSender] = useState(null);
  const [senders, setSenders] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [destinationInstitutions, setDestinationInstitutions] = useState([]);

  //console.log(form);

  const validateForm = () => {
    let errorList = { ...errors };
    keys.forEach((key) => {
      // TODO validate phone numbers
      if (!form[key]) {
        errorList[key] = "This field is required.";
      } else {
        delete errorList[key];
        if (key === "email") {
          const regex = /\S+@\S+\.\S+/;
          if (!regex.test(form[key])) {
            errorList[key] = "Enter a valid email.";
          }
        }
      }
    });
    setErrors(errorList);
    //console.log(errorList);
    return Object.keys(errorList).length === 0;
  };

  const next = () => {
    //console.log(validateForm());

    if (validateForm()) {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    restService.UtilsApi.getCountries()
      .then(({ data }) => {
        setCountries(data?.data);
        setForm({
          ...form,
          calling_code: data?.data.filter((country) => {
            return country.name === user.agency?.country?.name;
          })[0].calling_code,
          country_id: data?.data.filter((country) => {
            return country.name === user.agency?.country?.name;
          })[0].id,
        });

        restService.UtilsApi.getSenderRelationships().then(({ data }) => {
          setRelationships(data?.data);
        });
      })
      .catch((err) => {});

    restService.SendersApi.getSenders(null, "allItems")
      .then(({ data }) => {
        setSenders(data?.data.data);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);

  useEffect(() => {
    setForm({ ...form, relationship: relationships?.[0] });
  }, [relationships]);

  const countryOptions = countries.map((country) => {
    country.label = `${country.name}`;
    return country;
  });

  const onFormChange = ({ target }) => {
    let details = { ...form };
    details[target.name] = target.value;
    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);

    if (target.name === "country_id") {
      const country = countries.filter((c) => c.id == target.value)[0];
      details.calling_code = country?.calling_code;
      restService.UtilsApi.getDestinationInstitutionsByCountry(
        target.value
      ).then(({ data }) => {
        setDestinationInstitutions(data?.data);
      });
    }
    setForm(details);
  };
  const onPhoneNumberChange = (value) => {
    const calling_code = parsePhoneNumber(String(value));
    // let msisdn = value ? value?.trim()?.slice(calling_code?.length + 1) : "";
    // const country = countries.filter(
    //   (c) => c.iso_3166_2 == calling_code?.country
    // )[0];

    setForm({
      ...form,
      calling_code: calling_code?.countryCallingCode ?? "",
      msisdn: value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    setIsAddingReceiver(true);
    if (validateForm()) {
      let formData = { ...form };
      formData.contact_number = formData.msisdn;
      formData.destination_institution_id = user.agency?.id;
      formData.agent_id = user.id;
      restService.ReceiversApi.addReceiver(formData, user.id)
        .then(({ data }) => {
          setName(`${formData.firstname} ${formData.lastname}`);
          //  setConfirmation(data?.data?.id);
          setForm({
            firstname: "",
            lastname: "",
            account_number: "",
            email: "",
            msisdn: "",
            country_id: "",
            address: "",
            relationship: "",
            destination_institution_id: "",
          });
          refetchData();
          setIsAddingReceiver(false);
          setReceiverAdded(true);

          addToast("Receiver added successfully.", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });

          setCurrent(0);
          history.goBack();
        })
        .catch(({ response }) => {
          addToast(response?.data?.message ?? "Error occurred! Try again.", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          message.error("Error occurred! Try again");
        });
    }
  };

  const handleSenderSelect = (selectedOption) => {
    if (!selectedOption) {
      setSender("");
      form.sender_id = null;
      return;
    }

    setSender(selectedOption);
    form.sender_id = selectedOption.id;
  };

  const senderOptions = senders.map((user) => {
    user.label = `${user.firstname} ${user.lastname}`;
    return user;
  });

  const steps = [
    {
      title: "",
      content: (
        <Step1
          countries={countries}
          errors={errors}
          setForm={setForm}
          onPhoneNumberChange={onPhoneNumberChange}
          onFormChange={onFormChange}
          form={form}
          next={next}
          prev={prev}
        />
      ),
    },
    {
      title: "",
      content: (
        <Step2
          destinationInstitutions={destinationInstitutions}
          relationships={relationships}
          countries={countries}
          countryOptions={countryOptions}
          senderOptions={senderOptions}
          onFormChange={onFormChange}
          data={form}
          sender={sender}
          handleSubmit={handleSubmit}
          prev={prev}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <div
      className="container flex flex-col w-full gap-6 "
      style={{
        marginTop: "10rem",
      }}
    >
      <AddNewReceiver
        onSubmitSuccess={(data) => {
          history.goBack();
        }}
        initialStep={1}
      />
    </div>
  );
};

export default AddNewReceiverComp;
