import React, {useEffect, useState} from "react";
import Modal2 from "../Modal/Modal2";
import Step1 from "./Step1";
import Step2 from "./Step2";
import {Steps, message} from "antd";
import Loader from "../Loader";
import Success from "./Success";
import {useSelector} from "react-redux";
import restService from "../../../services/restService";
import {useToasts} from "react-toast-notifications";
import {parsePhoneNumber} from "react-phone-number-input";
import regions from "../../../assets/static_json/regions.json";
const AddNewStaff = ({show, refetchData, setShow}) => {
  const {addToast} = useToasts();

  const user = useSelector((state) => state.auth?.user);
  const [staffAdded, setStaffAdded] = useState(false);
  const [current, setCurrent] = useState(0);
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    calling_code: "",
    msisdn: "",
    country_id: "",
    region: regions[0].value,
    address: "",
    staff_role: "",
    partner_id: "",
  });

  const [confirmation, setConfirmation] = useState(false);
  const [callback, setCallback] = useState(() => {});
  const [countries, setCountries] = useState([]);
  const [partners, setPartners] = useState([]);
  const [roles, setRoles] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchCountriesAndRoles = async () => {
    const countryList = await restService.UtilsApi.getCountries();
    const roleList = await restService.UtilsApi.getStaffRoles();
    // const partnersList = await restService.UtilsApi.getPartners();

    setCountries(countryList.data?.data);
    setRoles(roleList.data.data);

    setForm({
      ...form,
      calling_code: user.country.calling_code,
      country_id: countryList.data?.data.filter((country) => {
        return country.name === user.country?.name;
      })[0].id,
    });
  };

  useEffect(() => {
    fetchCountriesAndRoles();
  }, []);

  const next = () => {
    if (validateForm()) {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const validateForm = () => {
    const keys = Object.keys(form);
    let errorList = {...errors};
    keys.forEach((key) => {
      // TODO validate phone numbers
      if (!form[key]) {
        if (key !== "partner_id") {
          errorList[key] = "This field is required.";
        }
      } else {
        delete errorList[key];
        if (key === "email") {
          const regex = /\S+@\S+\.\S+/;
          if (!regex.test(form[key])) {
            errorList[key] = "Enter a valid email.";
          }
        }
      }
    });
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };

  const handleSubmit = (data) => {
    //Api request to save staff
    setLoading(true);
    restService.StaffApi.addStaff(data)
      .then((response) => {
        //  setStep(1);
        setForm({
          firstname: "",
          lastname: "",
          email: "",
          msisdn: "",
          address: "",
        });
        setConfirmation(true);
        setStaffAdded(true);
      })
      .catch(({response}) => {
        addToast(response?.data?.message, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFormChange = ({target}) => {

    let details = {...form};

    details[target.name] = target.value;
    console.log(details)
    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);
    if (target.name === "country_id") {
      const country = countries.filter((c) => c.id == target.value)[0];
      details.calling_code = country?.calling_code;
    }
    setForm(details);
  };

  const onPhoneNumberChange = (value) => {
    const calling_code = parsePhoneNumber(String(value));
    setForm({
      ...form,
      calling_code: calling_code?.countryCallingCode ?? "",
      msisdn: value,
    });
  };

  const steps = [
    {
      title: "",
      content: (
        <Step1
          countries={countries}
          errors={errors}
          setForm={setForm}
          onPhoneNumberChange={onPhoneNumberChange}
          onFormChange={onFormChange}
          form={form}
          next={next}
          prev={prev}
          roles={roles}
        />
      ),
    },
    {
      title: "",
      content: <Step2 data={form} handleSubmit={handleSubmit} prev={prev} />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <>
      {loading ? <Loader /> : null}
      <Modal2
        onHide={() => {
          setShow(false);
          setCurrent(0);
          // if (staffAdded) {
          // }
        }}
        size={staffAdded ? "small" : "lg"}
      >
        {loading ? (
          ""
        ) : (
          <>
            {staffAdded ? (
              <div className="h-[50vh] intro-y flex items-center justify-center">
                <Success />
              </div>
            ) : (
              <div className="p-8 intro-y flex flex-col gap-4 ">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    if (current === 0) {
                      setShow(false);
                      return;
                    }

                    prev();
                  }}
                >
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.14446 1.14446L3.09818 5.19074H12.4741V6.80926H3.09818L7.14446 10.8555L6 12L0 6L6 0L7.14446 1.14446Z"
                      fill="black"
                    />
                  </svg>
                </span>

                <div className="md:max-w-[7vw] mx-auto">
                  <Steps
                    direction="horizontal"
                    current={current}
                    items={items}
                  />
                </div>
                <div>{steps[current].content}</div>
              </div>
            )}
          </>
        )}
      </Modal2>
    </>
  );
};

export default AddNewStaff;
