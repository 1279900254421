import React from "react";
const Modal = ({
  onClose,
  onClick,
  size,
  modalTitle,
  buttonTitle,
  buttonColor,
  body,
  disableButton,
}) => {
  return (
    <div
      className="fixed z-100 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className={
          "flex items-end justify-center modal-" +
          size +
          " pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        }
      >
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          style={{ maxWidth: "500px", width: "100%" }}
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all
                         sm:my-8 sm:align-middle"
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex w-full">
              <div className="mt-3 w-full text-center sm:mt-0  sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  {modalTitle}
                </h3>
                <div className="mt-2">{body}</div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              disabled={disableButton}
              onClick={onClick}
              className={
                "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm " +
                " px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2" +
                " sm:ml-3 sm:w-auto sm:text-sm " +
                buttonColor
              }
            >
              {buttonTitle}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
