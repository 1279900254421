import React from 'react';

const Confirmation = ({onClose,message,title,buttonText}) => {

        return (
            <div className="h-full flex">
                <div className="confirmationContent m-auto">
                    <div className="confirmationCheck">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24" fill="none" viewBox="0 0 24 24" stroke="#00D068">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <h1 className="text-green-400 text-4xl" >{title}</h1>
                    <div className="congratsText">
                        {message}
                    </div>
                    <button className="btn btn-primary px-5 m-2" onClick={onClose} >{buttonText}</button>
                </div>
            </div>
        )
}

export default Confirmation;
