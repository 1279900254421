import { useEffect } from "react";
import { usePagination } from "../../../hooks/use-pagination";
import Button from "../../common/Button";
import UserCard from "../card";
import restService from "../../../services/restService";
import { getRandomColor } from "../../../helpers/utils";

export default function SenderSelect({
  onSelect,
  selectedSenderId,
  searchValue,
  onFinally,
  onGoBack,
  setIsFetching,
}) {
  const {
    data,
    setPaginatedData,
    currentPage,
    nextPage,
    setIsFetchingMore,
    isFetchingMore,
    totalPages,
    setTotalPages,
    setSearchResults,
    resetQuery,
  } = usePagination();

  async function getSenders(page) {
    setIsFetchingMore(true);
    setIsFetching?.(true);
    try {
      const {
        data: { data },
      } = await restService.SendersApi.getSenders(page, 10);

      setTotalPages(data?.last_page);

      const s = (data?.data ?? [])?.map((sender, index) => ({
        ...sender,
        bgColor: getRandomColor(index),
      }));

      setPaginatedData(s);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingMore(false);
      setIsFetching?.(false);
      // onFinally?.(false);
    }
  }

  useEffect(() => {
    getSenders(currentPage);
  }, [currentPage]);

  async function searchForData(query) {
    try {
      const {
        data: { data },
      } = await restService.SendersApi.getSendersBySearchTerm(query);

      const s = (data?.data ?? [])?.map((sender, index) => ({
        ...sender,
        bgColor: getRandomColor(index),
      }));

      setSearchResults(s);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (searchValue) {
      searchForData(searchValue);
      return;
    }

    resetQuery();
  }, [searchValue]);

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-4  xl:grid-cols-7 gap-4">
        {data?.map((sender) => (
          <div
            onClick={() => {
              onSelect?.(sender);
            }}
            className={`zoom-in rounded-md cursor-pointer`}
            key={sender?.id}
          >
            <UserCard
              isActive={selectedSenderId && selectedSenderId === sender?.id}
              key={sender?.id}
              user={sender}
            />
          </div>
        ))}
      </div>

      <div className="flex justify-end gap-4">
        {onGoBack && (
          <Button
            onClick={() => {
              onGoBack?.();
            }}
            className="btn btn-secondary  flex items-center gap-2 text-xs"
          >
            Go back
          </Button>
        )}
        <Button
          onClick={() => {
            nextPage();
          }}
          disabled={isFetchingMore || currentPage >= totalPages}
          className="btn btn-primary  flex items-center gap-2 text-xs"
        >
          See more
        </Button>
      </div>
    </>
  );
}
