import React from 'react';
import PageTitleStyles from "./styles";

const PageTitle = ({title}) => {
  return (
    <PageTitleStyles>
      {title}
    </PageTitleStyles>
  );
};

export default PageTitle;
