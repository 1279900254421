import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import restService from "../../../../services/restService";
import Select from "react-select";
import {Result, Button, message} from "antd";
import {Link, useLocation} from "react-router-dom";
import {Frown} from "react-feather";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import RestService from "../../../../services/restService";

const keys = [
  "firstname",
  "lastname",
  "account_number",
  "msisdn",
  "country_id",
  "destination_institution_id",
  "sender_id",
];
function AddReceiver(props) {
  const user = useSelector((state) => state.auth?.user);
  const {search} = useLocation();
  const senderId = new URLSearchParams(search).get("senderId");

  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    account_number: "",
    msisdn: "",
    country_id: "",
    destination_institution_id: "",
    sender_id: "",
  });
  const [confirmation, setConfirmation] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [name, setName] = useState("");
  const [relationships, setRelationships] = useState([]);
  const [destinationInstitutions, setDestinationInstitutions] = useState([]);
  const [callback, setCallback] = useState(() => {});
  const [sender, setSender] = useState(null);
  const [senders, setSenders] = useState([]);

  useEffect(() => {
    restService.UtilsApi.getCountries()
      .then(({data}) => {
        setCountries(data?.data);
        setForm({
          ...form,
          calling_code: data?.data.filter((country) => {
            return country.name === user.agency?.country?.name;
          })[0].calling_code,
          country_id: data?.data.filter((country) => {
            return country.name === user.agency?.country?.name;
          })[0].id,
        });

        restService.UtilsApi.getSenderRelationships().then(({data}) => {
          setRelationships(data?.data);
        });
      })
      .catch((err) => {});

    RestService.SendersApi.getSenders(null, "allItems").then(({data}) => {
      setSenders(data?.data.data);
    });
  }, []);

  useEffect(() => {
    setForm({...form, relationship: relationships?.[0]});
  }, [relationships]);

  useEffect(() => {
    if (!sender) {
      const defaultSender = senders.filter((s) => s.id == senderId)?.[0];
      setSender(defaultSender);
      setForm({...form, sender_id: defaultSender?.id});
    }
  }, [senders]);
  const countryOptions = countries.map((country) => {
    country.label = `${country.name}`;
    return country;
  });

  const loadOptions = async (input, callback) => {
    const response = await RestService.SendersApi.getSendersBySearchTerm(input);
    const senders = await response?.data?.data?.data;
    callback(
      senders.map((user) => {
        user.label = `${user.firstname} ${user.lastname}`;
        return user;
      })
    );
  };
  const validateForm = () => {
    let errorList = {...errors};
    keys.forEach((key) => {
      // TODO validate phone numbers
      if (!form[key]) {
        errorList[key] = "This field is required.";
      } else {
        delete errorList[key];
        if (key === "email") {
          const regex = /\S+@\S+\.\S+/;
          if (!regex.test(form[key])) {
            errorList[key] = "Enter a valid email.";
          }
        }
      }
    });
    setErrors(errorList);
    //console.log(errorList);
    return Object.keys(errorList).length === 0;
  };

  const addReceiver = () => {
    if (validateForm()) {
      let formData = {...form};
      formData.contact_number = formData.calling_code + formData.msisdn;
      formData.destination_institution_id = user.agency?.id;
      formData.agent_id = user.id;
      restService.ReceiversApi.addReceiver(formData, user.id)
        .then(({data}) => {
          setName(`${formData.firstname} ${formData.lastname}`);
          setConfirmation(data?.data?.id);
          setForm({
            firstname: "",
            lastname: "",
            account_number: "",
            email: "",
            msisdn: "",
            country_id: "",
            address: "",
            relationship: "",
            destination_institution_id: "",
          });
        })
        .catch((err) => {
          message.error("Error occurred! Try again");
        });
    }
  };

  const onFormChange = ({target}) => {
    let details = {...form};
    details[target.name] = target.value;
    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);

    if (target.name === "country_id") {
      const country = countries.filter((c) => c.id == target.value)[0];
      details.calling_code = country?.calling_code;
      restService.UtilsApi.getDestinationInstitutionsByCountry(
        target.value
      ).then(({data}) => {
        setDestinationInstitutions(data?.data);
      });
    }
    setForm(details);
  };

  const handleCountryChange = (selectedOption) => {
    //console.log(selectedOption);
    setForm({
      ...form,
      country_id: selectedOption.id,
      calling_code: selectedOption.calling_code,
    });

    restService.UtilsApi.getDestinationInstitutionsByCountry(
      selectedOption.iso_3166_3
    ).then(({data}) => {
      setDestinationInstitutions(data?.data);
    });
  };

  if (confirmation) {
    return (
      <Result
        status="success"
        title="Successfully Added Receiver!"
        subTitle={`New receiver Added, you can now send money to ${name}`}
        extra={[
          <Link
            to={{
              pathname: "/send-money",
              search: `?senderId=${sender?.id}&receiverId=${confirmation}`,
            }}
          >
            <Button type="primary" key="sendmoney">
              Send Money
            </Button>
          </Link>,

          <Button onClick={() => setConfirmation(false)} key="addreceiver">
            Add Another Receiver
          </Button>,
        ]}
      />
    );
  }

  const colourStyles = {
    menuPortal: (provided, state) => {
      return {
        ...provided,
        backgroundColor: null,
        color: "#333333",
        zIndex: 9999,
      };
    },
  };

  const handleSenderSelect = (selectedOption) => {
    //console.log(selectedOption);
    if (!selectedOption) {
      setSender("");
      form.sender_id = null;
      return;
    }

    setSender(selectedOption);
    form.sender_id = selectedOption.id;
  };

  const CustomSenderMenu = ({innerRef, innerProps, isDisabled, children}) =>
    !isDisabled ? (
      <div
        ref={innerRef}
        {...innerProps}
        style={{position: "absolute", width: "100%", background: "white"}}
      >
        <Link
          to={"/add-sender"}
          className="btn btn-outline-secondary my-1 w-full"
        >
          Add New Sender
        </Link>
        {children}
      </div>
    ) : null;

  const senderOptions = senders.map((user) => {
    user.label = `${user.firstname} ${user.lastname}`;
    return user;
  });

  return (
    <div className="intro-y box py-10 sm:py-20 mt-5">
      <div className="px-5 mt-10">
        <div className="font-medium text-center text-lg">Add Receiver</div>
        <div className="text-gray-600 text-center mt-2">
          Please enter the sender's personal details.
        </div>
      </div>
      <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5">
        <div className="font-medium text-base">Receiver's Personal Data</div>
        <div className="mt-5">
          <div className="grid grid-cols-12 gap-4 gap-y-5 ">
            <div className="intro-y col-span-12 sm:col-span-4">
              <label htmlFor="input-wizard-1" className="form-label">
                First Name *
              </label>
              <input
                id="input-wizard-1"
                type="text"
                value={form.firstname}
                onChange={onFormChange}
                name="firstname"
                className="form-control"
                placeholder="Kojo"
              />
              <p className="text-red-500 text-xs italic pt-1">
                {errors.firstname}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-4">
              <label htmlFor="input-wizard-3" className="form-label">
                Other Names
              </label>
              <input
                id="input-wizard-3"
                type="text"
                value={form.othernames}
                onChange={onFormChange}
                name="othernames"
                className="form-control"
                placeholder="Y"
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-4">
              <label htmlFor="input-wizard-2" className="form-label">
                Last Name *
              </label>
              <input
                id="input-wizard-2"
                type="text"
                value={form.lastname}
                onChange={onFormChange}
                name="lastname"
                className="form-control"
                placeholder="Nsiah"
              />
              <p className="text-red-500 text-xs italic pt-1">
                {errors.lastname}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="crud-form-2" className="form-label">
                Sender
              </label>
              <AsyncSelect
                isSearchable={true}
                isClearable={true}
                menuPortalTarget={document.body}
                styles={colourStyles}
                value={sender}
                onChange={handleSenderSelect}
                placeholder={"Search Sender"}
                defaultOptions={senderOptions}
                loadOptions={loadOptions}
                components={{Menu: CustomSenderMenu}}
              />
              <p className="text-red-500 text-xs italic pt-1">
                {errors.sender_id}
              </p>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-3" className="form-label">
                Address{" "}
              </label>
              <input
                id="input-wizard-3"
                type="text"
                value={form.address}
                onChange={onFormChange}
                name="address"
                className="form-control"
                placeholder="Awoshie"
              />
              <p className="text-red-500 text-xs italic pt-1">
                {errors.address}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-5" className="form-label">
                Email{" "}
              </label>
              <input
                id="input-wizard-5"
                type="text"
                value={form.email}
                onChange={onFormChange}
                name="email"
                className="form-control"
                placeholder="kojo@gmail.com"
              />
              <p className="text-red-500 text-xs italic pt-1">{errors.email}</p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-5" className="form-label">
                Account Number *
              </label>
              <input
                id="input-wizard-5"
                type="number"
                value={form.account_number}
                onChange={onFormChange}
                name="account_number"
                className="form-control"
                placeholder="144454666"
              />
              <p className="text-red-500 text-xs italic pt-1">
                {errors.account_number}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-5" className="form-label">
                Country *
              </label>
              <select
                id="input-wizard-5"
                value={form.country_id}
                onChange={onFormChange}
                name="country_id"
                className="form-select"
              >
                {countries.map((country) => {
                  return (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-4" className="form-label">
                Phone Number *
              </label>
              <div className="grid grid-cols-7">
                <input
                  disabled={true}
                  type="text"
                  onChange={onFormChange}
                  value={form.calling_code}
                  name="msisdn"
                  className="col-span-1 form-control"
                  placeholder="44"
                />
                <input
                  id="input-wizard-4"
                  type="number"
                  value={form.msisdn}
                  onChange={onFormChange}
                  name="msisdn"
                  className="col-span-6 form-control"
                  placeholder="548410151"
                />
              </div>{" "}
              <p className="text-red-500 text-xs italic pt-1">
                {errors.msisdn}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-5" className="form-label">
                Relationship To Sender
              </label>
              <select
                id="input-wizard-6"
                value={form.relationship}
                onChange={onFormChange}
                name="relationship"
                className="form-select"
              >
                {relationships.map((relationship) => {
                  return <option value={relationship}>{relationship}</option>;
                })}
              </select>
              <p className="text-red-500 text-xs italic pt-1">
                {errors.relationship}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-7" className="form-label">
                Destination Institution
              </label>
              <select
                disabled={!form.calling_code}
                id="input-wizard-8"
                onChange={onFormChange}
                name="destination_institution_id"
                className="form-select"
              >
                <option value="">Select a destination institution</option>
                {destinationInstitutions.map((institution) => {
                  return (
                    <option value={institution.id}>{institution.name}</option>
                  );
                })}
              </select>
              <p className="text-red-500 text-xs italic pt-1">
                {errors.destination_institution_id}
              </p>
            </div>
          </div>
          <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
            <button className="btn btn-primary  ml-2" onClick={addReceiver}>
              {" "}
              Add receiver
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddReceiver;
