/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Pagination} from "antd";
const PaginationComponent = ({
  current,
  defaultCurrent,
  onChange,
  total,
  ...props
}) => {
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <button className="disabled:hidden text-[#999999]">Previous</button>
      );
    }
    if (type === "next") {
      return <button className="text-[#999999] disabled:hidden">Next</button>;
    }
    return originalElement;
  };

  return (
    <Pagination
      current={current}
      defaultCurrent={defaultCurrent}
      showSizeChanger={false}
      onChange={onChange}
      total={total}
      itemRender={itemRender}
      {...props}
    />
  );
};

export default PaginationComponent;
