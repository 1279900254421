import React from "react";
const Modal2 = ({
  onHide,
  size,

  children,
}) => {
  return (
    <div
      className="fixed z-100 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className={
          "flex items-end justify-center h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        }
      >
        <div
          onClick={() => onHide()}
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className={
            "inline-block align-bottom modal-" +
            size +
            " bg-white border border-theme-31 rounded text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal2;
