import axios from "axios";
import authHeader from "./auth-header";

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const API_URL = environment === "dev"? process.env.REACT_APP_API_ENDPOINT_DEVELOPMENT : environment === "staging"? process.env.REACT_APP_API_ENDPOINT_STAGING : process.env.REACT_APP_API_ENDPOINT_PRODUCTION;
const email =  environment === "dev"? process.env.REACT_APP_USER_EMAIL_DEVELOPMENT: process.env.REACT_APP_USER_EMAIL_PRODUCTION;
const password = environment === "dev"? process.env.REACT_APP_USER_PASSWORD_DEVELOPMENT: process.env.REACT_APP_USER_PASSWORD_PRODUCTION;

axios.interceptors.response.use(async response => {
    return response;
}, async error => {
    if (error.response?.status === 401 && !error.config?.isRetry) {
        //place your reentry code
        const token = await getAccessTokenAsync();

        if (token){
            //console.log("Retrying request")
            error.config.headers = authHeader();
            error.config.headers.Authorization= `Bearer ${token}`
            error.config.baseURL = undefined;
            error.config.isRetry = true;
            return axios.request(error.config);
        }
    }
    return Promise.reject(error);
});


const login = (msisdn, password, userType) => {
    //console.log(userType)
    return axios
        .post(API_URL + (userType === "staff" ? "staff/auth/login" : "agents/auth/login"),
            { msisdn, password, password_confirmation:password } ,
            { headers: authHeader() })
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response?.data;
        });


}


const getAccessToken = () => {

  return axios
    .post(API_URL + "auth/get-access-token",
      { email, password})
    .then((response) => {
      if (response.data.data.access_token) {
        localStorage.setItem("token", response.data.data.access_token);
      }
    })
}


const getAccessTokenAsync = async () => {

    try {
        const response = await axios.post(API_URL + "auth/get-access-token", { email, password});
        const token = response?.data?.data?.access_token;
        localStorage.setItem("token", token);
        return token;

    } catch (err){
        //console.log(err)
        return null
    }
}


const confirmOtp = (msisdn, otp, userType) => {

    return axios
        .post(API_URL + (userType === "staff" ? "staff/auth/confirm-otp": "agents/auth/confirm-otp"),
            { msisdn, otp} ,
            { headers: authHeader() })
        .then((response) => {
           if (response.data) {
               //console.log(response.data)
             localStorage.setItem("user", JSON.stringify(response?.data?.data));
            }

            return response?.data?.data;
        });


}

const requestPasswordReset = (msisdn, id, userType) => {
    return axios.post(API_URL + "signup", {
        msisdn,
    });
}


const logout = () => {
    localStorage.removeItem("auth");
}

const register = (username, email, password) => {
    return axios.post(API_URL + "signup", {
        username,
        email,
        password,
    });
}



const restPassword = (msisdn, userType) => {
    return axios
        .post(API_URL + (userType === "staff" ? "staff/auth/password-reset": "agents/auth/password-reset"),
            { msisdn} ,
            { headers: authHeader() });
}

export default {confirmOtp,login,logout,register, getAccessToken, getAccessTokenAsync, restPassword}
