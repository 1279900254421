import axios from 'axios';
import authHeader from './auth-header';
import AuthService from "./auth-service";

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const API_URL = environment === "dev"? process.env.REACT_APP_API_ENDPOINT_DEVELOPMENT : environment === "staging"? process.env.REACT_APP_API_ENDPOINT_STAGING : process.env.REACT_APP_API_ENDPOINT_PRODUCTION;


axios.interceptors.response.use(async response => {
  return response;
}, async error => {
  if (error.response?.status === 401 && !error.config?.isRetry) {
    //place your reentry code
    const token = await AuthService.getAccessTokenAsync();

    if (token){
      //console.log("Retrying request")
      error.config.headers = authHeader();
      error.config.headers.Authorization= `Bearer ${token}`
      error.config.baseURL = undefined;
      error.config.isRetry = true;
      return axios.request(error.config);
    }
  }
  return Promise.reject(error);
});
class RestService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  //staff
  getStaff(){
    return axios.get(API_URL + 'staff', { headers: authHeader() });
  }
  addStaff(){

  }

  //agents
  getAgents(){
    return axios.get(API_URL + 'agents', { headers: authHeader() });
  }
  getAgent(id){
    return axios.get(API_URL + `agents/${id}`, { headers: authHeader() });
  }

  //configurations
  getCountries(){
    return axios.get(API_URL + 'configurations/get-country-listing-info', { headers: authHeader() });
  }

  //senders
  getSenders(){
    return axios.get(API_URL + 'senders', { headers: authHeader() });
  }

  //receivers
  getReceivers(){
    return axios.get(API_URL + 'receivers', { headers: authHeader() });
  }

  //agencies
  getAgencies(){
    return axios.get(API_URL + 'agencies', { headers: authHeader() });
  }
}

export default new RestService();
