import React, { useEffect, useState, useContext } from "react";
import AuthService from "../../services/auth-service";
import PasswordField from "../../components/common/PasswordField/PasswordField";
import TextField from "../../components/common/TextField";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Redirect } from "react-router";
import ResetPassword from "./ResetPassword";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInputComponent from "../../components/common/PhoneInput";
import PasswordField2 from "../../components/common/PasswordField/PasswordField2";
import Button from "../../components/common/Button";
import { LOGIN_SUCCESS } from "../../redux/actions/types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { IPInfoContext } from 'ip-info-react';

const LoginForm = ({
  step,
  setStep,
  msisdn,
  setMsisdn,
  userType,
  toggleReset,
  callback,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [password, setPassword] = useState("");
  const [ipInfoJson, setIpInfoJson] = useState({});
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [staffOrAgent, setStaffOrAgent] = useState(undefined);
  const [validationMessage, setValidationMessage] = useState(null);

  const ipInfo = useContext(IPInfoContext);



  const handleLogin = (e) => {
    e.preventDefault();
    if (validateData()) {
      setLoading(true);
      AuthService.login(msisdn, password, userType)
        .then(({ data }) => {
          if (data?.expected_action != null) {
            setStep(step + 1);

            return;
          }

          let user = userType === "staff" ? data?.staff : data?.agent;
          if (userType === "staff") {
            user.isAdmin = true;
            user.type = "staff";
          } else {
            user.type = "agent";
            user.isAgent = true;
          }

          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              user: user,
              sessionId: data?.session_id,
            },
          });

          userType === "staff"
            ? history.push("/")
            : history.push("/send-money");
        })
        .catch((error) => {
          if (error?.response?.data?.code === 417) {
            setLoginError(
              error?.response?.data?.message
                ? error?.response.data.message
                : "Invalid Phone Number or Password"
            );
            if (
              error?.response?.data?.data?.expected_action === "change-password"
            ) {
              addToast(
                "Your credentials need to be reset. Please change your password",
                {
                  appearance: "info",
                  autoDismiss: true,
                  autoDismissTimeout: 5000,
                }
              );
              // setRedirect(true)
              if (userType === "agent") {
                if (error?.response?.data?.data?.agent) {
                  setStaffOrAgent(error.response.data.data.agent);
                  callback(true);
                } else {
                  setRedirect(true);
                }
              } else {
                if (error?.response?.data?.data?.staff) {
                  callback(true);
                  setStaffOrAgent(error.response.data.data.staff);
                } else {
                  setRedirect(true);
                }
              }
            }
          } else if (error?.data?.code === 400) {
            setLoginError("Invalid Request");
          } else {
            setLoginError("Something went wrong. Try again");
          }
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  const handlePhoneInput = (value) => {
    setMsisdn(value);
    setValidationMessage(null);
  };

  useEffect(() => {
    setLoginError("");
  }, [toggleReset, msisdn, password]);

  const validateData = () => {
    if (password.length < 5) {
      setLoginError("Enter a Valid Password");
    }
    if (!isValidPhoneNumber(msisdn)) {
      setValidationMessage("Enter a Valid Phone Number");
    }

    if (isValidPhoneNumber(msisdn) && password.length >= 5) {
      return true;
    }
  };

  if (redirect) {
    return (
      <Redirect
        to={userType === "agent" ? "/password-reset" : "/staff/password-reset"}
      />
    );
  }

  return !staffOrAgent || !toggleReset ? (
    <form className="w-full px-4 lg:px-0 mx-auto flex flex-col gap-8">
      {loginError && (
        <p className="validation-error flex items-center gap-2 text-left msisdn-error-text">
          <span>
            <svg
              width="15.6"
              height="15.6"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.78933 0.200012H2.81067L0 3.01068V6.98935L2.81067 9.80001H6.78933L9.6 6.98935V3.01068L6.78933 0.200012ZM7.46667 6.99468L6.79467 7.66668L4.8 5.67201L2.80533 7.66668L2.13333 6.99468L4.128 5.00001L2.13333 3.00535L2.80533 2.33335L4.8 4.32801L6.79467 2.33335L7.46667 3.00535L5.472 5.00001L7.46667 6.99468Z"
                fill="#F9105F"
              />
            </svg>
          </span>
          <span>{loginError}</span>
        </p>
      )}

      <div className="w-full z-10">
        <p className="text-2xl text-left text-black font-semibold">
          {" "}
          {!staffOrAgent || !toggleReset
            ? userType === "agent"
              ? "Agent Login"
              : "Staff Login"
            : "Change  Your Password"}
        </p>
      </div>

      <PhoneInputComponent
        labelFor={"msisdn"}
        labelName={"Phone Number"}
        placeholder="Enter phone number"
        value={msisdn}
        defaultCountry={ipInfo?.country_code}
        withCountryCallingCode
        international={true}
        className={`flex w-full text-base bg-white ${
          validationMessage && "msisdn-error"
        }`}
        validationMessage={validationMessage}
        handlePhoneInputChange={handlePhoneInput}
      />

      <div className="pb-2 pt-4 w-full flex flex-col items-start gap-2">
        <PasswordField2
          className={"block w-full text-lg border-none outline-none"}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          // validationMessage={loginError}
        />

        <div className="flex items-center w-full justify-between">
          <div className="flex items-center gap-2">
            <input type="checkbox" />
            <label className="text-xs label-text" for="">
              Keep me signed in
            </label>
          </div>

          <div className="text-xs">
            <Link
              className="underline text-link"
              to={
                userType === "agent" ? "password-reset" : "staff/password-reset"
              }
            >
              Forgot your password?
            </Link>
          </div>
        </div>
      </div>

      <div className="w-full flex items-center gap-4 flex-col">
        {loading ? (
          <Button
            disabled={loading}
            className={"primary-btn w-full justify-center"}
          >
            <div className=" flex justify-center items-center">
              <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
            </div>
          </Button>
        ) : (
          <Button
            onClick={handleLogin}
            className={"primary-btn w-full justify-center"}
          >
            <span>
              <svg
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5 5.5H4V3C4 2.46957 4.21071 1.96086 4.58579 1.58579C4.96086 1.21071 5.46957 1 6 1C6.53043 1 7.03914 1.21071 7.41421 1.58579C7.78929 1.96086 8 2.46957 8 3C8 3.13261 8.05268 3.25979 8.14645 3.35355C8.24021 3.44732 8.36739 3.5 8.5 3.5C8.63261 3.5 8.75979 3.44732 8.85355 3.35355C8.94732 3.25979 9 3.13261 9 3C9 2.20435 8.68393 1.44129 8.12132 0.87868C7.55871 0.31607 6.79565 0 6 0C5.20435 0 4.44129 0.31607 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3V5.5H2.5C1.96974 5.50058 1.46137 5.71148 1.08643 6.08643C0.711479 6.46137 0.500579 6.96974 0.5 7.5V13C0.500579 13.5303 0.711479 14.0386 1.08643 14.4136C1.46137 14.7885 1.96974 14.9994 2.5 15H9.5C10.0303 14.9994 10.5386 14.7885 10.9136 14.4136C11.2885 14.0386 11.4994 13.5303 11.5 13V7.5C11.4994 6.96974 11.2885 6.46137 10.9136 6.08643C10.5386 5.71148 10.0303 5.50058 9.5 5.5Z"
                  fill="white"
                />
              </svg>
            </span>

            <span>LOGIN</span>
          </Button>
        )}

        {userType === "agent" ? (
          <span className=" text-gray-400 hover:underline hover:text-gray-100 mb-2">
            <Link className="label-text text-sm" to={"staff"}>
              Staff? Log in here.
            </Link>
          </span>
        ) : (
          <span className="text-gray-400 hover:underline hover:text-gray-100 mb-2">
            <Link className="label-text text-sm" to={""}>
              Agent? Log in here.
            </Link>
          </span>
        )}
      </div>

      <div className="absolute bottom-4 left-16 label-text">
        ©2023 Instaant Transfers
      </div>
    </form>
  ) : (
    <ResetPassword staffOrAgent={staffOrAgent} userType={userType} />
  );
};

export default LoginForm;
