import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useToasts } from "react-toast-notifications";
import restService from "../../../services/restService";
import ErrorMessages from "../ErrorMessages";
const AddIdentification = ({
  description,
  onSubmitSuccess,
  onFinally,
  userId,
  handleSubmit,
  data: formdata,
}) => {
  const { addToast } = useToasts();
  const proofRef = useRef(null);
  const imgRef = useRef(null);

  const [apiErrors, setApiErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [proofOfAddress, setProofOfAddress] = useState({
    image: { preview: "", raw: "" },
  });
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    ...(formdata ?? {}),
    id_type: "",
    expiry_date: new Date().setDate(new Date().getDate() + 2),
    date_issued: new Date().setDate(new Date().getDate() - 2),
    id_category: "FULL IMAGE",
    id_number: "",
    id_full_image: { preview: "", raw: "" },
    type: "full",
  });

  function resetForm() {
    setForm({
      id_type: "",
      expiry_date: new Date().setDate(new Date().getDate() + 2),
      date_issued: new Date().setDate(new Date().getDate() - 2),
      id_category: "FULL IMAGE",
      id_number: "",
      id_full_image: { preview: "", raw: "" },
      type: "full",
    });
  }

  async function onSubmit(formData) {
    const payload = {
      id_type: form.id_type,
      expiry_date: formatDate(new Date(form.expiry_date)),
      date_issued: formatDate(new Date(form.date_issued)),
      id_category: form.id_category,
      id_image: form.id_full_image.raw || form?.proof?.image?.raw,
    };

    if (typeof handleSubmit === "function") {
      handleSubmit?.({
        ...form,
        ...payload,
      });
      return;
    }

    setIsLoading(true);
    addToast("Adding identification...", {
      appearance: "info",
      autoDismiss: true,
      autoDismissTimeout: 2000,
    });

    try {
      const { data } = await restService.SendersApi.uploadIdentification(
        userId,
        payload
      );

      resetForm();

      addToast("Identification added successfully.", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      onSubmitSuccess?.(data);
    } catch ({ response }) {
      addToast(response?.data?.message ?? "Error occurred! Try again.", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });

      const { data } = JSON.parse(JSON.stringify(response?.data));
      setApiErrors(data);
    } finally {
      setIsLoading(false);
      onFinally?.();
    }
  }

  const handleChange = ({ target }) => {
    const details = { ...form };
    if (
      target.name === "id_full_image" ||
      target.name === "id_front_image" ||
      target.name === "id_back_image"
    ) {
      if (target.files.length) {
        details[target.name] = {
          preview: URL.createObjectURL(target.files[0]),
          raw: target.files[0],
        };
      }
    } else if (target.name === "proof") {
      details[target.name] = {
        image: {
          preview: URL.createObjectURL(target.files[0]),
          raw: target.files[0],
        },
      };
      setProofOfAddress({
        image: {
          preview: URL.createObjectURL(target.files[0]),
          raw: target.files[0],
        },
      });
    } else {
      details[target.name] = target.value;
      setForm({ ...form, [target.name]: target.value });
    }
    setForm(details);
    delete errors[target.name];
  };

  const validate = () => {
    let errorList = {};
    // if (!form.id_number || form.id_number === "") {
    //   errorList.id_number = "Please enter an identification number";
    // }
    if (
      form.type === "full" &&
      !form.id_full_image.raw &&
      !form?.proof?.image?.raw
    ) {
      errorList.id_full_image = "Please upload proof of identification";
    }

    setErrors(errorList);

    return Object.keys(errorList).length === 0;
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0 to 11
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const save = () => {
    if (validate()) {
      //is valid
      let formData = { ...form };
      // formData.msisdn = form.calling_code + form.msisdn;
      if (form.id_full_image) {
        formData.id_full_image = form.id_full_image.raw || null;
        formData.category = "Full Image";
        // delete formData.id_back_image;
        // delete formData.id_front_image;

        formData.id_front_image = form.id_full_image.raw || null;
        formData.id_back_image = form.id_full_image.raw || null;
      } else {
        formData.id_front_image = form?.id_front_image?.raw;
        formData.id_back_image = form?.id_back_image?.raw;
        delete formData.id_full_image;
      }
      formData.proof_of_address = form?.proof?.image?.raw;
      formData.date_issued = moment(form.date_issued).format("YYYY-MM-DD");
      formData.expiry_date = moment(form.expiry_date).format("YYYY-MM-DD");

      if (!formData.proof_of_address) {
        delete formData.proof_of_address;
      }

      onSubmit(formData);
    }
  };

  useEffect(() => {
    restService.UtilsApi.getIdentificationConfigs()
      .then(({ data }) => {
        if (data.data.types) {
          let type = "";
          setForm({ ...form, id_type: data.data.types[0] });
          setTypes(data.data.types);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);

  const changeDateIssued = (date) => {
    setForm({ ...form, date_issued: date });
  };

  const changeExpiryDate = (date) => {
    setForm({ ...form, expiry_date: date });
  };

  const handleUploadProofOfAddress = () => {
    proofRef.current.click();
  };
  const handleUploadFullImage = () => {
    imgRef.current.click();
  };

  return (
    <div className="flex  intro-y flex-col items-center justify-center gap-11">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-theme-32 font-medium text-xl">
          Add Identification
        </h1>

        {description ? (
          <p className="text-[#727681] text-center text-xs font-normal">
            {description}
          </p>
        ) : (
          <p className="text-[#727681] text-center text-xs font-normal">
            Upload the sender's identification and proof of address. Upload full
            images or front and back images
          </p>
        )}
      </div>

      {apiErrors && <ErrorMessages errorData={apiErrors} />}

      <div className="flex flex-col md:max-w-[800px] gap-4 justify-center w-full">
        <div className="grid w-full grid-cols-12 items-start flex-wrap gap-4">
          <div className="col-span-12 md:col-span-12 flex-col flex w-full md:w-auto gap-1">
            <label className="text-xs font-normal" htmlFor="type">
              Identification Type
            </label>
            <div className="relative">
              <select
                id="type"
                name="id_type"
                className="border appearance-none  w-full focus:border-theme-31 outline-none rounded px-4 py-2 border-theme-31"
                onChange={handleChange}
              >
                {types.map((type) => {
                  return (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  );
                })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
              {errors.type && (
                <p className="text-red-500 text-xs italic">{errors.type}</p>
              )}
            </div>
          </div>
          {/* <div className="col-span-12 md:col-span-6  w-full  ">
            <Input
              containerStyle={"w-full md:w-auto"}
              labelFor={"id_number"}
              className="md:min-w-[200px] w-full"
              value={form.id_number}
              onChange={handleChange}
              name="id_number"
              id={"id_number"}
              labelName={"Identification Number"}
              placeholder="GH22333434332"
            />
            {errors.id_number && (
              <p className="text-red-500 text-[10px] italic pt-1">
                {errors?.id_number}
              </p>
            )}
          </div> */}
        </div>

        <div className="grid grid-cols-12 flex-wrap items-start gap-4 justify-center w-full">
          <div className=" col-span-12  md:col-span-6 flex flex-col w-full md:w-auto gap-1">
            <label className="text-xs font-normal" htmlFor="type">
              Date Issued
            </label>
            <div className="relative">
              <DatePicker
                selected={form.date_issued}
                onChange={changeDateIssued}
                maxDate={new Date().setDate(new Date().getDate() - 2)}
                selectsStart
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
                dateFormat="yyyy-MM-dd"
                className="border md:min-w-[200px] w-full focus:border-theme-31 outline-none rounded px-4 py-2 border-theme-31"
              />
            </div>
          </div>
          <div className=" col-span-12  md:col-span-6 flex flex-col w-full md:w-auto gap-1">
            <label className="text-xs font-normal" htmlFor="type">
              Expiry Date
            </label>
            <div className="relative">
              <DatePicker
                selected={form.expiry_date}
                onChange={changeExpiryDate}
                minDate={new Date().setDate(new Date().getDate() + 2)}
                selectsStart
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
                dateFormat="yyyy-MM-dd"
                className="border md:min-w-[200px] w-full focus:border-theme-31 outline-none rounded px-4 py-2 border-theme-31"
              />
            </div>
          </div>
        </div>

        <div className="mx-auto flex gap-8  flex-col justify-start w-full ">
          <div className=" grid grid-cols-12 gap-8 items-start justify-between w-full ">
            <div className="flex items-start flex-col gap-4 col-span-12  md:col-span-6 w-full md:w-auto">
              <div className="grid gap-1">
                <p>Proof of Identification</p>
                <button
                  onClick={handleUploadFullImage}
                  className="btn btn-secondary flex items-center gap-2"
                >
                  <span>
                    <svg
                      width="13"
                      height="11"
                      viewBox="0 0 13 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.23406 4.81487C1.63214 4.51949 2.14031 4.31657 2.66483 4.23852C2.82396 3.29762 3.2468 2.49336 3.898 1.89828C4.58767 1.26669 5.49968 0.919434 6.46627 0.919434C7.31603 0.919434 8.10089 1.18552 8.73478 1.68911C9.21639 2.07219 9.5915 2.57237 9.8242 3.14176C10.5439 3.25223 11.1973 3.54233 11.6845 3.97147C12.2966 4.51061 12.6201 5.22889 12.6201 6.04899C12.6201 6.87798 12.2718 7.63397 11.6396 8.18055C11.0357 8.70143 10.2228 8.9884 9.35089 8.9884H6.85089V5.30526L8.00473 6.45797L8.54872 5.91451L6.46627 3.83435L4.38382 5.91451L4.92781 6.45797L6.08166 5.30526V8.9884H3.10089C2.34752 8.9884 1.66074 8.77707 1.16699 8.39356C0.607857 7.95889 0.312425 7.33691 0.312425 6.59653C0.312425 5.87753 0.631175 5.26107 1.23406 4.81487ZM6.85089 8.9884H6.08166V10.9194H6.85089V8.9884Z"
                        fill="#0042C6"
                      />
                    </svg>
                  </span>

                  <span>Upload Full Image</span>
                </button>
              </div>

              {form.id_full_image.preview && (
                <figure>
                  <img
                    src={form.id_full_image.preview}
                    className="h-36 w-36 m-auto image-preview"
                    alt="id"
                  />
                  <figcaption className="text-center">Full Image</figcaption>
                </figure>
              )}
              {errors.id_full_image && (
                <p className="text-red-500 text-xs italic">
                  {errors.id_full_image}
                </p>
              )}
            </div>

            {/* <div className="flex items-start flex-col gap-4 col-span-12  md:col-span-6  w-full md:w-auto">
              <div className="grid gap-1">
                <p>Proof of Address</p>
                <div>
                  <button
                    onClick={handleUploadProofOfAddress}
                    className="btn btn-secondary flex items-center gap-2"
                  >
                    <span>
                      <svg
                        width="13"
                        height="11"
                        viewBox="0 0 13 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.23406 4.81487C1.63214 4.51949 2.14031 4.31657 2.66483 4.23852C2.82396 3.29762 3.2468 2.49336 3.898 1.89828C4.58767 1.26669 5.49968 0.919434 6.46627 0.919434C7.31603 0.919434 8.10089 1.18552 8.73478 1.68911C9.21639 2.07219 9.5915 2.57237 9.8242 3.14176C10.5439 3.25223 11.1973 3.54233 11.6845 3.97147C12.2966 4.51061 12.6201 5.22889 12.6201 6.04899C12.6201 6.87798 12.2718 7.63397 11.6396 8.18055C11.0357 8.70143 10.2228 8.9884 9.35089 8.9884H6.85089V5.30526L8.00473 6.45797L8.54872 5.91451L6.46627 3.83435L4.38382 5.91451L4.92781 6.45797L6.08166 5.30526V8.9884H3.10089C2.34752 8.9884 1.66074 8.77707 1.16699 8.39356C0.607857 7.95889 0.312425 7.33691 0.312425 6.59653C0.312425 5.87753 0.631175 5.26107 1.23406 4.81487ZM6.85089 8.9884H6.08166V10.9194H6.85089V8.9884Z"
                          fill="#0042C6"
                        />
                      </svg>
                    </span>

                    <span>Proof of Address</span>
                  </button>
                </div>
              </div>

              {proofOfAddress.image.preview && (
                <figure>
                  <img
                    src={proofOfAddress.image.preview}
                    className="h-36 w-36 object-cover m-auto image-preview"
                    alt="id"
                  />
                </figure>
              )}
            </div> */}
          </div>

          <input
            onChange={handleChange}
            hidden
            type="file"
            accept="image/*"
            id="upload-front-button"
            name="id_front_image"
          />
          <input
            onChange={handleChange}
            hidden
            accept="image/*"
            type="file"
            id="upload-back-button"
            name="id_back_image"
          />
          <input
            onChange={handleChange}
            hidden
            ref={imgRef}
            type="file"
            accept="image/*"
            id="upload-full-button"
            name="id_full_image"
          />
          {/* <input
            onChange={handleChange}
            hidden
            ref={proofRef}
            type="file"
            accept="image/*"
            id="upload-proof-button"
            name="proof"
          /> */}

          <div className="flex items-center justify-end">
            <button
              onClick={save}
              disabled={isLoading}
              className="flex items-center justify-end gap-1 bg-theme-32 border border-theme-32 hover:bg-theme-1 transition text-white rounded-md px-3 py-2"
            >
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddIdentification;
