import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import TransactionStyles from "../Transactions/styles";
import PageTitle from "../../../components/common/PageTitle/PageTitle";
import Table from "../../../components/common/Table";
import tableData from "../../../assets/static_json/fundingTable.json";
import {useSelector} from "react-redux";
import commissionsData from "../../../assets/static_json/commisions.json";
import restService from "../../../services/restService";
import moment from "moment";
import Search from "../../../components/common/Search";
import SkeletonTransaction from "../../../components/Skeletons/TransactionsSkeleton/SkeletonTransaction";
import PaginationComponent from "../../../components/common/Pagination";
import SkeletonElement from "../../../components/Skeletons/SkeletonElement";
import Table2 from "../../../components/common/Table/Table2";
import FilterModal from "../../AgentViews/FundingStatement/FilterModal";
import DateRangePicker from "../../../components/common/DateRange";
import {addDays} from "date-fns";
const renderHeadData = (item, index) => {
  return <th key={index}>{item}</th>;
};

const renderBodyData = (item, index) => {
  return (
    <tr key={index}>
      <td>
        <Link to={`#`} className="name">
          {item?.id}
        </Link>
        <div className="id">{item?.recipientId}</div>
      </td>
      <td>
        <div
          className={item.operation === "D" ? `text-theme-6` : `text-theme-9`}
        >
          {item.operation === "D" ? `-` : `+`} {item.amount}
        </div>
      </td>
      <td>{item.operation === "D" ? "Debit" : "Credit"}</td>
      <td>{item.description}</td>
      <td> {moment(new Date(item.created_at)).format("MMMM d, YYYY")}</td>
      {/*<td> {item.type} <span className="payment-medium">{item.paymentMedium}</span></td>*/}
    </tr>
  );
};

const FundingStatement = ({match}) => {
  const id = match.params.id;
  const type = match.params.type;

  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageTo, setPageTo] = useState(0);
  const [totalEntries, setTotalEntries] = useState(1);
  const [fundingStatements, setFundingStatements] = useState([]);

  const head = tableData.headData;
  const body = fundingStatements;
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const fetchFundingStatements = () => {
    setLoading(true);

    if (type === "agent") {
      restService.FundingStatementApi.getFundingStatementsByAgent(id)
        .then((response) => {
          if (response) {
            setPageTo(response.data?.data?.to);
            setTotalEntries(response.data?.data?.total);
            setFundingStatements(response.data?.data?.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      restService.FundingStatementApi.getFundingStatementsByAgency(id)
        .then((response) => {
          if (response) {
            setPageTo(response.data?.data?.to);
            setTotalEntries(response.data?.data?.total);
            setFundingStatements(response.data?.data?.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchFundingStatements();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const pageChange = (page) => {
    setCurrentPage(page);
    // fetchFundingStatements(agentId, roleId, page);
  };
  const applyFilter = (values) => {
    setShowModal(false);

    setLoading(true);

    if (values.dateRange) {
      setStartDate(values.dateRange[0].format("YYYY-MM-DD"));
      setEndDate(values.dateRange[1].format("YYYY-MM-DD"));
    }

    restService.FundingStatementApi.getFundingStatementsByDate(startDate)
      .then((response) => {
        setStartDate("");
        setEndDate("");
        setPageTo(response.data?.data?.to);
        setTotalEntries(response.data?.data?.total);
        setFundingStatements(response.data?.data?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    // <TransactionStyles>
    //   <PageTitle title={`Transactions Histoy`} />

    //   <div className="content-layout">
    //     <div className="header-layout">
    //       <div className="page">
    //         {loading
    //           ? ""
    //           : fundingStatements && fundingStatements.length <= 0
    //           ? "No Funding Statements Found"
    //           : `Showing 1 to ${pageTo} of ${totalEntries} entries`}
    //       </div>
    //       <Search
    //         placeholder={"Search Statement"}
    //         onChange={(e) => setSearchTerm(e.target.value)}
    //       />
    //     </div>

    //     {!loading ? (
    //       <div className="table-container">
    //         <Table
    //           headData={head}
    //           renderHead={(item, index) => renderHeadData(item, index)}
    //           bodyData={body}
    //           renderBody={(item, index) => renderBodyData(item, index)}
    //         />
    //       </div>
    //     ) : (
    //       <>
    //         <Table
    //           headData={head}
    //           renderHead={(item, index) => renderHeadData(item, index)}
    //         />
    //         {Array(2)
    //           .fill()
    //           .map((item, index) => (
    //             <SkeletonTransaction key={index} />
    //           ))}
    //       </>
    //     )}

    //     {/*Pagination ---> To be done well*/}
    //     <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
    //       <ul className="pagination">
    //         <li>
    //           <a className="pagination__link" href="">
    //             {" "}
    //             <i className="w-4 h-4" data-feather="chevrons-left"></i>{" "}
    //           </a>
    //         </li>
    //         <li>
    //           <a className="pagination__link" href="">
    //             {" "}
    //             <i className="w-4 h-4" data-feather="chevron-left"></i>{" "}
    //           </a>
    //         </li>
    //         <li>
    //           <a className="pagination__link" href="">
    //             ...
    //           </a>
    //         </li>
    //         <li>
    //           <a className="pagination__link" href="">
    //             1
    //           </a>
    //         </li>
    //         <li>
    //           <a className="pagination__link pagination__link--active" href="">
    //             2
    //           </a>
    //         </li>
    //         <li>
    //           <a className="pagination__link" href="">
    //             3
    //           </a>
    //         </li>
    //         <li>
    //           <a className="pagination__link" href="">
    //             ...
    //           </a>
    //         </li>
    //         <li>
    //           <a className="pagination__link" href="">
    //             {" "}
    //             <i className="w-4 h-4" data-feather="chevron-right"></i>{" "}
    //           </a>
    //         </li>
    //         <li>
    //           <a className="pagination__link" href="">
    //             {" "}
    //             <i className="w-4 h-4" data-feather="chevrons-right"></i>
    //           </a>
    //         </li>
    //       </ul>
    //       <select className="w-20 form-select box mt-3 sm:mt-0">
    //         <option>10</option>
    //         <option>25</option>
    //         <option>35</option>
    //         <option>50</option>
    //       </select>
    //     </div>
    //   </div>
    // </TransactionStyles>

    <div className="mt-13-important">
      <TransactionStyles>
        <div className="content-layout">
          <div className="header-layout gap-2">
            <div className="flex md:items-center w-full gap-2 md:flex-row flex-col ">
              <Search
                placeholder={"Search "}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className=" flex flex-col-reverse w-full justify-end sm:flex-row md:items-center">
              <div className=" gap-4 flex justify-end md:justify-end sm:flex-row items-center md:mr-3">
                <DateRangePicker
                  range={dateRange}
                  show={showDateRange}
                  setShow={setShowDateRange}
                  setRange={setDateRange}
                />

                <button
                  className="btn btn-primary-2  flex items-center gap-2 text-xs"
                  // onClick={exportTransactions}
                >
                  <span>
                    <svg
                      width="9"
                      height="11"
                      viewBox="0 0 9 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.77902 4.4763C1.63547 4.4763 1.4978 4.53332 1.3963 4.63482C1.2948 4.73633 1.23777 4.87399 1.23777 5.01754V9.70832C1.23777 9.85187 1.2948 9.98954 1.3963 10.091C1.4978 10.1925 1.63547 10.2496 1.77902 10.2496H7.19146C7.33501 10.2496 7.47267 10.1925 7.57418 10.091C7.67568 9.98954 7.7327 9.85187 7.7327 9.70832V5.01754C7.7327 4.87399 7.67568 4.73633 7.57418 4.63482C7.47267 4.53332 7.33501 4.4763 7.19146 4.4763H6.28939C6.0901 4.4763 5.92856 4.31475 5.92856 4.11547C5.92856 3.91619 6.0901 3.75464 6.28939 3.75464H7.19146C7.5264 3.75464 7.84763 3.88769 8.08447 4.12453C8.32131 4.36137 8.45436 4.6826 8.45436 5.01754V9.70832C8.45436 10.0433 8.32131 10.3645 8.08447 10.6013C7.84763 10.8382 7.5264 10.9712 7.19146 10.9712H1.77902C1.44407 10.9712 1.12285 10.8382 0.886009 10.6013C0.649169 10.3645 0.516113 10.0433 0.516113 9.70832V5.01754C0.516113 4.6826 0.649169 4.36137 0.886009 4.12453C1.12285 3.88769 1.44407 3.75464 1.77902 3.75464H2.68109C2.88037 3.75464 3.04192 3.91619 3.04192 4.11547C3.04192 4.31475 2.88037 4.4763 2.68109 4.4763H1.77902Z"
                        fill="white"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.84613 0.86792C5.04541 0.86792 5.20696 1.02178 5.20696 1.21157V6.91122L6.39513 5.77963C6.53604 5.64543 6.76451 5.64543 6.90542 5.77963C7.04633 5.91383 7.04633 6.13142 6.90542 6.26562L5.10127 7.98386C4.96036 8.11806 4.7319 8.11806 4.59098 7.98386L2.78684 6.26562C2.64592 6.13142 2.64592 5.91383 2.78684 5.77963C2.92775 5.64543 3.15621 5.64543 3.29713 5.77963L4.4853 6.91122V1.21157C4.4853 1.02178 4.64685 0.86792 4.84613 0.86792Z"
                        fill="white"
                      />
                    </svg>
                  </span>

                  <span>Download CSV</span>
                </button>
              </div>
            </div>
          </div>

          {showModal && (
            <FilterModal
              visible={showModal}
              onCreate={applyFilter}
              onCancel={() => {
                setShowModal(false);
              }}
            />
          )}

          <div className="header-layout gap-2">
            {!loading ? (
              <div className="flex w-full flex-col">
                <Table2
                  // headData={head}
                  tHead={
                    <>
                      <th>
                        <input type="checkbox" name="" value="" />
                      </th>
                      <th className="">ID</th>
                      <th className="">Transaction Amount</th>
                      <th className="">Credit | Debit</th>
                      <th className="">Description</th>
                      <th className="">Timestamp</th>
                    </>
                  }
                  // renderHead={(item, index) => renderHeadData(item, index)}
                >
                  {fundingStatements.map((item, index) => (
                    <tr key={index} className=" text-[#555555] font-normal">
                      <td>
                        <input type="checkbox" name="" value="" />
                      </td>

                      <td>
                        <p
                          title="sf"
                          className="overflow-hidden overflow-ellipsis whitespace-nowrap w-[140px]"
                        >
                          {item?.id}
                        </p>
                      </td>
                      <td>
                        <p
                          className={
                            item.operation === "D"
                              ? "text-[#F40B31]"
                              : "text-[#006D49]"
                          }
                        >
                          {item.operation === "D" ? `-` : `+`} {item.amount}
                        </p>
                      </td>
                      <td>
                        <p className="">
                          {item.operation === "D" ? "Debit" : "Credit"}
                        </p>
                      </td>
                      <td>
                        <p className="">{item.description}</p>
                      </td>
                      <td>
                        <p className="">
                          {moment(new Date(item.created_at)).format(
                            "MMMM d, YYYY"
                          )}
                        </p>
                      </td>
                    </tr>
                  ))}
                </Table2>
              </div>
            ) : (
              <div className="flex w-full flex-col">
                <Table2
                  // headData={head}
                  tHead={
                    <>
                      <th>
                        <input type="checkbox" name="" value="" />
                      </th>
                      <th className="">ID</th>
                      <th className="">Transaction Amount</th>
                      <th className="">Credit | Debit</th>
                      <th className="">Description</th>
                      <th className="">Timestamp</th>
                    </>
                  }
                  // renderHead={(item, index) => renderHeadData(item, index)}
                >
                  {Array(10)
                    .fill()
                    .map((item, index) => (
                      <tr key={index}>
                        {Array(6)
                          .fill()
                          .map((x, i) => (
                            <td key={i}>
                              <SkeletonElement type={"title"} />
                            </td>
                          ))}
                      </tr>
                    ))}
                </Table2>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col mb-[10vh] gap-4 justify-between md:items-center md:flex-row mt-8">
          <div className="page text-[#999999] text-xs">
            {loading
              ? ""
              : fundingStatements && fundingStatements.length <= 0
              ? "No Transactions Found"
              : `Showing  ${pageTo} out of ${totalEntries} results`}
          </div>

          <div className="mt-3  flex justify-end float-right">
            <PaginationComponent
              current={currentPage}
              defaultCurrent={currentPage}
              showSizeChanger={false}
              onChange={pageChange}
              total={totalEntries}
            />
          </div>
        </div>
      </TransactionStyles>
    </div>
  );
};

export default FundingStatement;
