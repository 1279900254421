import { useToasts } from "react-toast-notifications";
import { useSendMoneyContext } from "../../../../contexts/send-money";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/common/Button";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import restService from "../../../../services/restService";
import { ConfirmationModal } from "./components/confirmation-modal";

import Loader from "../../../../components/common/Loader";
import { ArrowLeft } from "react-feather";

export default function Review() {
  const { addToast } = useToasts();
  const { push } = useHistory();

  const agent = useSelector((state) => state.auth?.user);
  const [commissionConfigurationId, setCommissionConfigurationId] =
    useState(null);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const {
    sendMoneyData: { receiver, disbursal_country, sender, destination_wallet },
    sendAmount,
    agencyCommissionAmount,
    agentCommissionAmount,
    charge,
    receiveAmount,
    amountToPay,
    rate,
    agencyCurrency,
    transferReason,
    setAgentCommission,
    setAgencyCommission,
    setCurrentStep,
  } = useSendMoneyContext();

  const [isSubmitting, setSubmitting] = useState(false);

  const handleSendMoney = async () => {
    setSubmitting(true);
    addToast("Processing Transaction", {
      appearance: "info",
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });

    if (!transferReason) {
      addToast("Please select a transfer reason", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });

      setSubmitting(false);
      return;
    }

    let sendMoneyRequestData = {};
    sendMoneyRequestData.charge_id = 1;
    sendMoneyRequestData.rate_id = 1;
    sendMoneyRequestData.sender_id = sender.id;
    sendMoneyRequestData.commission_configuration_id =
      commissionConfigurationId;
    sendMoneyRequestData.receiver_id = receiver.id;
    sendMoneyRequestData.receiver_wallet_id = destination_wallet.id;
    sendMoneyRequestData.sender_relationship =
      receiver.pivot?.sender_relationship;
    sendMoneyRequestData.transaction_purpose = transferReason;
    sendMoneyRequestData.transaction_type = "Mobile Money";
    sendMoneyRequestData.receive_amount = Number(receiveAmount);
    sendMoneyRequestData.send_amount = Number(sendAmount);
    sendMoneyRequestData.destination_currency =
      receiver?.country?.currency_code;
    sendMoneyRequestData.source_currency = agencyCurrency?.label;
    sendMoneyRequestData.rate = rate;
    sendMoneyRequestData.charge = Number(charge);
    sendMoneyRequestData.agent_id = agent.id;
    sendMoneyRequestData.destination_institution_id =
      destination_wallet?.destination_institution.id;

    try {
      const {
        data: { data },
      } = await restService.TransactionsApi.sendMoney(sendMoneyRequestData);

      setShowConfirmationModal(true);
      addToast("Successfully Transferred to Receiver Wallet", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    } catch ({ response }) {
      addToast(response?.data?.message ?? "Transfer failed", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    } finally {
      setSubmitting(false);
    }
  };

  async function getCommissionConfiguration() {
    try {
      const {
        data: { data },
      } =
        await restService.CommissionsConfigApi.getCommissionConfigurationByAgency(
          agent?.agency?.id
        );

      setAgencyCommission(data.data?.[0]?.agency_percentage);
      setAgentCommission(data.data?.[0]?.agent_percentage);
      setCommissionConfigurationId(data.data?.[0]?.id);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCommissionConfiguration();
  }, []);

  return (
    <div className="container flex flex-col gap-8">
      <Button
        onClick={() => setCurrentStep((prev) => prev - 1)}
        className="h-6 w-6 bg-white rounded-full  flex items-center gap-2"
      >
        <ArrowLeft size={20} />
      </Button>
      <div className=" border border-[#DEDEDE] flex flex-col max-w-2xl mx-auto w-full md:px-9 px-4  py-14 gap-4">
        {isSubmitting ? <Loader message={"Please wait..."} /> : null}

        <h4 className="text-base text-black font-bold">Summary</h4>

        <div className="flex flex-col gap-12 w-full">
          <div className="flex flex-col gap-6 w-full">
            <div className="flex flex-col gap-3 w-full">
              <h5 className="text-sm text-black font-bold">
                Transaction Details
              </h5>

              <hr className="border-[#DEDEDE]" />
            </div>

            <ul className="flex flex-col gap-4">
              <li className="flex items-center justify-between gap-4">
                <p className="text-xs text-black">Exchange Rate</p>
                <p className="text-base text-black">
                  {agencyCurrency?.symbol}1 ={" "}
                  {disbursal_country?.currency_symbol} {rate}
                </p>
              </li>

              <hr className="border-[#DEDEDE]" />

              <li className="flex items-start justify-between gap-4">
                <p className="text-xs text-black">Sending From</p>
                <div className="flex flex-col">
                  <p className="text-base text-black">
                    {" "}
                    {sender?.firstname} {sender?.lastname}
                  </p>
                  <p className="text-xs text-black">{sender?.msisdn}</p>
                </div>
              </li>

              <hr className="border-[#DEDEDE]" />

              <li className="flex items-start justify-between gap-4">
                <p className="text-xs text-black">To</p>
                <div className="flex items-end justify-end flex-col">
                  <p className="text-base text-black">
                    {receiver?.firstname} {receiver?.lastname} <br />
                  </p>
                  <p className="text-xs text-black text-right">
                    {receiver?.country?.name}
                  </p>
                  <p className="text-xs text-black text-right">
                    {destination_wallet?.destination_institution?.name}
                  </p>
                  <p className="text-xs text-black">
                    {" "}
                    {destination_wallet?.account_number}
                  </p>
                </div>
              </li>

              <li className="flex items-center justify-between gap-4">
                <p className="text-xs text-black">Receive amount</p>

                <p className="text-2xl font-bold text-theme-1">
                  {disbursal_country?.currency_symbol}{" "}
                  {Number(
                    Math.sign(receiveAmount) < 0 ? 0 : receiveAmount
                  ).toFixed(2)}
                </p>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-6 w-full">
            <div className="flex flex-col gap-3 w-full">
              <h5 className="text-sm text-black font-bold">
                Disbursal Details
              </h5>

              <hr className="border-[#DEDEDE]" />
            </div>

            <ul className="flex flex-col gap-4">
              <li className="flex items-center justify-between gap-4">
                <p className="text-xs text-black">Charges</p>
                <p className="text-xs text-black">
                  {" "}
                  {agencyCurrency?.symbol} {charge?.toFixed(2)}
                </p>
              </li>
              <li className="flex items-center justify-between gap-4">
                <p className="text-xs text-black">Agent Commission</p>
                <p className="text-xs text-black">
                  {" "}
                  {agencyCurrency?.symbol} {agentCommissionAmount?.toFixed(2)}
                </p>
              </li>
              <li className="flex items-center justify-between gap-4">
                <p className="text-xs text-black">Agency Commission</p>
                <p className="text-xs text-black">
                  {" "}
                  {agencyCurrency?.symbol} {agencyCommissionAmount?.toFixed(2)}
                </p>
              </li>
              {/*<li className="flex items-center justify-between gap-4">*/}
              {/*  <p className="text-xs text-black">Amount to Pay</p>*/}
              {/*  <p className="text-2xl font-bold text-theme-1">*/}
              {/*    {agencyCurrency?.symbol} {Number(amountToPay).toFixed(2)}*/}
              {/*  </p>*/}
              {/*</li>*/}
            </ul>
          </div>
        </div>

        <div className="w-full">
          <Button
            disabled={
              !destination_wallet?.id ||
              !sendAmount ||
              !sender?.id ||
              isSubmitting
            }
            onClick={() => {
              handleSendMoney();
            }}
            className={"primary-btn-2 bg-[#0042C6] w-full justify-center"}
          >
            Send Money
          </Button>
        </div>

        <ConfirmationModal
          show={showConfirmationModal}
          disbursal_country={disbursal_country}
          sendAmount={sendAmount}
          setShow={() => {
            setShowConfirmationModal(false);
            push("/transactions");
          }}
        />
      </div>
    </div>
  );
}
