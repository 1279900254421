import React from "react";
import { Link } from "react-router-dom";

const SidebarItem = (props) => {
  // const adminActive =

  const handleActive = () => {
    if (props.userType?.toLowerCase() == "agent") {
      return props.active
        ? "bg-[#F7F8FD] text-[#0042C6] rounded-[100px]"
        : "rounded-[100px]";
    }

    return props.active ? "border-l-4 border-[#0042C6] text-[#0042C6]" : "";
  };

  return (
    <li
      key={props.index}
      onClick={() => props?.onOpenChange?.(false)}
      className="w-full"
    >
      <div className={`px-4 py-3   w-full font-medium ${handleActive()}`}>
        <Link to={props.route} className="flex items-center text-sm gap-4">
          <span>{props?.icon}</span>

          <p className="">{props.name}</p>
        </Link>
      </div>
    </li>
  );
};

export default SidebarItem;
