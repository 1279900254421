import React from "react";

const defaultImg =
  "https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png";

const UserCard = ({ user, isActive }) => {
  const fullImage =
    user?.identifications?.find((x) =>
      x?.identification_category?.name?.toLowerCase()?.includes("full image")
    )?.resource_path ?? defaultImg;

  return (
    <div
      style={user?.bgColor ? { backgroundColor: user?.bgColor } : {}}
      className={`flex flex-col border min-w-[150px]  gap-3 h-full w-full sm:w-auto items-start rounded-md p-3
          ${isActive ? " border-[#0042C6] " : " border-[#DEDEDE] "}
        `}
    >
      <span>
        <img
          src={fullImage}
          alt=""
          className="w-[30px] h-[30px] object-cover rounded-full"
        />
      </span>

      <p className="text-black font-medium">
        {user?.firstname} {user?.lastname}
      </p>
      <p
        title={user?.msisdn}
        className="text-black truncate w-[130px] font-normal text-xs min-h-[16px]"
      >
        {user?.msisdn ?? user?.contact_number}
      </p>
    </div>
  );
};

export default UserCard;
