import { Empty } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AddNewReceiver from "../../../components/common/AddNewReceiver";
import PaginationComponent from "../../../components/common/Pagination";
import ReceiverCard from "../../../components/common/ReceiverCard";
import Search from "../../../components/common/Search";
import SkeletonSubProfile from "../../../components/Skeletons/SubProfileSkeleton/SkeletonSubProfile";
import { downloadFile } from "../../../helpers/fileUtils";
import RestService from "../../../services/restService";
import { useHistory } from "react-router-dom";

const Receivers = () => {
  const history = useHistory();

  const [receivers, setReceivers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageTo, setPageTo] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [from, setFrom] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  // const [showModal, setShowModal] = useState(false);
  const [showAddNewReceiver, setShowAddNewReceiver] = useState(false);

  const [showDateRange, setShowDateRange] = useState(false);
  const fetchReceivers = (page) => {
    RestService.ReceiversApi.getReceivers(page).then(({ data }) => {
      setReceivers(data?.data?.data);
      setTotalEntries(data?.data?.total);
      setFrom(data?.data?.from);
      setPageTo(data?.data?.to);
    });
  };
  const searchReceiverByInput = async (input) => {
    const response = await RestService.ReceiversApi.getReceiverBySearchTerm(
      input
    );
    const receivers = await response?.data?.data?.data;
    setReceivers(receivers);
  };

  const exportUsers = () => {
    let headers = ["ID,Name, Phone Number, Email, Country, Status, Joined"];

    let userCsv = receivers.reduce((acc, user) => {
      const {
        id,
        firstname,
        lastname,
        email,
        contact_number,
        country,
        status,
        created_at,
      } = user;

      // console.log(user);
      acc.push(
        [
          id,
          `${firstname} ${lastname}`,
          contact_number,
          email,
          country.name,
          status,
          moment(new Date(created_at)).format("MMMM DD YYYY"),
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...userCsv].join("\n"),
      fileName: `Receivers-${moment().format("YYYY-MM-DD-H-m-s")}.csv`,
      fileType: "text/csv",
    });
  };

  const pageChange = (page) => {
    setCurrentPage(page);
    fetchReceivers(page);
  };

  useEffect(() => {
    fetchReceivers();
  }, []);

  return (
    <>
      {/* <PageTitle title={`Recipients`} /> */}

      <div className="flex flex-col w-full gap-6 mt-[5rem]">
        <div className="intro-y col-span-12 justify-between flex flex-wrap sm:flex-nowrap items-center mt-2">
          <button
            onClick={() => history.push("/add-receiver")}
            className="btn btn-primary-2 shadow-md mr-2"
          >
            Add New Receiver
          </button>

          <div className="flex flex-col sm:flex-row items-start md:w-auto w-full gap-2 md:gap-0 md:items-center">
            <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
              <Search
                placeholder={"Search Receiver"}
                onChange={(e) => searchReceiverByInput(e.target.value)}
              />
            </div>
            <div className="intro-y flex flex-col-reverse sm:flex-row items-center mr-3">
              <button className="btn btn-primary-2" onClick={exportUsers}>
                {" "}
                Download as CSV{" "}
              </button>
            </div>
          </div>
        </div>

        {!loading ? (
          receivers && receivers.length <= 0 ? (
            <div className="font-bold justify-center col-span-12">
              <Empty />
            </div>
          ) : (
            <ul className="grid intro-y grid-cols-12 gap-4 ">
              {receivers.map((user, index) => (
                <li className="lg:col-span-2 md:col-span-4 sm:col-span-6 col-span-6">
                  <ReceiverCard user={user} key={index} />
                </li>
              ))}
            </ul>
          )
        ) : (
          Array(9)
            .fill()
            .map((item, index) => <SkeletonSubProfile key={index} />)
        )}
      </div>
      <div className="flex flex-col mb-[10vh] gap-4 justify-between md:items-center md:flex-row mt-8">
        <div className="page text-[#999999] text-xs">
          {loading
            ? ""
            : receivers && receivers.length <= 0
            ? "No Receivers Found"
            : `Showing ${from} to ${pageTo} of ${totalEntries} entries`}
        </div>

        <div className=" flex justify-end float-right">
          <PaginationComponent
            current={currentPage}
            defaultCurrent={currentPage}
            showSizeChanger={false}
            onChange={pageChange}
            total={totalEntries}
          />
        </div>
      </div>

      {showAddNewReceiver && (
        <AddNewReceiver
          show={showAddNewReceiver}
          setShow={setShowAddNewReceiver}
          refetchData={fetchReceivers}
        />
      )}
    </>
  );
};

export default Receivers;
