import styled from "styled-components";
import tw from "twin.macro";

const TransactionStyles = styled.div.attrs({
  className: "main",
})`
  & {
    .page-header-title {
      ${tw`text-lg font-medium mt-10`}
    }

    .content-layout {
      ${tw`grid grid-cols-12 gap-6 mt-5`}
      .header-layout {
        ${tw`col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2`}
        .page {
          ${tw`hidden md:block mx-auto text-gray-600`}
        }
      }

      .table-container {
        ${tw`col-span-12 overflow-auto lg:overflow-scroll`}
      }
    }
  }
`;
export default TransactionStyles;
