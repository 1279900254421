import "./App.scss";
import React from "react";
import Layout from "./components/Layout";
import { BrowserRouter } from "react-router-dom";
import { history } from "./helpers/history";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import IPInfo from 'ip-info-react';

function App() {
    return (

        <IPInfo>
            <div className="App">
                <BrowserRouter history={history}>
                    <Layout />
                </BrowserRouter>
            </div>
        </IPInfo>
    );
}

export default App;
