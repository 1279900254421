import React from "react";
import Loader from "../../../../components/common/Loader";
import Modal2 from "../../../../components/common/Modal/Modal2";
import Input from "../../../../components/common/Input";
import {useToasts} from "react-toast-notifications";
import restService from "../../../../services/restService";
import {useEffect} from "react";
import {useState} from "react";

const keys = [
  "minimum_threshold_amount",
  "maximum_threshold_amount",
  "fixed_charge_amount",
  "percentage_charge_amount",
  "destination_currency",
  "source_currency",
];

const AddChargeModal = ({isAddingID, setShow}) => {
  const {addToast} = useToasts();
  const [form, setForm] = useState({
    agent_id: "",
    agency_id: "",
    minimum_threshold_amount: 0,
    maximum_threshold_amount: 0,
    fixed_charge_amount: 0,
    percentage_charge_amount: 0,
    destination_currency: "",
    source_currency: "",
  });

  const [confirmation, setConfirmation] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [errors, setErrors] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [agents, setAgents] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [callback, setCallback] = useState(() => {});

  const initFormValues = async () => {
    let details = {};
    const agencyList = await restService.AgencyApi.getAgencies();
    const agentList = await restService.AgentApi.getAgentsByAgency(
      agencyList.data?.data?.data[0]?.id
    );
    const countryList = await restService.UtilsApi.getCountries();
    const currencies = countryList?.data?.data?.map((c) => {
      return c.currency_code;
    });
    setCurrencies(currencies);
    setAgents(agentList.data?.data?.data);
    setAgencies(agencyList.data?.data?.data);

    setForm({
      ...form,
      source_currency: currencies[0],
      destination_currency: currencies[0],
    });
  };

  useEffect(() => {
    initFormValues();
  }, []);

  const validateForm = () => {
    let errorList = {...errors};
    keys.forEach((key) => {
      if (!form[key]) {
        if (
          key === "fixed_charge_amount" ||
          key === "percentage_charge_amount"
        ) {
          errorList[key] =
            "At least a fixed charge amount or percentage charge amount is required.";
        } else {
          errorList[key] = "This field is required.";
        }
      }
    });
    if (form.fixed_charge_amount || form.percentage_charge_amount) {
      delete errorList.fixed_charge_amount;
      delete errorList.percentage_charge_amount;
    }
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };

  const saveCharge = () => {
    if (validateForm()) {
      restService.ChargesApi.addCharge(form).then((response) => {
        setForm({
          agent_id: "",
          agency_id: "",
          minimum_threshold_amount: 0,
          maximum_threshold_amount: 0,
          fixed_charge_amount: 0,
          percentage_charge_amount: 0,
        });
        addToast("Successfully Added Charge", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });

        setShow(false);
      });
    }
  };

  const onFormChange = ({target}) => {
    let details = {...form};
    details[target.name] = target.value;

    if (target.name === "agency_id") {
      restService.AgentApi.getAgentsByAgency(target.value).then(({data}) => {
        setAgents(data?.data.data);
        setForm({...details, agent_id: data?.data?.data[0]?.id});
      });

      let errorList = {...errors};
      delete errorList["agent_id"];
    }
    setForm(details);

    let errorList = errors;
    delete errorList[target.name];

    if (details.fixed_charge_amount || details.percentage_charge_amount) {
      delete errorList.fixed_charge_amount;
      delete errorList.percentage_charge_amount;
    }
    setErrors(errorList);
  };

  return (
    <>
      {isAddingID ? <Loader /> : null}
      <Modal2
        onHide={() => {
          setShow(false);
        }}
        size={"lg"}
      >
        <>
          {/* {senderAdded ? (
              <div className="h-[50vh] flex items-center justify-center">
                <Success />
              </div>
            ) : ( */}
          <div className="p-8 flex intro-y flex-col gap-4 ">
            <span className="cursor-pointer" onClick={() => setShow(false)}>
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.14446 1.14446L3.09818 5.19074H12.4741V6.80926H3.09818L7.14446 10.8555L6 12L0 6L6 0L7.14446 1.14446Z"
                  fill="black"
                />
              </svg>
            </span>

            <div className="flex flex-col gap-11 items-center justify-center">
              <div className="flex flex-col items-center justify-center">
                <h1 className="text-theme-32 text-center font-medium text-xl">
                  Add Charge
                </h1>
                <p className="text-[#727681] text-center text-xs font-normal">
                  Please enter the charge details.
                </p>
              </div>
              <div className="grid w-full grid-cols-12 items-start flex-wrap gap-4">
                <div className="col-span-12  sm:col-span-6 w-full  ">
                  <Input
                    containerStyle={"w-full md:w-auto"}
                    labelFor={"account_number"}
                    className="md:min-w-[200px] w-full"
                    type="number"
                    min={0}
                    value={form.minimum_threshold_amount}
                    onChange={onFormChange}
                    name="minimum_threshold_amount"
                    labelName={" Minimum Threshold Amount *"}
                    placeholder="13.20"
                  />
                  {errors.minimum_threshold_amount && (
                    <p className="text-red-500 text-[10px] italic pt-1">
                      {errors?.minimum_threshold_amount}
                    </p>
                  )}
                </div>
                <div className="col-span-12  sm:col-span-6  w-full  ">
                  <Input
                    containerStyle={"w-full md:w-auto"}
                    labelFor={"account_number"}
                    className="md:min-w-[200px] w-full"
                    type="number"
                    min={0}
                    value={form.maximum_threshold_amount}
                    onChange={onFormChange}
                    name="maximum_threshold_amount"
                    labelName={" Maximum Threshold Amount *"}
                    placeholder="43.20"
                  />
                  {errors.maximum_threshold_amount && (
                    <p className="text-red-500 text-[10px] italic pt-1">
                      {errors?.maximum_threshold_amount}
                    </p>
                  )}
                </div>
                <div className="col-span-12  sm:col-span-6  w-full  ">
                  <Input
                    containerStyle={"w-full md:w-auto"}
                    labelFor={"account_number"}
                    className="md:min-w-[200px] w-full"
                    type="number"
                    min={0}
                    value={form.fixed_charge_amount}
                    onChange={onFormChange}
                    name="fixed_charge_amount"
                    labelName={` Fixed Charge Amount ${
                      !form.percentage_charge_amount ? "*" : ""
                    }`}
                    placeholder="43.20"
                  />
                  {errors.fixed_charge_amount && (
                    <p className="text-red-500 text-[10px] italic pt-1">
                      {errors?.fixed_charge_amount}
                    </p>
                  )}
                </div>

                <div className="col-span-12  sm:col-span-6  w-full  ">
                  <Input
                    containerStyle={"w-full md:w-auto"}
                    labelFor={"account_number"}
                    className="md:min-w-[200px] w-full"
                    type="number"
                    min={0}
                    max={100}
                    value={form.percentage_charge_amount}
                    onChange={onFormChange}
                    name="percentage_charge_amount"
                    labelName={`   Percentage Charge Amount ${
                      !form.fixed_charge_amount ? "*" : ""
                    }`}
                    placeholder="43.20"
                  />
                  {errors.percentage_charge_amount && (
                    <p className="text-red-500 text-[10px] italic pt-1">
                      {errors?.percentage_charge_amount}
                    </p>
                  )}
                </div>

                <div className="col-span-12  sm:col-span-6 flex-col flex w-full md:w-auto gap-1">
                  <label className="text-xs font-normal" htmlFor="type">
                    Agency
                  </label>
                  <div className="relative w-full">
                    <select
                      id="type"
                      value={form.agency_id}
                      onChange={onFormChange}
                      name="agency_id"
                      className="border  appearance-none w-full focus:border-theme-31 outline-none rounded px-4 py-2 border-theme-31"
                    >
                      <option value="">None</option>
                      {agencies.map((agency) => {
                        return <option value={agency.id}>{agency.name}</option>;
                      })}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                    {errors.agency_id && (
                      <p className="text-red-500 text-xs italic">
                        {errors.agency_id}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-span-12  sm:col-span-6 flex-col flex w-full md:w-auto gap-1">
                  <label className="text-xs font-normal" htmlFor="type">
                    Agent
                  </label>
                  <div className="relative w-full">
                    <select
                      id="type"
                      value={form.agent_id}
                      onChange={onFormChange}
                      name="agent_id"
                      className="border  appearance-none w-full focus:border-theme-31 outline-none rounded px-4 py-2 border-theme-31"
                    >
                      <option value="">None</option>
                      {agents.map((agent) => {
                        return (
                          <option value={agent.id}>
                            {agent.firstname} {agent.lastname}
                          </option>
                        );
                      })}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                    {errors.agent_id && (
                      <p className="text-red-500 text-xs italic">
                        {errors.agent_id}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-12  sm:col-span-6 flex-col flex w-full md:w-auto gap-1">
                  <label className="text-xs font-normal" htmlFor="type">
                    Source Currency
                  </label>
                  <div className="relative w-full">
                    <select
                      value={form.source_currency}
                      onChange={onFormChange}
                      name="source_currency"
                      className="border  appearance-none w-full focus:border-theme-31 outline-none rounded px-4 py-2 border-theme-31"
                    >
                      {currencies.map((currency) => {
                        return <option value={currency}>{currency}</option>;
                      })}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                    {errors.source_currency && (
                      <p className="text-red-500 text-xs italic">
                        {errors.source_currency}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-12  sm:col-span-6 flex-col flex w-full md:w-auto gap-1">
                  <label className="text-xs font-normal" htmlFor="type">
                    Destination currency
                  </label>
                  <div className="relative w-full">
                    <select
                      value={form.destination_currency}
                      onChange={onFormChange}
                      name="destination_currency"
                      className="border  appearance-none w-full focus:border-theme-31 outline-none rounded px-4 py-2 border-theme-31"
                    >
                      {currencies.map((currency) => {
                        return <option value={currency}>{currency}</option>;
                      })}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                    {errors.destination_currency && (
                      <p className="text-red-500 text-xs italic">
                        {errors.destination_currency}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="mx-auto flex items-start flex-col gap-8 justify-start w-full ">
                <div className="">
                  <button
                    onClick={saveCharge}
                    className="flex items-center gap-1 bg-theme-32 border border-theme-32 hover:bg-theme-1 transition text-white rounded-md px-3 py-2"
                  >
                    <span> Add Charge</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal2>
    </>
  );
};

export default AddChargeModal;
