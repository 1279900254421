import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import restService from "../../../../services/restService";
import Input from "../../../../components/common/Input";

const keys = [
  "agent_id",
  "agency_id",
  "company_percentage",
  "agent_percentage",
  "agency_percentage",
];

const UpdateCommissionConfiguration = ({
                                         commission,
                                         onSubmit,
                                         initCallback,
                                         setCommissionToUpdate,
                                       }) => {

  const [form, setForm] = useState({
    agent_id: "",
    agency_id: "",
    agency_percentage: commission.agency_percentage,
    agent_percentage: commission.agent_percentage,
    company_percentage: commission.company_percentage
  });
  const [errors, setErrors] = useState({});
  const [agents, setAgents] = useState([]);
  const [agencies, setAgencies] = useState([]);

  const initFormValues = async () => {
    const agencyList = await restService.AgencyApi.getAgencies();
    const agentList = await restService.AgentApi.getAgentsByAgency(
        agencyList.data?.data?.data[0]?.id
    );
    setAgents(agentList.data?.data?.data || []);
    setAgencies(agencyList.data?.data?.data || []);

    setForm({
      ...form,
      agency_id: commission.agency.id,
      agent_id: commission.agent.id,
    });
  };

  useEffect(() => {
    initFormValues();
  }, []);

  useEffect(() => {
    setForm(prevForm => ({
      ...prevForm,
      company_percentage: 100 - (Number(prevForm.agency_percentage) + Number(prevForm.agent_percentage))
    }));
  }, [form.agency_percentage, form.agent_percentage]);

  const validateForm = () => {
    let errorList = { ...errors };
    keys.forEach((key) => {
      if (form[key] === "" || form[key] === undefined) {
        errorList[key] = "This field is required.";
      }
    });
    if (
        Number(form.agency_percentage) +
        Number(form.agent_percentage) +
        Number(form.company_percentage) !==
        100
    ) {
      errorList.total =
          "Agency, agent, and company Percentage should add up to 100.";
    }
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };

  const updateCommission = () => {
    if (validateForm()) {
      restService.CommissionsConfigApi.updateCommissionConfiguration(form, commission?.id).then(
          (response) => {
            onSubmit();
          }
      );
    }
  };

  const onFormChange = ({ target }) => {
    const { name, value } = target;
    let updatedForm = { ...form, [name]: value };
    let errorList = { ...errors };

    if (name === "agency_id") {
      restService.AgentApi.getAgentsByAgency(value).then(({ data }) => {
        setAgents(data?.data.data || []);
        setForm(prevForm => ({ ...updatedForm, agent_id: data?.data?.data[0]?.id }));
      });
      delete errorList["agent_id"];
    }

    setForm(updatedForm);
    delete errorList[name];
    delete errorList.total;
    setErrors(errorList);
  };

  return (
      <div className="flex intro-y flex-col items-center md:gap-11 gap-6 justify-center py-10 px-10">
        <div className="flex items-start justify-between w-full">
          <div className="flex items-center flex-col gap-1 justify-center w-full">
            <h1 className="text-xl font-medium text-[#0042C6]">
              Update Commission
            </h1>
            <p className="text-xs text-[#727681]">
              Please enter the configuration details
            </p>
          </div>

          <span
              onClick={() => setCommissionToUpdate(undefined)}
              className="btn border-none"
          >
          <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M11.8337 1.34199L10.6587 0.166992L6.00033 4.82533L1.34199 0.166992L0.166992 1.34199L4.82533 6.00033L0.166992 10.6587L1.34199 11.8337L6.00033 7.17533L10.6587 11.8337L11.8337 10.6587L7.17533 6.00033L11.8337 1.34199Z"
                fill="black"
            />
          </svg>
        </span>
        </div>

        <div className="grid w-full md:max-w-[80%] md:grid-cols-2 gap-4 grid-cols-1">
          <Input
              containerStyle={"w-full md:w-auto"}
              type="number"
              min={0}
              max={100}
              value={form.agency_percentage}
              onChange={onFormChange}
              name="agency_percentage"
              className="form-control"
              placeholder="43.20"
              labelName={"  Agency Percentage * *"}
          />
          <Input
              containerStyle={"w-full md:w-auto"}
              type="number"
              min={0}
              max={100}
              value={form.agent_percentage}
              onChange={onFormChange}
              name="agent_percentage"
              className="form-control"
              placeholder="43.20"
              labelName={" Agent Percentage *"}
          />

          <Input
              containerStyle={"w-full md:w-auto"}
              type="number"
              min={0}
              max={100}
              value={form.company_percentage}
              onChange={onFormChange}
              name="company_percentage"
              className="form-control"
              placeholder="13.20"
              labelName={" Company Percentage *"}
          />
          <div className="flex flex-col gap-1">
            <label htmlFor="input-wizard-5" className="form-label mb-0 text-xs">
              Agency *
            </label>
            <select
                id="custom-form-select"
                value={form.agency_id}
                onChange={onFormChange}
                name="agency_id"
                className="form-select"
            >
              {agencies.map((agency) => (
                  <option key={agency.id} value={agency.id}>
                    {agency.name}
                  </option>
              ))}
            </select>
            <p className="text-red-500 text-xs italic pt-1">{errors.agency_id}</p>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="input-wizard-5" className="form-label mb-0 text-xs">
              Agent *
            </label>
            <select
                id="custom-form-select"
                value={form.agent_id}
                onChange={onFormChange}
                name="agent_id"
            >
              {agents.map((agent) => (
                  <option key={agent.id} value={agent.id}>
                    {agent.firstname} {agent.lastname}
                  </option>
              ))}
            </select>
            <p className="text-red-500 text-xs italic pt-1 text-center">
              {errors.agent_id}
            </p>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <button
              onClick={() => setCommissionToUpdate(undefined)}
              className="btn rounded-md border font-medium border-[#2D2C2C] text-[#2D2C2C] px-6 py-2"
          >
            Cancel
          </button>
          <button
              onClick={() => updateCommission()}
              className="btn btn-primary-2"
          >
            Update Commission
          </button>
        </div>
      </div>
  );
};

export default UpdateCommissionConfiguration;
