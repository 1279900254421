import React, {useState} from "react";
import Select from "react-select";
import currencies from "../../../assets/static_json/currencies.json";
const TopUp = () => {

    const currencyOptions = currencies;
    const [topUpAmount, setTopUpAmount] = useState("");
    const [currencySymbol, setCurrencySymbol] = useState("$")
    const [currency, setCurrency] = useState({})

    const handleCurrencyChange = selectedOption => {
        setCurrency(selectedOption.label);
        setCurrencySymbol(selectedOption.symbol);
    }



    return (
        <div>
            <div className="lg:text-center">
                <p className="mt-4 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    Top Up Agent's Wallet
                </p>
                <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                    Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis
                    in accusamus quisquam.
                </p>
            </div>
            <div className="mt-10 grid grid-cols-7">
                <div className="col-span-3 mt-10 px-1 space-y-10 md:space-y-0 md:grid md:gap-x-8 md:gap-y-10">
                    <div class="relative">
                        <dt>
                            <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                                </svg>
                            </div>
                            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Instruction 1</p>
                        </dt>
                        <dd class="mt-2 ml-16 text-base text-gray-500">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
                        </dd>
                    </div>

                    <div class="relative">
                        <dt>
                            <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                </svg>
                            </div>
                            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Instruction 2</p>
                        </dt>
                        <dd class="mt-2 ml-16 text-base text-gray-500">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
                        </dd>
                    </div>
                </div>
                <div className="intro-y box p-5 mt-10 col-span-4">
                    <div className="account-divs">
                        <div className="sender-div mt-3">
                            <label htmlFor="crud-form-2" className="form-label text-gray-700 font-semibold">Agent</label>
                            <input className="form-control" disabled={true} value="Damon Wayans"/>
                        </div>
                        <div className="mt-10">
                            <div className="sm:grid grid-cols-3 gap-2">
                                <div className="currency-div mt-2 sm:mt-0">
                                    <Select placeholder={`Select Currency`} id="crud-form-2" options={currencyOptions}/>
                                </div>
                                <div className="input-group mt-2 sm:mt-0">
                                    <div id="input-group-4" className="input-group-text">{currencySymbol}</div>
                                    <input type="text" className="form-control" onChange={(e)=>setTopUpAmount(e.target.value)} placeholder="Send Amount" aria-describedby="input-group-4"
                                    />
                                </div>
                                <div className="input-group mt-2 sm:mt-0">
                                    <div id="input-group-5" className="input-group-text">{currencySymbol}</div>
                                    <input type="text" readOnly={true} value={topUpAmount - (topUpAmount*0.05)} className="form-control" placeholder="Will Receive" aria-describedby="input-group-5"/>
                                </div>
                            </div>
                        </div>
                        <div className="mt-10">
                           <button className="btn btn-primary">Top Up Agent</button>
                        </div>

                    </div>




                </div>
            </div>
        </div>
    )

}
export default TopUp;
