import React, {useEffect, useState} from "react";
import IdentificationForm from "../../../components/Identification/identificationForm";
import restService from "../../../services/restService";
import {useSelector} from "react-redux";
import {Redirect} from "react-router";
import {useToasts} from "react-toast-notifications";

const keys = [
  "firstname",
  "lastname",
  "email",
  "calling_code",
  "msisdn",
  "country_id",
  "address",
];
const AddAgentToNewAgency = ({agency}) => {
  const {addToast} = useToasts();
  const [step, setStep] = useState(1);
  const user = useSelector((state) => state.auth?.user);
  const [redirect, setRedirect] = useState(false);
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    othernames: "",
    email: "",
    calling_code: agency?.country?.calling_code,
    msisdn: "",
    country_id: agency?.country?.id,
    address: "",
    agent_role: "Super Agent",
    agency_id: agency?.id,
  });
  const [confirmation, setConfirmation] = useState(false);
  const [roles, setRoles] = useState([]);
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [callback, setCallback] = useState(() => {});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    restService.UtilsApi.getCountries().then(({data}) => {
      setCountries(data?.data);
    });
    restService.AgencyApi.getAgencies().then(({data}) => {
      setAgencies(data?.data?.data);
    });
    restService.UtilsApi.getAgentRoles().then(({data}) => {
      setRoles(data?.data);
    });
  }, []);

  const handleSubmit = (data) => {
    //Api request to save agency
    if (validateForm()) {
      setLoading(true);
      data.msisdn = form.calling_code + form.msisdn;
      restService.AgentApi.addAgent(data)
        .then((response) => {
          addToast("Successfully Added Agent", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          setRedirect(true);
        })
        .catch(({response}) => {
          addToast(response?.data?.message, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const validateForm = () => {
    let errorList = {};
    keys.forEach((key) => {
      // TODO validate phone numbers
      if (!form[key]) {
        errorList[key] = "This field is required.";
      } else {
        delete errorList[key];
        if (key === "email") {
          const regex = /\S+@\S+\.\S+/;
          if (!regex.test(form[key])) {
            errorList[key] = "Enter a valid email.";
          }
        }
      }
    });
    setErrors(errorList);

    return Object.keys(errorList).length === 0;
  };
  const handlePrev = () => {
    if (step !== 1) {
      setStep(step - 1);
    }
  };
  const handleNext = () => {
    if (step === 1) {
      if (validateForm()) {
        setStep(step + 1);
      }
    } else {
      callback();
    }
  };
  const onFormChange = ({target}) => {
    let details = {...form};
    details[target.name] = target.value;
    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);

    if (target.name === "country_id") {
      const country = countries.filter((c) => c.id == target.value)[0];
      details.calling_code = country?.calling_code;
    }
    setForm(details);
  };

  if (redirect) {
    return <Redirect to="/agencies" />;
  }
  return (
    <div className="intro-y box py-10 sm:py-20 mt-5">
      <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5">
        <div className="font-medium text-base">Agent Data</div>
        <div className="mt-5">
          {step === 1 && (
            <div className="grid grid-cols-12 gap-4 gap-y-5 ">
              <div className="intro-y col-span-12 sm:col-span-6">
                <label htmlFor="input-wizard-1" className="form-label">
                  First Name *
                </label>
                <input
                  id="input-wizard-1"
                  type="text"
                  value={form.firstname}
                  onChange={onFormChange}
                  name="firstname"
                  className="form-control"
                  placeholder="Kojo"
                />
                <p className="text-red-500 text-xs italic pt-1">
                  {errors.firstname}
                </p>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <label htmlFor="input-wizard-3" className="form-label">
                  Other Names
                </label>
                <input
                  id="input-wizard-3"
                  type="text"
                  value={form.othernames}
                  onChange={onFormChange}
                  name="othernames"
                  className="form-control"
                  placeholder="Y"
                />
                <p className="text-red-500 text-xs italic pt-1">
                  {errors.othernames}
                </p>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <label htmlFor="input-wizard-2" className="form-label">
                  Last Name *
                </label>
                <input
                  id="input-wizard-2"
                  type="text"
                  value={form.lastname}
                  onChange={onFormChange}
                  name="lastname"
                  className="form-control"
                  placeholder="Nsiah"
                />
                <p className="text-red-500 text-xs italic pt-1">
                  {errors.lastname}
                </p>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <label htmlFor="input-wizard-5" className="form-label">
                  Agency *
                </label>
                <select
                  id="input-wizard-6"
                  disabled={true}
                  value={form?.agency_id}
                  onChange={onFormChange}
                  name="agency_id"
                  className="form-select"
                >
                  {agencies.map((agency) => {
                    return <option value={agency.id}>{agency.name}</option>;
                  })}
                </select>
                <p className="text-red-500 text-xs italic pt-1">
                  {errors?.agency_id}
                </p>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <label htmlFor="input-wizard-3" className="form-label">
                  Address *{" "}
                </label>
                <input
                  id="input-wizard-3"
                  type="text"
                  value={form?.address}
                  onChange={onFormChange}
                  name="address"
                  className="form-control"
                  placeholder="Awoshie"
                />
                <p className="text-red-500 text-xs italic pt-1">
                  {errors?.address}
                </p>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <label htmlFor="input-wizard-5" className="form-label">
                  Email *
                </label>
                <input
                  id="input-wizard-5"
                  type="text"
                  value={form?.email}
                  onChange={onFormChange}
                  name="email"
                  className="form-control"
                  placeholder="kojo@gmail.com"
                />
                <p className="text-red-500 text-xs italic pt-1">
                  {errors?.email}
                </p>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <label htmlFor="input-wizard-4" className="form-label">
                  Phone Number *
                </label>
                <div className="grid grid-cols-7">
                  <input
                    id="input-wizard-4"
                    disabled={true}
                    type="text"
                    onChange={onFormChange}
                    value={form?.calling_code}
                    name="msisdn"
                    className="col-span-1 form-control"
                    placeholder="44"
                  />
                  <input
                    id="input-wizard-4"
                    type="text"
                    value={form?.msisdn}
                    onChange={onFormChange}
                    name="msisdn"
                    className="col-span-6 form-control"
                    placeholder="548410151"
                  />
                </div>
                <p className="text-red-500 text-xs italic pt-1">
                  {errors?.msisdn}
                </p>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <label htmlFor="input-wizard-5" className="form-label">
                  Country *
                </label>
                <select
                  id="input-wizard-6"
                  disabled={true}
                  value={form.country_id}
                  onChange={onFormChange}
                  name="country_id"
                  className="form-select"
                >
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <label htmlFor="input-wizard-5" className="form-label">
                  Role *
                </label>
                <select
                  id="input-wizard-6"
                  disabled={true}
                  value={form.country}
                  onChange={onFormChange}
                  name="country"
                  className="form-select"
                >
                  {roles.map((role) => {
                    return <option value={role.name}>{role.name}</option>;
                  })}
                </select>
              </div>
            </div>
          )}
          {step === 2 && (
            <IdentificationForm
              data={form}
              initCallback={(callbackFunc) => setCallback(() => callbackFunc)}
              handleSubmit={handleSubmit}
              sendConfirmation={() => setConfirmation(true)}
            />
          )}
          <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
            {step === 2 && (
              <button className="btn btn-secondary w-24" onClick={handlePrev}>
                Previous
              </button>
            )}
            <button
              className="btn btn-primary w-24 ml-2"
              disabled={loading}
              onClick={handleNext}
            >
              {step === 2 ? "Add Agent" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAgentToNewAgency;
