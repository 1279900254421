import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import chargeList from "../../../../assets/static_json/charges.json";
import TransactionStyles from "../../../AgentViews/Transactions/styles";
import Table from "../../../../components/common/Table";
import Modal from "../../../../components/common/Modal";
import {useToasts} from "react-toast-notifications";
import restService from "../../../../services/restService";
import ReactTooltip from "react-tooltip";
import UpdateCharge from "./UpdateCharge";
import RestService from "../../../../services/restService";
import Search from "../../../../components/common/Search";
import {Pagination} from "antd";
import PaginationComponent from "../../../../components/common/Pagination";
import SkeletonElement from "../../../../components/Skeletons/SkeletonElement";
import Table2 from "../../../../components/common/Table/Table2";
import Modal2 from "../../../../components/common/Modal/Modal2";
import AddChargeModal from "./AddChargeModal";
const renderHeadData = (item, index) => {
  return <th key={index}>{item}</th>;
};

const Charges = () => {
  const {addToast} = useToasts();
  const [chargeToRemove, setChargeToRemove] = useState(undefined);
  const [chargeToUpdate, setChargeToUpdate] = useState(undefined);
  const [callback, setCallback] = useState(() => {});
  const [charges, setCharges] = useState([]);
  const [pageTo, setPageTo] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [from, setFrom] = useState(0);
  const [addNewCharge, setAddNewCharge] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchCharges = (page) => {
    setLoading(true);
    restService.ChargesApi.getCharges(page)
      .then(({data}) => {
        setCharges(data?.data?.data);
        setPageTo(data?.data?.to);
        setTotalEntries(data?.data?.total);
        setFrom(data?.data?.from);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const searchChargesByInput = async (input) => {
    const response = await RestService.ChargesApi.getChargesBySearchTerm(input);
    const charges = await response?.data?.data?.data;
    setCharges(charges);
  };

  useEffect(() => {
    fetchCharges();
  }, []);

  const onChargeUpdate = () => {
    setChargeToUpdate(undefined);
    addToast("Successfully Updated Charge", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 5000,
    });
    fetchCharges();
  };

  const removeCharge = () => {
    restService.ChargesApi.removeCharge(chargeToRemove.id).then((response) => {
      addToast("Successfully Removed Charge", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      setChargeToRemove(undefined);
      fetchCharges();
    });
  };
  const pageChange = (page) => {
    setCurrentPage(page);
    fetchCharges(page);
  };
  const renderBodyData = (item, index) => {
    return (
      <tr className="flex flex-col  flex-no wrap sm:table-row" key={index}>
        <td className="id"> {item.id}</td>
        <td className="id"> {item.agency?.name}</td>
        <td className="id">
          {" "}
          {item.agent?.firstname} {item.agent?.lastname}
        </td>
        <td className="id"> {item.source_currency}</td>
        <td className="id"> {item.destination_currency}</td>
        <td className="id"> {item.minimum_threshold_amount?.toFixed(2)}</td>
        <td className="id"> {item.maximum_threshold_amount?.toFixed(2)}</td>
        <td className="id"> {item.fixed_charge_amount?.toFixed(2)}</td>
        <td className="id"> {item.percentage_charge_amount?.toFixed(2)}</td>
        <td className="flex">
          <button
            data-tip="Update"
            onClick={() => {
              setChargeToUpdate(item);
            }}
            className="btn mr-3 border-none outline-none shadow-none focus:outline-none transaction-details"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </button>
          <button
            data-tip="Remove"
            onClick={() => {
              setChargeToRemove(item);
            }}
            className="btn outline-none border-none  shadow-none focus:outline-none transaction-details"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="#d32929"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <ReactTooltip backgroundColor="#f1f5f8" textColor="black" />
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="content-layout intro-y flex flex-col gap-4 bg-white border rounded-md border-[#ECECEC] pt-5 mt-2">
        {/* <div className="header-layout flex justify-between">
          <div className="inline-flex">
            <Link to={`/add-charge`} className="btn btn-primary shadow-md mr-2">
              Add New Charge
            </Link>
          </div>
          <div className="page inline-flex">
            {charges && charges.length <= 0
              ? "No Charges Found"
              : `Showing ${from} to ${pageTo} of ${totalEntries} entries`}
          </div>
          <div className="inline-flex float-right">
            <Search
              placeholder={"Search Charges"}
              onChange={(e) => searchChargesByInput(e.target.value)}
            />
          </div>
        </div> */}

        <div className="header-layout px-5 flex items-center justify-between sm:flex-row flex-col gap-2">
          <div className="flex md:items-center w-full gap-2 md:flex-row flex-col ">
            <Search
              placeholder={"Search "}
              onChange={(e) => searchChargesByInput(e.target.value)}
            />
          </div>

          <div className=" flex flex-col-reverse w-full justify-end sm:flex-row md:items-center">
            <button
              onClick={() => setAddNewCharge(true)}
              className="btn btn-primary-2  flex items-center gap-2 "
            >
              <span>
                <svg
                  width="12"
                  height="11"
                  viewBox="0 0 12 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.98438 0.986328C6.24237 0.986328 6.45151 1.19547 6.45151 1.45347V5.19058H10.1886C10.4466 5.19058 10.6558 5.39972 10.6558 5.65771C10.6558 5.91571 10.4466 6.12485 10.1886 6.12485H6.45151V9.86196C6.45151 10.12 6.24237 10.3291 5.98438 10.3291C5.72638 10.3291 5.51724 10.12 5.51724 9.86196V6.12485H1.78013C1.52213 6.12485 1.31299 5.91571 1.31299 5.65771C1.31299 5.39972 1.52213 5.19058 1.78013 5.19058H5.51724V1.45347C5.51724 1.19547 5.72638 0.986328 5.98438 0.986328Z"
                    fill="black"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>

              <span> Add New Charge</span>
            </button>
          </div>
        </div>

        <TransactionStyles>
          {/* <div className="table-container">
            <Table
              headData={chargeList.head}
              renderHead={(item, index) => renderHeadData(item, index)}
              bodyData={charges}
              renderBody={(item, index) => renderBodyData(item, index)}
            />

            <div className="mt-3 float-right">
              <Pagination
                current={currentPage}
                defaultCurrent={currentPage}
                showSizeChanger={false}
                onChange={pageChange}
                total={totalEntries}
              />
            </div>
          </div> */}

          <div className="header-layout gap-2">
            {!loading ? (
              <div className="flex w-full flex-col">
                <Table2
                  // headData={head}
                  tHead={
                    <>
                      <th className="">ID</th>
                      <th className="">Agency</th>
                      <th className="">Agent</th>
                      <th className="">Source</th>
                      <th className="">Destination</th>
                      <th className="">Min Threshold</th>
                      <th className="">Max Threshold</th>
                      <th className="">Fixed Charge</th>
                      <th className="">%Charge</th>
                      <th className="">Actions</th>
                    </>
                  }
                  // renderHead={(item, index) => renderHeadData(item, index)}
                >
                  {charges.map((item, index) => (
                    <tr key={index} className=" text-[#616161] font-normal">
                      <td>
                        <p className="whitespace-nowrap">{item.id}</p>
                      </td>
                      <td>
                        <p className="whitespace-nowrap">
                          {" "}
                          {item.agency?.name}
                        </p>
                      </td>
                      <td>
                        <p className="whitespace-nowrap">
                          {" "}
                          {item.agent?.firstname} {item.agent?.lastname}
                        </p>
                      </td>
                      <td>
                        <p className="whitespace-nowrap">
                          {" "}
                          {item.source_currency}
                        </p>
                      </td>
                      <td>
                        <p className="whitespace-nowrap">
                          {item.destination_currency}
                        </p>
                      </td>
                      <td>
                        <p className="whitespace-nowrap">
                          {item.minimum_threshold_amount?.toFixed(2)}
                        </p>
                      </td>
                      <td>
                        <p className="whitespace-nowrap">
                          {item.maximum_threshold_amount?.toFixed(2)}
                        </p>
                      </td>
                      <td>
                        <p className="whitespace-nowrap">
                          {" "}
                          {item.fixed_charge_amount?.toFixed(2)}
                        </p>
                      </td>
                      <td>
                        <p className="">
                          {item.percentage_charge_amount?.toFixed(2)}
                        </p>
                      </td>
                      <td>
                        <div className="flex items-center gap-8">
                          <button
                            data-tip="Update"
                            onClick={() => {
                              setChargeToUpdate(item);
                            }}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.679 0.282841C15.6234 0.22173 15.556 0.17253 15.4808 0.138214C15.4057 0.103897 15.3243 0.0851777 15.2417 0.0831855C15.1591 0.0811933 15.0769 0.0959699 15.0002 0.126623C14.9235 0.157275 14.8538 0.203168 14.7953 0.261526L14.3244 0.73006C14.2674 0.787157 14.2353 0.864575 14.2353 0.945297C14.2353 1.02602 14.2674 1.10344 14.3244 1.16053L14.7561 1.59139C14.7843 1.61981 14.818 1.64237 14.855 1.65776C14.892 1.67315 14.9318 1.68107 14.9719 1.68107C15.012 1.68107 15.0517 1.67315 15.0887 1.65776C15.1257 1.64237 15.1594 1.61981 15.1877 1.59139L15.6467 1.13465C15.8789 0.902858 15.9006 0.525291 15.679 0.282841ZM13.3725 1.68159L6.50171 8.54023C6.46006 8.58172 6.42978 8.63323 6.41379 8.68981L6.09598 9.63639C6.08837 9.66208 6.08783 9.68934 6.09442 9.71531C6.10101 9.74127 6.11449 9.76498 6.13343 9.78392C6.15237 9.80286 6.17608 9.81634 6.20204 9.82293C6.22801 9.82952 6.25527 9.82898 6.28096 9.82137L7.22678 9.50356C7.28336 9.48757 7.33487 9.4573 7.37636 9.41564L14.235 2.54406C14.2985 2.47993 14.334 2.39335 14.334 2.30313C14.334 2.21291 14.2985 2.12634 14.235 2.0622L13.8563 1.68159C13.7921 1.61756 13.7051 1.5816 13.6144 1.5816C13.5237 1.5816 13.4367 1.61756 13.3725 1.68159Z"
                                fill="#4F5664"
                              />
                              <path
                                d="M12.8776 5.62664L8.23836 10.2751C8.05905 10.4548 7.83867 10.5882 7.59626 10.6637L6.61048 10.9937C6.37653 11.0597 6.12919 11.0622 5.89396 11.0009C5.65872 10.9395 5.4441 10.8166 5.2722 10.6447C5.1003 10.4728 4.97735 10.2582 4.916 10.0229C4.85466 9.78769 4.85716 9.54035 4.92322 9.3064L5.25321 8.32061C5.32848 8.07828 5.46159 7.85791 5.64106 7.67852L10.2895 3.03847C10.3321 2.99591 10.3611 2.94167 10.3729 2.88261C10.3847 2.82355 10.3787 2.76232 10.3557 2.70666C10.3326 2.65101 10.2936 2.60344 10.2436 2.56995C10.1935 2.53647 10.1347 2.51858 10.0744 2.51855H2.13143C1.56614 2.51855 1.024 2.74312 0.624282 3.14284C0.224561 3.54256 0 4.08469 0 4.64998V13.7847C0 14.35 0.224561 14.8921 0.624282 15.2918C1.024 15.6916 1.56614 15.9161 2.13143 15.9161H11.2661C11.8314 15.9161 12.3736 15.6916 12.7733 15.2918C13.173 14.8921 13.3976 14.35 13.3976 13.7847V5.84168C13.3975 5.78146 13.3796 5.72259 13.3462 5.67253C13.3127 5.62248 13.2651 5.58347 13.2094 5.56045C13.1538 5.53743 13.0926 5.53144 13.0335 5.54322C12.9744 5.555 12.9202 5.58403 12.8776 5.62664Z"
                                fill="#4F5664"
                              />
                            </svg>
                          </button>
                          <button
                            data-tip="Remove"
                            onClick={() => {
                              setChargeToRemove(item);
                            }}
                          >
                            <svg
                              width="12"
                              height="16"
                              viewBox="0 0 12 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.6667 1.33333H8.75L7.91667 0.5H3.75L2.91667 1.33333H0V3H11.6667V1.33333ZM0.833333 3.83333V13.8333C0.833333 14.75 1.58333 15.5 2.5 15.5H9.16667C10.0833 15.5 10.8333 14.75 10.8333 13.8333V3.83333H0.833333Z"
                                fill="#F40B31"
                              />
                            </svg>
                          </button>
                        </div>
                        <ReactTooltip
                          backgroundColor="#f1f5f8"
                          textColor="black"
                        />
                      </td>
                    </tr>
                  ))}
                </Table2>
              </div>
            ) : (
              <div className="flex w-full flex-col">
                <Table2
                  tHead={
                    <>
                      <th className="">ID</th>
                      <th className="">Agency</th>
                      <th className="">Agent</th>
                      <th className="">Source</th>
                      <th className="">Destination</th>
                      <th className="">Min Threshold</th>
                      <th className="">Max Threshold</th>
                      <th className="">Fixed Charge</th>
                      <th className="">%Charge</th>
                      <th className="">Actions</th>
                    </>
                  }
                  // renderHead={(item, index) => renderHeadData(item, index)}
                >
                  {Array(10)
                    .fill()
                    .map((item, index) => (
                      <tr key={index}>
                        {Array(10)
                          .fill()
                          .map((x, i) => (
                            <td key={i}>
                              <SkeletonElement type={"title"} />
                            </td>
                          ))}
                      </tr>
                    ))}
                </Table2>
              </div>
            )}
          </div>
        </TransactionStyles>
      </div>

      <div className="flex flex-col mb-[10vh] px-5 gap-4 justify-between md:items-center md:flex-row mt-8">
        <div className="page text-[#999999] text-xs">
          {loading
            ? ""
            : charges && charges.length <= 0
            ? "No Transactions Found"
            : `Showing  ${pageTo} out of ${totalEntries} results`}
        </div>

        <div className="mt-3  flex justify-end float-right">
          <PaginationComponent
            current={currentPage}
            defaultCurrent={currentPage}
            showSizeChanger={false}
            onChange={pageChange}
            total={totalEntries}
          />
        </div>
      </div>

      {chargeToRemove && (
        <Modal2
          size="medium"
          onHide={() => {
            setChargeToRemove(undefined);
          }}
        >
          <div className="flex intro-y flex-col items-center md:gap-11 gap-6 justify-center py-10 md:px-10 px-4">
            <div className="flex items-center flex-col gap-1 justify-center w-full">
              <h1 className="text-xl font-medium text-[#0042C6]">
                Remove Charge
              </h1>

              <p className="text-xs text-center text-[#727681]">
                Are you sure you want to remove this charge? This action cannot
                be undone.
              </p>
            </div>

            <div className="flex items-center gap-4">
              <button
                onClick={() => setChargeToRemove(undefined)}
                className="btn rounded-md border font-medium border-[#2D2C2C] text-[#2D2C2C] px-6 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => removeCharge()}
                className="btn rounded-md border font-medium border-[#F40B31] text-[#fff] bg-[#F40B31] px-6 py-2"
              >
                Remove
              </button>
            </div>
          </div>
        </Modal2>
      )}

      {chargeToUpdate && (
        <Modal2
          size={"lg"}
          // onClick={callback}
          // modalTitle="Update Charge"
          // buttonTitle="Update Charge"
          // buttonColor="bg-blue-600"

          onHide={() => {
            setChargeToUpdate(undefined);
          }}
        >
          <UpdateCharge
            charge={chargeToUpdate}
            initCallback={(callbackFunc) => setCallback(() => callbackFunc)}
            onSubmit={onChargeUpdate}
            setChargeToUpdate={setChargeToUpdate}
          />
        </Modal2>
      )}

      {addNewCharge && <AddChargeModal setShow={setAddNewCharge} />}
    </>
  );
};
export default Charges;
