import React, {useEffect, useState} from "react";
import validate from "react-joi-validation";
import IdentificationForm from "../../../components/Identification/identificationForm";
import Confirmation from "../../../components/common/Confirmation";

import regions from "../../../assets/static_json/regions.json";
import roleList from "../../../assets/static_json/roles.json";
import restService from "../../../services/restService";
import {useSelector} from "react-redux";
import {useToasts} from "react-toast-notifications";

const active = "intro-y w-10 h-10 rounded-full btn btn-primary mx-2";
const inactive =
  "intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2";
const AddStaff = () => {
  const {addToast} = useToasts();
  const user = useSelector((state) => state.auth?.user);
  const [step, setStep] = useState(1);
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    calling_code: "",
    msisdn: "",
    country_id: "",
    region: regions[0].value,
    address: "",
    staff_role: "",
    partner_id: "",
  });
  const [confirmation, setConfirmation] = useState(false);
  const [callback, setCallback] = useState(() => {});
  const [countries, setCountries] = useState([]);
  const [partners, setPartners] = useState([]);
  const [roles, setRoles] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchCountriesAndRoles = async () => {
    const countryList = await restService.UtilsApi.getCountries();
    const roleList = await restService.UtilsApi.getStaffRoles();
    // const partnersList = await restService.UtilsApi.getPartners();

    setCountries(countryList.data?.data);
    setRoles(roleList.data.data);

    setForm({
      ...form,
      calling_code: user.country.calling_code,
      staff_role: roleList.data.data[0].name,
      country_id: countryList.data?.data.filter((country) => {
        return country.name === user.country?.name;
      })[0].id,
    });
  };
  useEffect(() => {
    fetchCountriesAndRoles();
  }, []);
  const handlePrev = () => {
    if (step !== 1) {
      setStep(step - 1);
    }
  };
  const validateForm = () => {
    const keys = Object.keys(form);
    let errorList = {...errors};
    keys.forEach((key) => {
      // TODO validate phone numbers
      if (!form[key]) {
        if (key !== "partner_id") {
          errorList[key] = "This field is required.";
        }
      } else {
        delete errorList[key];
        if (key === "email") {
          const regex = /\S+@\S+\.\S+/;
          if (!regex.test(form[key])) {
            errorList[key] = "Enter a valid email.";
          }
        }
      }
    });
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };
  const handleNext = () => {
    if (step === 1) {
      if (validateForm()) {
        setStep(step + 1);
      }
    } else {
      callback();
    }
  };
  const onFormChange = ({target}) => {
    let details = {...form};
    details[target.name] = target.value;


    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);
    if (target.name === "country_id") {
      const country = countries.filter((c) => c.id == target.value)[0];
      details.calling_code = country?.calling_code;
    }
    setForm(details);
  };

  const handleSubmit = (data) => {
    //Api request to save staff
    setLoading(true);
    restService.StaffApi.addStaff(data)
      .then((response) => {
        setStep(1);
        setForm({
          firstname: "",
          lastname: "",
          email: "",
          msisdn: "",
          address: "",
        });
        setConfirmation(true);
      })
      .catch(({response}) => {
        addToast(response?.data?.message, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (confirmation) {
    return (
      <Confirmation
        onClose={() => setConfirmation(false)}
        title={"Success"}
        buttonText={"Add Another"}
        message={"Staff member successfully added"}
      />
    );
  } else {
    return (
      <div className="intro-y mt-13-important box py-10 sm:py-20 mt-5">
        <div className="flex justify-center">
          <button className={step === 1 ? active : inactive}>1</button>
          <button className={step === 2 ? active : inactive}>2</button>
        </div>
        <div className="px-5 mt-10">
          <div className="font-medium text-center text-lg">Add Staff</div>
          <div className="text-gray-600 text-center mt-2">
            To start off, please enter the staff's personal details.
          </div>
        </div>
        <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5">
          <div className="mt-5">
            {step === 1 && (
              <div className="grid grid-cols-12 gap-4 gap-y-5 ">
                <div className="intro-y col-span-12 sm:col-span-6">
                  <label htmlFor="input-wizard-1" className="form-label">
                    First Name *
                  </label>
                  <input
                    id="input-wizard-1"
                    type="text"
                    value={form.firstname}
                    onChange={onFormChange}
                    name="firstname"
                    className="form-control"
                    placeholder="Kojo"
                  />
                  <p className="text-red-500 text-xs italic pt-1">
                    {errors.firstname}
                  </p>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <label htmlFor="input-wizard-3" className="form-label">
                    Other Names
                  </label>
                  <input
                    id="input-wizard-3"
                    type="text"
                    value={form.othernames}
                    onChange={onFormChange}
                    name="othernames"
                    className="form-control"
                    placeholder="Y"
                  />
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <label htmlFor="input-wizard-2" className="form-label">
                    Last Name *
                  </label>
                  <input
                    id="input-wizard-2"
                    type="text"
                    value={form.lastname}
                    onChange={onFormChange}
                    name="lastname"
                    className="form-control"
                    placeholder="Nsiah"
                  />
                  <p className="text-red-500 text-xs italic pt-1">
                    {errors.lastname}
                  </p>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <label htmlFor="input-wizard-3" className="form-label">
                    Address *
                  </label>
                  <input
                    id="input-wizard-3"
                    type="text"
                    value={form.address}
                    onChange={onFormChange}
                    name="address"
                    className="form-control"
                    placeholder="Awoshie"
                  />
                  <p className="text-red-500 text-xs italic pt-1">
                    {errors.address}
                  </p>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <label htmlFor="input-wizard-5" className="form-label">
                    Email *
                  </label>
                  <input
                    id="input-wizard-5"
                    type="text"
                    value={form.email}
                    onChange={onFormChange}
                    name="email"
                    className="form-control"
                    placeholder="kojo@gmail.com"
                  />
                  <p className="text-red-500 text-xs italic pt-1">
                    {errors.email}
                  </p>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <label htmlFor="input-wizard-4" className="form-label">
                    Phone Number *
                  </label>
                  <div className="grid grid-cols-7">
                    <input
                      id="input-wizard-4"
                      disabled={true}
                      type="text"
                      onChange={onFormChange}
                      value={form.calling_code}
                      name="msisdn"
                      className="col-span-1 form-control"
                      placeholder="44"
                    />
                    <input
                      id="input-wizard-4"
                      type="text"
                      value={form.msisdn}
                      onChange={onFormChange}
                      name="msisdn"
                      className="col-span-6 form-control"
                      placeholder="548410151"
                    />
                  </div>
                  <p className="text-red-500 text-xs italic pt-1">
                    {errors.msisdn}
                  </p>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <label htmlFor="input-wizard-5" className="form-label">
                    Country *
                  </label>
                  <select
                    id="input-wizard-6"
                    value={form.country_id}
                    onChange={onFormChange}
                    name="country_id"
                    className="form-select"
                  >
                    {countries.map((country) => {
                      return <option value={country.id}>{country.name}</option>;
                    })}
                  </select>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <label htmlFor="input-wizard-5" className="form-label">
                    Role *
                  </label>
                  <select
                    id="input-wizard-6"
                    value={form.staff_role}
                    onChange={onFormChange}
                    name="staff_role"
                    className="form-select"
                  >
                    {roles.map((role) => {
                      return <option value={role.name}>{role.name}</option>;
                    })}
                  </select>
                </div>
                {/*<div className="intro-y col-span-12 sm:col-span-6">*/}
                {/*    <label htmlFor="input-wizard-5" className="form-label">Partner {(form.staff_role === "Payout Agent"? "*": "")}</label>*/}
                {/*    <select id="input-wizard-6" value={form.partner} onChange={onFormChange} name="partner_id" className="form-select">*/}
                {/*        {partners.map(partner => {*/}
                {/*            return <option value={partner.id}>{partner.name}</option>*/}
                {/*        })}*/}
                {/*    </select>*/}
                {/*</div>*/}
              </div>
            )}
            {step === 2 && (
              <IdentificationForm
                data={form}
                initCallback={(callbackFunc) => setCallback(() => callbackFunc)}
                handleSubmit={handleSubmit}
                sendConfirmation={() => setConfirmation(true)}
              />
            )}
            <div className="m-auto flex items-center justify-center sm:justify-end mt-5">
              {step === 2 && (
                <button
                  className="btn btn-secondary w-24 outline-none"
                  onClick={handlePrev}
                >
                  Previous
                </button>
              )}
              <button
                className="btn btn-primary w-24 ml-2 outline-none focus:none"
                disabled={loading}
                onClick={handleNext}
              >
                {step === 2 ? "Add Staff" : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default AddStaff;
