import React, {useEffect} from "react";
import CodeFieldStyles from "./styles";
import OtpInput from "react-otp-input";

const CodeField = ({
  value,
  onSubmit,
  onChange,
  validationMessage,
  className,
  ...props
}) => {
  const codeLength = 6;

  useEffect(() => {
    if (value.length === codeLength) {
      // onSubmit();
    }
  }, [value]);

  return (
    <OtpInput
      value={value}
      onChange={onChange}
      numInputs={6}
      separator={<span> </span>}
      // hasErrored={validationMessage ? true : false}
      inputStyle={`border ${
        validationMessage ? "error-otp-style" : "otp-style"
      }  rounded-md text-black text-2xl`}
      {...props}
    />
    // <CodeFieldStyles>
    //   <input
    //     className={className}
    //     type="tel"
    //     name="code"
    //     value={value}
    //     onChange={onChange}
    //     placeholder="-    -    -    -    -    -"
    //   />
    //   {validationMessage &&
    //   <div className="validation-error">{validationMessage}</div>
    //   }

    // </CodeFieldStyles>
  );
};

export default CodeField;
