import React from "react";
import {Link} from "react-router-dom";

const defaultImg =
  "https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png";
const ReceiverCard = ({user}) => {
  return (
    <div className="flex min-w-[150px] flex-col border border-[#EFEFEF] gap-3 h-full w-full sm:w-auto items-start rounded-md bg-white p-3">
      <span>
        <img
          src={defaultImg}
          alt=""
          className="w-[30px] h-[30px] object-cover rounded-full"
        />
      </span>

      <Link to={`/receiver/${user?.id}`} className="text-black font-medium">
        {user?.firstname} {user?.lastname}
      </Link>
      <p className="text-black truncate w-[130px] font-normal min-h-[16px] text-xs">
        {" "}
        {user?.email}
      </p>

      <Link
        to={`/receiver/${user?.id}`}
        className="btn btn-secondary flex items-center gap-2"
      >
        Profile
      </Link>
    </div>
  );
};

export default ReceiverCard;
