import React from "react";
import {Empty} from "antd";
import SkeletonElement from "../../Skeletons/SkeletonElement";

const Table2 = ({
  headData,
  renderHead,
  bodyData,
  renderBody,
  tHead,
  divide,
  children,
}) => {
  return (
    <div className="w-full overflow-x-auto">
      <table
        className={`${
          !divide && "tb-report"
        } w-full border-collapse table sm:mt-2 table-auto border border-[#EFEFEF] rounded-md bg-white`}
      >
        <thead className="bg-white">
          <tr className="w-full bg-white-important whitespace-nowrap text-[#707070] text-sm">
            {tHead}
          </tr>
        </thead>

        <tbody>{children}</tbody>
      </table>

      {bodyData && bodyData.length <= 0 ? (
        <div className="font-bold col-span-12 w-full text-center">
          <Empty />
        </div>
      ) : null}
    </div>
  );
};

export default Table2;
