import { useState } from "react";
import { ArrowLeft } from "react-feather";
import { useToasts } from "react-toast-notifications";
import AddNewSender from "../../../../components/common/AddNewSender";
import Button from "../../../../components/common/Button";
import SenderSelect from "../../../../components/shared/sender";
import { useSendMoneyContext } from "../../../../contexts/send-money";
import { getRandomColor } from "../../../../helpers/utils";
import restService from "../../../../services/restService";
import AddItemCard from "./components/add-item-button";
import Search from "./components/search";
import { Title } from "./components/title";
import Loader from "../../../../components/common/Loader";

export function Senders() {
  const { addToast } = useToasts();

  const [searchQ, setSearchQ] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [showAddNewSender, setShowAddNewSender] = useState(false);

  const { setSendMoneyData, sendMoneyData, setCurrentStep, setReceivers } =
    useSendMoneyContext();

  async function getReceivers(id) {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await restService.ReceiversApi.getReceiversBySender(
        id,
        null,
        "allItems"
      );

      const s = (data?.data ?? []).map((receiver, index) => ({
        ...receiver,
        bgColor: getRandomColor(index),
        receiver_wallets: receiver?.receiver_wallets?.map((wallet, index) => ({
          ...wallet,
          bgColor: getRandomColor(index),
        })),
      }));

      setReceivers(s);

      setCurrentStep(3);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  function handleSelectSender(sender) {
    addToast("Selected " + sender?.firstname, {
      appearance: "info",
      autoDismiss: true,
      autoDismissTimeout: 2000,
    });
    setSendMoneyData({ ...sendMoneyData, sender });
    getReceivers(sender?.id);
  }

  return (
    <div className="container flex flex-col gap-8">
      {isLoading && <Loader message={"Please wait..."} />}
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-4">
          <Button
            onClick={() => setCurrentStep((prev) => prev - 1)}
            className="h-6 w-6 bg-white rounded-full  flex items-center gap-2"
          >
            <ArrowLeft size={20} />
          </Button>

          <Title title={"Sender"} />
        </div>

        <div className="flex items-center flex-col-reverse md:flex-row gap-4 justify-between">
          <div
            style={{
              maxWidth: "400px",
            }}
            className="w-full"
          >
            <Search
              placeholder={"Search for sender...  "}
              onChange={(e) => setSearchQ(e.target.value)}
            />
          </div>

          {!showAddNewSender && (
            <div className="md:w-auto w-full flex items-center justify-end">
              <AddItemCard onClick={() => setShowAddNewSender(true)} />
            </div>
          )}
        </div>
      </div>

      <hr className="border-[#DEDEDE]" />

      {!showAddNewSender && (
        <SenderSelect
          onSelect={handleSelectSender}
          searchValue={searchQ}
          selectedSenderId={sendMoneyData?.sender?.id}
          setIsFetching={setIsLoading}
        />
      )}

      {showAddNewSender && (
        <AddNewSender
          onCancel={() => {
            setShowAddNewSender(false);
          }}
          showGoback={false}
          refetchData={(response) => {
            handleSelectSender(response);
            // getSenders();
            setShowAddNewSender(false);
          }}
        />
      )}
    </div>
  );
}
