import { useEffect, useState } from "react";
import Button from "../../../../components/common/Button";
import { useSendMoneyContext } from "../../../../contexts/send-money";
import UserCard from "./components/user-card";
// import AddNewReceiver from "../../../../components/common/AddNewReceiver";
import { ArrowLeft } from "react-feather";
import { useToasts } from "react-toast-notifications";
import Loader from "../../../../components/common/Loader";
import { getRandomColor } from "../../../../helpers/utils";
import restService from "../../../../services/restService";
import AddItemCard from "./components/add-item-button";
import Search from "./components/search";
import { Title } from "./components/title";

import { Empty } from "antd";
import AddNewReceiver from "./components/add-recipient";

export function Receivers() {
  const { addToast } = useToasts();

  const [searchQ, setSearchQ] = useState("");
  const [showAddNewReceiver, setShowAddNewReceiver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    setSendMoneyData,
    sendMoneyData,
    setCurrentStep,
    receivers,
    setReceivers,
  } = useSendMoneyContext();

  async function getReceivers() {
    // setIsLoading(true);
    try {
      const {
        data: { data },
      } = await restService.ReceiversApi.getReceiversBySender(
        sendMoneyData?.sender?.id,
        null,
        "allItems"
      );

      const s = (data?.data ?? []).map((receiver, index) => ({
        ...receiver,
        bgColor: getRandomColor(index),
        receiver_wallets: receiver?.receiver_wallets?.map((wallet, index) => ({
          ...wallet,
          bgColor: getRandomColor(index),
        })),
      }));

      setReceivers(s);

      // setSendMoneyData({ ...sendMoneyData, receivers: s });
    } catch (error) {
      console.log(error);
    } finally {
      setShowAddNewReceiver(false);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getReceivers();
  }, []);

  function filteredData() {
    if (!searchQ) return receivers;

    return (receivers ?? []).filter(
      (receiver) =>
        receiver?.firstname?.toLowerCase()?.includes(searchQ?.toLowerCase()) ||
        receiver?.lastname?.toLowerCase()?.includes(searchQ?.toLowerCase())
    );
  }

  function handleSelectReceiver(receiver, wallet, step = 4) {
    addToast(
      `Send money from ${sendMoneyData?.sender?.firstname} to ${receiver?.firstname} ${receiver?.lastname}`,
      {
        appearance: "info",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      }
    );
    setSendMoneyData({
      ...sendMoneyData,
      receiver: receiver,
      destination_wallet: wallet,
    });
    setCurrentStep(step);
  }

  return (
    <div className="container flex flex-col gap-8">
      {isLoading && <Loader message={"Please wait..."} />}
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-4">
          <Button
            onClick={() => setCurrentStep((prev) => prev - 1)}
            className="h-6 w-6 bg-white rounded-full  flex items-center gap-2"
          >
            <ArrowLeft size={20} />
          </Button>

          <Title title={"Recipient"} />
        </div>

        <div className="flex items-center md:flex-row flex-col-reverse gap-4 justify-between">
          <div
            style={{
              maxWidth: "400px",
            }}
            className="w-full"
          >
            <Search
              disabled={showAddNewReceiver}
              placeholder={"Search for recipient...  "}
              onChange={(e) => setSearchQ(e.target.value)}
            />
          </div>

          <div className="flex items-center justify-end md:w-auto w-full">
            {showAddNewReceiver ? (
              <Button
                onClick={() => setShowAddNewReceiver(false)}
                className="btn btn-secondary"
              >
                Exit
              </Button>
            ) : (
              <AddItemCard
                label="Add a recipient"
                onClick={() => {
                  setShowAddNewReceiver(true);
                }}
              />
            )}
          </div>
        </div>
      </div>

      <hr className="border-[#DEDEDE]" />

      {!showAddNewReceiver && (
        <>
          {filteredData()?.length < 1 && (
            <div
              style={{
                height: "40vh",
              }}
              className="flex items-center justify-center"
            >
              <Empty />
            </div>
          )}
          <div className="grid grid-cols-2 md:grid-cols-4  xl:grid-cols-7 gap-4">
            {filteredData()?.map((receiver) => (
              <div
                onClick={() => {
                  handleSelectReceiver(receiver);
                }}
                className={`zoom-in rounded-md cursor-pointer `}
                key={receiver?.id}
              >
                <UserCard
                  isActive={sendMoneyData?.receiver?.id === receiver?.id}
                  key={receiver?.id}
                  user={receiver}
                />
              </div>
            ))}
          </div>

          {/* {filteredData()?.length > 10 && (
            <div className="flex justify-end">
              <Button
                // onClick={() => setCurrentStep(1)}
                className="btn btn-primary  flex items-center gap-2 text-xs"
              >
                See more
              </Button>
            </div>
          )} */}
        </>
      )}

      {showAddNewReceiver && (
        <AddNewReceiver
          onSubmitSuccess={(data) => {
            getReceivers().finally(() => {
              handleSelectReceiver(data, data?.receiver_wallets?.[0], 5);
            });

            // refetchData(sendMoneyData?.sender?.id);
          }}
          countryId={sendMoneyData?.disbursal_country?.id}
          senderId={sendMoneyData?.sender?.id}
          disbursal_country={sendMoneyData?.disbursal_country}
        />
      )}
    </div>
  );
}
