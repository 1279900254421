import React, {useEffect, useState} from "react";
import {useToasts} from "react-toast-notifications";
import restService from "../../../../services/restService";
import Input from "../../../../components/common/Input";

const keys = [
  "minimum_threshold_amount",
  "maximum_threshold_amount",
  "fixed_charge_amount",
  "percentage_charge_amount",
  "destination_currency",
  "source_currency",
];
const UpdateCharge = ({charge, onSubmit, initCallback, setChargeToUpdate}) => {
  const {addToast} = useToasts();

  const [form, setForm] = useState(charge);
  const [confirmation, setConfirmation] = useState(false);
  const [errors, setErrors] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [agents, setAgents] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [callback, setCallback] = useState(() => {});

  const initFormValues = async () => {
    let details = {};
    const agencyList = await restService.AgencyApi.getAgencies();
    const agentList = await restService.AgentApi.getAgentsByAgency(
      charge.agency.id
    );
    const countryList = await restService.UtilsApi.getCountries();
    const currencies = countryList?.data?.data?.map((c) => {
      return c.currency_code;
    });
    setCurrencies(currencies);
    setAgents(agentList.data?.data?.data);
    setAgencies(agencyList.data?.data?.data);

    setForm({...form, agency_id: charge.agency.id, agent_id: charge.agent.id});
  };

  useEffect(() => {
    initFormValues();
  }, []);
  useEffect(() => {
    initCallback(saveCharge);
  }, [form]);

  const validateForm = () => {
    let errorList = {...errors};
    keys.forEach((key) => {
      if (!form[key]) {
        errorList[key] = "This field is required.";
      }
    });
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };

  const saveCharge = () => {
    if (validateForm()) {
      restService.ChargesApi.updateCharge(form).then((response) => {
        onSubmit();
      });
    }
  };

  const onFormChange = ({target}) => {
    let details = {...form};
    details[target.name] = target.value;

    if (target.name === "agency_id") {
      restService.AgentApi.getAgentsByAgency(target.value).then(({data}) => {
        setAgents(data?.data.data);
        setForm({...details, agent_id: data?.data?.data[0]?.id});
      });

      let errorList = {...errors};
      delete errorList["agent_id"];
    }
    setForm(details);

    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);
  };

  return (
    // <div className="intro-y box py-10 sm:py-20 mt-5">
    //     <div className="px-5 mt-10">
    //         <div className="font-medium text-center text-lg">Update Charge</div>
    //         <div className="text-gray-600 text-center mt-2">Please enter the charge details.
    //         </div>
    //     </div>
    //     <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5">
    //         <div className="mt-5">
    //             <div className="grid grid-cols-12 gap-4 gap-y-5 ">
    //                 <div className="intro-y col-span-12 sm:col-span-6">
    //                     <label htmlFor="input-wizard-1" className="form-label">Minimum Threshold Amount *</label>
    //                     <input type="number" min={0} value={form.minimum_threshold_amount} onChange={onFormChange} name="minimum_threshold_amount" className="form-control" placeholder="13.20"/>
    //                     <p className="text-red-500 text-xs italic pt-1">{errors.minimum_threshold_amount}</p>
    //                 </div>
    //                 <div className="intro-y col-span-12 sm:col-span-6">
    //                     <label htmlFor="input-wizard-1" className="form-label">Maximum Threshold Amount *</label>
    //                     <input type="number" min={0} value={form.maximum_threshold_amount} onChange={onFormChange} name="maximum_threshold_amount" className="form-control" placeholder="43.20"/>
    //                     <p className="text-red-500 text-xs italic pt-1">{errors.maximum_threshold_amount}</p>
    //                 </div>
    //                 <div className="intro-y col-span-12 sm:col-span-6">
    //                     <label htmlFor="input-wizard-1" className="form-label">Fixed Charge Amount {!form.percentage_charge_amount?"*" : ""}</label>
    //                     <input type="number" min={0} value={form.fixed_charge_amount} onChange={onFormChange} name="fixed_charge_amount" className="form-control" placeholder="23.20"/>
    //                     <p className="text-red-500 text-xs italic pt-1">{errors.fixed_charge_amount}</p>
    //                 </div>
    //                 <div className="intro-y col-span-12 sm:col-span-6">
    //                     <label htmlFor="input-wizard-1" className="form-label">Percentage Charge Amount{!form.fixed_charge_amount?"*" : ""}</label>
    //                     <input type="number" min={0} max={100} value={form.percentage_charge_amount} onChange={onFormChange} name="percentage_charge_amount" className="form-control" placeholder="13.20"/>
    //                     <p className="text-red-500 text-xs italic pt-1">{errors.percentage_charge_amount}</p>
    //                 </div>
    //                 <div className="intro-y col-span-12 sm:col-span-6">
    //                     <label htmlFor="input-wizard-5" className="form-label">Agency</label>
    //                     <select id="input-wizard-6" value={form.agency_id} onChange={onFormChange} name="agency_id" className="form-select">
    //                         <option value="">None</option>
    //                         {agencies.map(agency => {
    //                             return <option value={agency.id}>{agency.name}</option>
    //                         })}
    //                     </select>
    //                     <p className="text-red-500 text-xs italic pt-1">{errors.agency_id}</p>

    //                 </div>
    //                 <div className="intro-y col-span-12 sm:col-span-6">
    //                     <label htmlFor="input-wizard-5" className="form-label">Agent</label>
    //                     <select id="input-wizard-6" value={form.agent_id} onChange={onFormChange} name="agent_id" className="form-select">
    //                         <option value="">None</option>
    //                         {agents.map(agent => {
    //                             return <option value={agent.id}>{agent.firstname} {agent.lastname}</option>
    //                         })}
    //                     </select>
    //                     <p className="text-red-500 text-xs italic pt-1">{errors.agent_id}</p>

    //                 </div>
    //                 <div className="intro-y col-span-12 sm:col-span-6">
    //                     <label htmlFor="input-wizard-5" className="form-label">Source Currency</label>
    //                     <select id="input-wizard-6" value={form.source_currency} onChange={onFormChange} name="source_currency" className="form-select">
    //                         {currencies.map(currency => {
    //                             return <option value={currency}>{currency}</option>
    //                         })}
    //                     </select>
    //                 </div>
    //                 <div className="intro-y col-span-12 sm:col-span-6">
    //                     <label htmlFor="input-wizard-5" className="form-label">Destination Currency</label>
    //                     <select id="input-wizard-6" value={form.destination_currency} onChange={onFormChange} name="destination_currency" className="form-select">
    //                         {currencies.map(currency => {
    //                             return <option value={currency}>{currency}</option>
    //                         })}
    //                     </select>
    //                 </div>

    //             </div>
    //         </div>
    //     </div>
    // </div>

    <div className="flex intro-y flex-col items-center md:gap-11 gap-6 justify-center py-10 px-10">
      <div className="flex items-start justify-between w-full">
        <div className="flex items-center flex-col gap-1 justify-center w-full">
          <h1 className="text-xl font-medium text-[#0042C6]">Update Charges</h1>
          <p className="text-xs text-[#727681]">
            Please enter the configuration details
          </p>
        </div>

        <span
          onClick={() => setChargeToUpdate(undefined)}
          className="btn border-none"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.8337 1.34199L10.6587 0.166992L6.00033 4.82533L1.34199 0.166992L0.166992 1.34199L4.82533 6.00033L0.166992 10.6587L1.34199 11.8337L6.00033 7.17533L10.6587 11.8337L11.8337 10.6587L7.17533 6.00033L11.8337 1.34199Z"
              fill="black"
            />
          </svg>
        </span>
      </div>

      <div className="grid w-full md:max-w-[80%] md:grid-cols-2 gap-4 grid-cols-1">
        <div className="flex flex-col gap-1">
          <Input
            containerStyle={"w-full md:w-auto"}
            type="number"
            min={0}
            value={form.minimum_threshold_amount}
            onChange={onFormChange}
            name="minimum_threshold_amount"
            className="form-control"
            placeholder="13.20"
            labelName={"Minimum Threshold Percentage*"}
          />

          <p className="text-red-500 text-xs italic pt-1">
            {errors?.minimum_threshold_amount}
          </p>
        </div>

        <div className="flex flex-col gap-1">
          <Input
            containerStyle={"w-full md:w-auto"}
            type="number"
            min={0}
            value={form.maximum_threshold_amount}
            onChange={onFormChange}
            name="maximum_threshold_amount"
            className="form-control"
            placeholder="43.20"
            labelName={"Maximum Threshold Percentage*"}
          />
          <p className="text-red-500 text-xs italic pt-1">
            {errors?.maximum_threshold_amount}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <Input
            containerStyle={"w-full md:w-auto"}
            type="number"
            min={0}
            value={form.fixed_charge_amount}
            onChange={onFormChange}
            name="fixed_charge_amount"
            className="form-control"
            placeholder="23.20"
            labelName={"Fixed Charge Amount"}
          />
          <p className="text-red-500 text-xs italic pt-1">
            {errors.fixed_charge_amount}
          </p>
        </div>

        <div className="flex flex-col gap-1">
          <Input
            containerStyle={"w-full md:w-auto"}
            type="number"
            min={0}
            max={100}
            value={form?.percentage_charge_amount}
            onChange={onFormChange}
            name="percentage_charge_amount"
            className="form-control"
            placeholder="13.20"
            labelName={"Percentage Charge Amount*"}
          />
          <p className="text-red-500 text-xs italic pt-1">
            {errors?.percentage_charge_amount}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="input-wizard-5" className="form-label mb-0 text-xs">
            Agency
          </label>
          <select
            id="custom-form-select"
            value={form.agency_id}
            onChange={onFormChange}
            name="agency_id"
            className="form-select"
          >
            <option value="">None</option>{" "}
            {agencies.map((agency) => {
              return <option value={agency.id}>{agency.name}</option>;
            })}
          </select>
          <p className="text-red-500 text-xs italic pt-1">
            {errors?.agency_id}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="input-wizard-5" className="form-label mb-0 text-xs">
            Agent
          </label>
          <select
            id="custom-form-select"
            value={form.agent_id}
            onChange={onFormChange}
            name="agent_id"
            className="form-select"
          >
            <option value="">None</option>
            {agents.map((agent) => {
              return (
                <option value={agent.id}>
                  {agent?.firstname} {agent?.lastname}
                </option>
              );
            })}
          </select>
          <p className="text-red-500 text-xs italic pt-1">{errors?.agent_id}</p>
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="input-wizard-5" className="form-label mb-0 text-xs">
            Source Currency
          </label>
          <select
            id="custom-form-select"
            value={form.source_currency}
            onChange={onFormChange}
            name="source_currency"
            className="form-select"
          >
            {" "}
            {currencies.map((currency) => {
              return <option value={currency}>{currency}</option>;
            })}
          </select>
          <p className="text-red-500 text-xs italic pt-1">{errors.agency_id}</p>
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="input-wizard-5" className="form-label mb-0 text-xs">
            Destination Currency
          </label>
          <select
            id="custom-form-select"
            value={form.destination_currency}
            onChange={onFormChange}
            name="destination_currency"
            className="form-select"
          >
            {currencies.map((currency) => {
              return <option value={currency}>{currency}</option>;
            })}
          </select>
          <p className="text-red-500 text-xs italic pt-1 text-center">
            {errors?.destination_currency}
          </p>
        </div>
      </div>

      <div className="flex items-center gap-4">
        <button
          onClick={() => setChargeToUpdate(undefined)}
          className="btn rounded-md border font-medium border-[#2D2C2C] text-[#2D2C2C] px-6 py-2"
        >
          Cancel
        </button>
        <button onClick={() => saveCharge()} className="btn btn-primary-2">
          Update Charge
        </button>
      </div>
    </div>
  );
};

export default UpdateCharge;
