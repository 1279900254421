export function Stepper({ steps, currentStep, setCurrentStep }) {
  return (
    <div
      className="flex gap-6 relative justify-center w-full items-center"
      style={{
        maxWidth: "500px",
        margin: "auto",
        width: "100%",
      }}
    >
      <div
        className=" w-full absolute"
        style={{
          top: "15%",
          width: "100%",
          maxWidth: "350px",
          zIndex: 1,
        }}
      >
        <hr className="border-[#DEDEDE]" />
      </div>
      {steps.map((step) => (
        <div
          onClick={() => {
            if (step.step <= currentStep) {
              setCurrentStep(step.step);
            }
          }}
          key={step.step}
          style={{
            zIndex: 2,
          }}
          className={`flex  flex-col items-center gap-4 cursor-pointer ${
            currentStep === step.step ? "text-theme-1" : "border-[#DEDEDE]"
          }`}
        >
          <span
            className={`rounded-full w-4 h-4 flex items-center justify-center ${
              currentStep === step.step ? "bg-theme-1" : "bg-[#DEDEDE]"
            }`}
          ></span>

          <span
            className={`text-sm  ${
              currentStep === step.step ? "text-theme-1 font-semibold" : ""
            }`}
          >
            {step.title}
          </span>
        </div>
      ))}
    </div>
  );
}
