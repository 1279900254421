import { Empty } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AddNewReceiver from "../../../components/common/AddNewReceiver";
import PaginationComponent from "../../../components/common/Pagination";
import ReceiverCard from "../../../components/common/ReceiverCard";
import Search from "../../../components/common/Search";
import SkeletonSubProfile from "../../../components/Skeletons/SubProfileSkeleton/SkeletonSubProfile";
import {
  default as restService,
  default as RestService,
} from "../../../services/restService";

const Receivers = () => {
  const history = useHistory();

  const [receivers, setReceivers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageTo, setPageTo] = useState(0);
  const [totalEntries, setTotalEntries] = useState(1);
  const [from, setFrom] = useState(0);
  const agentId = useSelector((state) => state?.auth?.user?.id);
  const agencyId = useSelector((state) => state?.auth?.user?.agency.id);
  const roleId = useSelector((state) => state?.auth?.user?.agent_role_id);
  const [showAddNewReceiver, setShowAddNewReceiver] = useState(false);

  const fetchReceivers = (agentId, roleId, page) => {
    setLoading(true);

    if (roleId === 1) {
      restService.ReceiversApi.getReceivers(page)
        .then((response) => {
          if (response) {
            setReceivers(response?.data?.data?.data);
            setPageTo(response.data?.data?.to);
            setTotalEntries(response.data?.data?.total);
            setFrom(response.data?.data?.from);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (roleId === 3) {
      restService.ReceiversApi.getReceiversCreatedByAgent(agentId, page)
        .then((response) => {
          if (response) {
            setReceivers(response?.data?.data?.data);
            setPageTo(response.data?.data?.to);
            setTotalEntries(response.data?.data?.total);
            setFrom(response.data?.data?.from);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    setLoading(false);
  };

  const searchReceiverByInput = async (input) => {
    const response = await RestService.ReceiversApi.getReceiverBySearchTerm(
      input
    );
    const receivers = await response?.data?.data?.data;
    setReceivers(receivers);

    // setPageTo(response.data?.data?.to);
    // setTotalEntries(response.data?.data?.total);
    // setFrom(response.data?.data?.from);
  };

  useEffect(() => {
    fetchReceivers(agentId, roleId);
  }, []);

  const pageChange = (page) => {
    setCurrentPage(page);
    fetchReceivers(agentId, roleId, page);
  };

  return (
    <>
      {/* <PageTitle title={`Recipients`} /> */}

      <div className="flex flex-col w-full gap-6 mt-[5rem]">
        <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <button
            onClick={() => history.push("/add-receiver")}
            className="btn btn-primary-2 shadow-md mr-2"
          >
            Add New Receiver
          </button>
          <div className="hidden md:block mx-auto text-gray-600">
            {/* {loading
              ? ""
              : receivers && receivers.length <= 0
              ? "No Receivers Found"
              : `Showing ${from} to ${pageTo} of ${totalEntries} entries`} */}
          </div>
          <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <Search
              placeholder={"Search Receiver"}
              onChange={(e) => searchReceiverByInput(e.target.value)}
            />
          </div>
        </div>

        {!loading ? (
          receivers && receivers.length <= 0 ? (
            <div className="font-bold h-[40vh] flex items-center justify-center col-span-12">
              <Empty />
            </div>
          ) : (
            <ul className="grid grid-cols-12 gap-4 ">
              {receivers.map((user, index) => (
                <li
                  key={user?.id}
                  className="lg:col-span-2 md:col-span-4 sm:col-span-6 col-span-6"
                >
                  <ReceiverCard user={user} key={index} />
                </li>
              ))}
            </ul>
          )
        ) : (
          Array(9)
            .fill()
            .map((item, index) => <SkeletonSubProfile key={index} />)
        )}
      </div>
      <div className="flex flex-col mb-[10vh] gap-4 justify-between md:items-center md:flex-row mt-8">
        <div className="page text-[#999999] text-xs">
          {loading
            ? ""
            : receivers && receivers.length <= 0
            ? "No Receivers Found"
            : `Showing ${from} to ${pageTo} of ${totalEntries} entries`}
        </div>

        <div className="mt-3  flex justify-end float-right">
          <PaginationComponent
            current={currentPage}
            defaultCurrent={currentPage}
            showSizeChanger={false}
            onChange={pageChange}
            total={totalEntries}
          />
        </div>
      </div>

      {showAddNewReceiver && (
        <AddNewReceiver
          show={showAddNewReceiver}
          setShow={setShowAddNewReceiver}
          refetchData={fetchReceivers}
        />
      )}
    </>
  );
};

export default Receivers;
