import React, {Fragment} from "react";
import {Combobox, Transition} from "@headlessui/react";


const SelectComponent = ({
  value,
  onChange,
  children,
  data,
  searchQuery,
  setSearchQuery,
  labelName,
  displayValue,
}) => {
  return (
    <div className="w-full">
      <Combobox value={value} onChange={onChange}>
        <div className="relative w-full">
          <div className="relative w-full cursor-default bg-white text-left text-sm">
            <Combobox.Button className="w-full flex justify-between items-center">
              <div className="w-full flex flex-col items-start">
                {labelName && (
                  <p className="text-xs  text-[#727681]">{labelName}</p>
                )}

                <Combobox.Input
                  className="w-full border-none focus:border-none outline-none py-2  pr-10 text-sm leading-5 text-gray-900"
                  displayValue={displayValue}
                  onChange={(event) => setSearchQuery(event.target.value)}
                  placeholder="Search"
                />
              </div>

              <label htmlFor="sender">
                <svg
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.209209 0.852032C-0.0697365 1.13098 -0.0697365 1.58324 0.209209 1.86218L4.49492 6.1479C4.77387 6.42684 5.22613 6.42684 5.50508 6.1479L9.79079 1.86218C10.0697 1.58324 10.0697 1.13098 9.79079 0.852032C9.51185 0.573086 9.05958 0.573086 8.78064 0.852032L5 4.63267L1.21936 0.852032C0.940416 0.573086 0.488155 0.573086 0.209209 0.852032Z"
                    fill="#969FB0"
                  />
                </svg>
              </label>
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setSearchQuery("")}
          >
            <Combobox.Options className="absolute z-40 flex flex-col gap-2 mt-1 max-h-60 w-full overflow-auto rounded-md border border-theme-31 bg-white  text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {children}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default SelectComponent;
