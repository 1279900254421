import {createGlobalStyle} from "styled-components";
import {colors, functions, properties} from "./constants";

const GlobalStyles = createGlobalStyle`
html,body{
  background-color: #FAFAFA !important;
}
*{
   margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

.validation-error {
    white-space: pre-line;
    width: 100%;
    padding: 0.3rem 1rem;
    font-size: 11px;
    color: ${colors.error};
    background-color: ${functions.rgba(colors.error, 0.1)};
    border-radius: ${properties.borderRadius};
    margin-top: 0.25rem;
    animation: flash 0.3s ease-in-out;
    animation-iteration-count: 3;
    @keyframes flash {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  .litepicker{
      z-index: 99999 !important;
    }
  
  .phone-black {
    input {
      background: black !important;
      color: white !important;
    }
  }
`;

export default GlobalStyles;
