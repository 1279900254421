import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Badge} from "antd";
import restService from "../../../services/restService";
import {useToasts} from "react-toast-notifications";
import Modal from "../../../components/common/Modal";
import Pusher from "pusher-js";
import Search from "../../../components/common/Search";
import PaginationComponent from "../../../components/common/Pagination";
import AddNewAgent from "../../../components/common/AddNewAgent";
import ReactTooltip from "react-tooltip";

const Agents = () => {
  const {addToast} = useToasts();
  const [agentList, setAgentList] = useState([]);
  const [pageTo, setPageTo] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [from, setFrom] = useState(0);
  const [topUpEValue, setTopUpEValue] = useState(undefined);
  const [topUpEValueAmount, setTopUpEValueAmount] = useState(0);
  const [reduceProfile, setReduceProfile] = useState(undefined);
  const [reduceProfileAmount, setReduceProfileAmount] = useState(0);
  const [pusherChannel, setPusherChannel] = useState(null);
  const [pusherChannel2, setPusherChannel2] = useState(null);
  const [showAddNewAgent, setShowAddNewAgent] = useState(false);

  const fetchAgents = (page) => {
    restService.AgentApi.getAgents(page).then(({data}) => {
      setAgentList(data?.data?.data);
      setPageTo(data?.data?.to);
      setTotalEntries(data?.data?.total);
      setFrom(data?.data?.from);
    });
  };

  useEffect(() => {
    fetchAgents();
    const pusher = new Pusher(
        process.env.REACT_APP_API_ENVIRONMENT === "dev"
            ? process.env.REACT_APP_PUSHER_KEY_DEVELOPMENT:
            process.env.REACT_APP_API_ENVIRONMENT === "staging" ?
                process.env.REACT_APP_PUSHER_KEY_STAGING
                : process.env.REACT_APP_PUSHER_KEY_PRODUCTION,
      {
        cluster: "eu",
        forceTLS: true,
      }
    );
    const channel = pusher.subscribe(`general-evalue-topup`);
    setPusherChannel(channel);

    const channel2 = pusher.subscribe(`general-debt-reduction`);
    setPusherChannel2(channel2);
  }, []);

  useEffect(() => {
    if (
      pusherChannel &&
      pusherChannel.bind &&
      pusherChannel2 &&
      pusherChannel2.bind
    ) {
      pusherChannel.unbind("agent-evalue-topup");
      pusherChannel2.unbind("agent-debt-reduction");
      pusherChannel.bind("agent-evalue-topup", setTopUpCallback);
      pusherChannel2.bind("agent-debt-reduction", setDebtCallback);
    }
  }, [pusherChannel, pusherChannel2, agentList]);

  const setTopUpCallback = (data) => {
    let indexOf = -1;
    agentList.forEach((a, index) => {
      if (a.id === data.message?.model.id) {
        indexOf = index;
      }
    });

    if (indexOf >= 0) {
      let agent = agentList[indexOf];
      agent.actual_balance = data.message.model.actual_balance;
      agent.balance_available = data.message.model.balance_available;
      agentList[indexOf] = agent;
      setAgentList(agentList);
    }
  };

  const setDebtCallback = (data) => {
    let indexOf = -1;
    agentList.forEach((a, index) => {
      if (a.id === data.message?.model.id) {
        indexOf = index;
      }
    });

    if (indexOf >= 0) {
      let agent = agentList[indexOf];
      agent.balance_owed = data.message.model.balance_owed;
      agentList[indexOf] = agent;
      setAgentList(agentList);
    }
  };
  const topUp = () => {
    let data = {amount: topUpEValueAmount};
    restService.EValueAPi.topUpAgent(data, topUpEValue.id)
      .then((response) => {
        addToast("Successfully Topped up Agent e-value", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .finally(() => {
        setTopUpEValueAmount(0);
        setTopUpEValue(undefined);
      });
  };

  const reduceDebt = () => {
    let data = {amount: reduceProfileAmount};
    restService.EValueAPi.reduceAgentDebt(data, reduceProfile.id)
      .then((response) => {
        addToast("Successfully reduced Agent debt profile", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .finally(() => {
        setReduceProfileAmount(0);
        setReduceProfile(undefined);
      });
  };

  const pageChange = (page) => {
    setCurrentPage(page);
    fetchAgents(page);
  };
  const searchAgentByInput = async (input) => {
    const response = await restService.AgentApi.getAgentBySearchTerm(input);
    const agents = await response?.data?.data?.data;
    setAgentList(agents);
  };

    return (
        <>
          <div className="grid mt-[5rem] grid-cols-12 gap-6  modal-active">
            <div
                className="intro-y col-span-12 flex w-full justify-between flex-wrap md:flex-row-reverse sm:flex-nowrap items-center mt-2">
              <button
                  onClick={() => setShowAddNewAgent(true)}
                  className="btn btn-primary-2 gap-1 items-center shadow-md mr-2"
              >
            <span>
              <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.98389 1.24805C6.24188 1.24805 6.45103 1.45719 6.45103 1.71519V5.45229H10.1881C10.4461 5.45229 10.6553 5.66144 10.6553 5.91943C10.6553 6.17743 10.4461 6.38657 10.1881 6.38657H6.45103V10.1237C6.45103 10.3817 6.24188 10.5908 5.98389 10.5908C5.72589 10.5908 5.51675 10.3817 5.51675 10.1237V6.38657H1.77964C1.52165 6.38657 1.3125 6.17743 1.3125 5.91943C1.3125 5.66144 1.52165 5.45229 1.77964 5.45229H5.51675V1.71519C5.51675 1.45719 5.72589 1.24805 5.98389 1.24805Z"
                    fill="black"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
              </svg>
            </span>
                <span>Add Agent</span>
              </button>

              <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                  <Search
                      placeholder={"Search Agent"}
                      onChange={(e) => searchAgentByInput(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {agentList.map((agent, index) => {
                  const label = agent.agent_role?.name
                  return (
                      <div className="intro-y col-span-12 md:col-span-6 lg:col-span-4">
                        <Badge.Ribbon size="default"
                                      text={`Available : ${agent.agency?.country?.currency_symbol}${agent.balance_available}`}
                                      color="#2db7f5">
                          <div className="box">
                            <div className="flex items-start px-5 pt-5">
                              <div className="w-full flex flex-col lg:flex-row items-center">
                                <div className="w-16 h-16 image-fit">
                                  <img alt="#" className="rounded-full"
                                       src="https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png"/>
                                </div>
                                <div className="lg:ml-4 text-center lg:text-left mt-3 lg:mt-0">
                                  <Link to={`/agent/${agent.id}`}
                                        className="font-medium">{`${agent.firstname} ${agent.lastname}`}</Link>
                                  <div className="text-gray-600 text-xs mt-0.5">{agent.agency?.name}</div>
                                  <div
                                      className={"text-xs mt-0.5 " + (label === "Agent" ? "text-green-400" : "text-red-400")}> {label}</div>
                                </div>
                              </div>

                            </div>
                            <div className="text-center lg:text-left p-5">
                              <div className="flex items-center justify-center lg:justify-start text-gray-600 mt-1">
                                Actual Balance : {agent.agency?.country?.currency_symbol}{agent.actual_balance}
                              </div>
                              {/*<div className="flex items-center justify-center lg:justify-start text-gray-600 mt-1">*/}
                              {/*  Amount Owed : {agent.agency?.country?.currency_symbol}{agent.balance_owed}*/}
                              {/*</div>*/}
                            </div>
                            <div
                                className="text-center justify-center flex p-3 border-t border-gray-200">
                              <div className="flex sm:mt-2">
                                <button
                                    data-tip="Topup Agent e-value"
                                    className="btn mr-3 mt-1 border-none outline-none shadow-none focus:outline-none transaction-details"
                                    onClick={() => {
                                      setTopUpEValue(agent)
                                    }}>
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-7 w-7"
                                      viewBox="0 0 24 24"
                                      fill="green"
                                  >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"
                                        clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              </div>

                              <div className="flex mr-2 sm:mt-2">
                                <Link to={`/statement/agent/${agent.id}`}
                                      data-tip="Statement"
                                      className="btn mr-3 border-none outline-none shadow-none focus:outline-none transaction-details">

                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-5 w-5"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                  >
                                    <path
                                        fillRule="evenodd"
                                        d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z"
                                        clipRule="evenodd"
                                    />
                                  </svg>

                                </Link>
                              </div>
                              <div className="flex mr-2 sm:mt-2">
                                <Link to={`/commissions/agent/${agent.id}`}
                                      data-tip="Commission"
                                      className="btn mr-3 border-none outline-none shadow-none focus:outline-none transaction-details">


                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-6 w-6"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="green"
                                  >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                  </svg>

                                </Link>
                              </div>
                              <div className="flex mr-2 sm:mt-2">
                                <Link to={`/agent/${agent.id}`}
                                      data-tip="Profile"
                                      className="btn mr-3 border-none outline-none shadow-none focus:outline-none transaction-details">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                       fill="none" stroke="currentColor" stroke-width="2" strokeLinecap="round"
                                       strokeLinejoin="round" className="feather feather-user">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                  </svg>

                                </Link>
                              </div>
                            </div>
                          </div>
                        </Badge.Ribbon>
                        <ReactTooltip backgroundColor="#f1f5f8" textColor="black"/>
                      </div>
                  )
                }
            )}
          </div>
          {topUpEValue && <Modal onClick={topUp}
                                 disableButton={!topUpEValueAmount || isNaN(topUpEValueAmount) || topUpEValueAmount <= 0}
                                 modalTitle="Top up agent e-value" buttonTitle="Top up" buttonColor={"bg-green-600"}
                                 size="small" body={
            <div className="grid grid-cols-12 gap-4 gap-y-5 ">
              <div className="intro-y col-span-12">
                <label htmlFor="input-wizard-1" className="form-label">Amount *</label>
                <input id="input-wizard-1" type="number" value={topUpEValueAmount}
                       onChange={(e) => {
                         setTopUpEValueAmount(e.target.value)
                       }}
                       name="reduce" className="form-control"/>
                <p className="text-red-500 text-xs italic pt-1">{(!topUpEValueAmount || isNaN(topUpEValueAmount)
                    || topUpEValueAmount <= 0) ? "Enter a valid number greater than 0" : ""}</p>
              </div>
            </div>
          } onClose={() => {
            setTopUpEValue(undefined);
            setTopUpEValueAmount(0)
          }}/>}

          {reduceProfile && <Modal onClick={reduceDebt}
                                   disableButton={!reduceProfileAmount || isNaN(reduceProfileAmount) || reduceProfileAmount <= 0}
                                   modalTitle="Reduce agent debt profile" buttonTitle="Reduce debt"
                                   buttonColor={"bg-green-600"} size="small" body={
            <div className="mt-5">
              <div className="grid grid-cols-12 gap-4 gap-y-5 ">
                <div className="intro-y col-span-12">
                  <label htmlFor="input-wizard-1" className="form-label">Amount *</label>
                  <input id="input-wizard-1" type="number" value={reduceProfileAmount}
                         onChange={(e) => {
                           setReduceProfileAmount(e.target.value)
                         }}
                         name="reduce" className="form-control"/>
                  <p className="text-red-500 text-xs italic pt-1">{(!reduceProfileAmount || isNaN(reduceProfileAmount)
                      || reduceProfileAmount <= 0) ? "Enter a valid number greater than 0" : ""}</p>
                </div>
              </div>
            </div>
          } onClose={() => {
            setReduceProfile(undefined);
            setReduceProfileAmount(0)
          }}/>}

          <div className="flex flex-col mb-[10vh] gap-4 justify-between md:items-center md:flex-row mt-8">
            <div className="page text-[#999999] text-xs">
              Showing {from} to {pageTo} of {totalEntries} entries
            </div>

            <div className="mt-3  flex justify-end float-right">
              <PaginationComponent
                  current={currentPage}
                  defaultCurrent={currentPage}
                  showSizeChanger={false}
                  onChange={pageChange}
                  total={totalEntries}
              />
            </div>
          </div>
          {showAddNewAgent && (
              <AddNewAgent
                  show={showAddNewAgent}
                  setShow={setShowAddNewAgent}
                  refetchData={fetchAgents}
              />
          )}
        </>

    )
}
export default Agents;
