import React from 'react';

const ErrorMessages = ({ errorData }) => {
    return (
        <div className="flex flex-col items-center justify-center w-full">
            {Object.keys(errorData).map(key => (
                <ul key={key}>
                    {errorData[key].map((message, index) => (
                        <li key={index}>
                            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-1" role="alert">
                                <p className="font-bold">{message}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            ))}
        </div>
    );
};





export default ErrorMessages;
