import React, {useState} from "react";
import Charts from "react-apexcharts";
const ApexChartComponent = ({
  series,
  fill,
  colors,
  type,
  dropShadow,
  curve,
  showToolbar,
  activeZoomOption,
}) => {
  const adjustTime = (label) => {
    switch (label) {
      case "1D":
        return Date.now() - 24 * 60 * 60 * 1000;
      case "1W":
        return Date.now() - 7 * 24 * 60 * 60 * 1000;
      case "1M":
        return new Date(
          new Date().setMonth(new Date().getMonth() - 1)
        ).getTime();
      case "3M":
        return new Date(
          new Date().setMonth(new Date().getMonth() - 3)
        ).getTime();
      case "6M":
        return new Date(
          new Date().setMonth(new Date().getMonth() - 6)
        ).getTime();
      case "YTD":
        return new Date(new Date().getFullYear(), 0, 1).getTime();
      default:
        return undefined;
    }
  };

  // const zoomOptions = [
  //   {label: "1D", type: "day"},
  //   {label: "1W", type: "week"},
  //   {label: "1M", type: "month"},
  //   {label: "3M", type: "month"},
  //   {label: "6M", type: "month"},
  //   {label: "YTD", type: "year"},
  // ];

  const options = {
    chart: {
      type: type ?? "line", // type is area

      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        // autoScaleYaxis: true,
      },
      toolbar: {
        show: showToolbar ?? true,
        autoSelected: "zoom",
      },
      dropShadow: dropShadow ?? {},
    },
    stroke: {
      width: 2,
      curve: curve ?? "smooth",
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: "",
    },
    yaxis: {
      labels: {},
      title: {
        text: "",
      },
    },
    xaxis: {
      type: "datetime",
      min: activeZoomOption ? adjustTime(activeZoomOption.label) : undefined,
    },
    tooltip: {
      shared: false,
      y: {},
      x: {
        format: "dd MMM yyyy",
      },
    },
    fill: fill ?? {
      type: "gradient",
      gradient: {
        shadeIntensity: 0,
        opacityFrom: 0,
        opacityTo: 0, // set opacity to 0 to turn off the fill
        stops: [0, 100],
      },
    },

    colors: colors ?? [],
    // ["#FCBB14", "#C6005F", "#0042C6", "#B4E29E"],
  };

  return (
    <div>
      <Charts
        options={options}
        series={series}
        type={type ?? "line"}
        height={350}
      />
    </div>
  );
};

export default ApexChartComponent;
