import React from "react";
import { SendMoneyProvider } from "../../../contexts/send-money";
import { SendMoneySteps } from "./steps";
import SendMoneyStyles from "./styles";

const Index = () => {
  return (
    <SendMoneyProvider>
      <SendMoneyStyles>
        <SendMoneySteps />
      </SendMoneyStyles>
    </SendMoneyProvider>
  );
};

export default Index;
