import React, {useEffect, useState} from 'react';
import Table from "../../../../components/common/Table";
import restService from "../../../../services/restService";
import {Link,useParams} from "react-router-dom";
import identificationData from "../../../../assets/static_json/identificationTable.json";
import TransactionStyles from "../../Transactions/styles";
import tableData from "../../../../assets/static_json/transactionTable.json";
import Lightbox from "react-image-lightbox";
import Modal from "../../../../components/common/Modal";
import {CreditCard, Mail, Phone} from "react-feather";
import {formatDate} from "../../../../helpers/dateUtil";

const images = [identificationData.bodyData[0].resource_path];
const head = tableData.headData;
const body = tableData.bodyData;

const renderTransactionsHeadData = (item,index) => {
    return(
        item !== "Transaction ID" && item !== "Type" && item !== "Sender"?<th key={index}>{item}</th>:null
    );
}

const renderTransactionBodyData = (item, index) => {
    return(
        <tr key={index}>
            <td>
                <Link to={`/user/${item.id}`} className="name">{item.receiver}</Link>
                <div className="id">{item.recipientId}</div>
            </td>
            <td> {`${item.sendCurrency}${item.amountSent}`}</td>
            <td> {`${item.receiveCurrency}${item.amountReceived}`}</td>
            <td> {`${item.sendCurrency}${item.charge}`}</td>
            <td>{item.partner}</td>
            <td> {item.date}</td>
            <td>
                <Link to={`/transaction/${item.id}`} className="btn btn-outline-secondary transaction-details">
                    View
                </Link>
            </td>
        </tr>
    );
}
const renderIdentificationHeadData = (item,index) => {
    return(
        <th key={index}>{item}</th>
    );
}

const User = () => {
    const [suspendStaff, setSuspendStaff] = useState(undefined);
    const [user, setUser] = useState({})
    const [lightBox, setLightBox] = useState( {
        photoIndex: 0,
        isOpen: false,
    })

  const {id} = useParams();
  const renderIdentificationBodyData = (item, index) => {

        return(
            <tr key={index}>
                <td>
                    {item.identification_type}
                </td>
                <td>
                    {item.identification_category}
                </td>
                <td>
                    {item.date_created}
                </td>
                <td className="text-red-500">
                    {item.status}
                </td>
                <td>
                    <button className="btn btn-primary" onClick={() => setLightBox({...lightBox, isOpen: true })}>
                        View Images
                    </button>
                </td>
            </tr>
        );
    };

  const handleSuspend = () => {
    //API CALL to suspend or activate
    if (user.status === "active"){
      setUser({...user, status : "inactive"})
    } else {
      setUser({...user, status : "active"})
    }
    setSuspendStaff(undefined)

  }

  const fetchSenderById = (id) =>{
    //console.log("Id : ",id)

    restService.SendersApi.getSenderById(id)
      .then((response) => {
        if(response){
          setUser(response.data.data);
        }
      })
  }

    useEffect(()=>{
      fetchSenderById(id)
    },[id])


  return (
        <>

          <div className="intro-y box px-5 pt-5 mt-5">
            <div className="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5">
              <div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
                <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
                  <img className="rounded-full h-auto w-full mx-auto"
                       src="https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png"
                       alt=""/>
                    <div
                      className={`absolute mr-4 flex items-center justify-center bottom-0 right-0 ${(user.status === "active"? "bg-green-500" : "bg-red-500")} rounded-full p-2`}>
                    </div>
                </div>
                <div className="ml-5">
                  <div className="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg">{`${user.firstname} ${user.lastname}`}</div>
                  <div className="text-gray-600">Joined : {formatDate(user.created_at)}</div>
                  <button onClick={() => setSuspendStaff(true)} className={"ml-auto mr-auto mt-3 block py-1 px-2 rounded" +
                  " text-white text-sm " + (user.status === "active"? "bg-red-500" : "bg-green-500")}>{user.status === "active"?
                    "Suspend" : "Reactivate"}</button>
                </div>
              </div>

              <div
                className="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0">
                <div className="font-medium text-center lg:text-left lg:mt-3">Contact Details</div>
                <div className="flex flex-col justify-center items-center lg:items-start mt-4">
                  <div className="truncate sm:whitespace-normal flex items-center text-blue-800">
                    <Mail className="w-4 h-4 mr-2"/> {user.email}
                  </div>
                  <div className="truncate sm:whitespace-normal flex items-center mt-3"><Phone className="w-4 h-4 mr-2"/>
                    {user.msisdn}
                  </div>
                  <div className="truncate sm:whitespace-normal flex items-center mt-3">
                    <CreditCard className="w-4 h-4 mr-2"/>
                    {user.id}
                  </div>
                </div>
              </div>



              <div
                className="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0">
                <div className="font-medium text-center lg:text-left lg:mt-3">Other Details</div>
                <div className="flex flex-col justify-center items-center lg:items-start mt-4">
                  <div className="truncate sm:whitespace-normal flex items-center text-blue-800">
                    <div className="font-semibold">Address</div>
                    <div className="px-4">{user.address}</div>
                  </div>
                  <div className="truncate sm:whitespace-normal flex items-center">
                    <div className="py-2 font-semibold">Nationality</div>
                    <div className="px-4">{user.country && user.country.citizenship}</div>
                  </div>
                  <div className="truncate sm:whitespace-normal flex items-center">
                    <div className="font-semibold">Date of Birth</div>
                    <div className="px-4">{user.date_of_birth}</div>
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div className="md:flex no-wrap md:-mx-2 lg:mt-2">
                <div className="w-full mx-2 h-64">
                  <div className="bg-white p-3 shadow-sm rounded-sm">
                        <div className="grid grid-cols-1">
                            <div>
                                <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                                    <span clas="text-green-500">
                                        <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                        </svg>
                                    </span>
                                    <span className="tracking-wide">Identifications</span>
                                </div>
                                <Table
                                    headData={identificationData.headData}
                                    renderHead={(item,index) => renderIdentificationHeadData(item,index)}
                                    bodyData={user.identifications}
                                    renderBody={(item,index) => renderIdentificationBodyData(item, index)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white p-3 shadow-sm rounded-sm">
                <div className="grid grid-cols-1">
                    <div>
                        <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                                    <span clas="text-green-500">
                                        <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                        </svg>
                                    </span>
                            <span className="tracking-wide">Transactions</span>
                        </div>
                        <TransactionStyles>
                            <div className="content-layout mt-0">
                                <div className="header-layout">
                                    <div className="page">Showing 1 to 5 of 5 entries</div>
                                    <div className="search-div">
                                        <div className="search">
                                            <input type="text" className="search-input box form-control"
                                                   placeholder="Search..."/>
                                            <i className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" data-feather="search"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <Table
                                        headData={head}
                                        renderHead={(item,index) => renderTransactionsHeadData(item,index)}
                                        bodyData={body.slice(0,5)}
                                        renderBody={(item,index) => renderTransactionBodyData(item, index)}
                                    />
                                </div>

                                {/*Pagination ---> To be done well*/}
                                <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                                    <ul className="pagination">
                                        <li>
                                            <a className="pagination__link" href=""> <i className="w-4 h-4" data-feather="chevrons-left"></i> </a>
                                        </li>
                                        <li>
                                            <a className="pagination__link" href=""> <i className="w-4 h-4" data-feather="chevron-left"></i> </a>
                                        </li>
                                        <li><a className="pagination__link" href="">...</a></li>
                                        <li><a className="pagination__link" href="">1</a></li>
                                        <li><a className="pagination__link pagination__link--active" href="">2</a></li>
                                        <li><a className="pagination__link" href="">3</a></li>
                                        <li><a className="pagination__link" href="">...</a></li>
                                        <li>
                                            <a className="pagination__link" href=""> <i className="w-4 h-4" data-feather="chevron-right"></i> </a>
                                        </li>
                                        <li>
                                            <a className="pagination__link" href=""> <i className="w-4 h-4" data-feather="chevrons-right"></i>
                                            </a>
                                        </li>
                                    </ul>
                                    <select className="w-20 form-select box mt-3 sm:mt-0">
                                        <option>10</option>
                                        <option>25</option>
                                        <option>35</option>
                                        <option>50</option>
                                    </select>
                                </div>
                            </div>
                        </TransactionStyles>
                    </div>
                </div>
            </div>
            {lightBox.isOpen && (
                <Lightbox
                    mainSrc={images[lightBox.photoIndex]}
                    nextSrc={images[(lightBox.photoIndex + 1) % images.length]}
                    prevSrc={images[(lightBox.photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setLightBox({...lightBox, isOpen: false })}
                    onMovePrevRequest={() =>
                        setLightBox({...lightBox,
                            photoIndex: (lightBox.photoIndex + images.length - 1) % images.length,
                        })
                    }
                    onMoveNextRequest={() =>
                        setLightBox({...lightBox,
                            photoIndex: (lightBox.photoIndex + 1) % images.length,
                        })
                    }
                />
            )}
            {suspendStaff && <Modal onClick={handleSuspend} size="small" modalTitle="Suspend Staff"
                                    buttonTitle={user.status === "active"?"Suspend" : "Reactivate"} buttonColor={user.status === "active"?"bg-red-600" : "bg-green-600"}  body={
                <p className="text-sm text-gray-500">
                    Are you sure you want to {user.status === "active"? "suspend" : "reactivate"} this staff?.
                </p>
            } onClose={() => {setSuspendStaff(false)}} />}
        </>
    );
};

export default User;
