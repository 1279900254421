import Modal2 from "../../../../../components/common/Modal/Modal2";
import Button from "../../../../../components/common/Button";

export function ConfirmationModal({
  show,
  setShow,
  sendAmount,
  disbursal_country,
}) {
  if (!show) return null;

  return (
    <Modal2
      size="medium"
      onHide={() => {
        setShow(false);
      }}
    >
      <div className="flex intro-y flex-col min-h-[50vh] justify-center items-center gap-8 px-8 py-10">
        <div className="mt-8">
          <span>
            <svg
              width="106"
              height="106"
              viewBox="0 0 106 106"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="53" cy="53" r="53" fill="#0042C6" />
              <path
                d="M22.7139 72.2728C22.7139 72.7839 22.9266 73.2741 23.3052 73.6356C23.6839 73.997 24.1974 74.2001 24.7329 74.2001H77.2281C77.7636 74.2001 78.2772 73.997 78.6558 73.6356C79.0345 73.2741 79.2472 72.7839 79.2472 72.2728V48.9046H22.7139V72.2728ZM31.0424 55.65C31.0424 55.3945 31.1488 55.1494 31.3381 54.9687C31.5274 54.7879 31.7842 54.6864 32.052 54.6864H43.6615C43.9292 54.6864 44.186 54.7879 44.3753 54.9687C44.5646 55.1494 44.671 55.3945 44.671 55.65V63.3591C44.671 63.6147 44.5646 63.8598 44.3753 64.0405C44.186 64.2213 43.9292 64.3228 43.6615 64.3228H32.052C31.7842 64.3228 31.5274 64.2213 31.3381 64.0405C31.1488 63.8598 31.0424 63.6147 31.0424 63.3591V55.65ZM77.2281 31.8H24.7329C24.1974 31.8 23.6839 32.0031 23.3052 32.3645C22.9266 32.726 22.7139 33.2162 22.7139 33.7273V41.6773H79.2472V33.7273C79.2472 33.2162 79.0345 32.726 78.6558 32.3645C78.2772 32.0031 77.7636 31.8 77.2281 31.8Z"
                fill="white"
              />
              <circle
                cx="74.9574"
                cy="68.9"
                r="11.9629"
                fill="white"
                stroke="#0042C6"
                stroke-width="1.81714"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M81.2942 64.4824L73.5606 73.3209L69.6572 69.4176L70.6046 68.4701L73.4953 71.3608L80.2859 63.6001L81.2942 64.4824Z"
                fill="#0042C6"
                stroke="#0042C6"
                stroke-width="1.51429"
                stroke-miterlimit="10"
                strokeLinecap="square"
              />
            </svg>
          </span>
        </div>

        <h4 className="text-3xl text-center font-medium text-theme-32">
          Payment <br /> In Process
        </h4>

        <div className="flex w-[40%] flex-col gap-2 items-center mx-auto justify-center">
          <h5 className="text-2xl font-medium text-black">
            {disbursal_country?.currency_symbol}
            {Number(sendAmount).toFixed(2)}
          </h5>
          <p className="text-[#727681] text-xs font-medium text-center">
            A confirmation code has been sent to your email
          </p>
        </div>

        <div className="w-full mt-8">
          <Button
            onClick={() => {
              setShow(false);
            }}
            className={"primary-btn-2 bg-[#0042C6] w-full justify-center"}
          >
            Done
          </Button>
        </div>
      </div>
    </Modal2>
  );
}
