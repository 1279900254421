import React, {useEffect, useState} from "react";
import Modal2 from "../Modal/Modal2";
import Step1 from "./Step1";
import Step2 from "./Step2";
import {Steps, message} from "antd";
import Loader from "../Loader";
import Success from "./Success";
import {useSelector} from "react-redux";
import restService from "../../../services/restService";
import {useToasts} from "react-toast-notifications";
import RestService from "../../../services/rest-service";
import {parsePhoneNumber} from "react-phone-number-input";
import regions from "../../../assets/static_json/regions.json";

const keys = [
  "firstname",
  "lastname",
  "email",
  "calling_code",
  "msisdn",
  "country_id",
  "address",
  "agent_role",
];
const AddNewAgency = ({show, refetchData, setShow}) => {
  const {addToast} = useToasts();

  const user = useSelector((state) => state.auth?.user);
  const [agencyAdded, setAgencyAdded] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    calling_code: "",
    msisdn: "",
    country_id: "",
    address: "",
  });
  const [agency, setAgency] = useState({});
  const [countries, setCountries] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const validateForm = () => {
    const keys = Object.keys(form);
    let errorList = {...errors};
    keys.forEach((key) => {
      // TODO validate phone numbers

      if (!form[key]) {
        errorList[key] = "This field is required.";
      } else {
        if (key === "email") {
          const regex = /\S+@\S+\.\S+/;
          if (!regex.test(form[key])) {
            errorList[key] = "Enter a valid email.";
          }
        }
      }
    });
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };

  const onFormChange = ({target}) => {
    let details = {...form};
    details[target.name] = target.value;
    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);

    if (target.name === "country_id") {
      const country = countries.filter((c) => c.id == target.value)[0];
      details.calling_code = country?.calling_code;
    }
    setForm(details);
  };

  const next = () => {
    // if (validateForm()) {
    setCurrent(current + 1);
    // }
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const handleSubmit = () => {
    //Api request to save agency
    if (validateForm()) {
      setLoading(true);
      let data = {...form};
      restService.AgencyApi.addAgency(data)
        .then(({data}) => {
          addToast("Successfully Added Agency", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
          setAgency(data?.data);
          setLoading(false);
          next();
        })
        .catch(({response}) => {
          addToast(response?.data?.message, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    RestService.getCountries().then(({data}) => {
      setCountries(data?.data);
      setForm({
        ...form,
        calling_code: data?.data.filter((country) => {
          return country.name === user.country?.name;
        })[0].calling_code,
        country_id: data?.data.filter((country) => {
          return country.name === user.country?.name;
        })[0].id,
      });
    });
  }, []);

  const onPhoneNumberChange = (value) => {
    const calling_code = parsePhoneNumber(String(value));
    // let msisdn = value ? value?.trim()?.slice(calling_code?.length + 1) : "";
    // const country = countries.filter(
    //   (c) => c.iso_3166_2 == calling_code?.country
    // )[0];

    setForm({
      ...form,
      calling_code: calling_code?.countryCallingCode ?? "",
      msisdn: value,
    });
  };

  const [step, setStep] = useState(1);
  const handlePrev = () => {
    if (step !== 1) {
      setStep(step - 1);
    }
  };
  const handleNext = (validate) => {
    if (step === 1) {
      if (validate()) {
        setStep(step + 1);
      }
    }
    return;
  };

  const steps = [
    {
      title: "",
      content: (
        <Step1
          countries={countries}
          errors={errors}
          setForm={setForm}
          onPhoneNumberChange={onPhoneNumberChange}
          onFormChange={onFormChange}
          form={form}
          next={handleSubmit}
          prev={prev}
        />
      ),
    },
    {
      title: "",
      content: (
        <Step2
          setCurrent={setCurrent}
          handlePrev={handlePrev}
          handleNext={handleNext}
          setAgencyAdded={setAgencyAdded}
          agency={agency}
          setLoading={setLoading}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <>
      {loading ? <Loader /> : null}
      <Modal2
        onHide={() => {
          // if (agencyAdded) {
          //   setShow(false);
          // }

          setShow(false);
          setCurrent(0);
        }}
        size={agencyAdded ? "small" : "lg"}
      >
        {loading ? (
          ""
        ) : (
          <>
            {agencyAdded ? (
              <div className="h-[50vh] intro-y flex items-center justify-center">
                <Success />
              </div>
            ) : (
              <div className="p-8 intro-y flex flex-col gap-4 ">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    if (current === 0) {
                      setShow(false);
                      return;
                    }

                    if (step === 2) {
                      handlePrev();
                      return;
                    }

                    prev();
                    setShow(false);
                  }}
                >
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.14446 1.14446L3.09818 5.19074H12.4741V6.80926H3.09818L7.14446 10.8555L6 12L0 6L6 0L7.14446 1.14446Z"
                      fill="black"
                    />
                  </svg>
                </span>

                <div className="md:max-w-[7vw] mx-auto">
                  <Steps
                    direction="horizontal"
                    current={current}
                    items={items}
                  />
                </div>
                <div>{steps[current].content}</div>
              </div>
            )}
          </>
        )}
      </Modal2>
    </>
  );
};

export default AddNewAgency;
