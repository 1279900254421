import React from 'react';
import SkeletonElementStyles from "./SkeletonElementStyles";

const SkeletonElement = ({type,width,height}) => {
  return (
    <SkeletonElementStyles height={height}>
      <div className={type}></div>
    </SkeletonElementStyles>
  );
};

export default SkeletonElement;
