import React from "react";
import Modal2 from "../Modal/Modal2";
import AddIdentification from "../AddNewSender/AddIdentification";

const AddIdentificationModal = ({ setShow, userId, onSubmitSuccess }) => {
  return (
    <>
      <Modal2
        onHide={() => {
          setShow(false);
        }}
        size={"sm"}
      >
        <>
          <div className="p-8 flex intro-y flex-col gap-4 ">
            <span className="cursor-pointer" onClick={() => setShow(false)}>
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.14446 1.14446L3.09818 5.19074H12.4741V6.80926H3.09818L7.14446 10.8555L6 12L0 6L6 0L7.14446 1.14446Z"
                  fill="black"
                />
              </svg>
            </span>

            <div>
              <AddIdentification
                userId={userId}
                onSubmitSuccess={onSubmitSuccess}
              />
            </div>
          </div>
        </>
      </Modal2>
    </>
  );
};

export default AddIdentificationModal;
