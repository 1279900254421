import { useSendMoneyContext } from "../../../../contexts/send-money";
import { Stepper } from "./components/stepper";
import { DestinationWallet } from "./destination-wallet";
import { Receivers } from "./receivers";
import Review from "./review";
import { SelectDisbursalCountry } from "./select-disbursal-country";
import SendMoney from "./send-money";
import { Senders } from "./senders";
import "./steps.scss";

const steps = [
  {
    title: "Country",
    step: 1,
  },
  {
    title: "Sender",
    step: 2,
  },
  {
    title: "Receiver",
    step: 3,
  },
  {
    title: "Wallet",
    step: 4,
  },
  {
    title: "Amount",
    step: 5,
  },
  {
    title: "Review",
    step: 6,
  },
];

export function SendMoneySteps() {
  const { currentStep, setCurrentStep } = useSendMoneyContext();

  return (
    <div className="flex flex-col gap-8">
      <Stepper
        steps={steps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />

      <hr className="border-[#DEDEDE]" />
      {currentStep === 1 && <SelectDisbursalCountry />}
      {currentStep === 2 && <Senders />}
      {currentStep === 3 && <Receivers />}
      {currentStep === 4 && <DestinationWallet />}
      {currentStep === 5 && <SendMoney />}
      {currentStep === 6 && <Review />}
    </div>
  );
}
