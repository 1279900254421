import React from "react";
import { Route, Switch } from "react-router-dom";
import Agents from "../app/AgentViews/Agents";
import AddAgent from "../app/AgentViews/Agents/AddAgent";
import AgentProfile from "../app/AgentViews/Agents/AgentProfile";
import TopUp from "../app/AgentViews/Agents/TopUp";
import AddIdentification from "../app/AgentViews/Agents/addIdentification";
import Commissions from "../app/AgentViews/Commisions";
import Dashboard from "../app/AgentViews/Dashboard";
import FundingStatement from "../app/AgentViews/FundingStatement";
import Receivers from "../app/AgentViews/Receivers";
import AddReceiver from "../app/AgentViews/Receivers/AddReceiver";
import ReceiverProfile from "../app/AgentViews/Receivers/ReceiverProfile";
import Index from "../app/AgentViews/SendMoney";
import Senders from "../app/AgentViews/Senders";
import SenderProfile from "../app/AgentViews/Senders/SenderProfile";
import Settings from "../app/AgentViews/Settings";
import Transactions from "../app/AgentViews/Transactions";
import TransactionReceipt from "../app/AgentViews/Transactions/TransactionReceipt";
import PageNotFound from "./404Page";
import AddNewSender from "./common/AddNewSender";
import AddNewReceiver from "./common/AddNewReceiver";

const AgentRoutes = ({ setShowSidebar, setShowNavbar }) => {
  return (
    <Switch>
      <Route path="/" exact component={Dashboard} />
      <Route path="/transactions" component={Transactions} />
      <Route path="/send-money" component={Index} />
      <Route path="/add-sender" component={AddNewSender} />
      <Route path="/add-agent" component={AddAgent} />
      <Route path="/topup/:id" component={TopUp} />
      <Route path="/add-receiver" component={AddNewReceiver} />
      <Route path="/add-identification" component={AddIdentification} />
      <Route path="/agents" exact component={Agents} />
      <Route path="/transaction/:id" component={TransactionReceipt} />
      {/* <Route path="/transaction/:id" component={Receipt} /> */}
      <Route path="/senders" component={Senders} />
      <Route path="/funding-statement" component={FundingStatement} />
      <Route path="/receivers" component={Receivers} />
      <Route path="/receiver/:id" component={ReceiverProfile} />
      {/* <Route path="/receiver/:id" component={Receiver} /> */}
      <Route path="/sender/:id" component={SenderProfile} />
      {/* <Route path="/sender/:id" component={Sender}/> */}
      <Route path="/agent/:id" component={AgentProfile} />
      {/* <Route path="/agent/:id" component={Agent} /> */}
      <Route path="/settings" component={Settings} />
      <Route path="/commissions" component={Commissions} />
      <Route
        path="*"
        render={(props) => (
          <PageNotFound
            {...props}
            setShowSidebar={setShowSidebar}
            setShowNavbar={setShowNavbar}
          />
        )}
      />
    </Switch>
  );
};

export default AgentRoutes;
