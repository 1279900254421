import React, {useState} from "react";
import PageTitle from "../../../components/common/PageTitle/PageTitle";

import {useSelector} from "react-redux";
import Rates from "./Rates";
import Charges from "./Charges/index";
import CommissionConfigurations from "./Commissions/index";

const activeClass = "btn btn-primary-2";
const inactiveClass =
  "text-gray-600 py-2 px-6 block hover:text-blue-500 focus:outline-none";
const Configurations = () => {
  const user = useSelector((state) => state.auth?.user);
  const agentRole = user?.agent_role?.name;
  const [tab, setTab] = useState("rates");

  return (
    <>
      <div className="content-layout  flex flex-col gap-4 mt-13-important">
        <div className="px-2 intro-y">
          <div className="">
            <div className=" w-fit">
              <nav className="flex items-center ">
                <button
                  className={tab === "rates" ? activeClass : inactiveClass}
                  onClick={() => setTab("rates")}
                >
                  Rates
                </button>
                <button
                  className={tab === "charges" ? activeClass : inactiveClass}
                  onClick={() => setTab("charges")}
                >
                  Charges
                </button>
                <button
                  className={
                    tab === "commissions" ? activeClass : inactiveClass
                  }
                  onClick={() => setTab("commissions")}
                >
                  Commissions
                </button>
              </nav>
            </div>
          </div>
        </div>
        <div id="tab-contents">
          {tab === "rates" && <Rates />}
          {tab === "charges" && <Charges />}
          {tab === "commissions" && <CommissionConfigurations />}
        </div>
      </div>
    </>
  );
};

export default Configurations;
