import { useEffect, useState } from "react";
import restService from "../../../services/restService";
import { CountryCard } from "./card";

export function DisbursalCountrySelect({
  onSelect = () => {},
  onFetchSuccess,
  onFetchError,
  onFinally,
  setIsFetching,
}) {
  const [disbursalCountries, setDisbursalCountries] = useState([]);

  useEffect(() => {
    if (!disbursalCountries.length) {
      getCountries();
    }
  }, []);

  async function getCountries() {
    setIsFetching?.(true);
    try {
      const {
        data: { data },
      } = await restService.UtilsApi.getCountries();

      const res = data?.filter(
        (country) => Number(country.is_disbursal_country) === 1
      );

      setDisbursalCountries(res);
      onFetchSuccess?.(res);
    } catch (error) {
      console.log(error);
      onFetchError?.(error);
    } finally {
      onFinally?.();
      setIsFetching?.(false);
    }
  }

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-9 lg:grid-cols-7 gap-4 md:gap-6 lg:gap-8">
      {disbursalCountries.map((country) => (
        <div
          onClick={() => {
            onSelect?.(country);
          }}
          key={country?.id}
          className="w-full "
        >
          <CountryCard
            iconCode={country?.iso_3166_2}
            countryName={country?.name}
          />
        </div>
      ))}
    </div>
  );
}
