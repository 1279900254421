import { createContext, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import currencies from "../../assets/static_json/currencies.json";

const SendMoneyContext = createContext({});
export const useSendMoneyContext = () => useContext(SendMoneyContext);

export function SendMoneyProvider({ children }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [receivers, setReceivers] = useState([]);
  const [agentCommission, setAgentCommission] = useState(0);
  const [agencyCommission, setAgencyCommission] = useState(0);
  const [companyCommission, setCompanyCommission] = useState(0);

  const [companyCommissionAmount, setCompanyCommissionAmount] = useState(0);
  const [agencyCommissionAmount, setAgencyCommissionAmount] = useState(0);
  const [agentCommissionAmount, setAgentCommissionAmount] = useState(0);

  const [amountToPay, setAmountToPay] = useState(0);

  const [receiveAmount, setReceiveAmount] = useState(0);

  const [charge, setCharge] = useState(0);

  const [sendMoneyData, setSendMoneyData] = useState({});

  const [rate, setRate] = useState(1);

  const [transferReason, setTransferReason] = useState("");
  const [sendAmount, setSendAmount] = useState(0);
  const currencyOptions = currencies;

  const defaultCurrency = useSelector(
    (state) => state.auth?.user?.agency?.country.currency_code
  );

  const agencyCurrency = useMemo(() => {
    return currencyOptions.find((curr) => curr.label === defaultCurrency);
  }, [defaultCurrency, currencyOptions]);

  return (
    <SendMoneyContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        setSendMoneyData,
        sendMoneyData,
        setReceivers,
        receivers,
        agentCommission,
        setAgentCommission,
        companyCommission,
        setCompanyCommission,
        agencyCommission,
        setAgencyCommission,
        charge,
        setCharge,
        agentCommissionAmount,
        setAgentCommissionAmount,
        agencyCommissionAmount,
        setAgencyCommissionAmount,
        companyCommissionAmount,
        setCompanyCommissionAmount,
        receiveAmount,
        setReceiveAmount,
        amountToPay,
        setAmountToPay,
        rate,
        setRate,
        agencyCurrency,
        defaultCurrency,
        transferReason,
        setTransferReason,
        sendAmount,
        setSendAmount,
      }}
    >
      {children}
    </SendMoneyContext.Provider>
  );
}
