import { useEffect, useState } from "react";

export function usePagination() {
  const [isFetchingMore, setIsFetchingMore] = useState(true);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQ, setSearchQ] = useState("");

  const [localPaginatedData, setLocalPaginatedData] = useState([]);

  const [totalPages, setTotalPages] = useState(1);

  const skipTo = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const setPaginatedData = (newData) => {
    const prevData = data?.slice();
    const dataMap = new Map(prevData.map((item) => [item.id, item]));
    newData.forEach((item) => dataMap.set(item.id, item));
    const res = Array.from(dataMap.values());

    setData(res);
    setLocalPaginatedData(res);
  };

  const setSearchResults = (results) => {
    setData(results);
  };

  const resetQuery = () => {
    setSearchQ("");
    setData(localPaginatedData);
  };

  return {
    totalItems,
    currentPage,
    itemsPerPage,
    skipTo,
    nextPage,
    prevPage,
    setPaginatedData,
    isFetchingMore,
    setIsFetchingMore,
    data,
    totalPages,
    setTotalPages,
    searchQ,
    setSearchQ,
    setSearchResults,
    resetQuery,
  };
}
