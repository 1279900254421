import React, {useEffect, useState} from "react";
import AddIdentification from "../AddNewSender/AddIdentification";
import {useSelector} from "react-redux";
import {Redirect} from "react-router";
import {useToasts} from "react-toast-notifications";
import restService from "../../../services/restService";
import AddNewAgent from "../AddNewAgent/Step1";
import {parsePhoneNumber} from "react-phone-number-input";
const keys = [
  "firstname",
  "lastname",
  "email",
  "calling_code",
  "msisdn",
  "country_id",
  "address",
];

const Step2 = ({
  agency,
  setCurrent,
  setAgencyAdded,
  handlePrev,
  handleNext,
  setLoading,
}) => {
  const {addToast} = useToasts();
  const [step, setStep] = useState(1);
  const user = useSelector((state) => state.auth?.user);
  const [redirect, setRedirect] = useState(false);
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    othernames: "",
    email: "",
    calling_code: agency?.country?.calling_code,
    msisdn: "",
    country_id: agency?.country?.id,
    address: "",
    agent_role: "Super Agent",
    agency_id: agency?.id,
  });
  const [confirmation, setConfirmation] = useState(false);
  const [roles, setRoles] = useState([]);
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [callback, setCallback] = useState(() => {});
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    restService.UtilsApi.getCountries().then(({data}) => {
      setCountries(data?.data);
    });
    restService.AgencyApi.getAgencies().then(({data}) => {
      setAgencies(data?.data?.data);
    });
    restService.UtilsApi.getAgentRoles().then(({data}) => {
      setRoles(data?.data);
    });
  }, []);

  const handleSubmit = (data) => {
    //Api request to save agency
    if (validateForm()) {
      setLoading(true);
      data.msisdn = form.calling_code + form.msisdn;
      restService.AgentApi.addAgent(data)
        .then((response) => {
          addToast("Successfully Added Agent", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          setCurrent(0);
          setAgencyAdded(true);
        })
        .catch(({response}) => {
          addToast(response?.data?.message, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const validateForm = () => {
    let errorList = {};
    keys.forEach((key) => {
      // TODO validate phone numbers
      if (!form[key]) {
        errorList[key] = "This field is required.";
      } else {
        delete errorList[key];
        if (key === "email") {
          const regex = /\S+@\S+\.\S+/;
          if (!regex.test(form[key])) {
            errorList[key] = "Enter a valid email.";
          }
        }
      }
    });
    setErrors(errorList);

    return Object.keys(errorList).length === 0;
  };

  const onFormChange = ({target}) => {
    let details = {...form};
    details[target.name] = target.value;
    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);

    if (target.name === "country_id") {
      const country = countries.filter((c) => c.id == target.value)[0];
      details.calling_code = country?.calling_code;
    }
    setForm(details);
  };

  const onPhoneNumberChange = (value) => {
    const calling_code = parsePhoneNumber(String(value));
    // let msisdn = value ? value?.trim()?.slice(calling_code?.length + 1) : "";
    // const country = countries.filter(
    //   (c) => c.iso_3166_2 == calling_code?.country
    // )[0];

    setForm({
      ...form,
      calling_code: calling_code?.countryCallingCode ?? "",
      msisdn: value,
    });
  };

  return (
    <>
      {step === 1 && (
        <AddNewAgent
          form={form}
          setForm={setForm}
          countries={countries}
          errors={errors}
          onFormChange={onFormChange}
          roles={roles}
          next={() => handleNext(validateForm)}
          onPhoneNumberChange={onPhoneNumberChange}
          title={"Agent Data"}
          agencies={agencies}
        />
      )}

      {step === 2 && (
        <AddIdentification
          description={
            "Upload the agent's identification and proof of address. Upload full images or front and back images"
          }
          handleSubmit={handleSubmit}
          data={form}
          prev={handlePrev}
        />
      )}
    </>
  );
};

export default Step2;
