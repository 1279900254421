import { useEffect, useState } from "react";
import { CreditCard, Smartphone } from "react-feather";
import { useToasts } from "react-toast-notifications";
import Button from "../../../../../../components/common/Button";
import Input from "../../../../../../components/common/Input";
import Loader from "../../../../../../components/common/Loader";
import { DisbursalCountrySelect } from "../../../../../../components/shared/disbursal-country/disbursal-country-select";
import { getRandomColor } from "../../../../../../helpers/utils";
import { useLocalPagination } from "../../../../../../hooks/use-local-paginaton";
import restService from "../../../../../../services/restService";
import Institutions from "../add-recipient/institutions";
import { WalletType } from "../add-recipient/wallet-type";

const keys = ["account_number", "country_id", "destination_institution_id"];

export default function AddWallet({
  onSuccess,
  disbursal_country,
  receiver,
  initialStep = 2,
}) {
  const { addToast } = useToasts();

  const [currentStep, setCurrentStep] = useState(initialStep);
  const [disbursalCountry, setDisbursalCountry] = useState(disbursal_country);

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWalletType, setSelectedWalletType] = useState("");

  const [form, setForm] = useState({
    account_number: "",
    country_id: disbursalCountry?.id,
    destination_institution_id: "",
  });

  const { data, nextPage, handleSearch, setInitialItems } =
    useLocalPagination();

  const validateForm = () => {
    let errorList = { ...errors };

    const wallets = receiver?.receiver_wallets;
    wallets?.forEach((wallet) => {
      if (
        wallet?.destination_institution?.id ==
          form.destination_institution_id &&
        wallet.account_number == form.account_number
      ) {
        errorList.account_number = "Cannot add duplicate wallets.";
      }
    });
    keys.forEach((key) => {
      if (!form[key]) {
        errorList[key] = "This field is required.";
      }
    });
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };

  const saveWallet = () => {
    if (validateForm()) {
      setIsLoading(true);

      restService.ReceiversApi.addWallet(form, receiver?.id)
        .then(({ data: { data } }) => {
          addToast("Wallet add successfully ", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });

          onSuccess?.(data);
        })
        .catch((error) => {
          console.log(error);
          addToast("An error occurred", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        })

        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  async function getDestinationInstitutionsByWallet(wallet) {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await restService.UtilsApi.getDestinationInstitutionsByWallet(
        disbursalCountry?.id,
        wallet
      );

      setInitialItems(
        data?.map((item, index) => ({
          ...item,
          bgColor: getRandomColor(index),
        }))
      );

      setCurrentStep(3);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const onFormChange = ({ target }) => {
    let details = { ...form };
    details[target.name] = target.value;

    setForm(details);

    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);
  };

  useEffect(() => {
    if (!disbursal_country?.id && currentStep > 1) {
      setCurrentStep(1);
      return;
    }
  }, [disbursal_country]);

  return (
    <div className="flex flex-col gap-4">
      {isLoading && <Loader message={"Please wait..."} />}
      <div className="flex items-center justify-center">
        <h2 className="text-2xl text-theme-1 font-semibold">Add New Wallet</h2>
      </div>

      {currentStep === 1 && (
        <DisbursalCountrySelect
          setIsFetching={setIsLoading}
          onSelect={(country) => {
            setDisbursalCountry(country);
            setForm((prev) => ({
              ...prev,
              country_id: country?.id,
            }));
            setCurrentStep(2);
          }}
          onFetchSuccess={(res) => {
            if (res.length === 1) {
              setForm((prev) => ({
                ...prev,
                country_id: res[0]?.id,
              }));
              setCurrentStep(2);
              return;
            }
          }}
        />
      )}

      {currentStep === 2 && (
        <WalletType
          data={[
            { name: "Mobile Money", label: "wallet", icon: Smartphone },
            { name: "Bank Account", label: "bank", icon: CreditCard },
          ]}
          onSelect={(type) => {
            setSelectedWalletType(type);
            getDestinationInstitutionsByWallet(type);
          }}
        />
      )}

      {currentStep === 3 && (
        <Institutions
          handleSearch={handleSearch}
          nextPage={nextPage}
          data={data}
          goBack={() => setCurrentStep(2)}
          onSelect={(institution) => {
            setForm((prev) => ({
              ...prev,
              destination_institution_id: institution?.id,
            }));
            setCurrentStep(4);
          }}
        />
      )}

      {currentStep === 4 && (
        <div className="flex flex-col mx-auto justify-center md:max-w-[800px] flex-wrap items-center gap-2 w-full">
          <div
            className="intro-y w-full sm:col-span-6"
            style={{
              maxWidth: "400px",
            }}
          >
            <Input
              type="text"
              value={form.account_number}
              onChange={onFormChange}
              name="account_number"
              containerStyle={"w-full md:w-auto"}
              className="form-control w-full"
              labelName={
                selectedWalletType === "bank"
                  ? "Account Number *"
                  : "Mobile Money Number *"
              }
              placeholder={
                selectedWalletType === "bank" ? "12333445556" : "024000000"
              }
            />
            <p className="text-red-500 text-xs italic pt-1">
              {errors.account_number}
            </p>
          </div>

          <div className=" flex mt-6  justify-end flex-col gap-8">
            <div className="flex justify-end gap-4 ">
              <Button
                onClick={() => {
                  setCurrentStep(3);
                }}
                className="btn btn-secondary  flex items-center gap-2 text-xs"
              >
                Go back
              </Button>
              <Button
                onClick={saveWallet}
                className="flex items-center gap-1 bg-theme-32 border border-theme-32 hover:bg-theme-1 transition text-white rounded-md px-3 py-2"
              >
                <span>Submit</span>
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
