import { Empty } from "antd";
import { ChevronRight, CreditCard, Smartphone } from "react-feather";
import Button from "../../../../../../components/common/Button";
import Search from "../search";

export default function Institutions({
  data = [],
  nextPage,
  handleSearch,
  goBack,
  onSelect,
}) {
  return (
    <div className="flex intro-y flex-col gap-6">
      <div className="flex flex-col gap-4 items-center justify-center">
        <h2 className="text-base">Select Institution</h2>

        <div
          style={{
            maxWidth: "300px",
          }}
          className="w-full"
        >
          <Search
            placeholder={"Search for institution...  "}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </div>

      {data.length === 0 && <Empty />}

      <div className="grid  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-4">
        {data?.map((institution) => (
          <div
            style={
              institution?.bgColor
                ? { backgroundColor: institution?.bgColor }
                : {}
            }
            className={`zoom-in  border border-[#DEDEDE]  rounded-md  cursor-pointer 
            }`}
            key={institution?.id}
            onClick={() => {
              onSelect?.(institution);
            }}
          >
            <div className="py-4 px-4 flex items-center justify-between">
              <div className="flex items-center gap-4">
                <div
                  style={{
                    width: 40,
                    height: 40,
                  }}
                  className="rounded-full flex items-center justify-center bg-[#DEDEDE]"
                >
                  {institution?.type?.toLowerCase() === "bank" ? (
                    <CreditCard />
                  ) : (
                    <Smartphone />
                  )}
                </div>
                <div>
                  {/* <p className="text-muted-foreground">{institution?.type}</p> */}
                  <h3 className="text-lg font-semibold">{institution?.name}</h3>
                </div>
              </div>

              <ChevronRight />
            </div>
          </div>
        ))}
      </div>

      {data.length > 0 && (
        <div className="flex justify-end gap-4">
          <Button
            onClick={() => {
              goBack?.();
            }}
            className="btn btn-secondary  flex items-center gap-2 text-xs"
          >
            Go back
          </Button>
          <Button
            onClick={() => {
              nextPage();
            }}
            //   disabled={isFetchingMore || currentPage >= totalPages}
            className="btn btn-primary  flex items-center gap-2 text-xs"
          >
            See more
          </Button>
        </div>
      )}
    </div>
  );
}
