import React, {useState} from "react";
import RestService from "../../services/restService";
import {useDispatch} from "react-redux";
import { useToasts } from 'react-toast-notifications';
import {logout} from "../../redux/actions/auth";
import PasswordField2 from "../../components/common/PasswordField/PasswordField2";

const ResetPassword = ({staffOrAgent, userType}) => {
    const { addToast } = useToasts();
    const dispatch = useDispatch();

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("")
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false)

    const handleInputChange = (({target}) => {
        if (target.name === "password"){
            setPassword(target.value);
        } else if (target.name === "oldPassword"){
            setOldPassword(target.value)
        }
        else {
            setConfirmPassword(target.value)
        }
        setError(undefined)
    })
    const containsLowerCase = str => /[a-z]/.test(str);
    const containsUpperCase = str => /[A-Z]/.test(str);

    const updatePassword = (e) => {
        e.preventDefault();
        if (oldPassword && (password === confirmPassword && password?.length >7) && (password?.toLowerCase() !== password) &&
            (password.toUpperCase() !== password && (/\d/.test(password)))){
            if (userType === "staff"){
                RestService.StaffApi.changePassword(staffOrAgent?.id, oldPassword, password, confirmPassword).then(() =>{
                    addToast("Successfully Changed Password. Please log in", {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 5000
                    });

                    setTimeout(() => {
                        dispatch(logout());
                        window.location = (userType === "agent"? "/" : "/staff");
                    }, 5000)
                }).catch(() => {
                    setError(error?.response?.data?.message? error?.response.data.message : "Invalid credentials")
                })
            } else {
                RestService.AgentsApi.changePassword(staffOrAgent?.id, oldPassword, password, confirmPassword).then(() =>{
                    addToast("Successfully Changed Password. Please log in", {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 5000
                    });

                    setTimeout(() => {
                        dispatch(logout());
                        window.location = "/";
                    }, 5000)
                }).catch(() => {
                    setError(error?.response?.data?.message? error?.response.data.message : "Invalid credentials")

                })
            }
        }
    }

    return (
        <>
            <form className=" sm:w-2/3 w-full px-4 lg:px-0 mx-auto">
                <div className="pb-2 pt-4">
                    <PasswordField2
                        className={"block w-full text-lg border-none outline-none"}
                        onChange={(e)=> setOldPassword(e.target.value)}
                        placeholder="Temporary Password"
                        name={"oldPassword"}
                    />
                </div>
                <div className="pb-2 pt-4">
                    <PasswordField2
                        className={"block w-full text-lg border-none outline-none"}
                        onChange={handleInputChange}
                        placeholder="Password"
                        name={"password"}

                    />
                </div>
                <div className="pb-2 pt-4">
                    <PasswordField2
                        className={"block w-full text-lg border-none outline-none"}
                        onChange={handleInputChange}
                        placeholder="Confirm Password"
                        name={"confirmPassword"}
                        validationMessage={error}
                    />
                </div>
                <div className="p-4">
                    {loading ?
                        <button type="button" disabled={loading}
                                className="uppercase block w-full p-4 text-lg rounded-full bg-indigo-500 ">
                            <div className=" flex justify-center items-center">
                                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                            </div>
                        </button>
                        :
                        <button onClick={updatePassword}
                                className="primary-btn w-full justify-center">Reset
                            Password</button>
                    }
                </div>
                <div>
                    <ul className="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
                        <li className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round"
                                 className={`feather feather-check-circle ${password.length > 7 ? "text-green-500" : ""} `}>
                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                            </svg>
                            <span className={`ml-5  ${password.length > 7 ? "text-green-500" : ""} `}>At least 8 characters</span>
                        </li>
                        <li className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round"
                                 className={`feather feather-check-circle ${containsUpperCase(password) ? "text-green-500" : ""} `}>
                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                            </svg>
                            <span className={`ml-5  ${containsUpperCase(password) ? "text-green-500" : ""} `}>At least 1 uppercase letter </span>
                        </li>
                        <li className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round"
                                 className={`feather feather-check-circle ${containsLowerCase(password) ? "text-green-500" : ""} `}>
                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                            </svg>
                            <span className={`ml-5  ${containsLowerCase(password) ? "text-green-500" : ""} `}>At least 1 lowercase letter </span>
                        </li>
                        <li className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round"
                                 className={`feather feather-check-circle ${/\d/.test(password) ? "text-green-500" : ""} `}>
                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                            </svg>
                            <span
                                className={`ml-5  ${/\d/.test(password) ? "text-green-500" : ""} `}>At least 1 number </span>
                        </li>
                        <li className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round"
                                 className={`feather feather-check-circle ${password && password === confirmPassword ? "text-green-500" : ""} `}>
                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                            </svg>
                            <span
                                className={`ml-5  ${password && password === confirmPassword ? "text-green-500" : ""} `}>Passwords must match</span>
                        </li>
                        <li className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round"
                                 className={`feather feather-check-circle ${oldPassword ? "text-green-500" : ""} `}>
                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                            </svg>
                            <span
                                className={`ml-5  ${oldPassword ? "text-green-500" : ""} `}>Input temporary password</span>
                        </li>
                    </ul>
                    {/*<p className={"p-1 text-gray-600 text-lg tracking-widest " + (password?.length > 7 ? "line-through text-gray-400" : "")}></p>*/}
                    {/*<p className={"p-1 text-gray-600 text-lg tracking-widest " + (password?.toLowerCase() !== password ? "line-through text-gray-400" : "")}></p>*/}
                    {/*    <p className={"p-1 text-gray-600 text-lg tracking-widest " + (password.toUpperCase() !== password ? "line-through text-gray-400" : "")}></p>*/}
                    {/*    <p className={"p-1 text-gray-600 text-lg tracking-widest " + (/\d/.test(password) ? "line-through text-gray-400" : "")}></p>*/}
                    {/*    <p className={"p-1 text-gray-600 text-lg tracking-widest " + (password && password === confirmPassword ? "line-through text-gray-400" : "")}>Passwords*/}
                    {/*        Must match</p>*/}
                    {/*    <p className={"p-1 text-gray-600 text-lg tracking-widest " + (oldPassword ? "line-through text-gray-400" : "")}></p>*/}
                </div>
            </form>

        </>
)
}

export default ResetPassword;
