import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "../../../../../../components/common/Button";
import ErrorMessages from "../../../../../../components/common/ErrorMessages";
import Input from "../../../../../../components/common/Input";
import PhoneInputComponent from "../../../../../../components/common/PhoneInput";
import restService from "../../../../../../services/restService";

export function RecipientDetails({
  onFormChange,
  data,
  setForm,
  onPhoneNumberChange,
  errors,
  onSubmit,
  goBack,
  apiErrors,
  disbursal_country,
  selectedAccountType,
}) {
  const [relationships, setRelationships] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [msisdn, setMsisdn] = useState("");

  async function getSenderRelationships() {
    try {
      const {
        data: { data },
      } = await restService.UtilsApi.getSenderRelationships();

      setRelationships(data ?? []);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getSenderRelationships();
  }, []);

  async function nameLookup(value) {
    setIsLoading(true);
    let msisdn = formatAndSetMsisdn(value);

    try {
      const { data: d } = await restService.ReceiversApi.nameLookup(
          data?.destination_institution_id,
          data?.account_number
      );

      const nameParts = d?.recipient_name?.split(" ") ?? [];
      let [fName, lName, otherName] = ["", "", ""];

      if (nameParts.length === 2) {
        [fName, lName] = nameParts;
      } else if (nameParts.length === 3) {
        [fName, otherName, lName] = nameParts;
      } else if (nameParts.length > 3) {
        fName = nameParts[0];
        otherName = nameParts[1];
        lName = nameParts.slice(2).join(" ");
      }

      const formUpdate = {
        ...data,
        firstname: fName,
        lastname: lName,
        othernames: otherName,
        ...(selectedAccountType !== "bank" && { msisdn })
      };

      setForm(formUpdate);

    } catch (error) {
      console.log(error);

      if (selectedAccountType !== "bank") {
        setForm({
          ...data,
          msisdn
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  function formatAndSetMsisdn(value) {

    let nv = ""
    if (value?.startsWith("0")) {
      nv = value.replace(/^0+/, "+233");
    } else if (value?.startsWith("233")) {
      nv = value.replace(/^233+/, "+233");
    }else{
        nv = value;
    }
    return nv;
  }

  return (
    <div className=" intro-y">
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <fieldset disabled={isLoading} className="flex flex-col gap-4">
          <p className="text-[#727681] text-center text-xs font-normal">
            Please enter the receiver's personal details.
          </p>

          {apiErrors && <ErrorMessages errorData={apiErrors} />}

          <div className="flex flex-col mx-auto justify-center md:max-w-[800px] flex-wrap items-center gap-2 w-full">
            <div className="grid w-full grid-cols-12 items-center flex-wrap gap-4">
              <div className="col-span-12 min-h-[90px] md:col-span-12  w-full  ">
                <Input
                  containerStyle={"w-full md:w-auto"}
                  labelFor={"account_number"}
                  className="md:min-w-[200px] w-full"
                  type="number"
                  value={data?.account_number}
                  onChange={(e) => {
                    onFormChange(e);
                  }}
                  name="account_number"
                  id={"account_number"}
                  labelName={
                    selectedAccountType?.toLowerCase() === "bank"
                      ? "Account Number *"
                      : "Mobile Money Number *"
                  }
                  placeholder={
                    selectedAccountType?.toLowerCase() === "bank"
                      ? "12333445556"
                      : "024000000"
                  }
                  onBlur={(e) => {
                      const value = e.target.value;
                      nameLookup(value);
                  }}
                />
                {errors?.account_number && (
                  <p className="text-red-500 text-[10px] italic pt-1">
                    {errors?.account_number}
                  </p>
                )}
              </div>
            </div>

            <div className="grid md:grid-cols-3 gap-4 w-full">
              <div className="flex flex-col">
                <Input
                  containerStyle={"w-full md:w-auto"}
                  labelFor={"firstname"}
                  id={"firstname"}
                  placeholder="Kojo"
                  labelName={" First Name *"}
                  type="text"
                  value={data?.firstname}
                  onChange={onFormChange}
                  name="firstname"
                />

                {errors?.firstname && (
                  <p className="text-red-500 text-[10px] italic pt-1">
                    {errors?.firstname}
                  </p>
                )}
              </div>

              <div className="flex flex-col">
                <Input
                  containerStyle={"w-full md:w-auto"}
                  labelFor={"OtherNames"}
                  placeholder=""
                  labelName={"Other Names"}
                  type="text"
                  value={data?.othernames}
                  onChange={onFormChange}
                  name="othernames"
                />
                {errors?.othernames && (
                  <p className="text-red-500 text-[10px] italic pt-1">
                    {errors?.othernames}
                  </p>
                )}
              </div>

              <div className="flex flex-col">
                <Input
                  containerStyle={"w-full md:w-auto"}
                  labelFor={"Last Name"}
                  placeholder=""
                  labelName={"Last Name"}
                  type="text"
                  value={data?.lastname}
                  onChange={onFormChange}
                  name="lastname"
                />

                {errors?.lastname && (
                  <p className="text-red-500 text-[10px] italic pt-1">
                    {errors?.lastname}
                  </p>
                )}
              </div>

              <div className="flex flex-col">
                <Input
                  containerStyle={"w-full md:w-auto"}
                  labelFor={"Email"}
                  placeholder="kojo@gmail.com"
                  labelName={"Email"}
                  type="email"
                  value={data?.email}
                  onChange={onFormChange}
                  name="email"
                />

                {errors?.email && (
                  <p className="text-red-500 text-[10px] italic pt-1">
                    {errors?.email}
                  </p>
                )}
              </div>

              <div className="flex flex-col">
                <PhoneInputComponent
                  labelFor={"msisdn"}
                  labelName={"Phone Number"}
                  placeholder="Enter phone number"
                  value={data?.msisdn}
                  onChange={onPhoneNumberChange}
                  name="msisdn"
                  defaultCountry={disbursal_country?.iso_3166_2 ?? "GB"}
                  withCountryCallingCode
                  international={true}
                  className={`flex  text-xs bg-white PhoneInput-2 `}
                  containerStyles={" w-full"}
                  // validationMessage={validationMessage}
                  // handlePhoneInputChange={handlePhoneInput}
                />
                {errors?.msisdn && (
                  <p className="text-red-500 text-[10px] italic pt-1">
                    {errors?.msisdn}
                  </p>
                )}
              </div>

              <div className="flex-col flex gap-1">
                <label className="text-xs font-normal" htmlFor="type">
                  Relationship To Sender
                </label>
                <div className="relative">
                  <select
                    id="type"
                    value={data?.relationship}
                    onChange={onFormChange}
                    name="relationship"
                    className="border appearance-none w-full focus:border-theme-31 outline-none rounded px-4 py-2 border-theme-31"
                  >
                    {relationships.map((relationship) => {
                      return (
                        <option key={relationship} value={relationship}>
                          {relationship}
                        </option>
                      );
                    })}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                {errors?.relationship && (
                  <p className="text-red-500 text-[10px] italic pt-1">
                    {errors?.relationship}
                  </p>
                )}
              </div>
            </div>

            <div className=" flex mt-6  justify-end flex-col gap-8 w-full ">
              <div className="flex justify-end gap-4 ">
                <Button
                  onClick={() => {
                    goBack?.();
                  }}
                  className="btn btn-secondary  flex items-center gap-2 text-xs"
                >
                  Go back
                </Button>
                <Button
                  onClick={onSubmit}
                  className="flex items-center gap-1 bg-theme-32 border border-theme-32 hover:bg-theme-1 transition text-white rounded-md px-3 py-2"
                >
                  <span>Submit</span>
                </Button>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
}
