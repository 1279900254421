/* eslint-disable import/no-anonymous-default-export */
import {LOGGED_OUT_DUE_TO_INACTIVITY} from "../actions/types";

const initialState = {wasIdle: false};

export default function (state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case LOGGED_OUT_DUE_TO_INACTIVITY:
      return {wasIdle: payload};

    default:
      return state;
  }
}
