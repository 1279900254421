import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/auth";
import { setLoggedOutDueToInactivity } from "../../redux/actions/idleMonitor";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const IdleMonitor = ({ children }) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(setLoggedOutDueToInactivity(true));
    dispatch(logout());
    window.location = "/";
  };

  let timer;

  // this function sets the timer that logs out the user after 5mins
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, 1800000); // 30mins
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  const logoutAction = () => {
    handleLogout();
  };

  return <>{children}</>;
};

export default IdleMonitor;
