import { useState } from "react";
import Loader from "../../../../components/common/Loader";
import { DisbursalCountrySelect } from "../../../../components/shared/disbursal-country/disbursal-country-select";
import { useSendMoneyContext } from "../../../../contexts/send-money";

export function SelectDisbursalCountry() {
  const { setSendMoneyData, sendMoneyData, setCurrentStep } =
    useSendMoneyContext();
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="container flex flex-col gap-8">
      {isLoading && <Loader message={"Please wait..."} />}
      <h3 className="text-3xl font-semibold text-theme-1">Select Country</h3>

      <DisbursalCountrySelect
        setIsFetching={setIsLoading}
        onSelect={(country) => {
          setSendMoneyData({
            ...(sendMoneyData ?? {}),
            disbursal_country: country,
          });
          setCurrentStep(2);
        }}
        onFetchSuccess={(res) => {
          if (res.length === 1) {
            setSendMoneyData({
              ...(sendMoneyData ?? {}),
              disbursal_country: res[0],
            });
            setCurrentStep(2);
            return;
          }
        }}
      />
    </div>
  );
}
