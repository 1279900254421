import React, {useReducer, useRef, useState} from "react";
import RestService from "../../../services/restService";
import {useDispatch, useSelector} from "react-redux";
import {useToasts} from "react-toast-notifications";
import {logout} from "../../../redux/actions/auth";
import PasswordField from "../../../components/common/PasswordField/PasswordField";
import Input from "../../../components/common/Input";

const ChangePassword = () => {
  const {addToast} = useToasts();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth?.user);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const handleInputChange = ({target}) => {
    if (target.name === "password") {
      setPassword(target.value);
    } else if (target.name === "oldPassword") {
      setOldPassword(target.value);
    } else {
      setConfirmPassword(target.value);
    }
    setError(undefined);
  };

  const updatePassword = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      password === confirmPassword &&
      password?.length > 7 &&
      password?.toLowerCase() !== password &&
      password.toUpperCase() !== password &&
      /\d/.test(password) &&
      oldPassword
    ) {
      RestService.AgentsApi.changePassword(
        user.id,
        oldPassword,
        password,
        confirmPassword
      )
        .then(() => {
          addToast("Successfully Changed Password. Please log in again", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });

          setTimeout(() => {
            dispatch(logout());
            window.location = "/";
          }, 5000);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="flex md:flex-row flex-col gap-4 items-start border-t border-[#ECECEC] mb-[5rem]  py-5 w-full">
        <div className="flex flex-col items-start flex-[.6]">
          <h4 className="text-base font-medium text-black">Password</h4>
          <p className="text-[#999999] text-xs">
            Enter your current password to change your password. At least 8
            characters, 1 uppercase letter 1 lowercase letter and 1 number
          </p>
        </div>

        <form className="flex-[1.4] flex gap-8 items-start w-full justify-start flex-col">
          <div className="grid gap-8 w-full md:w-[70%] mx-auto grid-cols-1 md:grid-cols-2">
            <div className="flex gap-8 items-center w-full justify-center flex-col">
              <div className="w-full">
                <Input
                  containerStyle={"w-full"}
                  labelName={"Old Password"}
                  name={"oldPassword"}
                  onChange={(e) => setOldPassword(e.target.value)}
                  type="password"
                />
              </div>
              <div className="w-full">
                <Input
                  containerStyle={"w-full"}
                  onChange={handleInputChange}
                  placeholder="Password"
                  labelName={"New Password"}
                  name={"password"}
                  type="password"
                />
              </div>
              <div className="w-full">
                <Input
                  containerStyle={"w-full"}
                  onChange={handleInputChange}
                  placeholder="Confirm Password"
                  name={"confirmPassword"}
                  labelName={"Confirm New Password"}
                  type="password"
                  // validationMessage={error}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex items-end justify-end  md:w-[85%]">
            <button onClick={updatePassword} className="btn btn-primary-2">
              Update Password
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
