import { Combobox } from "@headlessui/react";
import React, { useEffect, useMemo, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { ArrowLeft, Check } from "react-feather";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Button from "../../../../components/common/Button";
import SelectComponent from "../../../../components/common/Select";
import { useSendMoneyContext } from "../../../../contexts/send-money";
import {
  default as RestService,
  default as restService,
} from "../../../../services/restService";
import { Title } from "./components/title";

const SendMoney = () => {
  const {
    sendMoneyData: { disbursal_country },
    agentCommission,
    setAgentCommission,
    companyCommission,
    setCompanyCommission,
    agencyCommission,
    setAgencyCommission,
    charge,
    setCharge,
    agentCommissionAmount,
    setAgentCommissionAmount,
    companyCommissionAmount,
    setCompanyCommissionAmount,
    agencyCommissionAmount,
    setAgencyCommissionAmount,
    receiveAmount,
    setReceiveAmount,
    amountToPay,
    setAmountToPay,
    rate,
    setRate,
    agencyCurrency,
    setCurrentStep,
    sendAmount,
    setSendAmount,
    setTransferReason,
    transferReason,
  } = useSendMoneyContext();

  const agent = useSelector((state) => state.auth?.user);
  const { addToast } = useToasts();

  const [rates, setRates] = useState([]);
  const [searchReasonQuery, setSearchReasonQuery] = useState("");
  const [transferReasons, setTransferReasons] = useState([]);

  const getCharge = () => {
    RestService.TransactionsApi.getChargeByAmount(
      sendAmount,
      agent?.agency?.country.currency_code,
      disbursal_country?.currency_code
    )
      .then(({ data }) => {
        let charges = data?.data;
        let chargeAmount = 0;

        if (charges) {
          chargeAmount =
            charges.fixed_charge_amount +
            (sendAmount * charges.percentage_charge_amount) / 100;
          setCharge(chargeAmount);
        }
      })
      .catch((err) => {
        setCharge(0);
      });
  };

  useEffect(() => {
    getCharge();
  }, [sendAmount]);

  const getChargeAsync = async (amount) => {
    return await RestService.TransactionsApi.getChargeByAmount(
      amount,
      agent?.agency?.country.currency_code,
      disbursal_country?.currency_code
    );
  };

  useEffect(() => {
    RestService.TransactionsApi.getRates(
        agent?.agency?.country.currency_code,
        disbursal_country?.currency_code
    ).then(({ data }) => {
      setRate(data?.data?.data?.[0].rate ? data.data.data[0].rate : 1);
    });

    restService.RatesApi.getRatesBySourceCurrency(
        agent?.agency?.country.currency_code
    )
      .then(({ data }) => {
        setRates(data?.data?.data);
      })
      .catch((err) => {});

    restService.UtilsApi.getTransferReasons()
      .then(({ data }) => {
        setTransferReasons(data?.data);
      })
      .catch((err) => {});

    restService.CommissionsConfigApi.getCommissionConfigurationByAgency(
      agent?.agency?.id
    ).then(({ data }) => {
      setAgencyCommission(data?.data.data?.[0]?.agency_percentage);
      setAgentCommission(data?.data.data?.[0]?.agent_percentage);
      setCompanyCommission(data?.data.data?.[0]?.company_percentage);
      // setCommissionConfigurationId(data?.data.data?.[0]?.id);
    });
  }, []);

  useEffect(() => {
    setAgentCommissionAmount(
      agentCommission ? (agentCommission / 100) * charge : 0
    );
    setAgencyCommissionAmount(
      agencyCommission ? (agencyCommission / 100) * charge : 0
    );
    setCompanyCommissionAmount(
      companyCommission ? (companyCommission / 100) * charge : 0
    );
  }, [charge, agentCommission, agencyCommission]);

  const handleReasonSelect = (selectedOption) => {
    setTransferReason(selectedOption.label);
  };

  const handleSendAmount = async (sendAmount) => {
    sendAmount = Number(sendAmount);
    if (sendAmount === 0) {
      setReceiveAmount(0);
      setSendAmount(0);
      return;
    }

    setSendAmount(sendAmount?.toFixed(2));
    try {
      // const chargeData = await getChargeAsync(sendAmount);
      // const charges = chargeData?.data?.data;
      //
      // if (charges) {
      //   let chargeAmount =
      //     charges.fixed_charge_amount +
      //     (sendAmount * charges.percentage_charge_amount) / 100;
      //   setCharge(chargeAmount);
      //   setReceiveAmount(Number((sendAmount - chargeAmount) * rate).toFixed(2))
      // } else {
      //   setCharge(0);
      //   setReceiveAmount(Number((sendAmount - charge) * rate).toFixed(2))
      //   // setReceiveAmount(Number(sendAmount * rate).toFixed(2));
      // }
      setReceiveAmount(Number(sendAmount * rate).toFixed(2))
    } catch (err) {
      setReceiveAmount(Number(sendAmount * rate).toFixed(2));
      // setCharge(0);
    }
  };

  const handleReceiveAmount = async (receiveAmount) => {
    if (receiveAmount === 0) {
      setSendAmount(0);
      setReceiveAmount(0);
      return;
    }
    setReceiveAmount(Number(receiveAmount).toFixed(2));

    try {
      // const chargeData = await getChargeAsync(receiveAmount / rate);
      // const charges = chargeData?.data?.data;
      // if (charges) {
      //   let chargeAmount =
      //     charges.fixed_charge_amount +
      //     (sendAmount * charges.percentage_charge_amount) / 100;
      //   setCharge(chargeAmount);
      // }
      setSendAmount(Number(receiveAmount / rate).toFixed(2));
    } catch (err) {
      setSendAmount(Number(receiveAmount / rate).toFixed(2));
      // setCharge(0);
    }
  };

  useEffect(() => {
    setAmountToPay((Number(sendAmount) + Number(charge)).toFixed(2));
  }, [charge, sendAmount]);

  const transferOptions = useMemo(() => {
    return transferReasons.map((val) => {
      return { label: val, value: val };
    });
  }, [transferReasons]);

  const filteredReason = useMemo(() => {
    return searchReasonQuery === ""
      ? transferOptions
      : transferOptions.filter((item) =>
          item?.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(searchReasonQuery.toLowerCase().replace(/\s+/g, ""))
        );
  }, [transferOptions, searchReasonQuery]);

  return (
    <>
      <div className="flex items-center gap-4">
        <Button
          onClick={() => setCurrentStep((prev) => prev - 1)}
          className="h-6 w-6 bg-white rounded-full  flex items-center gap-2"
        >
          <ArrowLeft size={20} />
        </Button>

        {/* <Title title={"Amount"} /> */}
      </div>
      <div className="intro-y flex flex-col lg:flex-row items-center max-w-5xl mx-auto gap-6">
        <div className="w-full lg:w-3/5 bg-white border border-[#DEDEDE] flex flex-col py-7 px-4 gap-6">
          <div className="flex md:flex-row flex-col py-6 gap-6 text-theme-32 items-center justify-between">
            <div className="flex flex-col gap-2 items-center">
              <p className="text-sm font-[500]">Send amount</p>
              <CurrencyInput
                  allowNegativeValue={false}
                  autoFocus
                  name="amountToSend"
                  placeholder={`${agencyCurrency?.symbol ?? "$"}0.00`}
                  defaultValue={"0.00"}
                  value={sendAmount ? sendAmount : ""}
                  decimalsLimit={2}
                  onValueChange={(value) =>
                      !isNaN(Number(value)) && handleSendAmount(Number(value))
                  }
                  className="text-center w-full border-none amount-to-send  outline-none focus:border-none text-[3rem] font-semibold"
                  prefix={agencyCurrency?.symbol}
                  step={"0.1"}
                  min={0}
              />
            </div>

            <div className="flex flex-col gap-2 items-center">
              <p className="text-sm font-[500]">Receive amount</p>
              <CurrencyInput
                  name="receiveAmount"
                  placeholder={`${disbursal_country?.currency_symbol ?? "$"}0.00`}
                  defaultValue={"0.00"}
                  value={receiveAmount ? receiveAmount : ""}
                  decimalsLimit={2}
                  onValueChange={(value) =>
                      !isNaN(Number(value)) && handleReceiveAmount(Number(value))
                  }
                  className="text-center w-full border-none amount-to-send  outline-none focus:border-none text-[3rem] font-semibold"
                  prefix={disbursal_country?.currency_symbol}
                  step={"0.1"}
                  min={0}
              />
            </div>
          </div>

          <ul className="border border-[#DEDEDE] rounded flex flex-col">
            <li className="flex items-center cursor-pointer justify-between px-6 py-3">
              <div className="flex flex-col w-full items-start gap-2">
                <div className="flex w-full items-center gap-2">
                  <SelectComponent
                      labelName={"Transfer Reason"}
                      data={filteredReason}
                      searchQuery={searchReasonQuery}
                      setSearchQuery={setSearchReasonQuery}
                      displayValue={(item) => transferReason}
                      onChange={(e) => handleReasonSelect(e)}
                      value={transferReason}
                  >
                    {filteredReason.length === 0 && !searchReasonQuery && (
                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                          Nothing found.
                        </div>
                    )}

                    {filteredReason.length === 0 && searchReasonQuery !== "" ? (
                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                          Nothing found.
                        </div>
                    ) : (
                        filteredReason.map((item, index) => (
                            <Combobox.Option
                                key={index}
                                className={({active}) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                        active
                                            ? "text-[#0042C6] bg-[#0042C61a]"
                                            : "text-gray-900"
                                    }`
                                }
                                value={item}
                            >
                              {({selected, active}) => (
                                  <>
                              <span
                                  className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                  }`}
                              >
                                {item?.label}
                              </span>
                                    {selected ? (
                                        <span
                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                active ? "text-black" : ""
                                            }`}
                                        >
                                  <Check
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                  />
                                </span>
                                    ) : null}
                                  </>
                              )}
                            </Combobox.Option>
                        ))
                    )}
                  </SelectComponent>
                </div>
              </div>
            </li>

            <hr className="bg-[#DEDEDE] text-[#DEDEDE] border-[#DEDEDE]"/>
          </ul>

          <div className="">
            <Button
                disabled={sendAmount <= 0 || receiveAmount <= 0 || !transferReason}
                onClick={() => {
                  if (!transferReason) {
                    addToast("Please select a transfer reason", {
                      appearance: "error",
                      autoDismiss: true,
                      autoDismissTimeout: 5000,
                    });

                    return;
                  }

                  setCurrentStep(6);
                }}
                className={"primary-btn-2 bg-[#0042C6] w-full justify-center"}
            >
              Send Money
            </Button>
          </div>
        </div>
        <div className="w-full lg:w-2/5 intro-y col-span-12 lg:col-span-3">
          <div className="intro-y box bg-theme-1 p-5">
            <button className="w-full text-white dark:bg-theme-1 text-lg">
              Summary
            </button>

            <div className="border-t border-theme-3 dark:border-dark-5 mt-6 pt-6 text-white">

              <div className="w-full justify-center items-center px-5">
                <>
                  <div className="flex mt-4 items-center pt-4 border-t border-gray-200 dark:border-dark-5">
                    <div className="mr-auto">Exchange Rate</div>
                    {agencyCurrency?.symbol} 1 ={" "}
                    {disbursal_country?.currency_symbol} {rate}
                  </div>
                  <div className="flex mt-4 items-center pt-4 border-t border-gray-200 dark:border-dark-5">
                    <div className="mr-auto">Charge</div>
                    <div className="font-medium">{" "}
                      {agencyCurrency?.symbol} {charge?.toFixed(2)}</div>
                  </div>

                  <div className="flex mt-4">
                    <div className="mr-auto">Admin Commission</div>
                    <div className="font-medium">
                      {" "}
                      {agencyCurrency?.symbol} {companyCommissionAmount?.toFixed(2)}
                    </div>
                  </div>
                  <div className="flex mt-4">
                    <div className="mr-auto">Agent Commission</div>
                    <div className="font-medium">
                      {" "}
                      {agencyCurrency?.symbol} {agentCommissionAmount?.toFixed(2)}
                    </div>
                  </div>
                  <div className="flex mt-4">
                    <div className="mr-auto">Agency Commission</div>
                    <div className="font-medium">
                      {" "}
                      {agencyCurrency?.symbol} {agencyCommissionAmount?.toFixed(2)}
                    </div>
                  </div>
                </>
                <div className="flex mt-4 items-center">
                  <div className="mr-auto font-medium text-base">Amount Payable</div>
                  <div className="font-extrabold text-4xl">{agencyCurrency?.symbol} {Number(amountToPay).toFixed(2)}</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendMoney;
