import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import staffData from "../../../assets/static_json/staff.json";
import roles from "../../../assets/static_json/roles.json";
import PageTitle from "../../../components/common/PageTitle/PageTitle";
import TransactionStyles from "../../AgentViews/Transactions/styles";
import Table from "../../../components/common/Table";
import Modal from "../../../components/common/Modal";
import UpdateStaff from "./UpdateStaff";
import ReactTooltip from "react-tooltip";
import restService from "../../../services/restService";
import {useToasts} from "react-toast-notifications";
import Search from "../../../components/common/Search";
import {Pagination} from "antd";
import PaginationComponent from "../../../components/common/Pagination";
import SkeletonElement from "../../../components/Skeletons/SkeletonElement";
import Table2 from "../../../components/common/Table/Table2";
import Modal2 from "../../../components/common/Modal/Modal2";
import Input from "../../../components/common/Input";
import AddNewStaff from "../../../components/common/AddNewStaff";

const moment = require("moment");

const renderHeadData = (item, index) => {
  return <th key={index}>{item}</th>;
};

const Staff = () => {
  const {addToast} = useToasts();
  const [staff, setStaff] = useState([]);
  const [staffToRemove, setStaffToRemove] = useState(undefined);
  const [staffToUpdate, setStaffToUpdate] = useState(undefined);
  const [callback, setCallback] = useState(() => {});
  const [staffToSuspend, setStaffToSuspend] = useState(undefined);
  const [staffToActivate, setStaffToActivate] = useState(undefined);
  const [pageTo, setPageTo] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [from, setFrom] = useState(0);
  const [topUpEValue, setTopUpEValue] = useState(undefined);
  const [topUpEValueAmount, setTopUpEValueAmount] = useState(0);
  const [reduceProfile, setReduceProfile] = useState(undefined);
  const [reduceProfileAmount, setReduceProfileAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [inputAmountError, setInputAmountError] = useState(false);
  const [showAddNewStaff, setShowAddNewStaff] = useState(false);

  const onStaffUpdate = () => {
    setStaffToUpdate(undefined);
    addToast("Successfully Updated Staff", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 5000,
    });
    fetchStaff(currentPage);
  };

  const renderBodyData = (item, index) => {
    return (
      <tr key={index}>
        <td className="id"> {item.id}</td>
        <td>
          <div className="flex">
            <div className="w-12 h-12 image-fit lg:mr-1">
              <img
                alt="#"
                className="rounded-full"
                src="https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png"
              />
            </div>
            <div className="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
              <div className="font-medium">{`${item.firstname} ${item.lastname}`}</div>
              <div className={"text-xs mt-0.5 text-green-400"}>
                {" "}
                {item.staff_role.name}
              </div>
              {item.staff_role.name === "Payout Agent" ? (
                <>
                  <p className={"text-xs mt-0.5 text-gray-400"}>
                    Actual Balance:{" "}
                    <span className={"text-black"}>
                      {item.country?.currency_symbol} {item.actual_balance}
                    </span>
                  </p>
                  <p className={"text-xs mt-0.5 text-gray-400"}>
                    Available Balance:{" "}
                    <span className={"text-black"}>
                      {item.country?.currency_symbol} {item.balance_available}
                    </span>
                  </p>
                  <p className={"text-xs mt-0.5 text-gray-400"}>
                    Balance Owed:{" "}
                    <span className={"text-black"}>
                      {item.country?.currency_symbol} {item.balance_owed}
                    </span>
                  </p>
                </>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </td>
        <td>
          <div>{item.address}</div>
        </td>
        <td>
          <div>{item.msisdn}</div>
        </td>
        <td>{moment(new Date(item.created_at)).format("MMMM DD, YYYY")}</td>
        <td>
          <div className="whitespace-nowrap">
            <button
              disabled={!(item.staff_role.name === "Payout Agent")}
              data-tip="Topup Staff e-value"
              onClick={() => {
                setTopUpEValue(item);
              }}
              className="btn pt-1 mr-3 border-none outline-none shadow-none focus:outline-none transaction-details"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-7 w-7"
                viewBox="0 0 24 24"
                fill="green"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              disabled={!(item.staff_role.name === "Payout Agent")}
              data-tip="Reduce Staff Debt Profile"
              onClick={() => {
                setReduceProfile(item);
              }}
              className="btn pt-1 mr-3 border-none outline-none shadow-none focus:outline-none transaction-details"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-7 w-7"
                viewBox="0 0 24 24"
                fill="red"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              data-tip="Update"
              onClick={() => {
                setStaffToUpdate(item);
              }}
              className="btn mr-3 border-none outline-none shadow-none focus:outline-none transaction-details"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </button>
            {item.status === "active" && (
              <button
                data-tip="Suspend"
                onClick={() => {
                  setStaffToSuspend(item);
                }}
                className="btn mr-3 border-none outline-none shadow-none focus:outline-none transaction-details"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="#d32929"
                  viewBox="0 0 24 24"
                  stroke="white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            )}
            {item.status !== "active" && (
              <button
                data-tip="Activate"
                onClick={() => {
                  setStaffToActivate(item);
                }}
                className="btn mr-3 border-none outline-none shadow-none focus:outline-none transaction-details"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="green"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            )}
            <button
              data-tip="Remove"
              onClick={() => {
                setStaffToRemove(item);
              }}
              className="btn outline-none border-none  shadow-none focus:outline-none transaction-details"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                fill="#d32929"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <ReactTooltip backgroundColor="#f1f5f8" textColor="black" />
          </div>
        </td>
      </tr>
    );
  };

  const topUp = () => {
    if (
      !topUpEValueAmount ||
      isNaN(topUpEValueAmount) ||
      topUpEValueAmount <= 0
    ) {
      setInputAmountError(true);

      return;
    }

    let data = {amount: topUpEValueAmount};
    restService.EValueAPi.topUpStaff(data, topUpEValue.id)
      .then((response) => {
        addToast("Successfully topped up staff e-value", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
        fetchStaff(currentPage);
      })
      .finally(() => {
        setTopUpEValueAmount(0);
        setTopUpEValue(undefined);
      });
  };

  const reduceDebt = () => {
    if (
      !reduceProfileAmount ||
      isNaN(reduceProfileAmount) ||
      reduceProfileAmount <= 0 ||
      reduceProfile.balance_owed - reduceProfileAmount < 0
    ) {
      setInputAmountError(true);
      return;
    }

    let data = {amount: reduceProfileAmount};
    restService.EValueAPi.reduceStaffDebt(data, reduceProfile.id)
      .then((response) => {
        addToast("Successfully reduced staff debt profile", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
        fetchStaff(currentPage);
      })
      .finally(() => {
        setReduceProfileAmount(0);
        setReduceProfile(undefined);
      });
  };

  const fetchStaff = (page) => {
    setLoading(true);
    restService.StaffApi.getStaff(page)
      .then((response) => {
        if (response) {
          setStaff(response.data.data.data);
          setPageTo(response.data?.data?.to);
          setTotalEntries(response.data?.data?.total);
          setFrom(response.data?.data?.from);
        }
      })
      .finally(() => setLoading(false));
  };
  const suspendStaff = () => {
    let data = {id: staffToSuspend.id, status: "suspended"};
    restService.StaffApi.updateStaff(data).then((response) => {
      addToast("Successfully Suspended Staff", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      setStaffToSuspend(undefined);
      fetchStaff(currentPage);
    });
  };

  const activateStaff = () => {
    let data = {id: staffToActivate.id, status: "active"};
    restService.StaffApi.updateStaff(data).then((response) => {
      addToast("Successfully activated Staff", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      setStaffToActivate(undefined);
      fetchStaff(currentPage);
    });
  };

  const removeStaff = () => {
    let data = {id: staffToRemove.id};
    restService.StaffApi.removeStaff(data).then((response) => {
      addToast("Successfully Removed Staff", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      setStaffToRemove(undefined);
      fetchStaff(currentPage);
    });
  };
  useEffect(() => {
    fetchStaff(currentPage);
  }, []);

  const pageChange = (page) => {
    setCurrentPage(page);
    fetchStaff(page);
  };

  // console.log(staff);

  const defaultImg =
    "https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png";

  const fullImage = (user) =>
    user?.identifications?.find((x) =>
      x?.identification_category?.name?.toLowerCase()?.includes("full image")
    )?.resource_path ?? defaultImg;

  return (
    // <div className="mt-13-important">
    //   <TransactionStyles>
    //     <PageTitle title={`Staff`} />
    //     <div className="content-layout">
    //       <div className="header-layout flex justify-between">
    //         <div className="inline-flex">
    //           <Link
    //             to={`/add-staff`}
    //             className="btn btn-primary shadow-md mr-2"
    //           >
    //             Add New Staff
    //           </Link>
    //         </div>
    //         <div className="page inline-flex">
    //           {staff && staff.length <= 0
    //             ? "No Charges Found"
    //             : `Showing ${from} to ${pageTo} of ${totalEntries} entries`}
    //         </div>
    //       </div>

    //       <div className="table-container">
    //         <Table
    //           headData={staffData.headData}
    //           renderHead={(item, index) => renderHeadData(item, index)}
    //           bodyData={staff}
    //           renderBody={(item, index) => renderBodyData(item, index)}
    //         />
    //       </div>
    //     </div>
    //     <div className="mt-3 float-right">
    //       <Pagination
    //         current={currentPage}
    //         defaultCurrent={currentPage}
    //         showSizeChanger={false}
    //         onChange={pageChange}
    //         total={totalEntries}
    //       />
    //     </div>
    //   </TransactionStyles>

    //   {staffToRemove && (
    //     <Modal
    //       onClick={removeStaff}
    //       modalTitle="Remove Staff"
    //       buttonTitle="Remove"
    //       buttonColor={"bg-red-600"}
    //       size="small"
    //       body={
    //         <p className="text-sm text-gray-500">
    //           Are you sure you want to remove{" "}
    //           {`${staffToRemove.firstname} ${staffToRemove.lastname}`}? This
    //           action cannot be undone.
    //         </p>
    //       }
    //       onClose={() => {
    //         setStaffToRemove(undefined);
    //       }}
    //     />
    //   )}

    //   {staffToUpdate && (
    //     <Modal
    //       onClick={callback}
    //       modalTitle="Update Staff"
    //       size={"small"}
    //       buttonTitle="Update Staff"
    //       buttonColor="bg-blue-600"
    //       body={
    //         <UpdateStaff
    //           staff={staffToUpdate}
    //           onSubmit={onStaffUpdate}
    //           initCallback={(callbackFunc) => setCallback(() => callbackFunc)}
    //         />
    //       }
    //       onClose={() => {
    //         setStaffToUpdate(undefined);
    //       }}
    //     />
    //   )}

    //   {staffToSuspend && (
    //     <Modal
    //       onClick={suspendStaff}
    //       modalTitle="Suspend Staff"
    //       buttonTitle="Suspend"
    //       buttonColor={"bg-red-600"}
    //       size="small"
    //       body={
    //         <p className="text-sm text-gray-500">
    //           Are you sure you want to suspend{" "}
    //           {`${staffToSuspend.firstname} ${staffToSuspend.lastname}`}?
    //         </p>
    //       }
    //       onClose={() => {
    //         setStaffToSuspend(undefined);
    //       }}
    //     />
    //   )}

    //   {staffToActivate && (
    //     <Modal
    //       onClick={activateStaff}
    //       modalTitle="Activate Staff"
    //       buttonTitle="Activate"
    //       buttonColor={"bg-green-600"}
    //       size="small"
    //       body={
    //         <p className="text-sm text-gray-500">
    //           Are you sure you want to activate{" "}
    //           {`${staffToActivate.firstname} ${staffToActivate.lastname}`}?
    //         </p>
    //       }
    //       onClose={() => {
    //         setStaffToActivate(undefined);
    //       }}
    //     />
    //   )}

    //   {topUpEValue && (
    //     <Modal
    //       onClick={topUp}
    //       disableButton={
    //         !topUpEValueAmount ||
    //         isNaN(topUpEValueAmount) ||
    //         topUpEValueAmount <= 0
    //       }
    //       modalTitle="Top up staff e-value"
    //       buttonTitle="Top up"
    //       buttonColor={"bg-green-600"}
    //       size="small"
    //       body={
    //         <div className="grid grid-cols-12 gap-4 gap-y-5 ">
    //           <div className="intro-y col-span-12">
    //             <label htmlFor="input-wizard-1" className="form-label">
    //               Amount *
    //             </label>
    //             <input
    //               id="input-wizard-1"
    //               type="number"
    //               value={topUpEValueAmount}
    //               onChange={(e) => {
    //                 setTopUpEValueAmount(e.target.value);
    //               }}
    //               name="reduce"
    //               className="form-control"
    //             />
    //             <p className="text-red-500 text-xs italic pt-1">
    //               {!topUpEValueAmount ||
    //               isNaN(topUpEValueAmount) ||
    //               topUpEValueAmount <= 0
    //                 ? "Enter a valid number greater than 0"
    //                 : ""}
    //             </p>
    //           </div>
    //         </div>
    //       }
    //       onClose={() => {
    //         setTopUpEValue(undefined);
    //         setTopUpEValueAmount(0);
    //       }}
    //     />
    //   )}

    //   {reduceProfile && (
    //     <Modal
    //       onClick={reduceDebt}
    //       disableButton={
    //         !reduceProfileAmount ||
    //         isNaN(reduceProfileAmount) ||
    //         reduceProfileAmount <= 0 ||
    //         reduceProfile.balance_owed - reduceProfileAmount < 0
    //       }
    //       modalTitle="Reduce staff debt profile"
    //       buttonTitle="Reduce debt"
    //       buttonColor={"bg-green-600"}
    //       size="small"
    //       body={
    //         <div className="mt-5">
    //           <div className="grid grid-cols-12 gap-4 gap-y-5 ">
    //             <div className="intro-y col-span-12">
    //               <p className="mb-2 text-md leading-3 font-medium text-gray-900">
    //                 Current debt: {reduceProfile.country?.currency_symbol}
    //                 {reduceProfile.balance_owed}
    //               </p>

    //               <label htmlFor="input-wizard-1" className="form-label">
    //                 Amount *
    //               </label>
    //               <input
    //                 id="input-wizard-1"
    //                 type="number"
    //                 value={reduceProfileAmount}
    //                 max={reduceProfile.balance_owed}
    //                 onChange={(e) => {
    //                   setReduceProfileAmount(e.target.value);
    //                 }}
    //                 name="reduce"
    //                 className="form-control"
    //               />
    //               <p className="text-red-500 text-xs italic pt-1">
    //                 {!reduceProfileAmount ||
    //                 isNaN(reduceProfileAmount) ||
    //                 reduceProfileAmount <= 0
    //                   ? "Enter a valid number greater than 0"
    //                   : ""}
    //               </p>
    //               <p className="text-red-500 text-xs italic pt-1">
    //                 {reduceProfile.balance_owed - reduceProfileAmount < 0
    //                   ? "Cannot reduce a debt to an mount less than 0"
    //                   : ""}
    //               </p>
    //             </div>
    //           </div>
    //         </div>
    //       }
    //       onClose={() => {
    //         setReduceProfile(undefined);
    //         setReduceProfileAmount(0);
    //       }}
    //     />
    //   )}
    // </div>

    <div className="mt-13-important flex flex-col gap-4">
      <div className="header-layout flex items-center justify-between sm:flex-row flex-col gap-2">
        <div className="flex md:items-center w-full gap-2 md:flex-row flex-col ">
          <Search
            placeholder={"Search "}
            // onChange={(e) => searchChargesByInput(e.target.value)}
          />
        </div>

        <div className=" flex flex-col-reverse w-full justify-end sm:flex-row md:items-center">
          <button
            onClick={() => setShowAddNewStaff(true)}
            className="btn btn-primary-2  flex items-center gap-2 "
          >
            <span>
              <svg
                width="12"
                height="11"
                viewBox="0 0 12 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.98438 0.986328C6.24237 0.986328 6.45151 1.19547 6.45151 1.45347V5.19058H10.1886C10.4466 5.19058 10.6558 5.39972 10.6558 5.65771C10.6558 5.91571 10.4466 6.12485 10.1886 6.12485H6.45151V9.86196C6.45151 10.12 6.24237 10.3291 5.98438 10.3291C5.72638 10.3291 5.51724 10.12 5.51724 9.86196V6.12485H1.78013C1.52213 6.12485 1.31299 5.91571 1.31299 5.65771C1.31299 5.39972 1.52213 5.19058 1.78013 5.19058H5.51724V1.45347C5.51724 1.19547 5.72638 0.986328 5.98438 0.986328Z"
                  fill="black"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>

            <span> Add New Staff</span>
          </button>
        </div>
      </div>
      <TransactionStyles>
        <div className="header-layout gap-2">
          {!loading ? (
            <div className="flex w-full flex-col">
              <Table2
                // headData={head}
                tHead={
                  <>
                    <th className="">ID</th>
                    <th className="">Employee</th>
                    <th className="">Address</th>
                    <th className="">Date</th>
                    <th className="">Phone Number</th>
                    <th className="">Action</th>
                  </>
                }
                // renderHead={(item, index) => renderHeadData(item, index)}
              >
                {staff.map((item, index) => (
                  <tr key={index} className=" text-[#616161] font-normal">
                    <td>
                      <p className="">{item.id}</p>
                    </td>
                    <td>
                      <div className="flex items-center gap-2">
                        <span>
                          <img
                            src={fullImage()}
                            alt=""
                            className="w-[30px] h-[30px] object-cover rounded-full"
                          />
                        </span>
                        <p className="flex flex-col">
                          <span className="text-black font-medium">{`${item.firstname} ${item.lastname}`}</span>
                          <span className="text-[10px] text-[#377E22]">
                            {item.staff_role.name}
                          </span>
                        </p>
                      </div>
                    </td>
                    <td>
                      <p className="whitespace-nowrap max-w-[180px] overflow-hidden overflow-ellipsis">
                        {item.address}
                      </p>
                    </td>
                    <td>
                      <p className="">
                        {moment(new Date(item.created_at)).format(
                          "MMMM DD, YYYY"
                        )}
                      </p>
                    </td>
                    <td>
                      <p className="">{item.msisdn}</p>
                    </td>
                    <td>
                      <div className="flex items-center gap-8">
                        {item.status === "active" ? (
                          <button
                            data-tip="Suspend"
                            onClick={() => {
                              setStaffToSuspend(item);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="#d32929"
                              viewBox="0 0 24 24"
                              stroke="white"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </button>
                        ) : (
                          <button
                            data-tip="Activate"
                            onClick={() => {
                              setStaffToActivate(item);
                            }}
                          >
                            <svg
                              width="17"
                              height="18"
                              viewBox="0 0 17 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="8.33333"
                                cy="9.00033"
                                r="8.33333"
                                fill="#377E22"
                              />
                              <path d="M6 6L11 9L6 12L6 6Z" fill="white" />
                            </svg>
                          </button>
                        )}

                        <button
                          data-tip="Top Up Staff E-Value"
                          onClick={() => {
                            setTopUpEValue(item);
                          }}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="8.99984"
                              cy="9.00033"
                              r="8.33333"
                              fill="#377E22"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.33639 4.98043C9.15043 4.78517 8.84892 4.78517 8.66296 4.98043L5.80581 7.98043C5.61985 8.17569 5.61985 8.49228 5.80581 8.68754C5.99178 8.8828 6.29329 8.8828 6.47925 8.68754L8.52348 6.54109V11.834C8.52348 12.1101 8.73668 12.334 8.99967 12.334C9.26267 12.334 9.47586 12.1101 9.47586 11.834V6.54109L11.5201 8.68754C11.7061 8.8828 12.0076 8.8828 12.1935 8.68754C12.3795 8.49228 12.3795 8.17569 12.1935 7.98043L9.33639 4.98043Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                        <button
                          data-tip="Reduce staff debt profile"
                          onClick={() => {
                            setReduceProfile(item);
                          }}
                        >
                          <svg
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="8.66683"
                              cy="9.00033"
                              r="8.33333"
                              fill="#EA3323"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8.33011 12.1875C8.51608 12.3828 8.81758 12.3828 9.00355 12.1875L11.8607 9.18754C12.0467 8.99228 12.0467 8.67569 11.8607 8.48043C11.6747 8.28517 11.3732 8.28517 11.1873 8.48043L9.14302 10.6269V5.33398C9.14302 5.05784 8.92982 4.83398 8.66683 4.83398C8.40384 4.83398 8.19064 5.05784 8.19064 5.33398V10.6269L6.1464 8.48043C5.96044 8.28517 5.65893 8.28517 5.47297 8.48043C5.28701 8.67569 5.28701 8.99228 5.47297 9.18754L8.33011 12.1875Z"
                              fill="white"
                            />
                          </svg>
                        </button>

                        <button
                          data-tip="Update Staff"
                          onClick={() => {
                            setStaffToUpdate(item);
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.679 0.282841C15.6234 0.22173 15.556 0.17253 15.4808 0.138214C15.4057 0.103897 15.3243 0.0851777 15.2417 0.0831855C15.1591 0.0811933 15.0769 0.0959699 15.0002 0.126623C14.9235 0.157275 14.8538 0.203168 14.7953 0.261526L14.3244 0.73006C14.2674 0.787157 14.2353 0.864575 14.2353 0.945297C14.2353 1.02602 14.2674 1.10344 14.3244 1.16053L14.7561 1.59139C14.7843 1.61981 14.818 1.64237 14.855 1.65776C14.892 1.67315 14.9318 1.68107 14.9719 1.68107C15.012 1.68107 15.0517 1.67315 15.0887 1.65776C15.1257 1.64237 15.1594 1.61981 15.1877 1.59139L15.6467 1.13465C15.8789 0.902858 15.9006 0.525291 15.679 0.282841ZM13.3725 1.68159L6.50171 8.54023C6.46006 8.58172 6.42978 8.63323 6.41379 8.68981L6.09598 9.63639C6.08837 9.66208 6.08783 9.68934 6.09442 9.71531C6.10101 9.74127 6.11449 9.76498 6.13343 9.78392C6.15237 9.80286 6.17608 9.81634 6.20204 9.82293C6.22801 9.82952 6.25527 9.82898 6.28096 9.82137L7.22678 9.50356C7.28336 9.48757 7.33487 9.4573 7.37636 9.41564L14.235 2.54406C14.2985 2.47993 14.334 2.39335 14.334 2.30313C14.334 2.21291 14.2985 2.12634 14.235 2.0622L13.8563 1.68159C13.7921 1.61756 13.7051 1.5816 13.6144 1.5816C13.5237 1.5816 13.4367 1.61756 13.3725 1.68159Z"
                              fill="#4F5664"
                            />
                            <path
                              d="M12.8776 5.62664L8.23836 10.2751C8.05905 10.4548 7.83867 10.5882 7.59626 10.6637L6.61048 10.9937C6.37653 11.0597 6.12919 11.0622 5.89396 11.0009C5.65872 10.9395 5.4441 10.8166 5.2722 10.6447C5.1003 10.4728 4.97735 10.2582 4.916 10.0229C4.85466 9.78769 4.85716 9.54035 4.92322 9.3064L5.25321 8.32061C5.32848 8.07828 5.46159 7.85791 5.64106 7.67852L10.2895 3.03847C10.3321 2.99591 10.3611 2.94167 10.3729 2.88261C10.3847 2.82355 10.3787 2.76232 10.3557 2.70666C10.3326 2.65101 10.2936 2.60344 10.2436 2.56995C10.1935 2.53647 10.1347 2.51858 10.0744 2.51855H2.13143C1.56614 2.51855 1.024 2.74312 0.624282 3.14284C0.224561 3.54256 0 4.08469 0 4.64998V13.7847C0 14.35 0.224561 14.8921 0.624282 15.2918C1.024 15.6916 1.56614 15.9161 2.13143 15.9161H11.2661C11.8314 15.9161 12.3736 15.6916 12.7733 15.2918C13.173 14.8921 13.3976 14.35 13.3976 13.7847V5.84168C13.3975 5.78146 13.3796 5.72259 13.3462 5.67253C13.3127 5.62248 13.2651 5.58347 13.2094 5.56045C13.1538 5.53743 13.0926 5.53144 13.0335 5.54322C12.9744 5.555 12.9202 5.58403 12.8776 5.62664Z"
                              fill="#4F5664"
                            />
                          </svg>
                        </button>
                        <button
                          data-tip="Remove Staff"
                          onClick={() => {
                            setStaffToRemove(item);
                          }}
                        >
                          <svg
                            width="12"
                            height="16"
                            viewBox="0 0 12 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.6667 1.33333H8.75L7.91667 0.5H3.75L2.91667 1.33333H0V3H11.6667V1.33333ZM0.833333 3.83333V13.8333C0.833333 14.75 1.58333 15.5 2.5 15.5H9.16667C10.0833 15.5 10.8333 14.75 10.8333 13.8333V3.83333H0.833333Z"
                              fill="#F40B31"
                            />
                          </svg>
                        </button>
                      </div>
                      <ReactTooltip
                        backgroundColor="#f1f5f8"
                        textColor="black"
                      />
                    </td>
                  </tr>
                ))}
              </Table2>
            </div>
          ) : (
            <div className="flex w-full flex-col">
              <Table2
                tHead={
                  <>
                    <th className="">ID</th>
                    <th className="">Employee</th>
                    <th className="">Address</th>
                    <th className="">Date</th>
                    <th className="">Phone Number</th>
                    <th className="">Action</th>
                  </>
                }
                // renderHead={(item, index) => renderHeadData(item, index)}
              >
                {Array(10)
                  .fill()
                  .map((item, index) => (
                    <tr key={index}>
                      {Array(6)
                        .fill()
                        .map((x, i) => (
                          <td key={i}>
                            <SkeletonElement type={"title"} />
                          </td>
                        ))}
                    </tr>
                  ))}
              </Table2>
            </div>
          )}
        </div>
      </TransactionStyles>

      <div className="flex flex-col mb-[10vh] px-5 gap-4 justify-between md:items-center md:flex-row mt-8">
        <div className="page text-[#999999] text-xs">
          {loading
            ? ""
            : staff && staff.length <= 0
            ? "No Transactions Found"
            : `Showing  ${pageTo} out of ${totalEntries} results`}
        </div>

        <div className=" flex justify-end float-right">
          <PaginationComponent
            current={currentPage}
            defaultCurrent={currentPage}
            showSizeChanger={false}
            onChange={pageChange}
            total={totalEntries}
          />
        </div>
      </div>

      {staffToRemove && (
        <Modal2
          size="medium"
          onHide={() => {
            setStaffToRemove(undefined);
          }}
        >
          <div className="flex intro-y flex-col items-center md:gap-11 gap-6 justify-center py-10 md:px-10 px-4">
            <div className="flex items-center flex-col gap-1 justify-center w-full">
              <h1 className="text-xl font-medium text-[#0042C6]">
                Remove Staff
              </h1>

              <p className="text-xs text-center text-[#727681]">
                Are you sure you want to remove{" "}
                {`${staffToRemove.firstname} ${staffToRemove.lastname}`}? This
                action cannot be undone.
              </p>
            </div>

            <div className="flex items-center gap-4">
              <button
                onClick={() => setStaffToRemove(undefined)}
                className="btn rounded-md border font-medium border-[#2D2C2C] text-[#2D2C2C] px-6 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => removeStaff()}
                className="btn rounded-md border font-medium border-[#F40B31] text-[#fff] bg-[#F40B31] px-6 py-2"
              >
                Remove
              </button>
            </div>
          </div>
        </Modal2>
      )}

      {staffToActivate && (
        <Modal2
          size="small"
          onHide={() => {
            setStaffToActivate(undefined);
          }}
        >
          <div className="flex intro-y flex-col items-center md:gap-11 gap-6 justify-center py-10 md:px-10 px-4">
            <div className="flex items-center flex-col gap-1 justify-center w-full">
              <h1 className="text-xl font-medium text-[#0042C6]">
                Activate Staff
              </h1>

              <p className="text-xs text-center text-[#727681]">
                Are you sure you want to activate{" "}
                {`${staffToActivate.firstname} ${staffToActivate.lastname}`}?
              </p>
            </div>

            <div className="flex items-center gap-4">
              <button
                onClick={() => setStaffToActivate(undefined)}
                className="btn rounded-md border font-medium border-[#2D2C2C] text-[#2D2C2C] px-6 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => activateStaff()}
                className="btn rounded-md border bg-[#0042C6] font-medium border-[#0042C6] text-[#fff] px-6 py-2"
              >
                Activate
              </button>
            </div>
          </div>
        </Modal2>
      )}

      {staffToSuspend && (
        <Modal2
          size="small"
          onHide={() => {
            setStaffToSuspend(undefined);
          }}
        >
          <div className="flex intro-y flex-col items-center md:gap-11 gap-6 justify-center py-10 md:px-10 px-4">
            <div className="flex items-center flex-col gap-1 justify-center w-full">
              <h1 className="text-xl font-medium text-[#0042C6]">
                Suspend Staff
              </h1>

              <p className="text-xs text-center text-[#727681]">
                Are you sure you want to suspend{" "}
                {`${staffToSuspend.firstname} ${staffToSuspend.lastname}`}?
              </p>
            </div>

            <div className="flex items-center gap-4">
              <button
                onClick={() => setStaffToSuspend(undefined)}
                className="btn rounded-md border font-medium border-[#2D2C2C] text-[#2D2C2C] px-6 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => suspendStaff()}
                className="btn rounded-md border font-medium border-[#F40B31] text-[#fff] bg-[#F40B31] px-6 py-2"
              >
                Suspend
              </button>
            </div>
          </div>
        </Modal2>
      )}

      {staffToUpdate && (
        <Modal2
          // onClick={callback}
          size={"lg"}
          onHide={() => {
            setStaffToUpdate(undefined);
          }}
        >
          <UpdateStaff
            setStaffToUpdate={setStaffToUpdate}
            staff={staffToUpdate}
            onSubmit={onStaffUpdate}
            initCallback={(callbackFunc) => setCallback(() => callbackFunc)}
          />
        </Modal2>
      )}

      {topUpEValue && (
        <Modal2
          size="small"
          onHide={() => {
            setTopUpEValue(undefined);
            setTopUpEValueAmount(0);
            setInputAmountError(false);
          }}
        >
          <div className="flex intro-y flex-col items-center md:gap-11 gap-6 justify-center py-10 md:px-10 px-4">
            <div className="flex flex-col gap-3 w-full">
              <div className="flex items-end justify-end w-full">
                <span
                  className="btn border-none"
                  onClick={() => {
                    setInputAmountError(false);
                    setTopUpEValue(undefined);
                    setTopUpEValueAmount(0);
                  }}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.8337 1.34199L10.6587 0.166992L6.00033 4.82533L1.34199 0.166992L0.166992 1.34199L4.82533 6.00033L0.166992 10.6587L1.34199 11.8337L6.00033 7.17533L10.6587 11.8337L11.8337 10.6587L7.17533 6.00033L11.8337 1.34199Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
              <div className="flex items-center flex-col gap-4 justify-center w-full">
                <h1 className="text-xl font-medium text-[#0042C6]">
                  Top Up Staff E-Value
                </h1>

                <div className="w-full mt-5">
                  <Input
                    type="number"
                    onChange={(e) => {
                      setInputAmountError(false);
                      setTopUpEValueAmount(e.target.value);
                    }}
                    placeholder="0"
                    labelName={"Amount"}
                    className={`${
                      inputAmountError && "msisdn-error"
                    } bg-white z-20`}
                  />

                  {inputAmountError && (
                    <p className="validation-error z-[-10] mt-[-5px!important] msisdn-error w-full">
                      Enter valid number greater than 0
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <button
                onClick={() => {
                  setInputAmountError(false);
                  setTopUpEValueAmount(0);
                  setTopUpEValue(undefined);
                }}
                className="btn rounded-md border font-medium border-[#2D2C2C] text-[#2D2C2C] px-6 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => topUp()}
                className="btn rounded-md border bg-[#0042C6] font-medium border-[#0042C6] text-[#fff] px-6 py-2"
              >
                Top Up
              </button>
            </div>
          </div>
        </Modal2>
      )}

      {reduceProfile && (
        <Modal2
          size="small"
          onHide={() => {
            setReduceProfile(undefined);
            setReduceProfileAmount(0);
            setInputAmountError(false);
          }}
        >
          <div className="flex intro-y flex-col items-center md:gap-11 gap-6 justify-center py-10 md:px-10 px-4">
            <div className="flex flex-col gap-3 w-full">
              <div className="flex items-end justify-end w-full">
                <span
                  className="btn border-none"
                  onClick={() => {
                    setReduceProfile(undefined);
                    setReduceProfileAmount(0);
                    setInputAmountError(false);
                  }}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.8337 1.34199L10.6587 0.166992L6.00033 4.82533L1.34199 0.166992L0.166992 1.34199L4.82533 6.00033L0.166992 10.6587L1.34199 11.8337L6.00033 7.17533L10.6587 11.8337L11.8337 10.6587L7.17533 6.00033L11.8337 1.34199Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
              <div className="flex items-center flex-col gap-4 justify-center w-full">
                <h1 className="text-xl font-medium text-[#0042C6]">
                  Reduce staff debt profile
                </h1>

                <div className="w-full mt-5">
                  <Input
                    value={reduceProfileAmount}
                    max={reduceProfile.balance_owed}
                    type="number"
                    onChange={(e) => {
                      setInputAmountError(false);
                      setReduceProfileAmount(e.target.value);
                    }}
                    placeholder="0"
                    labelName={"Amount"}
                    className={`${
                      inputAmountError && "msisdn-error"
                    } bg-white z-20`}
                  />

                  {inputAmountError && (
                    <p className="validation-error z-[-10] mt-[-5px!important] msisdn-error w-full">
                      Enter valid number greater than 0
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-3 w-full items-center justify-center">
              <p className="text-[#7E7E7E]">
                Current debt: {reduceProfile.country?.currency_symbol}
                {reduceProfile.balance_owed}
              </p>

              <div className="flex items-center gap-4">
                <button
                  onClick={() => {
                    setInputAmountError(false);
                    setReduceProfile(undefined);
                    setReduceProfileAmount(0);
                  }}
                  className="btn rounded-md border font-medium border-[#2D2C2C] text-[#2D2C2C] px-6 py-2"
                >
                  Cancel
                </button>
                <button
                  onClick={() => reduceDebt()}
                  className="btn rounded-md border bg-[#0042C6] font-medium border-[#0042C6] text-[#fff] px-6 py-2"
                >
                  Reduce Debt
                </button>
              </div>
            </div>
          </div>
        </Modal2>
      )}

      {showAddNewStaff && <AddNewStaff setShow={setShowAddNewStaff} />}
    </div>
  );
};

export default Staff;
