import styled from 'styled-components'

const CodeFieldStyles = styled.div`
   text-align: center;
   input {
    font-size: 1.2rem;
    text-align: center;
    letter-spacing: 1rem;
    width: 20rem;
    margin: 0 auto;
    display: block;
    -moz-appearance: textfield;
    -webkit-appearance: none;
    &::placeholder {
      letter-spacing: normal;
    }
  }
  .validation-error {
    text-align: center;
    display: inline-flex;
    width: 20rem;

  }
`;

export default CodeFieldStyles;
