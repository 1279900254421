import { Switch, Route, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MobileMenu from "../MobileMenu";
import Sidebar from "../Sidebar";
// import IdleMonitor from "../IdleTimeout";
import AdminRoutes from "../AdminRoutes";
import AgentRoutes from "../AgentRoutes";
import GlobalStyles from "../../GlobalStyles";
import Login from "../../app/AuthViews/Login";
import { useDispatch, useSelector } from "react-redux";
import TopNav from "../common/TopNav";
import { ToastProvider } from "react-toast-notifications";
import StaffLogin from "../../app/AuthViews/StaffLogin";
import PasswordReset from "../../app/AuthViews/PasswordReset";
import StaffPasswordReset from "../../app/AuthViews/StaffPasswordReset";
import Pusher from "pusher-js";
import { setUserObject } from "../../redux/actions/user";
import IdleMonitor from "../IdleTimeout/idleMonitor";
import Modal2 from "../common/Modal/Modal2";
import { setLoggedOutDueToInactivity } from "../../redux/actions/idleMonitor";
import PageNotFound from "../404Page";

const Layout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [loginComponent, setLoginComponent] = useState(Login);
  const [passwordResetComponent, setPasswordResetComponent] =
    useState(PasswordReset);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { wasIdle } = useSelector((state) => state?.idleMonitor);

  const [showSidebar, setShowSidebar] = useState(true);
  const [showNavbar, setShowNavbar] = useState(true);


  useEffect(() => {
    if (location.pathname === "/") {
      setLoginComponent(Login);
      setPasswordResetComponent(PasswordReset);
    } else if (location.pathname === "/staff") {
      setLoginComponent(StaffLogin);
      setPasswordResetComponent(StaffPasswordReset);
    }
  }, [location]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.type === "agent") {
        const pusher = new Pusher(
          process.env.REACT_APP_API_ENVIRONMENT === "dev"
            ? process.env.REACT_APP_PUSHER_KEY_DEVELOPMENT:
              process.env.REACT_APP_API_ENVIRONMENT === "staging" ?
                  process.env.REACT_APP_PUSHER_KEY_STAGING
            : process.env.REACT_APP_PUSHER_KEY_PRODUCTION,
          {
            cluster: "eu",
            forceTLS: true,
          }
        );
        const channel = pusher.subscribe(
          `funding-agency-${currentUser.agency.id}-agent-${currentUser.id}`
        );
        channel.bind("agent-evalue-topup", function (data) {
          let agent = { ...currentUser };
          agent.balance_available = data.message?.model.balance_available;
          agent.actual_balance = data.message?.model.actual_balance;
          dispatch(setUserObject(agent));
        });
      }
      setUser(currentUser);
    }
  }, [currentUser]);

  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <>
      <GlobalStyles />
      {/* <IdleMonitor /> */}
      <ToastProvider>
        {user.hasOwnProperty("agent_role") && (
          <IdleMonitor>
            <Route
              render={(props) => (
                <div>
                  <div className="">
                    {showSidebar && (
                      <Sidebar
                        location={props.location.pathname}
                        user={user}
                        setShowSidebar={setOpenSidebar}
                        showSidebar={openSidebar}
                      />
                    )}
                    <div id="main-content" className="content">
                      {showNavbar && (
                        <TopNav
                          page={props.location.pathname}
                          user={user}
                          setShowSidebar={setOpenSidebar}
                          showSidebar={openSidebar}
                        />
                      )}
                      <AgentRoutes
                        setShowNavbar={setShowNavbar}
                        setShowSidebar={setShowSidebar}
                      />
                    </div>
                  </div>
                </div>
              )}
            />
          </IdleMonitor>
        )}

        {user.hasOwnProperty("staff_role") && (
          <IdleMonitor>
            <Route
              render={(props) => (
                <div>
                  <div className="">
                    {/* <MobileMenu /> */}
                    {showSidebar && (
                      <Sidebar
                          location={props.location.pathname}
                          user={user}
                          setShowSidebar={setOpenSidebar}
                          showSidebar={openSidebar}
                      />
                    )}
                    <div id="main-content" className="content">
                      {showNavbar && (
                        <TopNav
                            page={props.location.pathname}
                            user={user}
                            setShowSidebar={setOpenSidebar}
                            showSidebar={openSidebar}/>
                      )}
                      <AdminRoutes
                        setShowNavbar={setShowNavbar}
                        setShowSidebar={setShowSidebar}
                      />
                    </div>
                  </div>
                </div>
              )}
            />
          </IdleMonitor>
        )}
        {!user.hasOwnProperty("agent_role") &&
          !user.hasOwnProperty("staff_role") && (
            <Switch>
              <Route
                path="/password-reset"
                exact={true}
                component={PasswordReset}
              />
              <Route
                path="/staff/password-reset"
                exact={true}
                component={StaffPasswordReset}
              />
              <Route path="/" exact={true} component={Login} />
              <Route path="/staff" exact={true} component={StaffLogin} />
              <Route path="*">
                <PageNotFound />
              </Route>
            </Switch>
          )}

        {wasIdle && (
          <Modal2
            size={"small"}
            onHide={() => dispatch(setLoggedOutDueToInactivity(false))}
          >
            <div className="flex gap-4 flex-col px-6 py-6 items-center">
              <p className="text-base">
                You’ve been logged out due to in-activity
              </p>

              <button
                className="btn-primary-2"
                onClick={() => dispatch(setLoggedOutDueToInactivity(false))}
              >
                Close
              </button>
            </div>
          </Modal2>
        )}
      </ToastProvider>
    </>
  );
};

export default Layout;
