import React, {useEffect, useState} from "react";
import {Col, DatePicker, Row, Form, Modal, Input, Select} from "antd";
import restService from "../../../services/restService";
const {RangePicker} = DatePicker;

const isBlank = (str) => {
  return !str || /^\s*$/.test(str);
};
export const FilterModal = ({visible, onCreate, onCancel}) => {
  const [form] = Form.useForm();
  const [currencies, setCurrencies] = useState([]);
  const [agents, setAgents] = useState([]);
  const [partners, setPartners] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [agent, setAgent] = useState(null);
  const [partner, setPartner] = useState(null);
  const [agency, setAgency] = useState(null);

  const rangeConfig = {
    rules: [
      {
        type: "array",
        required: false,
        message: "Please select a date",
      },
    ],
  };

  const fetchAgents = async (agencyId) => {
    const agentList = agencyId
      ? await restService.AgentApi.getAgentsByAgency(agencyId, null, "allItems")
      : await restService.AgentApi.getAgents(null, "allItems");
    setAgents(agentList.data?.data);
  };

  const fetchAgencies = async () => {
    const agencyList = await restService.AgencyApi.getAgencies(
      null,
      "allItems"
    );
    setAgencies(agencyList.data?.data);
  };

  const fetchPartners = async () => {
    restService.UtilsApi.getPartners("allItems").then(({data}) => {
      const partnerObject = data?.data;
      const formattedPartners = [];
      Object.keys(partnerObject)?.forEach((key) => {
        formattedPartners.push({name: partnerObject[key], id: Number(key)});
      });

      setPartners(formattedPartners);
    });
  };

  useEffect(async () => {
    restService.UtilsApi.getCountries().then(({data}) => {
      if (data?.data) {
        let currencyData = data.data.map((c) => c.currency_code);

        currencyData = currencyData.reduce((set, entry) => {
          if (set.includes(entry) || isBlank(entry)) {
            return set;
          } else {
            return [...set, entry];
          }
        }, []);
        setCurrencies(currencyData);
      }
    });
    await fetchAgencies();
    await fetchAgents();
    await fetchPartners();
  }, []);

  useEffect(async () => {
    if (agency) {
      await fetchAgents(agency);
    } else {
      await fetchAgents();
    }
  }, [agency]);

  useEffect(async () => {
    if (agent) {
      setAgencies([]);
    } else {
      await fetchAgencies();
    }
  }, [agent]);

  return (
    <Modal
      visible={visible}
      title="Filter Transactions"
      okText="Apply Filters"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate({
              ...values,
              agency_id: agency,
              agent_id: agent,
              partner_id: partner,
            });
          })
          .catch((info) => {
            //console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="sendCurrency"
              label="Send Currency"
              rules={[
                {
                  required: false,
                  message: "Please select a send currency",
                },
              ]}
            >
              <Select>
                {currencies.map((currency) => {
                  return (
                    <Select.Option value={currency}>{currency}</Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="receiveCurrency"
              label="Receive Currency"
              rules={[
                {
                  required: false,
                  message: "Please select a receive currency",
                },
              ]}
            >
              <Select>
                {currencies.map((currency) => {
                  return (
                    <Select.Option value={currency}>{currency}</Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="sendAmount"
              label="Send Amount"
              rules={[
                {
                  required: false,
                  message: "Please enter a send amount",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="receiveAmount"
              label="Receive Amount"
              rules={[
                {
                  required: false,
                  message: "Please enter a receive amount",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name="dateRange" label="Date Range" {...rangeConfig}>
              <RangePicker />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="status" label="Status">
              <Select>
                <Select.Option value="success">Success</Select.Option>
                <Select.Option value="accepted">Accepted</Select.Option>
                <Select.Option value="created">Created</Select.Option>
                <Select.Option value="error">Error</Select.Option>
                <Select.Option value="failed">Failed</Select.Option>
                <Select.Option value="reversed">Reversed</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name="agency_id" label="Agency">
              <Select onSelect={(value) => setAgency(value)}>
                <Select.Option value={null}>All</Select.Option>
                {agencies.map((agency) => {
                  return (
                    <Select.Option value={agency.id}>
                      {agency.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="agent_id" label="Agent">
              <Select onSelect={(value) => setAgent(value)}>
                <Select.Option value={null}>All</Select.Option>
                {agents.map((agent) => {
                  return (
                    <Select.Option value={agent.id}>
                      {agent.firstname} {agent.lastname}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="partner_id" label="Partner">
              <Select onSelect={(value) => setPartner(value)}>
                <Select.Option value={null}>All</Select.Option>
                {partners.map((partner) => {
                  return <option value={partner.id}>{partner.name}</option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default FilterModal;
