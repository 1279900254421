import React, {useEffect, useState} from "react";
import {useToasts} from "react-toast-notifications";
import restService from "../../../../services/restService";

const AddCommissionConfiguration = () => {
  const {addToast} = useToasts();

  const [form, setForm] = useState({
    agent_id: "",
    agency_id: "",
    agency_percentage: 30,
    agent_percentage: 30,
    company_percentage: 40,
  });
  const [errors, setErrors] = useState({});
  const [agents, setAgents] = useState([]);
  const [agencies, setAgencies] = useState([]);

  const initFormValues = async () => {
    const agencyList = await restService.AgencyApi.getAgencies();
    const agentList = await restService.AgentApi.getAgentsByAgency(
      agencyList.data?.data?.data[0]?.id
    );
    setAgents(agentList.data?.data?.data);
    setAgencies(agencyList.data?.data?.data);

    setForm({
      ...form,
      agency_id: agencyList.data?.data?.data[0]?.id,
      agent_id: agentList.data?.data?.data[0]?.id,
    });
  };

  useEffect(() => {
    initFormValues();
  }, []);

  const validateForm = () => {
    const keys = Object.keys(form);

    let errorList = {...errors};
    keys.forEach((key) => {
      if (form[key] === "" || form[key] === undefined) {
        errorList[key] = "This field is required.";
      }
    });

    if (
      Number(form.agency_percentage) +
        Number(form.agent_percentage) +
        Number(form.company_percentage) !==
      100
    ) {
      errorList.total =
        "Agency, agent, and company Percentage should add up to 100.";
    }
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };

  const saveCommission = () => {
    if (validateForm()) {
      restService.CommissionsConfigApi.addCommissionConfiguration(form).then(
        (response) => {
          setForm({
            agency_percentage: 30,
            agent_percentage: 30,
            company_percentage: 40,
          });
          addToast("Successfully Added Commission Configuration", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      );
    }
  };

  const onFormChange = ({target}) => {
    let details = {...form};
    details[target.name] = target.value;
    let errorList = {...errors};

    if (target.name === "agency_id") {
      restService.AgentApi.getAgentsByAgency(target.value).then(({data}) => {
        setAgents(data?.data.data);
        setForm({...details, agent_id: data?.data?.data[0]?.id});
      });

      delete errorList["agent_id"];
    }
    setForm(details);

    delete errorList[target.name];
    delete errorList.total;
    setErrors(errorList);
  };
  useEffect(() => {
    setForm({
      ...form,
      company_percentage:
        100 - (Number(form.agency_percentage) + Number(form.agent_percentage)),
    });
  }, [form.agency_percentage_percentage, form.agent_percentage]);

  return (
    <div className="intro-y mt-13-important box py-10 sm:py-20 mt-5">
      <div className="px-5 mt-10">
        <div className="font-medium text-center text-lg">
          Add Commission Configuration
        </div>
        <div className="text-gray-600 text-center mt-2">
          Please enter the configuration details.
        </div>
      </div>
      <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5">
        <div className="mt-5">
          <div className="grid grid-cols-12 gap-4 gap-y-5 ">
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-1" className="form-label">
                Agency Percentage *
              </label>
              <input
                type="number"
                min={0}
                max={100}
                value={form.agency_percentage}
                onChange={onFormChange}
                name="agency_percentage"
                className="form-control"
                placeholder="43.20"
              />
              <p className="text-red-500 text-xs italic pt-1">
                {errors.agency_percentage}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-1" className="form-label">
                Agent Percentage *
              </label>
              <input
                type="number"
                min={0}
                max={100 - form.agency_percentage}
                value={form.agent_percentage}
                onChange={onFormChange}
                name="agent_percentage"
                className="form-control"
                placeholder="23.20"
              />
              <p className="text-red-500 text-xs italic pt-1">
                {errors.agent_percentage}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-1" className="form-label">
                Company Percentage *
              </label>
              <input
                type="number"
                min={0}
                max={100}
                value={
                  100 -
                  (Number(form.agency_percentage) +
                    Number(form.agent_percentage))
                }
                onChange={onFormChange}
                name="company_percentage"
                className="form-control"
                placeholder="13.20"
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-5" className="form-label">
                Agency *
              </label>
              <select
                id="input-wizard-6"
                value={form.agency_id}
                onChange={onFormChange}
                name="agency_id"
                className="form-select"
              >
                {agencies.map((agency) => {
                  return <option value={agency.id}>{agency.name}</option>;
                })}
              </select>
              <p className="text-red-500 text-xs italic pt-1">
                {errors.agency_id}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-5" className="form-label">
                Agent *
              </label>
              <select
                id="input-wizard-6"
                value={form.agent_id}
                onChange={onFormChange}
                name="agent_id"
                className="form-select"
              >
                {agents.map((agent) => {
                  return (
                    <option value={agent.id}>
                      {agent.firstname} {agent.lastname}
                    </option>
                  );
                })}
              </select>
              <p className="text-red-500 text-xs italic pt-1">
                {errors.agent_id}
              </p>
            </div>
          </div>
          <p className="text-red-500 text-xs italic pt-1 text-center">
            {errors.total}
          </p>
          <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
            <button className="btn btn-primary  ml-2" onClick={saveCommission}>
              {" "}
              Add Commission Configuration
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCommissionConfiguration;
