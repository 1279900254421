import React, {useState, useEffect} from "react";
import regions from "../../../assets/static_json/regions.json";
import roleList from "../../../assets/static_json/roles.json";
import RestService from "../../../services/rest-service";
import restService from "../../../services/restService";

const formKeys = ["name", "country_id", "email", "msisdn", "address"]
const UpdateAgency = ({agency, onSubmit, initCallback}) => {
    const [errors, setErrors] = useState({});
    const [countries, setCountries] = useState([]);
    const [form, setForm] = useState({...agency, calling_code : ''});

    const validateForm = () => {
        let errorList = {}
        formKeys.forEach(key => {
            // TODO validate phone numbers
            if (!form[key]){
                errorList[key] = "This field is required."
            } else {
                if (key === "email"){
                    const regex = /\S+@\S+\.\S+/;
                    if(!regex.test(form[key])){
                        errorList[key] = "Enter a valid email."
                    }
                }
            }
        })
        setErrors(errorList);
        return Object.keys(errorList).length === 0;
    }

    const onFormChange = ({target}) => {
        let details = {...form}
        details[target.name] = target.value;
        let errorList = errors;
        delete errorList[target.name];
        setErrors(errorList)

        if (target.name === "country_id"){
            const country = countries.filter(c => c.id == target.value)[0];
            details.calling_code = country?.calling_code;
        }
        setForm(details);

    }

    const handleSubmit = () => {
        //Api request to save agency
        if (validateForm()){
            let data = {...form};
            data.msisdn = form.calling_code + form.msisdn

            restService.AgencyApi.updateAgency(data).then((response) => {
                onSubmit()
            })

        }
    }
    useEffect(() => {
        RestService.getCountries().then(({data}) => {
            setCountries(data?.data)
            setForm({...form, calling_code: agency.country.calling_code, country_id: data?.data.filter(country => {return country.name === agency.country?.name})[0].id})
        })
    }, []);

    useEffect(() => {
        initCallback(handleSubmit)
    }, [form])


    return (
        <div className="mt-5">
            <div className="grid grid-cols-12 gap-4 gap-y-5 ">
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="input-wizard-1" className="form-label">Name *</label>
                    <input id="input-wizard-1" type="text" value={form.name} onChange={onFormChange} name="name" className="form-control" placeholder="WestView Finances"/>
                    <p className="text-red-500 text-xs italic pt-1">{errors.name}</p>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="input-wizard-3" className="form-label">Address *</label>
                    <input id="input-wizard-3" type="text" value={form.address} onChange={onFormChange} name="address" className="form-control" placeholder="Awoshie"/>
                    <p className="text-red-500 text-xs italic pt-1">{errors.address}</p>

                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="input-wizard-5" className="form-label">Email *</label>
                    <input id="input-wizard-5" type="text" value={form.email} onChange={onFormChange} name="email" className="form-control" placeholder="kojo@gmail.com"/>
                    <p className="text-red-500 text-xs italic pt-1">{errors.email}</p>

                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="input-wizard-4" className="form-label">Phone Number *</label>
                    <div className="grid grid-cols-7">
                        <input id="input-wizard-4" disabled={true} type="text" onChange={onFormChange} value={form.calling_code}  name="msisdn" className="col-span-1 form-control" placeholder="44"/>
                        <input id="input-wizard-4" type="text" value={form.msisdn} onChange={onFormChange} name="msisdn" className="col-span-6 form-control" placeholder="548410151"/>
                    </div>
                    <p className="text-red-500 text-xs italic pt-1">{errors.msisdn}</p>

                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="input-wizard-5" className="form-label">Country *</label>
                    <select id="input-wizard-6" value={form.country_id} onChange={onFormChange} name="country_id" className="form-select">
                        {countries.map(country => {
                            return <option key={country.id} value={country.id}>{country.name}</option>
                        })}
                    </select>
                </div>

            </div>
        </div>

    )
}
export default UpdateAgency;
