import React from "react";
import Input from "../Input";
import PhoneInputComponent from "../PhoneInput/index";
import ErrorMessages from "../ErrorMessages";
// import {parsePhoneNumber} from "react-phone-number-input";

const AddSender = ({
  form,
  setForm,
  onPhoneNumberChange,
  onFormChange,
  errors,
  submit,
  onCancel,
  apiErrors,
}) => {
  // const phoneNumber = parsePhoneNumber("+233133734253");

  // console.log(phoneNumber);

  return (
    <div className="flex flex-col items-center justify-center gap-11">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-theme-32 text-center font-medium text-xl">
          Add Sender
        </h1>
        <p className="text-[#727681] text-center text-xs font-normal">
          To start off, please enter the sender’s personal details
        </p>
      </div>

      {apiErrors && <ErrorMessages errorData={apiErrors} />}

      <div className="flex flex-col md:max-w-[800px] w-full gap-8">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 w-full gap-2">
          <div className="flex flex-col w-full">
            <Input
              containerStyle={"w-full md:w-auto"}
              labelFor={"firstname"}
              id={"firstname"}
              placeholder="Kojo"
              labelName={"First Name"}
              type="text"
              value={form.firstname}
              onChange={onFormChange}
              name="firstname"
            />

            {errors?.firstname && (
              <p className="text-red-500 text-[10px] italic pt-1">
                {errors?.firstname}
              </p>
            )}
          </div>

          <div className="flex  flex-col">
            <Input
              containerStyle={"w-full md:w-auto"}
              labelFor={"OtherNames"}
              placeholder=""
              labelName={"Other Names"}
              type="text"
              value={form.othernames}
              onChange={onFormChange}
              name="othernames"
            />
            {errors?.othernames && (
              <p className="text-red-500 text-[10px] italic pt-1">
                {errors?.othernames}
              </p>
            )}
          </div>
          <div className="flex  flex-col">
            <Input
              containerStyle={"w-full md:w-auto"}
              labelFor={"Last Name"}
              placeholder=""
              labelName={"Last Name"}
              type="text"
              value={form.lastname}
              onChange={onFormChange}
              name="lastname"
            />

            {errors?.lastname && (
              <p className="text-red-500 text-[10px] italic pt-1">
                {errors?.lastname}
              </p>
            )}
          </div>

          <div className="flex flex-col">
            <Input
              containerStyle={"w-full md:w-auto"}
              labelFor={"Address"}
              placeholder=""
              labelName={"Address"}
              type="text"
              value={form.address}
              onChange={onFormChange}
              name="address"
            />

            {errors?.address && (
              <p className="text-red-500 text-[10px] italic pt-1">
                {errors?.address}
              </p>
            )}
          </div>

          <div className="flex flex-col">
            <Input
              containerStyle={"w-full md:w-auto"}
              labelFor={"Email"}
              placeholder="Kojo"
              labelName={"Email"}
              type="email"
              value={form.email}
              onChange={onFormChange}
              name="email"
            />

            {/*{errors?.email && (*/}
            {/*  <p className="text-red-500 text-[10px] italic pt-1">*/}
            {/*    {errors?.email}*/}
            {/*  </p>*/}
            {/*)}*/}
          </div>

          <div className="flex  flex-col">
            <PhoneInputComponent
              labelFor={"msisdn"}
              labelName={"Phone Number"}
              placeholder="Enter phone number"
              value={form.msisdn}
              onChange={onPhoneNumberChange}
              name="msisdn"
              defaultCountry="GB"
              withCountryCallingCode
              international={true}
              className={`flex text-xs bg-white PhoneInput-2 `}
              containerStyles={"w-full"}
              // validationMessage={validationMessage}
              // handlePhoneInputChange={handlePhoneInput}
            />
            {errors?.msisdn && (
              <p className="text-red-500 text-[10px] italic pt-1">
                {errors?.msisdn}
              </p>
            )}
          </div>
        </div>
        <div className="mb-[2rem] gap-4 w-full flex justify-end items-center">
          {typeof onCancel === "function" && (
            <button onClick={onCancel} className="btn-secondary  px-3 py-2">
              <span>Cancel</span>
            </button>
          )}
          <button
            onClick={submit}
            className="flex justify-end items-center gap-1 bg-theme-32 border border-theme-32 hover:bg-theme-1 transition text-white rounded-md px-3 py-2"
          >
            <span>Submit</span>

            <span>
              <svg
                width="10"
                height="9"
                viewBox="0 0 10 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.44357 1.68241L7.14109 4.37993H0.890509V5.45894H7.14109L4.44357 8.15646L5.20654 8.91943L9.20654 4.91943L5.20654 0.919434L4.44357 1.68241Z"
                  fill="white"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSender;
