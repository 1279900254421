import React, {useEffect, useState} from 'react';
import{Col, DatePicker, Row , Form,Modal,Input,Select} from "antd";
import restService from "../../../services/restService";
const { RangePicker } = DatePicker;

const isBlank = (str) =>{
  return (!str || /^\s*$/.test(str));
}
export const FilterModal = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [currencies, setCurrencies] = useState([])


  const rangeConfig = {
    rules: [
      {
        type: 'array',
        required: false,
        message: 'Please select a date',
      },
    ],
  };

  useEffect(() => {
    restService.UtilsApi.getCountries().then(({data}) => {
      if (data?.data){
        let currencyData = data.data.map(c => c.currency_code);

        currencyData = currencyData.reduce((set, entry) => {
          if (set.includes(entry) || isBlank(entry)) {
            return set;
          } else {
            return [...set, entry];
          }
        }, [])
        setCurrencies(currencyData)
      }
    });
  }, [])


  return (
      <Modal
          visible={visible}
          title="Filter Transactions"
          okText="Apply Filters"
          cancelText="Cancel"
          onCancel={onCancel}
          onOk={() => {
            form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  onCreate(values);
                })
                .catch((info) => {
                  //console.log('Validate Failed:', info);
                });
          }}
      >
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
        >

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                  name="sendCurrency"
                  label="Send Currency"
                  rules={[
                    {
                      required: false,
                      message: 'Please select a send currency',
                    },
                  ]}
              >
                <Select>
                  {currencies.map(currency => {
                    return <Select.Option value={currency}>{currency}</Select.Option>

                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                  name="receiveCurrency"
                  label="Receive Currency"
                  rules={[
                    {
                      required: false,
                      message: 'Please select a receive currency',
                    },
                  ]}
              >
                <Select>
                  {currencies.map(currency => {
                    return <Select.Option value={currency}>{currency}</Select.Option>

                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                  name="sendAmount"
                  label="Send Amount"
                  rules={[
                    {
                      required: false,
                      message: 'Please enter a send amount',
                    },
                  ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                  name="receiveAmount"
                  label="Receive Amount"
                  rules={[
                    {
                      required: false,
                      message: 'Please enter a receive amount',
                    },
                  ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="dateRange" label="Date Range" {...rangeConfig}>
                <RangePicker />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="status" label="Status">
                <Select>
                  <Select.Option value="success">Success</Select.Option>
                  <Select.Option value="pending">Pending</Select.Option>
                  <Select.Option value="failed">Failed</Select.Option>
                  <Select.Option value="reversed">Reversed</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
  );
};

export default FilterModal;
