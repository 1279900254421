import React, {useState} from 'react';
import AuthService from "../../services/auth-service";
import PasswordField from "../../components/common/PasswordField/PasswordField";
import TextField from "../../components/common/TextField";
import {Link} from "react-router-dom";
import PhoneInputComponent from "../../components/common/PhoneInput";

const LoginForm = ({onSuccess,msisdn, setMsisdn, userType}) => {

    const [loading,setLoading] = useState(false);

    const [error, setError] = useState("");
    const [phoneValidation, setPhoneValidation] = useState("")

    const handleReset = (e) => {
        e.preventDefault();

        setLoading(true);
        AuthService.restPassword(msisdn, userType)
            .then((data) => {
                if (data?.data?.data?.agent){
                    onSuccess(data.data.data.agent)

                }
                if (data?.data?.data?.staff){
                    onSuccess(data.data.data.staff)
                }

            })
            .catch((error) => {
                //console.log(error);
                setError("Invalid user credentials")
            })
            .finally(() => {
                setLoading(false)
            })
    }


    return (
        <form className=" sm:w-2/3 w-full px-4 lg:px-0 mx-auto">
            <div className="pb-2 pt-4">
                <PhoneInputComponent
                    labelFor={"msisdn"}
                    labelName={"Phone Number"}
                    placeholder="Enter phone number"
                    value={msisdn}
                    onChange={(value)=> {setMsisdn(value); setError(undefined)}}
                    name="msisdn"
                    defaultCountry="GB"
                    withCountryCallingCode
                    international={true}
                    className={`flex text-lg rounded-sm bg-black w-full border-none`}
                    containerStyles={"block w-full p-4 text-lg rounded-sm phone-black"}
                    inputClassName="bg-black w-full"
                    // validationMessage={validationMessage}
                    // handlePhoneInputChange={handlePhoneInput}
                />
                <p className="error">{error}</p>

            </div>

            <div className="px-4 pb-2 pt-4">
                {loading ?
                    <button type="button" disabled={loading}
                            className="uppercase block w-full p-4 text-lg rounded-full bg-indigo-500 ">
                        <div className=" flex justify-center items-center">
                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                        </div>
                    </button>
                    :
                    <button onClick={handleReset}
                            className="uppercase block w-full p-4 text-lg rounded-full bg-indigo-500 hover:bg-indigo-600 focus:outline-none">
                        Reset Password
                    </button>
                }
            </div>
        </form>

    );
};

export default LoginForm;
