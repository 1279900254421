import styled from 'styled-components';
import tw from 'twin.macro'


const SkeletonSubProfileStyles = styled.div.attrs({
  className : "box col-span-12 md:col-span-4 rounded-md p-2"
})`
  &{
    .skeleton-wrapper {
      ${tw`flex animate-pulse flex-row items-center h-full space-x-5`}
    }
  }

`
export default SkeletonSubProfileStyles;
