import React from "react";
import SearchStyles from "./styles";

const Search = ({ placeholder, onChange }) => {
  return (
    <SearchStyles>
      <div className="search-div mr-0">
        <div className="search w-full">
          <input
            type="text"
            onChange={onChange}
            className="search-input box form-control search-primary"
            placeholder={placeholder}
          />

          <span className="search-icon">
            <svg
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.61657 1.49792C2.4031 0.711397 3.46986 0.269531 4.58217 0.269531C5.69449 0.269531 6.76125 0.711397 7.54777 1.49792C8.3343 2.28445 8.77616 3.35121 8.77616 4.46352C8.77616 5.38775 8.47109 6.28054 7.91672 7.00724L10.29 9.38049C10.5084 9.59887 10.5084 9.95294 10.29 10.1713C10.0716 10.3897 9.71753 10.3897 9.49915 10.1713L7.12589 7.79806C6.39919 8.35244 5.50641 8.65751 4.58217 8.65751C3.46986 8.65751 2.4031 8.21564 1.61657 7.42912C0.830049 6.64259 0.388184 5.57584 0.388184 4.46352C0.388184 3.35121 0.830049 2.28445 1.61657 1.49792ZM4.58217 1.38793C3.76647 1.38793 2.98419 1.71196 2.4074 2.28875C1.83062 2.86553 1.50658 3.64782 1.50658 4.46352C1.50658 5.27922 1.83062 6.06151 2.4074 6.63829C2.98419 7.21508 3.76647 7.53911 4.58217 7.53911C5.39787 7.53911 6.18016 7.21508 6.75695 6.63829C7.33373 6.06151 7.65776 5.27922 7.65776 4.46352C7.65776 3.64782 7.33373 2.86553 6.75695 2.28875C6.18016 1.71196 5.39787 1.38793 4.58217 1.38793Z"
                fill="#1B1F3B"
                fillOpacity="0.4"
              />
            </svg>
          </span>
          {/* <SearchIcon className="search-icon" /> */}
        </div>
      </div>
    </SearchStyles>
  );
};

export default Search;
