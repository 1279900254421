import React from "react";
import { Route, Switch } from "react-router-dom";
import Agencies from "../app/AdminViews/Agencies";
import AddAgency from "../app/AdminViews/Agencies/AddAgency";
import AgencyProfile from "../app/AdminViews/Agencies/AgencyProfile";
import AddAgent from "../app/AdminViews/Agents/AddAgent";
import AgentProfile from "../app/AdminViews/Agents/AgentProfile";
import Agents from "../app/AdminViews/Agents/Agents";
import Commissions from "../app/AdminViews/Commisions";
import Configurations from "../app/AdminViews/Configurations";
import AddCharge from "../app/AdminViews/Configurations/Charges/AddCharge";
import AddCommissionConfiguration from "../app/AdminViews/Configurations/Commissions/AddCommissionConfiguration";
import AddRate from "../app/AdminViews/Configurations/Rates/AddRate";
import Dashboard from "../app/AdminViews/Dashboard";
import FundingStatement from "../app/AdminViews/FundingStatement";
import Receivers from "../app/AdminViews/Receivers";
import ReceiverProfile from "../app/AdminViews/Receivers/ReceiverProfile";
import Senders from "../app/AdminViews/Senders";
import SenderProfile from "../app/AdminViews/Senders/SenderProfile";
import Settings from "../app/AdminViews/Settings";
import Staff from "../app/AdminViews/Staff";
import AddStaff from "../app/AdminViews/Staff/AddStaff";
import Transactions from "../app/AdminViews/Transactions";
import TransactionReceipt from "../app/AdminViews/Transactions/TransactionReceipt";
import PageNotFound from "./404Page";
import AddNewReceiverComp from "./common/AddNewReceiver";
import AddNewSender from "./common/AddNewSender";
const AdminRoutes = ({ setShowSidebar, setShowNavbar }) => {
  return (
    <Switch>
      <Route path="/" exact component={Dashboard} />
      <Route path="/dashboard" exact component={Dashboard} />
      <Route path="/transactions" exact component={Transactions} />
      <Route path="/transaction/:id" exact component={TransactionReceipt} />
      {/* <Route path="/transaction/:id" exact component={Receipt} /> */}
      <Route path="/revenue" exact component={Commissions} />
      <Route path="/add-agent" component={AddAgent} />
      <Route path="/add-sender" component={AddNewSender} />
      <Route path="/add-receiver" component={AddNewReceiverComp} />
      <Route path="/agencies" exact component={Agencies} />
      <Route path="/agents" exact component={Agents} />
      <Route path="/agent/:id" exact component={AgentProfile} />
      <Route path="/agency/:id" exact component={AgencyProfile} />
      {/* <Route path="/agent/:id" exact component={Agent} /> */}
      <Route path="/senders" exact component={Senders} />
      <Route path="/receivers" exact component={Receivers} />
      <Route path="/sender/:id" exact component={SenderProfile} />
      {/* <Route path="/sender/:id" exact component={Sender}/> */}
      <Route path="/receiver/:id" exact component={ReceiverProfile} />
      {/* <Route path="/receiver/:id" exact component={Receiver} /> */}
      <Route path="/settings" exact component={Settings} />
      <Route path="/staff" exact component={Staff} />
      <Route path="/add-staff" exact component={AddStaff} />
      <Route path="/add-agency" exact component={AddAgency} />
      <Route path="/add-rate" exact component={AddRate} />
      <Route path="/add-charge" exact component={AddCharge} />
      <Route
        path="/commission-config"
        exact
        component={AddCommissionConfiguration}
      />
      <Route path="/configurations" exact component={Configurations} />
      <Route path="/commissions/:type/:id" exact component={Commissions} />
      <Route path="/statement/:type/:id" exact component={FundingStatement} />
      <Route
        render={(props) => (
          <PageNotFound
            {...props}
            setShowSidebar={setShowSidebar}
            setShowNavbar={setShowNavbar}
          />
        )}
      />
    </Switch>
  );
};

export default AdminRoutes;
