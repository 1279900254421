import React from "react";
import PhoneInput from "react-phone-number-input";

const PhoneInputComponent = ({
  validationMessage,
  handlePhoneInputChange,
  value,
  labelName,
  labelFor,
  containerStyles,
   inputClassName,
  ...props
}) => {
  return (
    <div className={`${containerStyles} flex flex-col items-start gap-1`}>
      {labelName && (
        <label className="text-xs font-medium label-text " for={labelFor}>
          {labelName}
        </label>
      )}
      <div className={"w-full phoneInputContainer"}>
        <PhoneInput
          //   placeholder="Enter phone number"
          value={value}
          //   defaultCountry="GB"
          //   withCountryCallingCode
          //   international={true}
          //   className="flex w-full p-4 text-lg rounded-sm bg-black"

          onChange={handlePhoneInputChange}
          {...props}
        />
        {validationMessage && (
          <div className="validation-error flex items-center gap-2 text-left msisdn-error-text">
            <span>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.78933 0.200012H2.81067L0 3.01068V6.98935L2.81067 9.80001H6.78933L9.6 6.98935V3.01068L6.78933 0.200012ZM7.46667 6.99468L6.79467 7.66668L4.8 5.67201L2.80533 7.66668L2.13333 6.99468L4.128 5.00001L2.13333 3.00535L2.80533 2.33335L4.8 4.32801L6.79467 2.33335L7.46667 3.00535L5.472 5.00001L7.46667 6.99468Z"
                  fill="#F9105F"
                />
              </svg>
            </span>
            <span>{validationMessage}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhoneInputComponent;
