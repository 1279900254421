import React, {useEffect, useState} from "react";
import {Redirect} from "react-router";

// import validate from 'react-joi-validation';
// import Confirmation from "../../../components/common/Confirmation";
import RestService from "../../../services/rest-service";
import {useSelector} from "react-redux";
import restService from "../../../services/restService";
import {useToasts} from "react-toast-notifications";
import AddAgentToNewAgency from "./AddAgentToNewAgency";

const active = "intro-y w-10 h-10 rounded-full btn btn-primary mx-2";
const inactive =
  "intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2";
const AddAgency = () => {
  const {addToast} = useToasts();
  const [step, setStep] = useState(1);
  const user = useSelector((state) => state.auth?.user);
  const [form, setForm] = useState({
    name: "",
    email: "",
    calling_code: "",
    msisdn: "",
    country_id: "",
    address: "",
  });
  const [agency, setAgency] = useState({});
  const [countries, setCountries] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    const keys = Object.keys(form);
    let errorList = {...errors};
    keys.forEach((key) => {
      // TODO validate phone numbers

      if (!form[key]) {
        errorList[key] = "This field is required.";
      } else {
        if (key === "email") {
          const regex = /\S+@\S+\.\S+/;
          if (!regex.test(form[key])) {
            errorList[key] = "Enter a valid email.";
          }
        }
      }
    });
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };

  const onFormChange = ({target}) => {
    let details = {...form};
    details[target.name] = target.value;
    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);

    if (target.name === "country_id") {
      const country = countries.filter((c) => c.id == target.value)[0];
      details.calling_code = country?.calling_code;
    }
    setForm(details);
  };

  const handleSubmit = () => {
    //Api request to save agency
    if (validateForm()) {
      setLoading(true);
      let data = {...form};
      data.msisdn = form.calling_code + form.msisdn;
      restService.AgencyApi.addAgency(data)
        .then(({data}) => {
          addToast("Successfully Added Agency", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
          setAgency(data?.data);
          setStep(2);
        })
        .catch(({response}) => {
          addToast(response?.data?.message, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    RestService.getCountries().then(({data}) => {
      setCountries(data?.data);
      setForm({
        ...form,
        calling_code: data?.data.filter((country) => {
          return country.name === user.country?.name;
        })[0].calling_code,
        country_id: data?.data.filter((country) => {
          return country.name === user.country?.name;
        })[0].id,
      });
    });
  }, []);
  if (redirect) {
    return <Redirect to="/agencies" />;
  }

  return (
    <div className="intro-y mt-13-important box py-10 sm:py-20 mt-5">
      <div className="flex justify-center">
        <button className={step === 1 ? active : inactive}>1</button>
        <button className={step === 2 ? active : inactive}>2</button>
      </div>
      <div className="px-5 mt-10">
        <div className="font-medium text-center text-lg">Add New Agency</div>
        <div className="text-gray-600 text-center mt-2">
          {step === 1
            ? "To start off, please add the agency."
            : `Add agent to ${agency.name}. This agent will be a super agent`}
        </div>
      </div>
      {step === 1 && (
        <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5">
          <div className="mt-5">
            <div className="grid grid-cols-12 gap-4 gap-y-5 ">
              <div className="intro-y col-span-12 sm:col-span-6">
                <label htmlFor="input-wizard-1" className="form-label">
                  Name *
                </label>
                <input
                  id="input-wizard-1"
                  type="text"
                  value={form.name}
                  onChange={onFormChange}
                  name="name"
                  className="form-control"
                  placeholder="WestView Finances"
                />
                <p className="text-red-500 text-xs italic pt-1">
                  {errors.name}
                </p>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <label htmlFor="input-wizard-3" className="form-label">
                  Address *
                </label>
                <input
                  id="input-wizard-3"
                  type="text"
                  value={form.address}
                  onChange={onFormChange}
                  name="address"
                  className="form-control"
                  placeholder="Awoshie"
                />
                <p className="text-red-500 text-xs italic pt-1">
                  {errors.address}
                </p>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <label htmlFor="input-wizard-5" className="form-label">
                  Email *
                </label>
                <input
                  id="input-wizard-5"
                  type="text"
                  value={form.email}
                  onChange={onFormChange}
                  name="email"
                  className="form-control"
                  placeholder="kojo@gmail.com"
                />
                <p className="text-red-500 text-xs italic pt-1">
                  {errors.email}
                </p>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <label htmlFor="input-wizard-4" className="form-label">
                  Phone Number *
                </label>
                <div className="grid grid-cols-7">
                  <input
                    id="input-wizard-4"
                    disabled={true}
                    type="text"
                    onChange={onFormChange}
                    value={form.calling_code}
                    name="msisdn"
                    className="col-span-1 form-control"
                    placeholder="44"
                  />
                  <input
                    id="input-wizard-4"
                    type="text"
                    value={form.msisdn}
                    onChange={onFormChange}
                    name="msisdn"
                    className="col-span-6 form-control"
                    placeholder="548410151"
                  />
                </div>
                <p className="text-red-500 text-xs italic pt-1">
                  {errors.msisdn}
                </p>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <label htmlFor="input-wizard-5" className="form-label">
                  Country *
                </label>
                <select
                  id="input-wizard-6"
                  value={form.country_id}
                  onChange={onFormChange}
                  name="country_id"
                  className="form-select"
                >
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="m-auto max-w-3xl flex items-center justify-center sm:justify-end mt-5">
              <button
                className="btn btn-primary w-auto ml-2 outline-none focus:none"
                disabled={loading}
                onClick={handleSubmit}
              >
                Add Agency
              </button>
            </div>
          </div>
        </div>
      )}
      {step === 2 && <AddAgentToNewAgency agency={agency} />}
    </div>
  );
};
export default AddAgency;
