import {combineReducers} from "redux";
import auth from "./auth";
import message from "./message";
import idleMonitor from "./idleMonitor";

export default combineReducers({
  auth,
  idleMonitor,
  message,
});
