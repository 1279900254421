import React from "react";

const Input = ({labelFor, labelName, className, containerStyle, ...props}) => {
  return (
    <div className={`${containerStyle} flex flex-col gap-1`}>
      {labelName && (
        <label className="text-xs font-normal" for={labelFor}>
          {labelName}
        </label>
      )}

      <input
        {...props}
        className={`border focus:border-theme-31 outline-none rounded px-4 py-2 border-theme-31 ${className}`}
      />
    </div>
  );
};

export default Input;
