import React, { useEffect, useState } from "react";
import restService from "../../../services/restService";
import SkeletonSubProfile from "../../../components/Skeletons/SubProfileSkeleton/SkeletonSubProfile";
import { useSelector } from "react-redux";
import Search from "../../../components/common/Search";
import RestService from "../../../services/restService";
import { Empty } from "antd";
import PaginationComponent from "../../../components/common/Pagination";
import SenderCard from "../../../components/common/SenderCard";
import AddNewSender from "../../../components/common/AddNewSender";
import { useHistory } from "react-router-dom";

const Senders = () => {
  const history = useHistory();

  const [senders, setSenders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageTo, setPageTo] = useState(0);
  const [totalEntries, setTotalEntries] = useState(1);
  const [from, setFrom] = useState(0);
  const [showDateRange, setShowDateRange] = useState(false);

  const agentId = useSelector((state) => state?.auth?.user?.id);
  const roleId = useSelector((state) => state?.auth?.user?.agent_role_id);

  const fetchSenders = (agentId, page) => {
    setLoading(true);
    if (roleId === 1) {
      restService.SendersApi.getSenders(page)
        .then((response) => {
          if (response) {
            setSenders(response.data.data.data);
            setPageTo(response.data?.data?.to);
            setTotalEntries(response.data?.data?.total);
            setFrom(response.data?.data?.from);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (roleId === 3) {
      restService.SendersApi.getSendersCreatedByAgent(agentId, page)
        .then((response) => {
          if (response) {
            setSenders(response.data.data.data);
            setPageTo(response.data?.data?.to);
            setTotalEntries(response.data?.data?.total);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    setLoading(false);
  };

  const searchSenderByInput = async (input) => {
    const response = await RestService.SendersApi.getSendersBySearchTerm(input);
    const senders = await response?.data?.data?.data;
    setSenders(senders);
  };

  const pageChange = (page) => {
    setCurrentPage(page);
    fetchSenders(agentId, page);
  };

  useEffect(() => {
    fetchSenders(agentId, currentPage);
  }, []);

  const [showAddNewSender, setShowAddNewSender] = useState(false);
  return (
    <>
      {" "}
      <div className="flex flex-col w-full gap-6 mt-[5rem]">
        <div className="grid grid-cols-12 ">
          <div className=" col-span-12 flex flex-wrap sm:flex-nowrap items-center gap-2 mt-2">

            <div className="flex md:items-center w-full gap-2 md:flex-row flex-col ">
              <Search
                placeholder={"Search"}
                onChange={(e) => searchSenderByInput(e.target.value)}
              />
            </div>

            <div className="flex intro-y flex-col-reverse w-full justify-end sm:flex-row md:items-center">
              <div className="gap-4 flex justify-end md:justify-end sm:flex-row items-center md:mr-3">
                <button
                  onClick={() => history.push("/add-sender")}
                  className="btn btn-primary-2  flex items-center gap-2 text-xs"
                >
                  <span>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.98438 1.24805C6.24237 1.24805 6.45151 1.45719 6.45151 1.71519V5.45229H10.1886C10.4466 5.45229 10.6558 5.66144 10.6558 5.91943C10.6558 6.17743 10.4466 6.38657 10.1886 6.38657H6.45151V10.1237C6.45151 10.3817 6.24237 10.5908 5.98438 10.5908C5.72638 10.5908 5.51724 10.3817 5.51724 10.1237V6.38657H1.78013C1.52213 6.38657 1.31299 6.17743 1.31299 5.91943C1.31299 5.66144 1.52213 5.45229 1.78013 5.45229H5.51724V1.71519C5.51724 1.45719 5.72638 1.24805 5.98438 1.24805Z"
                        fill="black"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>

                  <span>Add New Sender</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {!loading ? (
          senders && senders.length <= 0 ? (
            <div className="font-bold justify-center h-[55vh] flex items-center  col-span-12">
              <Empty />
            </div>
          ) : (
            <ul className="grid grid-cols-12 gap-4 ">
              {senders.map((item, index) => (
                <li
                  key={item.id}
                  className="lg:col-span-2 md:col-span-4 sm:col-span-6 col-span-6"
                >
                  <SenderCard key={index} sender={item} />
                </li>
              ))}
            </ul>
          )
        ) : (
          <>
            <div className="flex gap-4 flex-wrap">
              {Array(10)
                .fill()
                .map((item, index) => (
                  <SkeletonSubProfile key={index} />
                ))}
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col mb-[10vh] gap-4 justify-between md:items-center md:flex-row mt-8">
        <div className="page text-[#999999] text-xs">
          {loading
            ? ""
            : senders && senders.length <= 0
            ? "No Entries Found"
            : `Showing  ${pageTo} out of ${totalEntries} results`}
        </div>

        <div className="mt-3  flex justify-end float-right">
          <PaginationComponent
            current={currentPage}
            defaultCurrent={currentPage}
            showSizeChanger={false}
            onChange={pageChange}
            total={totalEntries}
          />
        </div>
      </div>
      {showAddNewSender && (
        <AddNewSender
          refetchData={fetchSenders}
          setShow={setShowAddNewSender}
          show={showAddNewSender}
        />
      )}
    </>
  );
};

export default Senders;
