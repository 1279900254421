import React from "react";
import {TrendingDown, TrendingUp} from "react-feather";
const DashboardCard = ({
  icon,
  isTrendingUp,
  value,
  currency,
  label,
  containerStyle,
  trendingValue,
}) => {
  return (
    <div
      className={`flex flex-col gap-6 p-4 border bg-white border-[#DEDEDE] rounded-md ${containerStyle}`}
    >
      <div className="flex items-center w-full justify-between">
        <span>
          <img src={icon} alt="" />
        </span>

        {isTrendingUp && (
          <span className="flex items-center gap-1 text-[10px] px-2 text-white bg-[#299B7E] rounded">
            <TrendingUp className="w-4" />
            <span>{trendingValue}</span>
          </span>
        )}
        {!isTrendingUp && (
          <span className="flex items-center gap-1 text-[10px] px-2 text-white bg-[#F40B31] rounded">
            <TrendingDown className="w-4" />
            <span>{trendingValue}</span>
          </span>
        )}
      </div>

      <div className="flex flex-col gap-1">
        <h4 className="flex items-center text-[#141820] font-medium text-xl">
          {currency && <span>{currency}</span>}
          <span>{value}</span>
        </h4>
        <p className="text-[#797979] text-xs font-normal">{label}</p>
      </div>
    </div>
  );
};

export default DashboardCard;
