import React, {useState} from "react";
import UpdateAccount from "./updateAccount";
import PageTitle from "../../../components/common/PageTitle/PageTitle";
import TransactionStyles from "../Transactions/styles";
import ChangePassword from "./ChangePassword";
import {useSelector} from "react-redux";
const activeClass =
  "text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none text-blue-500 border-b-2 font-medium border-blue-500";
const inactiveClass =
  "text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none";
const Settings = () => {
  const user = useSelector((state) => state.auth?.user);

  return (
    <>
      <div className="content-layout intro-y mt-[5rem]">
        <div className="flex flex-col gap-8">
          <UpdateAccount />
          <ChangePassword />
        </div>
      </div>
    </>
  );
};

export default Settings;
