import styled from "styled-components";
import tw from "twin.macro";

const SendMoneyStyles = styled.div.attrs({
  className: "main",
})`
  margin-top: 7rem;
  margin-bottom: 5rem;
  & {
    .rates-div {
      ${tw`grid  grid-flow-row grid-cols-6 auto-rows-max gap-2`};
      .rate {
        ${tw`flex overflow-hidden bg-white rounded-lg mx-3 shadow-md dark:bg-gray-800`};
      }
    }
    .account-divs {
      ${tw`grid grid-flow-col grid-cols-3 gap-5`}
    }
    .recipient-div,
    .sender-div {
      & > div {
        ${tw`z-20`}
      }
    }

    .currency-div {
      ${tw`flex-grow`}
      &.crud-form-2 {
        ${tw`flex-grow`}
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
  }
`;
export default SendMoneyStyles;
