import { Empty } from "antd";
import { ChevronRight } from "react-feather";
import { getRandomColor } from "../../../../../../helpers/utils";

export function WalletType({ data = [], onSelect, ...props }) {
  return (
    <div className="flex intro-y flex-col gap-6">
      <div className="flex items-center justify-center">
        <h2 className="text-base">Select Account Type</h2>
      </div>

      {data.length === 0 && <Empty />}

      <div className="flex items-center md:flex-row flex-col justify-center gap-4">
        {data?.map((institution, index) => (
          <div
            style={{ backgroundColor: getRandomColor(index) }}
            className={`zoom-in  border border-[#DEDEDE]  rounded-md  cursor-pointer }`}
            key={institution?.label}
            {...props}
            onClick={() => {
              onSelect?.(institution?.label);
            }}
          >
            <div className="py-4 px-4 flex gap-6 items-center justify-between">
              <div className="flex items-center gap-4">
                <div
                  style={{
                    width: 40,
                    height: 40,
                  }}
                  className="rounded-full flex items-center justify-center bg-[#DEDEDE]"
                >
                  {<institution.icon />}
                </div>
                <div>
                  {/* <p className="text-muted-foreground">{institution?.label}</p> */}
                  <h3 className="text-lg font-semibold">{institution?.name}</h3>
                </div>
              </div>

              <ChevronRight />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
