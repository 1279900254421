import { useMemo, useState } from "react";
import { getRandomColor } from "../helpers/utils";

export function useLocalPagination() {
  const [isFetchingMore, setIsFetchingMore] = useState(true);
  const [data, setData] = useState([]);
  const [allLocalItems, setAllLocalItems] = useState([]);
  const [totalItems, setTotalItems] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQ, setSearchQ] = useState("");

  const totalPages = useMemo(
    () => Math.ceil(totalItems / itemsPerPage),
    [totalItems]
  );

  const [localPaginatedData, setLocalPaginatedData] = useState([]);

  const skipTo = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    if (currentPage * itemsPerPage >= allLocalItems.length) {
      return;
    }

    setPaginatedData(
      allLocalItems.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
      )
    );
    setCurrentPage((prev) => prev + 1);
  };

  const prevPage = () => {
    setPaginatedData(allLocalItems.slice(0, (currentPage - 1) * itemsPerPage));
  };

  const setPaginatedData = (newData) => {
    const prevData = data?.slice();
    const dataMap = new Map(prevData.map((item) => [item.id, item]));
    newData.forEach((item) => dataMap.set(item.id, item));
    const res = Array.from(dataMap.values());

    setData(
      res?.map((item, index) => ({ ...item, bgColor: getRandomColor(index) }))
    );
    setLocalPaginatedData(
      res?.map((item, index) => ({ ...item, bgColor: getRandomColor(index) }))
    );
  };

  const handleSearch = (query) => {
    if (!query) {
      const da = allLocalItems.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );
      setData(da);
      return;
    }

    const results = allLocalItems.filter((item) =>
      item?.name?.toLowerCase()?.includes(query?.toLowerCase())
    );

    setData(results);
    setCurrentPage(1);
  };

  const resetQuery = () => {
    setSearchQ("");
    setData(localPaginatedData);
  };

  function setInitialItems(items) {
    setAllLocalItems(items);
    setTotalItems(items.length);

    setData(items.slice(0, itemsPerPage));
  }

  return {
    totalItems,
    currentPage,
    itemsPerPage,
    skipTo,
    nextPage,
    prevPage,
    setPaginatedData,
    isFetchingMore,
    setIsFetchingMore,
    data,
    totalPages,
    searchQ,
    setSearchQ,
    handleSearch,
    resetQuery,
    setInitialItems,
  };
}
