import React, {useEffect, useState} from "react";
import {useToasts} from "react-toast-notifications";
import restService from "../../../services/restService";
import {Redirect} from "react-router";

const keys = ["account_number", "country_id", "destination_institution_id"];
const AddWallet = ({receiver, initializeCallback, closeModal}) => {
  const {addToast} = useToasts();

  const [form, setForm] = useState({
    account_number: "",
    country_id: "",
    destination_institution_id: "",
  });
  const [errors, setErrors] = useState({});
  const [institutions, setInstitutions] = useState([]);

  const initFormValues = async () => {
    let details = {};
    restService.UtilsApi.getDestinationInstitutionsByCountry(receiver.id).then(
      ({data}) => {
        setInstitutions(data?.data);
        setForm({
          ...form,
          country_id: receiver.country.id,
          destination_institution_id: data?.data?.[0]?.id,
        });
      }
    );
  };

  useEffect(() => {
    initFormValues();
  }, []);

  useEffect(() => {
    initializeCallback(saveWallet);
  }, [form]);

  const validateForm = () => {
    let errorList = {...errors};

    const wallets = receiver.receiver_wallets;
    wallets?.forEach((wallet) => {
      if (
        wallet?.destination_institution?.id ==
          form.destination_institution_id &&
        wallet.account_number == form.account_number
      ) {
        errorList.account_number = "Cannot add duplicate wallets.";
      }
    });
    keys.forEach((key) => {
      if (!form[key]) {
        errorList[key] = "This field is required.";
      }
    });
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };

  const saveWallet = () => {
    if (validateForm()) {
      restService.ReceiversApi.addWallet(form, receiver.id).then((response) => {
        addToast("Successfully Added Charge", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
        closeModal();
      });
    }
  };

  const onFormChange = ({target}) => {
    let details = {...form};
    details[target.name] = target.value;

    setForm(details);

    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);
  };

  return (
    <div className="intro-y box">
      <div className="">
        <div className="text-gray-600 text-center">
          Please enter the wallet details.
        </div>
      </div>
      <div className="px-2 border-t border-gray-200 dark:border-dark-5">
        <div className="mt-5">
          <div className="grid grid-cols-12 gap-4 gap-y-5 ">
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-1" className="form-label">
                Account Number *
              </label>
              <input
                type="text"
                value={form.account_number}
                onChange={onFormChange}
                name="account_number"
                className="form-control"
                placeholder="44449993849322"
              />
              <p className="text-red-500 text-xs italic pt-1">
                {errors.account_number}
              </p>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <label htmlFor="input-wizard-5" className="form-label">
                Destination Institution
              </label>
              <select
                id="input-wizard-6"
                value={form.destination_institution_id}
                onChange={onFormChange}
                name="destination_institution_id"
                className="form-select"
              >
                <option value="">None</option>
                {institutions.map((institution) => {
                  return (
                    <option value={institution.id}>{institution.name}</option>
                  );
                })}
              </select>
              <p className="text-red-500 text-xs italic pt-1">
                {errors.destination_institution_id}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddWallet;
