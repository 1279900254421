import { useEffect, useState } from "react";
import { ArrowLeft, ChevronRight, User } from "react-feather";
import Button from "../../../../components/common/Button";
import { useSendMoneyContext } from "../../../../contexts/send-money";
import { getRandomColor } from "../../../../helpers/utils";
import restService from "../../../../services/restService";
import { AddNewWalletModal } from "./components/add-new-wallet";
import Search from "./components/search";
import { Title } from "./components/title";
import AddWallet from "./components/add-wallet";
import Loader from "../../../../components/common/Loader";

export function DestinationWallet() {
  const [searchQ, setSearchQ] = useState("");
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { setSendMoneyData, sendMoneyData, setCurrentStep } =
    useSendMoneyContext();

  const fetchReceiverById = async () => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await restService.ReceiversApi.getReceiverById(
        sendMoneyData?.receiver?.id
      );

      const s = {
        ...data,
        receiver_wallets: data?.receiver_wallets?.map((wallet, index) => ({
          ...wallet,
          bgColor: getRandomColor(index),
        })),
      };

      setSendMoneyData({ ...sendMoneyData, receiver: s });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchReceiverById();
  // }, []);

  function filteredData() {
    if (!searchQ) return sendMoneyData?.receiver?.receiver_wallets ?? [];

    return (sendMoneyData?.receiver?.receiver_wallets ?? []).filter((wallet) =>
      wallet?.account_number?.includes(searchQ)
    );
  }

  return (
    <div className="container flex flex-col gap-8">
      {isLoading && <Loader message={"Please wait..."} />}
      <div className="flex flex-col gap-4">
        <div className="flex gap-4 items-center justify-between">
          <div className="flex items-center gap-4">
            <Button
              onClick={() => setCurrentStep((prev) => prev - 1)}
              className="h-6 w-6 bg-white rounded-full  flex items-center gap-2"
            >
              <ArrowLeft size={20} />
            </Button>

            <Title title={"Wallet"} />
          </div>

          {showWalletModal && (
            <Button
              onClick={() => setShowWalletModal(false)}
              className="btn btn-secondary  flex items-center gap-2 text-xs"
            >
              Cancel
            </Button>
          )}
        </div>

        <div className="flex items-center justify-between">
          <div
            style={{
              maxWidth: "300px",
            }}
            className="w-full"
          >
            <Search
              disabled={showWalletModal}
              placeholder={"Search for wallet...  "}
              onChange={(e) => setSearchQ(e.target.value)}
            />
          </div>
        </div>
      </div>

      {!showWalletModal ? (
        <>
          <div className="flex flex-col gap-4">
            <h4 className="text-lg font-medium">Saved Wallets</h4>
            <hr className="border-[#DEDEDE]" />

            <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-4">
              {filteredData()?.map((wallet) => (
                <div
                  style={
                    wallet?.bgColor ? { backgroundColor: wallet?.bgColor } : {}
                  }
                  onClick={() => {
                    setSendMoneyData({
                      ...sendMoneyData,
                      destination_wallet: wallet,
                    });
                    setCurrentStep(5);
                  }}
                  className={`zoom-in  border rounded-md  cursor-pointer ${
                    sendMoneyData?.destination_wallet?.id === wallet?.id
                      ? " border-[#0042C6] "
                      : " border-[#DEDEDE] "
                  }
            }`}
                  key={wallet?.id}
                >
                  <div className="py-4 px-4 flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      <div
                        style={{
                          width: 40,
                          height: 40,
                        }}
                        className="rounded-full flex items-center justify-center bg-[#DEDEDE]"
                      >
                        <User />
                      </div>
                      <div>
                        <p className="text-muted-foreground">
                          {wallet?.destination_institution?.name}
                        </p>
                        <h3 className="text-lg font-semibold">
                          {wallet?.account_number}
                        </h3>
                      </div>
                    </div>

                    <ChevronRight />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <h4 className="text-lg font-medium">Additional Wallets</h4>
            <hr className="border-[#DEDEDE]" />

            <div>
              <button className="py-4 rounded px-4 flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div
                    style={{
                      width: 40,
                      height: 40,
                    }}
                    className="rounded-full flex items-center justify-center bg-[#DEDEDE]"
                  >
                    <User />
                  </div>
                  <div>
                    <button
                      onClick={() => setShowWalletModal(true)}
                      className="text-base font-semibold"
                    >
                      Add a new wallet
                    </button>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </>
      ) : (
        <AddWallet
          disbursal_country={sendMoneyData?.disbursal_country}
          receiver={sendMoneyData?.receiver}
          onSuccess={async (newWallet) => {
            fetchReceiverById().finally(() => {
              setSendMoneyData({
                ...sendMoneyData,
                destination_wallet: newWallet,
              });
              setCurrentStep(5);
            });
          }}
        />
      )}

      {/* <AddNewWalletModal
        fetchReceiverById={fetchReceiverById}
        receiver={sendMoneyData?.receiver}
        setShowWalletModal={setShowWalletModal}
        showWalletModal={showWalletModal}
      /> */}
    </div>
  );
}
