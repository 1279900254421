import React from 'react';
import{Col, DatePicker, Row , Form,Modal,Input,Select} from "antd";
const { RangePicker } = DatePicker;

export const FilterModal = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();


  const rangeConfig = {
    rules: [
      {
        type: 'array',
        required: false,
        message: 'Please select a date',
      },
    ],
  };



  return (
    <Modal
      visible={visible}
      title="Filter Commissions"
      okText="Apply Filters"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            // console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
      >

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item name="dateRange" label="Date Range" {...rangeConfig}>
              <RangePicker />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default FilterModal;
