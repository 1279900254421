import React, {useState} from "react";
import {confirmOtp} from "../../redux/actions/auth";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import CodeField from "../../components/common/CodeField";
import Button from "../../components/common/Button";

const Otp = ({msisdn, userType}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [codeValidation, setCodeValidation] = useState("");

  const handleOtpConfirmation = () => {
    setLoading(true);
    dispatch(confirmOtp(msisdn, otp, userType))
      .then(() => {
        userType === "staff" ? history.push("/") : history.push("/send-money");
      })
      .catch((error) => {
        if (
          error?.response?.data?.code === 417 ||
          error?.response?.data?.code === 400
        ) {
          setCodeValidation("Invalid Code");
        } else {
          setCodeValidation("Validation Error");
        }
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col gap-8 items-start">
      {codeValidation && (
        <p className="validation-error flex items-center gap-2 text-left msisdn-error-text">
          <span>
            <svg
              width="15.6"
              height="15.6"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.78933 0.200012H2.81067L0 3.01068V6.98935L2.81067 9.80001H6.78933L9.6 6.98935V3.01068L6.78933 0.200012ZM7.46667 6.99468L6.79467 7.66668L4.8 5.67201L2.80533 7.66668L2.13333 6.99468L4.128 5.00001L2.13333 3.00535L2.80533 2.33335L4.8 4.32801L6.79467 2.33335L7.46667 3.00535L5.472 5.00001L7.46667 6.99468Z"
                fill="#F9105F"
              />
            </svg>
          </span>
          <span>{codeValidation}</span>
        </p>
      )}

      <div className="flex w-full flex-col gap-16 items-start">
        <div className="flex w-full flex-col gap-8 items-start">
          <div className="flex flex-col items-start gap-4">
            <h2 className="font-bold text-2xl">Enter OTP Code</h2>
            <p className="font-medium text-sm label-text">
              Code sent to {msisdn}
            </p>
          </div>
          <div className="pb-2 w-full pt-4">
            <CodeField
              className={"block w-full p-4 text-lg rounded-sm"}
              value={otp}
              onChange={(e) => {
                setCodeValidation("");
                setOtp(e);
              }}
              validationMessage={codeValidation}
              onSubmit={handleOtpConfirmation}
              isDisabled={loading}
            />
          </div>
          <div className="text-gray-400 font-medium  hover:text-gray-100">
            <a className="hover:underline" href="#">
              Resend Code
            </a>
          </div>
        </div>

        <div className="w-full">
          {loading ? (
            <Button
              disabled={loading}
              className={"primary-btn w-full justify-center"}
            >
              <div className=" flex justify-center items-center">
                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
              </div>
            </Button>
          ) : (
            <Button
              onClick={handleOtpConfirmation}
              disabled={otp.length < 6}
              className={"primary-btn w-full justify-center"}
            >
              Verify Phone
            </Button>
          )}
        </div>
      </div>

      <div className="absolute bottom-4 left-16 label-text">
        ©2023 Instaant Transfers
      </div>
    </div>
  );
};

export default Otp;
