import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import Modal from "../../../components/common/Modal";
import ReactTooltip from "react-tooltip";
import UpdateAgency from "./UpdateAgency";
import {useToasts} from "react-toast-notifications";
import restService from "../../../services/restService";
import {Badge} from "antd";
import Pusher from "pusher-js";
import Search from "../../../components/common/Search";
import PaginationComponent from "../../../components/common/Pagination";
import AddNewAgency from "../../../components/common/AddNewAgency";
require("moment");
const Agencies = () => {
  const {addToast} = useToasts();
  const [agencyToRemove, setAgencyToRemove] = useState(undefined);
  const [agencyToUpdate, setAgencyToUpdate] = useState(undefined);
  const [agencyToSuspend, setAgencyToSuspend] = useState(undefined);
  const [agencyToActivate, setAgencyToActivate] = useState(undefined);
  const [topUpEValue, setTopUpEValue] = useState(undefined);
  const [topUpEValueAmount, setTopUpEValueAmount] = useState(0);
  const [reduceProfile, setReduceProfile] = useState(undefined);
  const [reduceProfileAmount, setReduceProfileAmount] = useState(0);
  const [callback, setCallback] = useState(() => {});
  const [agencies, setAgencies] = useState([]);
  const [pageTo, setPageTo] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [from, setFrom] = useState(0);
  const [pusherChannel, setPusherChannel] = useState(null);
  const [pusherChannel2, setPusherChannel2] = useState(null);
  const [showAddNewAgency, setShowAddNewAgency] = useState(false);

  const fetchAgencies = (page) => {
    restService.AgencyApi.getAgencies(page).then(({data}) => {
      setAgencies(data?.data?.data);
      setPageTo(data?.data?.to);
      setTotalEntries(data?.data?.total);
      setFrom(data?.data?.from);
    });
  };
  const setTopUpCallback = (data) => {
    let indexOf = -1;
    agencies.forEach((a, index) => {
      if (a.id === data.message?.model.id) {
        indexOf = index;
      }
    });

    if (indexOf >= 0) {
      let agency = agencies[indexOf];
      agency.actual_balance = data.message.model.actual_balance;
      agency.balance_available = data.message.model.balance_available;
      agencies[indexOf] = agency;
      setAgencies([...agencies]);
    }
  };

  const setDebtCallback = (data) => {
    let indexOf = -1;
    agencies.forEach((a, index) => {
      if (a.id === data.message?.model.id) {
        indexOf = index;
      }
    });

    if (indexOf >= 0) {
      let agency = agencies[indexOf];
      agency.balance_owed = data.message.model.balance_owed;
      agencies[indexOf] = agency;

      setAgencies([...agencies]);
    }
  };
  useEffect(() => {
    fetchAgencies();
    const pusher = new Pusher(
        process.env.REACT_APP_API_ENVIRONMENT === "dev"
            ? process.env.REACT_APP_PUSHER_KEY_DEVELOPMENT:
            process.env.REACT_APP_API_ENVIRONMENT === "staging" ?
                process.env.REACT_APP_PUSHER_KEY_STAGING
                : process.env.REACT_APP_PUSHER_KEY_PRODUCTION,
      {
        cluster: "eu",
        forceTLS: true,
      }
    );
    const channel = pusher.subscribe(`general-evalue-topup`);
    setPusherChannel(channel);

    const channel2 = pusher.subscribe(`general-debt-reduction`);
    setPusherChannel2(channel2);
  }, []);

  useEffect(() => {
    if (
      pusherChannel &&
      pusherChannel.bind &&
      pusherChannel2 &&
      pusherChannel2.bind
    ) {
      pusherChannel.unbind("agency-evalue-topup");
      pusherChannel2.unbind("agency-debt-reduction");
      pusherChannel.bind("agency-evalue-topup", setTopUpCallback);
      pusherChannel2.bind("agency-debt-reduction", setDebtCallback);
    }
  }, [pusherChannel, pusherChannel2, agencies]);

  const onAgencyUpdate = () => {
    setAgencyToUpdate(undefined);
    addToast("Successfully Updated Agency", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 5000,
    });
    fetchAgencies(currentPage);
  };

  const suspendAgency = () => {
    let data = {id: agencyToSuspend.id, status: "suspended"};
    restService.AgencyApi.updateAgency(data).then((response) => {
      addToast("Successfully Suspended Agency", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      setAgencyToSuspend(undefined);
      fetchAgencies(currentPage);
    });
  };

  const activateAgency = () => {
    let data = {id: agencyToActivate.id, status: "active"};
    restService.AgencyApi.updateAgency(data).then((response) => {
      addToast("Successfully activated Agency", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      setAgencyToActivate(undefined);
      fetchAgencies(currentPage);
    });
  };

  const topUp = () => {
    let data = {amount: topUpEValueAmount};
    restService.EValueAPi.topUpAgency(data, topUpEValue.id)
      .then((response) => {
        addToast("Successfully Topped up Agency e-value", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .finally(() => {
        setTopUpEValueAmount(0);
        setTopUpEValue(undefined);
      });
  };

  const reduceDebt = () => {
    let data = {amount: reduceProfileAmount};
    restService.EValueAPi.reduceAgencyDebt(data, reduceProfile.id)
      .then((response) => {
        addToast("Successfully reduced Agency debt profile", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .finally(() => {
        setReduceProfileAmount(0);
        setReduceProfile(undefined);
      });
  };

  const removeAgency = () => {
    let data = {id: agencyToRemove.id};
    restService.AgencyApi.removeAgency(data).then((response) => {
      addToast("Successfully Removed Agency", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      setAgencyToRemove(undefined);
      fetchAgencies(currentPage);
    });
  };
  const pageChange = (page) => {
    setCurrentPage(page);
    fetchAgencies(page);
  };
  return (
    <div className="mt-[5rem]">
      <div className="intro-y col-span-12 flex w-full justify-between flex-wrap md:flex-row-reverse sm:flex-nowrap items-center mt-2">
        <button
          onClick={() => setShowAddNewAgency(true)}
          className="btn btn-primary-2 gap-1 items-center shadow-md mr-2"
        >
          <span>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.98389 1.24805C6.24188 1.24805 6.45103 1.45719 6.45103 1.71519V5.45229H10.1881C10.4461 5.45229 10.6553 5.66144 10.6553 5.91943C10.6553 6.17743 10.4461 6.38657 10.1881 6.38657H6.45103V10.1237C6.45103 10.3817 6.24188 10.5908 5.98389 10.5908C5.72589 10.5908 5.51675 10.3817 5.51675 10.1237V6.38657H1.77964C1.52165 6.38657 1.3125 6.17743 1.3125 5.91943C1.3125 5.66144 1.52165 5.45229 1.77964 5.45229H5.51675V1.71519C5.51675 1.45719 5.72589 1.24805 5.98389 1.24805Z"
                fill="black"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span>Add New Agency</span>
        </button>

        <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <Search
              placeholder={"Search "}
              // onChange={(e) => searchAgentByInput(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-6 mt-5 modal-active">
        {agencies.map((agency) => {
          return (
            <div className="intro-y col-span-12 md:col-span-6 lg:col-span-4">
              <Badge.Ribbon
                size="default"
                text={`Available : ${agency?.country?.currency_symbol}${agency.balance_available}`}
                color="#2db7f5"
              >
                <div className="box">
                  <div className="flex items-start px-5 pt-5">
                    <div className="w-full flex flex-col lg:flex-row items-center">
                      <div className="w-16 h-16 image-fit">
                        <img
                          alt="#"
                          className="rounded-full"
                          src="https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png"
                        />
                      </div>
                      <div className="lg:ml-4 text-center lg:text-left mt-3 lg:mt-0">
                        <div className="font-medium">{`${agency.name}`}</div>
                        <div className="text-gray-600 text-xs mt-0.5">
                          {agency.email}
                        </div>
                        <div className="text-gray-600 text-xs mt-0.5">
                          {agency.msisdn}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center lg:text-left p-5">
                    <div className="flex items-center justify-center lg:justify-start text-gray-600 mt-1">
                      Actual Balance : {agency?.country?.currency_symbol}
                      {agency.actual_balance}
                    </div>
                    {/*<div className="flex items-center justify-center lg:justify-start text-gray-600 mt-1">*/}
                    {/*  Amount Owed : {agency?.country?.currency_symbol}*/}
                    {/*  {agency.balance_owed}*/}
                    {/*</div>*/}
                  </div>
                  <div className="text-center justify-end flex flex-col md:flex-row  lg:text-right p-3 border-t border-gray-200 ">
                    <div className="flex  flex-wrap items-center justify-center">
                      {/*<button*/}
                      {/*  data-tip="Topup Agency e-value"*/}
                      {/*  onClick={() => {*/}
                      {/*    setTopUpEValue(agency);*/}
                      {/*  }}*/}
                      {/*  className="btn mr-3 mt-1 border-none outline-none shadow-none focus:outline-none transaction-details"*/}
                      {/*>*/}
                      {/*  <svg*/}
                      {/*    xmlns="http://www.w3.org/2000/svg"*/}
                      {/*    className="h-7 w-7"*/}
                      {/*    viewBox="0 0 24 24"*/}
                      {/*    fill="green"*/}
                      {/*  >*/}
                      {/*    <path*/}
                      {/*      fillRule="evenodd"*/}
                      {/*      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"*/}
                      {/*      clipRule="evenodd"*/}
                      {/*    />*/}
                      {/*  </svg>*/}
                      {/*</button>*/}
                      {/*<button*/}
                      {/*  data-tip="Reduce Agency Debt Profile"*/}
                      {/*  onClick={() => {*/}
                      {/*    setReduceProfile(agency);*/}
                      {/*  }}*/}
                      {/*  className="btn mt-1 mr-3 border-none outline-none shadow-none focus:outline-none transaction-details"*/}
                      {/*>*/}
                      {/*  <svg*/}
                      {/*    xmlns="http://www.w3.org/2000/svg"*/}
                      {/*    className="h-7 w-7"*/}
                      {/*    viewBox="0 0 24 24"*/}
                      {/*    fill="red"*/}
                      {/*  >*/}
                      {/*    <path*/}
                      {/*      fillRule="evenodd"*/}
                      {/*      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"*/}
                      {/*      clipRule="evenodd"*/}
                      {/*    />*/}
                      {/*  </svg>*/}
                      {/*</button>*/}
                      <Link
                        to={`/commissions/agency/${agency.id}`}
                        data-tip="Commissions"
                        onClick={() => {}}
                        className="btn mr-3 border-none outline-none shadow-none focus:outline-none transaction-details"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="green"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </Link>
                      <Link
                        to={`/statement/agency/${agency.id}`}
                        data-tip="statement"
                        onClick={() => {}}
                        className="btn mr-3 border-none outline-none shadow-none focus:outline-none transaction-details"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Link>
                      <button
                        data-tip="Update"
                        onClick={() => {
                          setAgencyToUpdate(agency);
                        }}
                        className="btn mr-3 border-none outline-none shadow-none focus:outline-none transaction-details"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </button>
                      {agency.status === "active" && (
                        <button
                          data-tip="Suspend"
                          onClick={() => {
                            setAgencyToSuspend(agency);
                          }}
                          className="btn mr-3 border-none outline-none shadow-none focus:outline-none transaction-details"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="#d32929"
                            viewBox="0 0 24 24"
                            stroke="white"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </button>
                      )}
                      {agency.status !== "active" && (
                        <button
                          data-tip="Activate"
                          onClick={() => {
                            setAgencyToActivate(agency);
                          }}
                          className="btn mr-3 border-none outline-none shadow-none focus:outline-none transaction-details"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="green"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      )}
                      <ReactTooltip
                        backgroundColor="#f1f5f8"
                        textColor="black"
                      />
                    </div>
                  </div>
                </div>
              </Badge.Ribbon>

              {/*<AgencyCard agency={agency} />*/}
            </div>
          );
        })}
      </div>
      {agencyToRemove && (
        <Modal
          onClick={removeAgency}
          modalTitle="Remove Agency"
          buttonTitle="Remove"
          buttonColor={"bg-red-600"}
          size="small"
          body={
            <p className="text-sm text-gray-500">
              Are you sure you want to remove {agencyToRemove.name}? This action
              cannot be undone.
            </p>
          }
          onClose={() => {
            setAgencyToRemove(undefined);
          }}
        />
      )}
      {agencyToSuspend && (
        <Modal
          onClick={suspendAgency}
          modalTitle="Suspend Agency"
          buttonTitle="Suspend"
          buttonColor={"bg-red-600"}
          size="small"
          body={
            <p className="text-sm text-gray-500">
              Are you sure you want to suspend {`${agencyToSuspend.name} `}?
            </p>
          }
          onClose={() => {
            setAgencyToSuspend(undefined);
          }}
        />
      )}

      {agencyToActivate && (
        <Modal
          onClick={activateAgency}
          modalTitle="Activate Agency"
          buttonTitle="Activate"
          buttonColor={"bg-green-600"}
          size="small"
          body={
            <p className="text-sm text-gray-500">
              Are you sure you want to activate {`${agencyToActivate.name}`}?
            </p>
          }
          onClose={() => {
            setAgencyToActivate(undefined);
          }}
        />
      )}

      {agencyToUpdate && (
        <Modal
          size={"small"}
          onClick={callback}
          modalTitle="Update agency"
          buttonTitle="Update agency"
          buttonColor="bg-blue-600"
          body={
            <UpdateAgency
              agency={agencyToUpdate}
              initCallback={(callbackFunc) => setCallback(() => callbackFunc)}
              onSubmit={onAgencyUpdate}
            />
          }
          onClose={() => {
            setAgencyToUpdate(undefined);
          }}
        />
      )}

      {topUpEValue && (
        <Modal
          onClick={topUp}
          disableButton={
            !topUpEValueAmount ||
            isNaN(topUpEValueAmount) ||
            topUpEValueAmount <= 0
          }
          modalTitle="Top up agency e-value"
          buttonTitle="Top up"
          buttonColor={"bg-green-600"}
          size="small"
          body={
            <div className="grid grid-cols-12 gap-4 gap-y-5 ">
              <div className="intro-y col-span-12">
                <label htmlFor="input-wizard-1" className="form-label">
                  Amount *
                </label>
                <input
                  id="input-wizard-1"
                  type="number"
                  value={topUpEValueAmount}
                  onChange={(e) => {
                    setTopUpEValueAmount(e.target.value);
                  }}
                  name="reduce"
                  className="form-control"
                />
                <p className="text-red-500 text-xs italic pt-1">
                  {!topUpEValueAmount ||
                  isNaN(topUpEValueAmount) ||
                  topUpEValueAmount <= 0
                    ? "Enter a valid number greater than 0"
                    : ""}
                </p>
              </div>
            </div>
          }
          onClose={() => {
            setTopUpEValue(undefined);
            setTopUpEValueAmount(0);
          }}
        />
      )}

      {reduceProfile && (
        <Modal
          onClick={reduceDebt}
          disableButton={
            !reduceProfileAmount ||
            isNaN(reduceProfileAmount) ||
            reduceProfileAmount <= 0
          }
          modalTitle="Reduce agency debt profile"
          buttonTitle="Reduce debt"
          buttonColor={"bg-green-600"}
          size="small"
          body={
            <div className="mt-5">
              <div className="grid grid-cols-12 gap-4 gap-y-5 ">
                <div className="intro-y col-span-12">
                  <label htmlFor="input-wizard-1" className="form-label">
                    Amount *
                  </label>
                  <input
                    id="input-wizard-1"
                    type="number"
                    value={reduceProfileAmount}
                    onChange={(e) => {
                      setReduceProfileAmount(e.target.value);
                    }}
                    name="reduce"
                    className="form-control"
                  />
                  <p className="text-red-500 text-xs italic pt-1">
                    {!reduceProfileAmount ||
                    isNaN(reduceProfileAmount) ||
                    reduceProfileAmount <= 0
                      ? "Enter a valid number greater than 0"
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          }
          onClose={() => {
            setReduceProfile(undefined);
            setReduceProfileAmount(0);
          }}
        />
      )}

      <div className="flex flex-col mb-[10vh] gap-4 justify-between md:items-center md:flex-row mt-8">
        <div className="page text-[#999999] text-xs">
          {agencies && agencies.length <= 0
            ? "No Agencies Found"
            : `Showing ${from} to ${pageTo} of ${totalEntries} entries`}
        </div>

        <div className="mt-3  flex justify-end float-right">
          <PaginationComponent
            current={currentPage}
            defaultCurrent={currentPage}
            showSizeChanger={false}
            onChange={pageChange}
            total={totalEntries}
          />
        </div>
      </div>

      {showAddNewAgency && <AddNewAgency setShow={setShowAddNewAgency} />}
    </div>
  );
};
export default Agencies;
