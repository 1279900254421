import {applyMiddleware, createStore} from "redux";
import rootReducer from './reducers';

import thunk from "redux-thunk";
const loadState = () => {
    try {
        const serializedState = localStorage.getItem("state");
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
}

const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("state", serializedState);
    } catch (error) {
        //console.log(error)
    }
}
const persistedState = loadState();
const store = createStore(
    rootReducer,
    persistedState, applyMiddleware(thunk)
);
store.subscribe(() => {
    saveState(store.getState())
})
export default store;
