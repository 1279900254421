import React, { useEffect } from "react";
import errorIMG from "../../assets/images/404.svg";

const PageNotFound = ({ setShowSidebar, setShowNavbar }) => {
  useEffect(() => {
    const parentElement = document.getElementById("main-content");
    if (parentElement?.classList?.contains("content")) {
      parentElement.classList.remove("content");
    }
    setShowSidebar?.(false);
    setShowNavbar?.(false);

    return () => {
      setShowSidebar?.(true);
      setShowNavbar?.(true);
    };
  }, [setShowSidebar, setShowNavbar]);

  return (
    <div className="w-full max-h-screen overflow-hidden">
      <img src={errorIMG} alt="" className="object-cover w-full h-screen" />
    </div>
  );
};

export default PageNotFound;
