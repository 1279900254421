import React from "react";
import Loader from "../../../../components/common/Loader";
import Modal2 from "../../../../components/common/Modal/Modal2";
import Input from "../../../../components/common/Input";
import {useToasts} from "react-toast-notifications";
import restService from "../../../../services/restService";
import {useEffect} from "react";
import {useState} from "react";

const keys = ["rate", "destination_currency", "source_currency"];
const AddCommissionModal = ({isAddingID, setShow}) => {
  const {addToast} = useToasts();

  const [form, setForm] = useState({
    agent_id: "",
    agency_id: "",
    agency_percentage: 30,
    agent_percentage: 30,
    company_percentage: 40,
  });
  const [errors, setErrors] = useState({});
  const [agents, setAgents] = useState([]);
  const [agencies, setAgencies] = useState([]);

  const initFormValues = async () => {
    const agencyList = await restService.AgencyApi.getAgencies();
    const agentList = await restService.AgentApi.getAgentsByAgency(
      agencyList.data?.data?.data[0]?.id
    );
    setAgents(agentList.data?.data?.data);
    setAgencies(agencyList.data?.data?.data);

    setForm({
      ...form,
      agency_id: agencyList.data?.data?.data[0]?.id,
      agent_id: agentList.data?.data?.data[0]?.id,
    });
  };

  useEffect(() => {
    initFormValues();
  }, []);

  const validateForm = () => {
    const keys = Object.keys(form);

    let errorList = {...errors};
    keys.forEach((key) => {
      if (form[key] === "" || form[key] === undefined) {
        errorList[key] = "This field is required.";
      }
    });

    if (
      Number(form.agency_percentage) +
        Number(form.agent_percentage) +
        Number(form.company_percentage) !==
      100
    ) {
      errorList.total =
        "Agency, agent, and company Percentage should add up to 100.";
    }
    setErrors(errorList);
    return Object.keys(errorList).length === 0;
  };

  const saveCommission = () => {
    if (validateForm()) {
      restService.CommissionsConfigApi.addCommissionConfiguration(form).then(
        (response) => {
          setForm({
            agency_percentage: 30,
            agent_percentage: 30,
            company_percentage: 40,
          });
          addToast("Successfully Added Commission Configuration", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      );
    }
  };

  const onFormChange = ({target}) => {
    let details = {...form};
    details[target.name] = target.value;
    let errorList = {...errors};

    if (target.name === "agency_id") {
      restService.AgentApi.getAgentsByAgency(target.value).then(({data}) => {
        setAgents(data?.data.data);
        setForm({...details, agent_id: data?.data?.data[0]?.id});
      });

      delete errorList["agent_id"];
    }
    setForm(details);

    delete errorList[target.name];
    delete errorList.total;
    setErrors(errorList);
  };

  const handlePercentageChange = (e) => {
    const {name, value} = e.target;
    const otherPercentage =
      name === "agent_percentage"
        ? form.agency_percentage
        : form.agent_percentage;
    let sum = parseFloat(value) + parseFloat(otherPercentage);

    if (sum < 0) {
      setForm({
        ...form,
        [name]: 0,
        [name === "agent_percentage"
          ? "agency_percentage"
          : "agent_percentage"]: 0,
      });
    } else if (sum > 100) {
      setForm({
        ...form,
        [name]: 100 - otherPercentage,
        [name === "agent_percentage"
          ? "agency_percentage"
          : "agent_percentage"]: otherPercentage,
      });
    } else {
      setForm({...form, [name]: value});
    }
  };

  useEffect(() => {
    setForm({
      ...form,
      company_percentage:
        100 - (Number(form.agency_percentage) + Number(form.agent_percentage)),
    });
  }, [form.agency_percentage_percentage, form.agent_percentage]);

  return (
    <>
      {isAddingID ? <Loader /> : null}
      <Modal2
        onHide={() => {
          setShow(false);
        }}
        size={"lg"}
      >
        <>
          {/* {senderAdded ? (
              <div className="h-[50vh] flex items-center justify-center">
                <Success />
              </div>
            ) : ( */}
          <div className="p-8 flex intro-y flex-col gap-4 ">
            <span className="cursor-pointer" onClick={() => setShow(false)}>
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.14446 1.14446L3.09818 5.19074H12.4741V6.80926H3.09818L7.14446 10.8555L6 12L0 6L6 0L7.14446 1.14446Z"
                  fill="black"
                />
              </svg>
            </span>

            <div className="flex flex-col gap-11 items-center justify-center">
              <div className="flex flex-col items-center justify-center">
                <h1 className="text-theme-32 text-center font-medium text-xl">
                  Add Commission Configuration
                </h1>
                <p className="text-[#727681] text-center text-xs font-normal">
                  Please enter the configuration details.
                </p>
              </div>
              <div className="grid w-full grid-cols-12 items-start flex-wrap gap-4">
                <div className="col-span-12  sm:col-span-6 w-full  ">
                  <Input
                    containerStyle={"w-full md:w-auto"}
                    labelFor={"agency_percentage"}
                    className="md:min-w-[200px] w-full"
                    type="number"
                    min={0}
                    max={100}
                    value={form.agency_percentage}
                    onChange={handlePercentageChange}
                    name="agency_percentage"
                    labelName={" Agency Percentage *"}
                    placeholder="13.20"
                  />
                  {errors.agency_percentage && (
                    <p className="text-red-500 h-full text-[10px] italic pt-1">
                      {errors?.agency_percentage}
                    </p>
                  )}
                </div>

                <div className="col-span-12  sm:col-span-6 w-full  ">
                  <Input
                    containerStyle={"w-full md:w-auto"}
                    labelFor={"agent_percentage"}
                    className="md:min-w-[200px] w-full"
                    type="number"
                    min={0}
                    max={100}
                    value={form.agent_percentage}
                    onChange={handlePercentageChange}
                    name="agent_percentage"
                    labelName={" Agent Percentage *"}
                    placeholder="13.20"
                  />
                  {errors.agent_percentage && (
                    <p className="text-red-500 h-full text-[10px] italic pt-1">
                      {errors?.agent_percentage}
                    </p>
                  )}
                </div>

                <div className="col-span-12  sm:col-span-6 w-full  ">
                  <Input
                    containerStyle={"w-full md:w-auto"}
                    labelFor={"agent_percentage"}
                    className="md:min-w-[200px] w-full"
                    type="number"
                    min={0}
                    max={100}
                    value={
                      100 -
                      (Number(form.agency_percentage) +
                        Number(form.agent_percentage))
                    }
                    disabled
                    onChange={onFormChange}
                    name="company_percentage"
                    labelName={"Company Percentage *"}
                    placeholder="13.20"
                  />
                  {errors.company_percentage && (
                    <p className="text-red-500 h-full text-[10px] italic pt-1">
                      {errors?.company_percentage}
                    </p>
                  )}
                </div>

                <div className="col-span-12  sm:col-span-6 flex-col flex w-full md:w-auto gap-1">
                  <label className="text-xs font-normal" htmlFor="type">
                    Agency
                  </label>
                  <div className="relative w-full">
                    <select
                      id="agency_id"
                      value={form.agency_id}
                      onChange={onFormChange}
                      name="agency_id"
                      className="border  appearance-none w-full focus:border-theme-31 outline-none rounded px-4 py-2 border-theme-31"
                    >
                      <option value="">None</option>
                      {agencies.map((agency) => {
                        return <option value={agency.id}>{agency.name}</option>;
                      })}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                    {errors.agency_id && (
                      <p className="text-red-500 text-xs italic">
                        {errors.agency_id}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-span-12 sm:col-span-6 flex-col flex w-full md:w-auto gap-1">
                  <label className="text-xs font-normal" htmlFor="type">
                    Agent
                  </label>
                  <div className="relative w-full">
                    <select
                      id="type"
                      value={form?.agent_id}
                      onChange={onFormChange}
                      name="agent_id"
                      className="border  appearance-none w-full focus:border-theme-31 outline-none rounded px-4 py-2 border-theme-31"
                    >
                      <option value="">None</option>
                      {agents.map((agent) => {
                        return (
                          <option value={agent.id}>
                            {agent.firstname} {agent.lastname}
                          </option>
                        );
                      })}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                    {errors.agent_id && (
                      <p className="text-red-500 text-xs italic">
                        {errors.agent_id}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="mx-auto flex items-start flex-col gap-8 justify-start w-full ">
                <div className="">
                  <button
                    onClick={saveCommission}
                    className="flex items-center gap-1 bg-theme-32 border border-theme-32 hover:bg-theme-1 transition text-white rounded-md px-3 py-2"
                  >
                    <span>Add Commission Configuration</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal2>
    </>
  );
};

export default AddCommissionModal;
