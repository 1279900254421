import React, { useEffect, useState } from "react";
import Table2 from "../../../components/common/Table/Table2";
import restService from "../../../services/restService";
import { Link } from "react-router-dom";
import TransactionStyles from "./styles";
import PageTitle from "../../../components/common/PageTitle/PageTitle";
import { useSelector } from "react-redux";
import Search from "../../../components/common/Search";
import "react-datepicker/dist/react-datepicker.css";
import FilterModal from "./FilterModal";
import PaginationComponent from "../../../components/common/Pagination";
import { downloadFile } from "../../../helpers/fileUtils";
import Pusher from "pusher-js";
import DateRangePicker from "../../../components/common/DateRange";
import SkeletonElement from "../../../components/Skeletons/SkeletonElement";
import { Empty } from "antd";
import moment from "moment";
import { addDays } from "date-fns";
import RestService from "../../../services/restService";
const defaultImg =
  "https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png";


const Transact = () => {

  const agentId = useSelector((state) => state?.auth?.user?.id);
  const agencyId = useSelector((state) => state?.auth?.user?.agency?.id);
  const roleId = useSelector((state) => state?.auth.user?.agent_role_id);

  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageTo, setPageTo] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [from, setFrom] = useState(0);
  const [transactionStatuses, setTransactionStatuses] = useState({});
  const [pusherChannel, setPusherChannel] = useState(null);
  const [showDateRange, setShowDateRange] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const fetchTransactions = (agentId, page) => {
    page = page -1;
    setLoading(true);
    if (roleId === 1) {
      restService.TransactionsApi.getTransactions(page)
        .then((response) => {
          if (response) {
            setPageTo(response.data?.data?.to);
            setTotalEntries(response.data?.data?.total);
            setTransactions(response.data?.data?.data);
            setFrom(response.data?.data?.from);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (roleId === 3) {
      restService.TransactionsApi.getTransactionsByAgent(agentId, page)
        .then((response) => {
          if (response) {
            setPageTo(response.data?.data?.to);
            setTotalEntries(response.data?.data?.total);
            setTransactions(response.data?.data?.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchTransactions(agentId, currentPage);

    restService.UtilsApi.getTransactionStatuses().then(({ data }) => {
      if (data?.data) {
        let statusData = {};
        data?.data?.forEach((item) => {
          statusData[item.status] = item.status_message;
        });
        setTransactionStatuses(statusData);
      }
    });

    const pusher = new Pusher(
        process.env.REACT_APP_API_ENVIRONMENT === "dev"
            ? process.env.REACT_APP_PUSHER_KEY_DEVELOPMENT:
            process.env.REACT_APP_API_ENVIRONMENT === "staging" ?
                process.env.REACT_APP_PUSHER_KEY_STAGING
                : process.env.REACT_APP_PUSHER_KEY_PRODUCTION,
      {
        cluster: "eu",
        forceTLS: true,
      }
    );
    const channel = pusher.subscribe(
        roleId === 1
            ? `transactions-agency-${agencyId}`
            : `transactions-agent-${agentId}`
    );

    setPusherChannel(channel);
  }, []);

  useEffect(() => {
    if (pusherChannel && pusherChannel.bind) {
      pusherChannel.unbind("transaction-created");
      pusherChannel.unbind("transaction-update");
      pusherChannel.bind("transaction-created", function (data) {
        //console.log("New transaction", data.message);
        let list = [...transactions];
        list.unshift(data.message);

        setTransactions(list);
      });
      pusherChannel.bind("transaction-update", function (data) {
        //console.log("New transaction updated", data.message);
        if (transactions?.length > 0) {
          let index = transactions.findIndex((trans) => {
            // console.log(data?.message?.id)
            // console.log(trans)
            return trans.id === data?.message?.id;
          });

          if (index >= 0) {
            transactions[index] = data.message;
            setTransactions([...transactions]);
          }
        }
      });
    }
  }, [pusherChannel, transactions]);

  const [fromSortModal, setFromSortModal] = useState(false);

  // useEffect(() => {
  //   if (!fromSortModal) {
  //     handleDateFilter();
  //   }
  // }, [dateRange]);
  const handleDateFilter = (startDate, endDate, page) => {
    setLoading(true);
    restService.TransactionsApi.getTransactionsBySearchTerms(
      moment(startDate).format("YYYY-M-D"),
      moment(endDate).format("YYYY-M-D"),
      "",
      "",
      "",
      "",
      "",
      agentId,
      "",
      "",
      page ?? 0
    )
      .then((response) => {
        setPageTo(response.data?.data?.to);
        setTotalEntries(response.data?.data?.total);
        setTransactions(response.data?.data?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const applyFilter = (values) => {
    setShowModal(false);
    setLoading(true);
    setFromSortModal(true);

    let startDate = values.dateRange
      ? values.dateRange[0].format("YYYY-MM-DD")
      : null;
    let endDate = values.dateRange
      ? values.dateRange[1].format("YYYY-MM-DD")
      : null;

    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);

    if (newStartDate) {
      setDateRange([
        { ...dateRange[0], startDate: newStartDate, endDate: newEndDate },
      ]);
    }

    const { sendAmount, receiveAmount, sendCurrency, receiveCurrency, status } =
      values;
    restService.TransactionsApi.getTransactionsBySearchTerms(
      startDate,
      endDate,
      sendAmount,
      receiveAmount,
      sendCurrency,
      receiveCurrency,
      status
    )
      .then((response) => {
        setPageTo(response.data?.data?.to);
        setTotalEntries(response.data?.data?.total);
        setTransactions(response.data?.data?.data);
        setFromSortModal(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const [paginateDateFilter, setPaginateDateFilter] = useState(false);
  const pageChange = (page) => {
    setCurrentPage(page);

    if (paginateDateFilter) {
      handleDateFilter(page);
    } else {
      fetchTransactions(agentId, page);
    }
  };

  const [selectedTransactions, setSelectedTransactions] = useState([]);

  const exportTransactions = () => {
    let headers = [
      "ID,Sender,Receiver,Send Amount,Receive Amount,Charge,Status,Date",
    ];

    let transactionCsv = transactions.reduce((acc, transaction) => {
      const {
        id,
        sender: { firstname: senderFirstName, lastname: senderLastName },
        receiver: { firstname: receiverFirstName, lastname: receiverLastName },
        send_amount,
        source_currency,
        destination_currency,
        receive_amount,
        charge,
        transaction_status: { status },
        created_at,
      } = transaction;
      acc.push(
        [
          id,
          `${senderFirstName} ${senderLastName}`,
          `${receiverFirstName} ${receiverLastName}`,
          `${source_currency}${send_amount}`,
          `${destination_currency}${receive_amount}`,
          `${source_currency}${charge}`,
          status,
          moment(new Date(created_at)).format("MMMM DD YYYY"),
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...transactionCsv].join("\n"),
      fileName: `Transaction-${moment().format("YYYY-MM-DD-H-m-s")}.csv`,
      fileType: "text/csv",
    });
  };

  const searchTransactionByInput = async (input) => {
    const response = await restService.TransactionsApi.getTransactionsBySearchTerms(
        null,
        null,
        "",
        "",
        "",
        "",
        "",
        agentId,
        "",
        "",
        0
    );
    const transactions = await response?.data?.data?.data;
    setTransactions(transactions || []);
  };

  return (
    <div className="mt-13-important ">
      <TransactionStyles>
        <PageTitle title={`Transaction History`} />

        <div className="content-layout ">
          <div className="header-layout gap-2">
            <div className="flex md:items-center w-full gap-2 md:flex-row flex-col ">
                <Search
                  placeholder={"Search Transaction"}
                  onChange={(e) => searchTransactionByInput(e.target.value)}
                />

              <DateRangePicker
                range={dateRange}
                show={showDateRange}
                setShow={setShowDateRange}
                setRange={setDateRange}
                handleSelectedRange={({ startDate, endDate }) => {
                  setPaginateDateFilter(true);
                  handleDateFilter(startDate, endDate);
                }}
              />
            </div>

            <div className=" intro-y flex flex-col-reverse w-full justify-end sm:flex-row md:items-center">
              <div className="intro-y gap-4 flex justify-end md:justify-end sm:flex-row items-center md:mr-3">
                <button
                  onClick={(e) => {
                    setShowModal(true);
                  }}
                  className="btn btn-secondary flex items-center gap-2"
                >
                  <span>
                    <svg
                      width="13"
                      height="9"
                      viewBox="0 0 13 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.312988 0.919434H12.313V2.3312H0.312988V0.919434ZM2.31299 4.21355H10.313V5.62532H2.31299V4.21355ZM4.71299 7.50767H7.91299V8.91943H4.71299V7.50767Z"
                        fill="#0042C6"
                      />
                    </svg>
                  </span>
                  <span>Sort by</span>
                </button>

                <button
                  className="btn btn-primary-2  flex items-center gap-2 text-xs"
                  onClick={exportTransactions}
                >
                  <span>
                    <svg
                      width="9"
                      height="11"
                      viewBox="0 0 9 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.77902 4.4763C1.63547 4.4763 1.4978 4.53332 1.3963 4.63482C1.2948 4.73633 1.23777 4.87399 1.23777 5.01754V9.70832C1.23777 9.85187 1.2948 9.98954 1.3963 10.091C1.4978 10.1925 1.63547 10.2496 1.77902 10.2496H7.19146C7.33501 10.2496 7.47267 10.1925 7.57418 10.091C7.67568 9.98954 7.7327 9.85187 7.7327 9.70832V5.01754C7.7327 4.87399 7.67568 4.73633 7.57418 4.63482C7.47267 4.53332 7.33501 4.4763 7.19146 4.4763H6.28939C6.0901 4.4763 5.92856 4.31475 5.92856 4.11547C5.92856 3.91619 6.0901 3.75464 6.28939 3.75464H7.19146C7.5264 3.75464 7.84763 3.88769 8.08447 4.12453C8.32131 4.36137 8.45436 4.6826 8.45436 5.01754V9.70832C8.45436 10.0433 8.32131 10.3645 8.08447 10.6013C7.84763 10.8382 7.5264 10.9712 7.19146 10.9712H1.77902C1.44407 10.9712 1.12285 10.8382 0.886009 10.6013C0.649169 10.3645 0.516113 10.0433 0.516113 9.70832V5.01754C0.516113 4.6826 0.649169 4.36137 0.886009 4.12453C1.12285 3.88769 1.44407 3.75464 1.77902 3.75464H2.68109C2.88037 3.75464 3.04192 3.91619 3.04192 4.11547C3.04192 4.31475 2.88037 4.4763 2.68109 4.4763H1.77902Z"
                        fill="white"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.84613 0.86792C5.04541 0.86792 5.20696 1.02178 5.20696 1.21157V6.91122L6.39513 5.77963C6.53604 5.64543 6.76451 5.64543 6.90542 5.77963C7.04633 5.91383 7.04633 6.13142 6.90542 6.26562L5.10127 7.98386C4.96036 8.11806 4.7319 8.11806 4.59098 7.98386L2.78684 6.26562C2.64592 6.13142 2.64592 5.91383 2.78684 5.77963C2.92775 5.64543 3.15621 5.64543 3.29713 5.77963L4.4853 6.91122V1.21157C4.4853 1.02178 4.64685 0.86792 4.84613 0.86792Z"
                        fill="white"
                      />
                    </svg>
                  </span>

                  <span>Export CSV </span>
                </button>
              </div>
            </div>
          </div>

          {showModal && (
            <FilterModal
              visible={showModal}
              onCreate={applyFilter}
              onCancel={() => {
                setShowModal(false);
              }}
            />
          )}

          <div className="header-layout gap-2">
            {!loading ? (
              <div className="flex w-full flex-col">
                <Table2
                  // headData={head}
                  tHead={
                    <>
                      <th>
                        <input
                          type="checkbox"
                          onChange={({ target }) => {
                            const checked = target.checked;

                            if (!checked) {
                              setSelectedTransactions([]);
                              return;
                            }
                            setSelectedTransactions(transactions);
                          }}
                          checked={
                            selectedTransactions?.length > 0 &&
                            selectedTransactions?.length ===
                              transactions.length &&
                            selectedTransactions?.map((x) =>
                              transactions?.every((y) => y?.id === x?.id)
                            )
                          }
                          name=""
                          value=""
                        />
                      </th>
                      <th className="">Sender</th>
                      <th className="">Recipient</th>
                      <th className="">Transaction ID</th>
                      <th className="">Amount Sent</th>
                      <th className="">Receive Amount</th>
                      <th className="">Date</th>
                      <th className="">Partner</th>
                      <th className="text-center">Status</th>
                      <th className=""></th>
                    </>
                  }
                >
                  {transactions.map((item, index) => (
                      <tr key={index} className="text-[#999999] font-normal">
                        <td>
                          <input
                              onChange={({target}) => {
                                const checked = target.checked;

                                if (!checked) {
                                  const update = selectedTransactions
                                      ?.slice()
                                      ?.filter((x) => x.id !== item.id);

                                  setSelectedTransactions(update);
                                  return;
                                }
                                setSelectedTransactions([
                                  ...selectedTransactions,
                                  item,
                                ]);
                              }}
                              checked={selectedTransactions?.find(
                                  (x) => x.id === item.id
                              )}
                              type="checkbox"
                              name=""
                              value=""
                          />
                        </td>
                        <td>
                          <Link
                              to={`/sender/${item.sender.id}`}
                              className="flex items-center gap-2"
                          >
                          <span>
                          {item?.sender?.profile_image ?

                              <img
                                  src={item?.sender?.profile_image}
                                  alt=""
                                  className="w-[30px] h-[30px] object-cover rounded-full"
                              />
                              :
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                   fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                   strokeLinejoin="round" className="feather feather-user">
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                              </svg>
                          }
                          </span>

                            <span
                                title={`${item.sender.firstname} ${item.sender.lastname}`}
                                className="text-black font-medium  whitespace-nowrap"
                            >
                            {`${item.sender.firstname} ${item.sender.lastname}`}
                          </span>
                          </Link>
                        </td>
                        <td>
                          <Link
                              to={`/receiver/${item.receiver.id}`}
                              className="whitespace-nowrap font-normal"
                          >
                            {`${item.receiver.firstname} ${item.receiver.lastname}`}
                          </Link>
                        </td>
                        <td>
                          <p className="">{item.id}</p>
                        </td>
                        <td>
                          <p className="font-medium text-black">
                            {" "}
                            {item?.source_currency}{" "}
                            {Number(item?.send_amount)?.toFixed(2)}
                          </p>
                        </td>
                        <td>
                          <p className="font-medium text-black">
                            {" "}
                            {item?.destination_currency}{" "}
                            {Number(item?.receive_amount)?.toFixed(2)}
                          </p>
                        </td>
                        <td>
                          <p className="">
                            {moment(new Date(item.created_at)).format(
                                "MMMM DD, YYYY"
                            )}
                          </p>
                        </td>
                        <td>
                          <p className="">{item?.partner?.name}</p>
                        </td>
                        <td>
                          <p
                              className={`rounded-[100px] status-${item?.transaction_status?.status?.toLowerCase()} text-center text-black px-2 py-1.5`}
                          >
                            {item?.transaction_status?.status}
                          </p>
                        </td>
                        <td>
                          <Link to={`/transaction/${item.id}`}>
                            <svg
                                className="cursor-pointer"
                                width="16"
                                height="10"
                                viewBox="0 0 16 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                  d="M7.8418 0C4.43271 0 1.52134 2.07333 0.341797 5C1.52134 7.92667 4.43271 10 7.8418 10C11.2509 10 14.1623 7.92667 15.3418 5C14.1623 2.07333 11.2509 0 7.8418 0ZM7.8418 8.33333C5.95998 8.33333 4.43271 6.84 4.43271 5C4.43271 3.16 5.95998 1.66667 7.8418 1.66667C9.72361 1.66667 11.2509 3.16 11.2509 5C11.2509 6.84 9.72361 8.33333 7.8418 8.33333ZM7.8418 3C6.70998 3 5.79634 3.89333 5.79634 5C5.79634 6.10667 6.70998 7 7.8418 7C8.97361 7 9.88725 6.10667 9.88725 5C9.88725 3.89333 8.97361 3 7.8418 3Z"
                                  fill="#ACACAC"
                              />
                            </svg>
                          </Link>
                        </td>
                      </tr>
                  ))}
                </Table2>

                {transactions.length < 1 && (
                    <div className="flex items-center justify-center h-[30vh]">
                    <Empty />
                  </div>
                )}
              </div>
            ) : (
              <div className="flex w-full flex-col">
                <Table2
                  // headData={head}
                  tHead={
                    <>
                      <th>
                        <input type="checkbox" name="" value="" />
                      </th>
                      <th className="">Sender</th>
                      <th className="">Recipient</th>
                      <th className="">Transaction ID</th>
                      <th className="">Amount Sent</th>
                      <th className="">Status</th>
                      <th className=""></th>
                    </>
                  }
                  // renderHead={(item, index) => renderHeadData(item, index)}
                >
                  {Array(5)
                    .fill()
                    .map((item, index) => (
                      <tr key={index}>
                        {Array(7)
                          .fill()
                          .map((x, i) => (
                            <td key={i}>
                              <SkeletonElement type={"title"} />
                            </td>
                          ))}
                      </tr>
                    ))}
                </Table2>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-4 justify-between md:items-center md:flex-row mt-8">
          <div className="page text-[#999999] text-xs">
            {loading
              ? ""
              : transactions && transactions.length <= 0
              ? "No Transactions Found"
              : `Showing  ${pageTo} out of ${totalEntries} results`}
          </div>

          <div className="mt-3 mb-[10vh] flex justify-end float-right">
            <PaginationComponent
              current={currentPage}
              defaultCurrent={currentPage}
              showSizeChanger={false}
              onChange={pageChange}
              // total={500}
              total={totalEntries}
            />
          </div>
        </div>
      </TransactionStyles>
    </div>
  );
};

export default Transact;
