import React, { useEffect, useState } from "react";
import Modal2 from "../Modal/Modal2";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { Steps, message } from "antd";
import Loader from "../Loader";
import Success from "./Success";
import { useSelector } from "react-redux";
import restService from "../../../services/restService";
import { useToasts } from "react-toast-notifications";
import RestService from "../../../services/rest-service";
import { parsePhoneNumber } from "react-phone-number-input";

const keys = [
  "firstname",
  "lastname",
  "msisdn",
  "country_id",
  "address",
  "agent_role",
  "agency_id",
];
const AddNewAgent = ({ show, refetchData, setShow }) => {
  const { addToast } = useToasts();

  const user = useSelector((state) => state.auth?.user);

  const [current, setCurrent] = useState(0);

  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    othernames: "",
    email: "",
    calling_code: "",
    msisdn: "",
    country_id: "",
    address: "",
    agent_role: "",
    agency_id: "",
    phone_number_prefix: user?.agency?.country?.iso_3166_2,
  });
  const [confirmation, setConfirmation] = useState(false);
  const [roles, setRoles] = useState([]);
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [callback, setCallback] = useState(() => {});
  const [loading, setLoading] = useState(false);
  const [agentAdded, setAgentAdded] = useState(false);

  useEffect(() => {
    restService.UtilsApi.getCountries().then(({ data }) => {
      const callingCode = data?.data.find(
        (country) => country.name === user.country?.name
      )?.calling_code;
      const countryId = data?.data.find(
        (country) => country.name === user.agency?.country?.name
      )?.id;

      restService.AgencyApi.getAgencies().then(({ data: agencyData }) => {
        const agencyId = agencyData?.data?.data?.[0]?.id;

        restService.UtilsApi.getAgentRoles().then(({ data: rolesData }) => {
          const agentRole = rolesData?.data?.[0]?.name;

          setForm((prevForm) => ({
            ...prevForm,
            calling_code: callingCode,
            country_id: countryId,
            agency_id: agencyId,
            agent_role: agentRole,
          }));

          setCountries(data?.data);
          setAgencies(user?.isAdmin ? agencyData?.data?.data : [user?.agency]);
          setRoles(rolesData?.data);
        });
      });
    });
  }, []);

  const next = () => {
    if (validateForm()) {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleSubmit = (data) => {
    //Api request to save agent
    if (validateForm()) {
      setLoading(true);
      // data.agency_id = user?.agency?.id ;
      restService.AgentApi.addAgent(data)
        .then((response) => {
          addToast("Successfully Added Agent", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          //  setRedirect(true);
          setAgentAdded(true);
        })
        .catch((err) => {
          addToast("Failed to add agent", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const validateForm = () => {
    let errorList = {};
    keys.forEach((key) => {
      // TODO validate phone numbers
      if (!form[key]) {
        errorList[key] = "This field is required.";
      } else {
        delete errorList[key];
        if (key === "email") {
          const regex = /\S+@\S+\.\S+/;
          if (!regex.test(form[key])) {
            errorList[key] = "Enter a valid email.";
          }
        }
      }
    });
    setErrors(errorList);

    return Object.keys(errorList).length === 0;
  };

  const onFormChange = ({ target }) => {
    let details = { ...form };
    details[target.name] = target.value;
    let errorList = errors;
    delete errorList[target.name];
    setErrors(errorList);

    if (target.name === "country_id") {
      const country = countries.filter((c) => c.id === target.value)[0];
      details.calling_code = country?.calling_code;
    }
    setForm(details);
  };

  const countryOptions = countries.map((country) => {
    country.label = `${country.name}`;
    return country;
  });

  const onPhoneNumberChange = (value) => {
    const calling_code = parsePhoneNumber(String(value));
    // let msisdn = value ? value?.trim()?.slice(calling_code?.length + 1) : "";
    // const country = countries.filter(
    //   (c) => c.iso_3166_2 == calling_code?.country
    // )[0];

    setForm({
      ...form,
      calling_code: calling_code?.countryCallingCode ?? "",
      msisdn: value,
    });
  };

  const steps = [
    {
      title: "",
      content: (
        <Step1
          countries={countries}
          errors={errors}
          setForm={setForm}
          onPhoneNumberChange={onPhoneNumberChange}
          onFormChange={onFormChange}
          form={form}
          next={next}
          prev={prev}
          user={user}
          roles={roles}
          agencies={agencies}
        />
      ),
    },
    {
      title: "",
      content: <Step2 data={form} handleSubmit={handleSubmit} prev={prev} />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <>
      {loading ? <Loader /> : null}
      <Modal2
        onHide={() => {
          // if (agentAdded) {
          //   setShow(false);
          // }

          setShow(false);
          setCurrent(0);
        }}
        size={agentAdded ? "small" : "lg"}
      >
        {loading ? (
          ""
        ) : (
          <>
            {agentAdded ? (
              <div className="h-[50vh] intro-y flex items-center justify-center">
                <Success />
              </div>
            ) : (
              <div className="p-8 intro-y flex flex-col gap-4 ">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    if (current === 0) {
                      setShow(false);
                      return;
                    }

                    prev();
                  }}
                >
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.14446 1.14446L3.09818 5.19074H12.4741V6.80926H3.09818L7.14446 10.8555L6 12L0 6L6 0L7.14446 1.14446Z"
                      fill="black"
                    />
                  </svg>
                </span>

                <div className="md:max-w-[7vw] mx-auto">
                  <Steps
                    direction="horizontal"
                    current={current}
                    items={items}
                  />
                </div>
                <div>{steps[current].content}</div>
              </div>
            )}
          </>
        )}
      </Modal2>
    </>
  );
};

export default AddNewAgent;
