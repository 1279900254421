import React from "react";
import {Link} from "react-router-dom";

const defaultImg =
  "https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png";
const SenderCard = ({sender}) => {
  const fullImage =
    sender?.identifications?.find((x) =>
      x?.identification_category?.name?.toLowerCase()?.includes("full image")
    )?.resource_path ?? defaultImg;

  return (
    <div className="flex flex-col min-w-[150px] border border-[#EFEFEF] gap-3 h-full w-full sm:w-auto items-start rounded-md bg-white p-3">
      <span>
        <img
          src={fullImage}
          alt=""
          className="w-[30px] h-[30px] object-cover rounded-full"
        />
      </span>

      <Link to={`/sender/${sender?.id}`} className="text-black font-medium">
        {sender?.firstname} {sender?.lastname}
      </Link>
      <p
        title={sender?.email}
        className="text-black truncate w-[130px] font-normal text-xs min-h-[16px]"
      >
        {" "}
        {sender?.email}
      </p>

      <Link
        to={`/sender/${sender?.id}`}
        className="btn btn-secondary flex items-center gap-2"
      >
        Profile
      </Link>
    </div>
  );
};

export default SenderCard;
